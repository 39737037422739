import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import {
    AuthAlertsManager,
    emailConfirmationValidation,
    checkName,
    emailValidation,
    FormWrapper,
    MainWrapper,
    passwordValidation,
    StyledInput,
    SubmitButton, FormHeader, phoneValidation, MoveTo
} from '../AuthHelpers';
import Spinner from "../../../layout/Spinner";
import {useSelector} from "react-redux";
import styled from "styled-components";
import { LOGIN } from '../../../../utils/globals';

const Navigations = () => {
    return (
        <>
            <MoveTo
                to={LOGIN}
                text="יש לך כבר משתמש? התחבר"
            />
        </>
    );
};


const Text = styled.div`
  margin: auto;
  //margin: -5px 0;
  color: gray;
  font-size: 14px;
  font-family: 'Rubik', Sens-Serif, serif;
`;


const ProRegister = ({onProRegister}) => {

    const [loading, setLoading] = useState(false)
    const {error} = useSelector(state => state.auth);
    const {success} = useSelector(state => state.auth);
    const [show, setShow] = useState(true);

    useEffect(() => {
        setLoading(false)
    }, [error, success])

    const hidePassHelper = (bool) => {
        setShow(bool);
    }
    return (
        <MainWrapper>
            <FormHeader text="הרשמת מטפלים"/>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    profession: '',
                    degree: '',
                    expertie: '',
                    experience: '',
                    email: '',
                    confirmEmail: '',
                    localPhone: '',
                    internationalPhone: '',
                    password: '',
                }}
                validate={values => {
                    const errors = {};
                    checkName(errors, values.firstName, values.lastName);
                    emailValidation(errors, values.email);
                    emailConfirmationValidation(errors, values.email, values.confirmEmail);
                    phoneValidation(errors, values.localPhone);
                    passwordValidation(errors, values.password);
                    hidePassHelper(false);
                    return errors;
                }}
                onSubmit={
                    data => {
                        setLoading(true)
                        onProRegister(data)
                    }}
            >
                {() => (
                    <FormWrapper>
                        <StyledInput name="firstName" type="input" placeholder="שם פרטי" label="שם פרטי"/>
                        <StyledInput name="lastName" type="input" placeholder="שם משפחה" label="שם משפחה"/>
                        <StyledInput name="profession" type="input" placeholder="עיסוק" label="עיסוק"/>
                        {/* <StyledInput name="expertie" type="input" placeholder="התמחות" label="התמחות"/> */}
                        <StyledInput name="email" type="input" placeholder='דוא"ל' label='דוא"ל'/>
                        <StyledInput name="confirmEmail" type="input" placeholder='אשר דוא"ל' label='אשר דוא"ל'/>
                        <StyledInput name="localPhone" type="input" placeholder="דוגמה: 0512345678"
                                     label="מס' טלפון נייד"/>
                        <StyledInput name="password" type="password" placeholder="סיסמה" label="סיסמה" />
                        {show ? <>
                                <Text>הסיסמה צריכה להחיל 8 תווים עם </Text>
                                <Text>לפחות ספרה אחת ואות אחת באנגלית</Text>
                            </>
                            :
                            null
                        }
                        {loading ? <Spinner/> : <SubmitButton name="submit" text="הרשם"/>}


                    </FormWrapper>
                )}
            </Formik>
            <Navigations />
            <AuthAlertsManager/>
        </MainWrapper>
    )
}

export default ProRegister
