import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getAllProfessionals,
  presentCurrentProfessional,
} from "../../../../actions/ProfessionalsActions";
import CategoriesHeader from "../CategoriesHeader";
import Professionals from "../../professionals/Professionals";
import Spinner from "../../../layout/Spinner";
import {
  OPEN_PROFESSIONAL_DIALOG,
  KIDSANDYOUTH,
  FAMILY,
  WORKCAREER,
  EATINGDISORDERS,
  DEPRESSIONANXIETY,
} from "../../../../utils/globals";
import BlackLogicIcon from "../../../../static/images/geniePictures/BlackLogicIcon.png";
import { openDialog } from "../../../../actions/DialogActions";
import { getCurrentRecommendations } from "../../../../actions/RecommendationsActions";
import styled from "styled-components";

const Paragraph = styled.div`
  padding: 10px;
  margin: 10px;
`;
const Container = styled.p`
  margin-right: 4px;
  padding: 0;
  font-family: "Rubik", Sens-Serif;
  justify-content: center;
  align-content: center;
  display: grid;
  direction: rtl;
`;

const SearchPage = ({ location }) => {
  const { allProfessionals, professionalsLoading } = useSelector(
    (state) => state.professionals
  );
  const { scheduleLoading } = useSelector(
    (state) => state.schedule
  );
  const dispatch = useDispatch();
  const currentQuery = new URLSearchParams(window.location.search);

  const query = currentQuery.get("name");

  useEffect(() => {
    //Fetch all the professionals list
    dispatch(getAllProfessionals());
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("expert")) {
      const professionalId = searchParams.get("expert");
      onLearnMorePress(professionalId);
    }
    // eslint-disable-next-line
  }, [location.search]);
  

  const onLearnMorePress = (professionalId) => {
    dispatch(presentCurrentProfessional(professionalId));
    dispatch(getCurrentRecommendations(professionalId));
    dispatch(openDialog(OPEN_PROFESSIONAL_DIALOG));
  };




  const filterProfessionals = () => {
    const filtered = [];

    /**
     * Prevents duplicates
     * @param professional
     */
    filtered.specialPush = function (professional) {
      if (!filtered.includes(professional)) filtered.push(professional);

    };

    /**
     * If some category matches the query , add it to the filtered array
     * Search Categories are :
     * First name
     * Last name
     * full name
     * Expertise headers
     * Catagory
     */
    allProfessionals.forEach((professional) => {
      let fullName = `${professional.firstName} ${professional.lastName} `;
      if (fullName.includes(query)) {
        filtered.specialPush(professional);
      }

      if (
        professional.expertise.firstExpertie.header.includes(query) ||
        professional.expertise.secondExpertie.header.includes(query) ||
        professional.expertise.thirdExpertie.header.includes(query)
      ) {
        filtered.specialPush(professional);
      }

      if (professional.experience.defaultExperience.includes(query)) {
        filtered.specialPush(professional);
      }

      if (query === "עברית" && professional.languages.hebrew)
        filtered.specialPush(professional);

      if (query === "צרפתית" && professional.languages.france)
        filtered.specialPush(professional);

      if (query === "רוסית" && professional.languages.russian)
        filtered.specialPush(professional);

      if (
        (query === "אנגלית" || query === "english") &&
        professional.languages.english
      )
        filtered.specialPush(professional);

      if (query === "ערבית" && professional.languages.arabic)
        filtered.specialPush(professional);

      if (translateType(professional.type).includes(query))
        filtered.specialPush(professional);
      if (translateType(professional.secondaryType).includes(query))
        filtered.specialPush(professional);
    });
    return filtered;
  };

  return (
    <div>
      <CategoriesHeader icon={BlackLogicIcon} label="חיפוש מטפלים" />

      {professionalsLoading || scheduleLoading ? (
        <Spinner />
      ) : //if there are no professionals today
        filterProfessionals().length === 0 ? (
          <>
            <Container>
              <Paragraph>לא נמצאו מטפלים בחיפוש!</Paragraph>
            </Container>
            <Container>
              <img
                src="https://img.icons8.com/material-rounded/96/000000/user-not-found.png"
                style={{ justifyContent: "center" }}
                alt={""}
              />
            </Container>
          </>
        ) : (
          <Professionals profObject={filterProfessionals()} />
        )}
    </div>
  );
};

export default SearchPage;

export const translateType = (type) => {
  switch (type) {
    case KIDSANDYOUTH:
      return "ילדים ונוער";
    case FAMILY:
      return "משפחה וזוגיות";
    case WORKCAREER:
      return "עבודה וקריירה";
    case EATINGDISORDERS:
      return "הפרעות אכילה";
    case DEPRESSIONANXIETY:
      return "דכאון וחרדה";
    default:
      return 'אחר';
  }
};