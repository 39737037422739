import React, {useEffect, useState} from 'react';
import { Formik } from 'formik';
import {
    AuthAlertsManager,
    emailValidation,
    FormHeader,
    FormWrapper,
    MainWrapper,
    MoveTo,
    StyledInput,
    SubmitButton
} from '../AuthHelpers';
import {RESET_PASSWORD, USER_REGISTER} from '../../../../utils/globals';
import Spinner from "../../../layout/Spinner";
import {useSelector} from "react-redux";
const Navigations = () => {
    return (
        <>
            <MoveTo
                to={RESET_PASSWORD}
                text="שכחת סיסמה? אפס"
            />
            <MoveTo
                to={USER_REGISTER}
                text="אין לך משתמש? הרשם"
            />
        </>
    );
};

const LoginForm = ({ onLogin }) => {
    const [loading,setLoading] = useState(false)
    const { error } = useSelector(state => state.auth);
    const { success } = useSelector(state => state.auth);

    useEffect(()=>{
        setLoading(false)
    },[error,success])
    return (
        <MainWrapper>
            <FormHeader text="התחבר ל- OpenMynd" />
            <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                    const errors = {};
                    emailValidation(errors, values.email);
                    return errors;
                }}
                onSubmit={data => {
                    setLoading(true)
                    onLogin(data);
                }}
            >
                {() => (
                    <FormWrapper>
                        <StyledInput name="email" type="input" placeholder='דוא"ל' label='דוא"ל' />
                        <StyledInput name="password" type="password" placeholder="סיסמה" label="סיסמה" />
                        <div className="d-flex justify-content-center">
                            {loading? <Spinner/> : <SubmitButton
                                name="submit"
                                text="התחבר"
                            />}
                        </div>

                    </FormWrapper>
                )}
            </Formik>
            <Navigations />
            <AuthAlertsManager />
        </MainWrapper>
    )
}

export default LoginForm
