import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { closeDialog, openDialog } from '../../actions/DialogActions';
import { getProfessionalAssets } from '../../actions/StorageActions';
import { OPEN_VIDEO_DIALOG, VIDEO_BOX } from '../../utils/globals';
import Spinner from '../layout/Spinner';
import Dialog from '@material-ui/core/Dialog';
import { routeQuery } from '../../utils/routes';
import { withRouter } from 'react-router-dom';
import SettingsBar from "../professionalZone/calander/SettingsBar";

const MainWrapper = styled.div`
    width: auto;
    height: auto;
    background-color: #F0F0F0;
    align-items: 'center';
`;

// const isMobile = window.innerWidth < 578;

const videoSize ={
    width:  '100%',
    height: '550px',
};

const VideoModal = ({ history }) => {
    
    const { professionalToPresent } = useSelector(state => state.professionals); 
    const { videoDialog } = useSelector(state => state.dialogs);
    const { id, video } = professionalToPresent;
    const [ asset, setAsset ] = useState('');

    useEffect(() => {
        let isMounted = true;
        const getVideo = async () => {
            const videoURL = 
            await getProfessionalAssets(
                id, 
                "professionals", 
                "video", 
                video.presentationVideo.stringName
            );
            isMounted && setAsset(videoURL);
        };
        !!video && getVideo();
        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line
    }, [professionalToPresent])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const boxToPresent = urlParams.get('box');
        if (boxToPresent === VIDEO_BOX) {
            !videoDialog && dispatch(openDialog(OPEN_VIDEO_DIALOG));
        }
        // eslint-disable-next-line
    }, [window.location.search])

    const dispatch = useDispatch();

    // Cleaning state and closing dialog.
    const onDialogClose = () => {
        dispatch(closeDialog(OPEN_VIDEO_DIALOG));
        history.replace(routeQuery({box: undefined}));
    };

    return (
        
        <Dialog 
            open={videoDialog}
            maxWidth='xl'
        >   
            <MainWrapper>
                <SettingsBar
                    label={""}
                    onExit={onDialogClose}
                  />


                {!professionalToPresent ? <Spinner /> :
                    <video 
                        src={asset} 
                        
                    style={videoSize}

                        
                        controls
                        autoPlay
                    >  
                    </video>
                }

            </MainWrapper>

        </Dialog>

    )
}

export default withRouter(VideoModal)
