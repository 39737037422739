import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const iconStyle = {
    width: '32px',
    height: '32px',
}

export const professionalsIcons = [
    {
        icon: 'https://img.icons8.com/wired/64/000000/business-contact.png',
        label: 'פרטי התקשרות',
        screenToRender: 1,
    },
    {
        icon: 'https://img.icons8.com/dotty/80/000000/add-list.png',
        label: 'עיסוק והתמחויות',
        screenToRender: 2,
    },
    {
        icon: 'https://img.icons8.com/ios/50/000000/language.png',
        label: 'שפות ורשתות חברתיות',
        screenToRender: 3,
    },
    {
        icon: 'https://img.icons8.com/wired/64/000000/diploma.png',
        label: 'תארים, הסמכות ורישיונות',
        screenToRender: 4,
    },
    {
        icon: 'https://img.icons8.com/ios/50/000000/personal-growth.png',
        label: 'ניסיון מקצועי',
        screenToRender: 5,
    },

    {
        icon: 'https://img.icons8.com/wired/64/000000/money.png',
        label: 'מחיר',
        screenToRender: 6,
    },
    {
        icon: 'https://img.icons8.com/dotty/80/000000/selfie.png',
        label: 'תמונת פרופיל וסרטון וידאו',
        screenToRender: 7,
    },
];

export const usersIcons = [
    {
        icon: 'https://img.icons8.com/wired/64/000000/business-contact.png',
        label: 'פרטי התקשרות',
        screenToRender: 1,
    },
];

// const iconsContainer = {
//     initial: { opacity: 0 },
//     animate: {
//         opacity: 1,
//         transition: {
//             staggerChildren: 0.3
//         },
//     }
// }

const iconItem = {
    initial: { opacity: 0 },
     animate: { opacity: 1 }
}

const MainWrapper = styled.div`
    display: grid;
    justify-items: flex-start;
    align-items: center;
    grid-gap: 5px;
    direction: rtl;
`;

const IconsWrapper = styled(motion.div)`
    margin-right: 20px;
`;

const IconGroup = styled(motion.div)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin: 5px;
`;

const IconLabel = styled.p`
    font-family: 'Rubik', Sens-Serif;
    margin-right: 10px;
`;

const Option = ({ iconProperties, onClick }) => {
    const { icon, label, screenToRender } = iconProperties;
    return (
        <IconGroup
            onClick={() => onClick(screenToRender)}
            variants={iconItem}
        >
            <img 
                src={icon}
                alt="icon"
                style={iconStyle}
            />
            <IconLabel>{label}</IconLabel>
        </IconGroup>
    )
};

const ProfessionalsOptions = ({ onClick }) => {
    return (
        <>
            {professionalsIcons.map((iconProperties, index) => 
                <Option 
                    key={index}
                    iconProperties={iconProperties}
                    onClick={onClick}
                />
            )}
        </>
    );
};

const UsersOptions = ({ onClick }) => {
    return (
        <>
            {usersIcons.map((iconProperties, index) => 
                <Option 
                    key={index}
                    iconProperties={iconProperties}
                    onClick={onClick}
                />
            )}
        </>
    );
};

const Options = ({ onClick, isProfessional }) => {
    return (
        <IconsWrapper

            initial="initial"
            animate="animate"
        >
            {
                isProfessional ?
                    <ProfessionalsOptions onClick={onClick}/> :
                    <UsersOptions onClick={onClick}/>
            }
        </IconsWrapper>
    );
};

const SettingsHome = ({ onClick, isProfessional }) => {
    return (
        <MainWrapper> 
            <Options 
                onClick={onClick}
                isProfessional={isProfessional}
            />
        </MainWrapper>
    )
}

export default SettingsHome
