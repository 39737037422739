import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import CustomAlert from '../../../../layout/CustomAlert';
import NoteTextField from '../notesEdit/NoteTextField';

import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import {updateDocument, getEntireDocument, addNewDoucment} from '../../../../../actions/FirestoreActions';
import {LEAD_BLUE} from '../../../../../utils/globals';

const Bold = styled.span`
    color: ${LEAD_BLUE};
`;

const Text = styled(Typography)`
    direction: rtl;
    font-family: 'Rubik', Sens-Serif;
    text-align: right;
`;

const ShowNotes = ({meeting, isNotesOpen, lineId, firstName}) => {

    const {show, line} = isNotesOpen;

    const [newNotes, setNewNotes] = useState('');

    const [display, setDisplay] = useState(false);
    const [alert, setAlert] = useState({
        display: false,
        message: '',
        type: ''
    });

    useEffect(() => {
        const getNote = async () => {
            if (line === lineId) {
                const res = await getEntireDocument("notes", meeting.professional.id);
                if (res.type === "success") {
                    // eslint-disable-next-line
                    Object.keys(res.data).map(meetingId => {
                        meetingId === meeting.meetingId &&
                        setNewNotes(res.data[meetingId].notes)
                    });
                } else {
                    setNewNotes('');
                }
            }
        };
        show && getNote();
        // eslint-disable-next-line
    }, [show])

    const postNewNote = async (professionalId, note) => {
        try {
            const check = await getEntireDocument("notes", meeting.professional.id);
            let res;
            if (check.type === 'success') {
                res = await updateDocument("notes", professionalId, note);
            } else {
                res = await addNewDoucment("notes", professionalId, note);
            }
            setAlert({
                display: true,
                type: res.type,
                message: res.message
            })

        } catch (error) {
            return {
                type: 'error',
                message: error
            }
        }
        ;
    };

    const onSaveClick = () => {
        setAlert({
            display: false,
            type: '',
            message: ''
        });
        setDisplay(false);
        postNewNote(meeting.professional.id,
            {[meeting.meetingId]: {notes: newNotes}});
    };

    const onChange = event => {
        setDisplay(true);
        setNewNotes(event.target.value);
    };

    // const compareDates = recoDate => {
    //     const now = new Date();
    //     const recoTime = new Date(recoDate);
    //     if (now.getTime() > recoTime.getTime()) {
    //         return true;
    //     }
    //     ;
    //     return false;
    // };

    return (

        <Collapse in={!!(show && line === lineId)}>

            <Text>
                <Bold>ההערות</Bold>
                {' '}שלך על הפגישה עם {firstName}
            </Text>


            <NoteTextField
                onChange={onChange}
                newNotes={newNotes}
                display={display}
                onSaveClick={onSaveClick}
                helperText="כתוב הערה, ולחץ שמור"
            />

            {
                alert.display && <CustomAlert
                    type={alert.type}
                    msg={alert.message}
                />
            }

        </Collapse>
    );
};

export default ShowNotes