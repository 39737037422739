import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Box, Button } from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";
import { Container } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { LinkWrapper } from "../../../authForms/AuthHelpers";
import CheckIcon from "@material-ui/icons/Check";
import {
  OPEN_ADMIN_DIALOG,
  RecoBackground,
} from "../../../../../utils/globals";
import { motion } from "framer-motion";
import { getProfessionalAssets } from "../../../../../actions/StorageActions";
import { routeQuery } from "../../../../../utils/routes";
import { setIndepedent } from "../../../../../actions/MessagesActions";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import {
  permDeletePro,
  updateFieldInCollection,
} from "../../../../../actions/FirestoreActions";
import { openDialog } from "../../../../../actions/DialogActions";
import { presentCurrentProfessional } from "../../../../../actions/ProfessionalsActions";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const ComponentsWrapper = styled(Box)`
  margin-top: 16px;
`;

const PaginationWrapper = styled(Box)`
  margin: 16px;
`;

const ProfessionalsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px 30px;
  @media only screen and (max-width: 1289px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
`;
const ImageStyle = {
  height: "60px",
  width: "60px",
  borderRadius: "50%",
};

const CardWrapper = styled.div`
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 200px;
  cursor: pointer;

  margin: 15px;
`;

const ContentWrapper = styled.div`
  padding: 0 10px;
  background-color: ${RecoBackground};
  height: 100%;
  text-align: right;
`;

const Image = styled(motion.div)`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${RecoBackground};
  object-fit: cover;
  height: auto;
`;

const Descripation = styled.p`
  direction: rtl;
  font-family: "Rubik", Sens-Serif;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
`;

const NameWrapper = styled.div`
  @media (max-width: 450px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
  }
`;

const MobileImage = styled.img`
  display: none;
  @media (max-width: 450px) {
    display: inline-block;
  }
`;

const Name = styled.p`
  text-align: center;
  font-size: 18px;
`;

const AdminActions = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: center;
`;

const Content = ({ prefix, firstName, lastName, expertise, picture }) => {
  let professionalPrefix = "";
  let mainProfession = "";
  let description = "";
  try {
    professionalPrefix = prefix.prefix;
    mainProfession = expertise.profession;
    description = expertise.firstExpertie.description;
  } catch (e) {}
  return (
    <ContentWrapper>
      <NameWrapper>
        <Name>
          {professionalPrefix} {firstName} {lastName}
        </Name>
        <MobileImage src={picture} style={ImageStyle} alt="pic" />
      </NameWrapper>
      <p>{mainProfession}</p>
      <Descripation>{description}</Descripation>
    </ContentWrapper>
  );
};

const ProfessionalsItemAdmin = ({ professionalObject, picture, onclick }) => {
  const [deleteShow, setdeleteShow] = useState(false);

  const dispatch = useDispatch();

  const setPendingToNull = async (id) => {
    try {
      const res = await updateFieldInCollection(
        "professionals",
        id,
        "pending",
        {
          status: "removed",
          message: "",
        }
      );
      if (res.type === "success") {
        setResMsg("!המטפל הוסר בהצלחה");
      } else {
        setResMsg("Error: " + res.message);
      }
    } catch (e) {
      setResMsg(e.message);
    }
  };

  const deleteProfessional = async (id) => {
    const res = await permDeletePro(id);
    setResMsg(res.msg);
  };

  const PermanentlyDelete = () => {
    return (
      <>
        האם אתה בטוח? פעולה זו בלתי ניתנת לשחזור
        <Button
          variant="contained"
          style={{ background: "RGB(84,130,53)" }}
          onClick={() => deleteProfessional(id)}
          startIcon={<CheckIcon />}
        >
          כן
        </Button>
        <Button
          variant="contained"
          style={{ background: "RGB(200,23,100)" }}
          onClick={() => setdeleteShow(false)}
          startIcon={<HighlightOffIcon />}
        >
          לא{" "}
        </Button>
      </>
    );
  };
  const [resMsg, setResMsg] = useState();
  const { id, firstName, lastName, prefix, discription, expertise, languages } =
    professionalObject;

 

  return (
    <>
      <LinkWrapper
        to={routeQuery({ expert: id })}
        replace
        onClick={() => dispatch(setIndepedent(true))}
      >
         {professionalObject.isApprovedProWhoMadeAChange? 
        <>
        מטפל זה היה מאושר ע"י המערכת
        {'\n'}
        וביצע שינוי באחד מן השדות המשפיעים על המחיר
        </>
        : ''}
        <CardWrapper onClick={() => onclick(id)}>
          <Content
            description={discription}
            prefix={prefix}
            firstName={firstName}
            lastName={lastName}
            expertise={expertise}
            languages={languages}
            picture={picture}
          />
          <Image image={picture} whileHover={{ scale: 1.1 }} />
        </CardWrapper>
      </LinkWrapper>
      <AdminActions>
        <Button
          variant="contained"
          style={{ background: "RGB(255,165,0)" }}
          onClick={() => setdeleteShow(true)}
          startIcon={<HighlightOffIcon />}
        >
          מחק
        </Button>
        {deleteShow ? (
          <PermanentlyDelete />
        ) : (
          <>
            <Button
              variant="contained"
             style={{
               background: 'RGB(200,23,100)',
               color:'white'
             }}
              onClick={() => setPendingToNull(id)}
              startIcon={<DeleteIcon />}
            >
              הסר
            </Button>

            <Button
              variant="contained"
              style={{
                background: 'RGB(32,56,100)',
                color:'white'
              }}
              onClick={async () => {
                await dispatch(presentCurrentProfessional(id));
                dispatch(openDialog(OPEN_ADMIN_DIALOG));
              }}
              startIcon={<SettingsEthernetIcon />}
            >
              עריכה
            </Button>
            
          </>
        )}

        <div>{resMsg}</div>
     
      </AdminActions>

    
    </>
  );
};

const AdminProfessionalsPresent = ({ profObject, onclick }) => {
  const [page, setPage] = useState(1);
  const [assets, setAssets] = useState([]);
  const isMounted = useRef();

  useEffect(() => {
    const req = [];
    isMounted.current = true;
    const currentPresented = profObject;
    currentPresented.forEach((profObject) => {
      try {
        let photoRef = profObject.picture.profilePicture.stringName;
        let promise = getProfessionalAssets(
          profObject.id,
          "professionals",
          "picture",
          photoRef
        );
        req.push(promise);
      } catch (e) {
        req.push(
          "https://firebasestorage.googleapis.com/v0/b/jiniz-5fc71.appspot.com/o/professionals%2F5QMZLsvUjhQudaYdn9trGZ77qWC3%2Fpicture%2FprofilePicture.png?alt=media&token=afd3b7d8-bdf3-4e23-bd49-d3fcad2d97c8"
        );
      }
    });
    const getPictures = async () => {
      const res = await Promise.all(req);
      isMounted.current && setAssets(res);
    };
    getPictures();
    return () => {
      isMounted.current = false;
    };
  }, [page, profObject]);

  // Changing the current page on page change event.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Container>
      <ComponentsWrapper align="center">
        <ProfessionalsWrapper>
          {!assets.length
            ? null
            : profObject.map((item, index) => (
                <ProfessionalsItemAdmin
                  professionalObject={item}
                  key={item.id}
                  picture={assets[index]}
                  onclick={onclick}
                />
              ))}
              
        </ProfessionalsWrapper>
      </ComponentsWrapper>
      {Math.floor(profObject.length / 16) <= 1 ? null : (
        <PaginationWrapper>
          <Pagination
            count={Math.floor(profObject.length / 3)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </PaginationWrapper>
      )}
    </Container>
  );
};

export default AdminProfessionalsPresent;
