import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../../actions/DialogActions";
import { presentCurrentProfessional } from "../../../../actions/ProfessionalsActions";
import { getCurrentRecommendations } from "../../../../actions/RecommendationsActions";
import { OPEN_PROFESSIONAL_DIALOG } from "../../../../utils/globals";

import AdminProfessionalsPresent from "./reviewForm/AdminProfessionalsPresent";

const ProfessionalsMangment = ({ isPending,title }) => {
  const dispatch = useDispatch();
  const adminReduxState = useSelector((state) => state.admin);
  const { Approved, Pending, inProgress, Suspended } = adminReduxState;
  const onLearnMorePress = (professionalId) => {
    dispatch(presentCurrentProfessional(professionalId));
    dispatch(getCurrentRecommendations(professionalId));
    dispatch(openDialog(OPEN_PROFESSIONAL_DIALOG));
  };

  function feedPros() {

    switch (isPending) {
      case true:
        return Pending
      case false:
        return Approved;
      case 'removed':
        return Suspended
      default:
        return inProgress
    }

  }

  

  return (
   <>
   <div>{title}</div>
    <AdminProfessionalsPresent
      profObject={feedPros()}
      onclick={onLearnMorePress}
    />
   </>
  )

}


export default ProfessionalsMangment;
