import React from 'react';
import styled from 'styled-components';
import { LEAD_BLUE } from "../../../../../utils/globals";
import { useSelector } from 'react-redux';
import { evaluatePrice } from '../../settingsUtils';










const MainWrapper = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    direction: rtl;
`;



const DescriptionMainWrapper = styled.div`
    ${'' /* box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.20), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.10),
              0 16px 16px rgba(0,0,0,0.05); */}
    direction: rtl;
    font-family: 'Rubik', Sens-Serif;
`;

const Header = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    text-align: right;
    margin: 5px;
    padding: 5px;
    color: ${LEAD_BLUE};
`;

const TextWrapper = styled.div`
    margin: 1px 4px;
    padding: 4px;

`;

const Text = styled.p`
     font-family: 'Roboto', sans-serif;
`;


const Description = ({ normalPrice }) => {

    return (
        <DescriptionMainWrapper>
            <Header>
                מחיר
            </Header>
            <TextWrapper>
                {/* <Text>
                    יש לך גמישות מלאה בקביעת המחיר לשעה (ליתר דיוק - 45 דקות). יחד עם זאת, ככל שהמחיר באתר יהיה אטרקטיבי יותר, כך יהיה לו משקל גדול יותר בקבלת ההחלטה של הלקוח.
                </Text> */}
                <Text>
                    המחיר לשעת מפגש  עבור 50 דקות של מפגש הוא {normalPrice} ₪

                </Text>

                <Text>
                    לקוחות יכולים לבחור לשלם מראש עבור מפגש אחד במחיר הנ״ל או עבור שלושה מפגשים בהנחה של 10%.
                </Text>

            </TextWrapper>
        </DescriptionMainWrapper>
    )
};



const EconomyForm = () => {



    const { currentProfessional } = useSelector(state => state.professionals);

    var price;
    if (currentProfessional.pending.status === false) {
        price = currentProfessional.price.normalPrice
    } else {
        price = evaluatePrice(currentProfessional)
    }



    return (
        <MainWrapper>
            <Description
                normalPrice={price} />
        </MainWrapper>
    )
}

export default EconomyForm;
