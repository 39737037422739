export const objectExtractor = form => {


  try{
    const newForm = {};
    for (const [key, value] of Object.entries(form)) {
        // Splitting by S since keys arrive as <`${keyName}`State>
        let originalKeyName = key.split("S")[0];
        if (key !== "video")
            newForm[originalKeyName] = value;
    }

    return newForm;
  }catch(e){
    //   console.log(e)
  }
};