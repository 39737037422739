import {
    GET_USER_TOKENS,
    SET_TOKENS_LOADING, CLEAR_TOKENS
} from "../actions/types"

const initalState = {
    tokens: [],
    tokensLoading: false
};

export const tokensReducer = (state = initalState, action) => {

    switch (action.type) {
        case CLEAR_TOKENS:
            return {
                tokens: [],
                tokensLoading: false
            }

        case  GET_USER_TOKENS:
            return {
                ...state,
                tokens: action.payload,
            };

        case  SET_TOKENS_LOADING:
            return {
                ...state,
                tokensLoading: action.payload
            };
        default:
            return state;

    }
}
