import React, { useState } from 'react';
import { LEAD_BLUE } from '../../../utils/globals';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles, Typography } from '@material-ui/core';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import DateRangeIcon from '@material-ui/icons/DateRange';
import styled from 'styled-components';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { priceFormatter } from './paymentHelpers';
import SettingsBar from '../../professionalZone/calander/SettingsBar';
import Checkbox from '@material-ui/core/Checkbox';
import { TableButton } from "../../modals/ProfessionalZone";
import Content from '../presentation/Content';

const useStyles = makeStyles(() => ({

    tableCell: {
        fontFamily: 'Rubik',
        padding: '10px',
        fontSize: '16px'

    },
}));

const TableBodyStyle = styled(TableBody)`
  direction: rtl;
`;

const MainContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 10px 10px;
  direction: rtl;
  font-size: 16px;
  font-family: 'Rubik', Sens-Serif;
`;

const ContentContainer = styled.div`
  display: grid;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  margin: 10px;
  width: 90%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  margin: 10px;
`;
const SpanClick = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ checkBoxState }) =>
        checkBoxState ? 'black' : 'red'
    };

`;

const Title = styled.div`
  font-family: 'Rubik', Sens-Serif;
  background-color: ${LEAD_BLUE};
  color: white;
`;

const Text = styled.p`
  text-align: right;
  margin: 0;
  padding: 8px;
  color: ${props => props.color && props.color};
`;
const LegalText = styled.p`
  text-align: right;
  margin: 0;
  padding: 8px;
  color: ${({ checkBoxState }) =>
        checkBoxState ? 'black' : 'red'
    };
`;

const MeetingsAmount = ({ meetingAmount }) => {
    return (
        meetingAmount ? 3 : 1
    );
};

// const ServiceName = ({ type }) => {

//     switch (type) {
//         case PSYCHOLOGY:
//             return 'מפגש עם פסיכולוגים ועובדים סוציאלים קליניים'
//         case MYSTICANS:
//             return 'מפגש עם מיסטיקנים'
//         case MENTORS:
//             return 'מפגש עם מנטורים ומאמנים אישיים'
//         case ALTERNATIVES:
//             return 'מפגש עם יועצים ומטפלים אלטרנטיביים'
//         default:
//             return ''

//     }

// }

const ReviewTable = ({ type, price, prefix, firstName, lastName, classes, meetingAmount }) => {
//price is null
    let amount1 = (price.normalPrice);
    if (price.coupAMT != 0) {amount1 = price.normalPrice - price.coupAMT;}
    if (price.coupPRCT != 0) {amount1 = price.normalPrice-(price.normalPrice*price.coupPRCT*0.01);}
    let amount3 = (amount1*3)-(amount1*3*0.1);
    return (
        <Table size={"small"}>
            <TableBodyStyle>
                {/* <TableRow>
                    <TableCell className={classes.tableCell} align="right">שם השירות:</TableCell>
                    <TableCell className={classes.tableCell} align="right"><ServiceName type={type} /></TableCell>
                </TableRow> */}
                <TableRow>
                    <TableCell className={classes.tableCell} align="right">שם המטפל:</TableCell>
                    <TableCell className={classes.tableCell}
                        align="right">{prefix?.prefix} {firstName} {lastName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.tableCell} align="right">מספר מפגשים:</TableCell>
                    <TableCell className={classes.tableCell} align="right"><MeetingsAmount
                        meetingAmount={meetingAmount} /></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.tableCell} align="right">סה"כ לתשלום:</TableCell>
                    {
                        meetingAmount ?
                            <TableCell className={classes.tableCell}
                                //Talea align="right">{priceFormatter((price.normalPrice*3)-(price.normalPrice*3*0.1))} ש"ח</TableCell>
                                align="right">{priceFormatter(amount3)} ש"ח</TableCell>
                            :
                            <TableCell className={classes.tableCell}
                                //Talea align="right">{priceFormatter(price.normalPrice)} ש"ח</TableCell>
                                align="right">{priceFormatter(amount1)} ש"ח</TableCell>
                    }
                </TableRow>
            </TableBodyStyle>
        </Table>
    );
};

const ReviewButton = ({ onClick, label, checkBoxState, disabled }) => {
    return (
        <Button
            style={checkBoxState ? {
                backgroundColor: LEAD_BLUE, color: 'white'
            } : {
                backgroundColor: LEAD_BLUE, opacity: 0.5, color: 'white'
            }}
            onClick={onClick}
            disabled={disabled}
            >
            {label}
        </Button> 
    );
};

const ReviewNewMeeting = ({
    prefix, firstName, lastName, price,
    type, nextStep, classes, onExit, prevStep, meetingAmount
}) => {
    const [checkBoxState, setCheckBoxState] = useState(false);

    const checkBoxChange = () => {
        setCheckBoxState(!checkBoxState)
    }
    
    return (
        <MainContainer>
            <SettingsBar label="פרטי העסקה" onExit={prevStep} />
            <ReviewTable
                type={type}
                price={price}
                prefix={prefix}
                firstName={firstName}
                lastName={lastName}
                classes={classes}
                meetingAmount={meetingAmount}
            />
            {meetingAmount ?
                <p style={{ fontFamily: "  font-family: 'Rubik', Sens-Serif", fontSize: 16, margin: '6px' }}>התשלום הוא
                    מראש לשלושת
                    המפגשים. קביעת המפגש השני והשלישי תעשה בתיאום עם המטפל בתום כל מפגש</p> : null}
            <p style={{ fontFamily: "  font-family: 'Rubik', Sens-Serif;", fontSize: 16, margin: '6px' }}>
                <LegalText
                    checkBoxState={checkBoxState}
                >
                    <Checkbox
                        checked={checkBoxState}
                        onChange={checkBoxChange}
                        color="primary"
                    />
                    קראתי ואני מאשר את <SpanClick
                        href="https://firebasestorage.googleapis.com/v0/b/jiniz-5fc71.appspot.com/o/admin%2F%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA%20%D7%A9%D7%9E%D7%99%D7%A8%D7%AA%20%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA%20OpenMynd.pdf?alt=media&token=4ea5465f-2324-4d28-879f-376df5dacaf9"
                        target="_blank"
                        checkBoxState={checkBoxState}
                    >
                        מדיניות הפרטיות
                    </SpanClick> {" "}
                    <SpanClick
                        checkBoxState={checkBoxState}
                        href="https://firebasestorage.googleapis.com/v0/b/jiniz-5fc71.appspot.com/o/admin%2F%D7%AA%D7%A0%D7%90%D7%99%20%D7%A9%D7%99%D7%9E%D7%95%D7%A9%20%D7%91%D7%90%D7%AA%D7%A8%20OpenMynd.pdf?alt=media&token=df82ae2a-9b63-41f6-9e82-556b0cd6ceaf"
                        target="_blank"
                    >
                        ותנאי השימוש
                    </SpanClick>
                    {" "} באתר
                </LegalText>
            </p>
            <ButtonWrapper>
                <ReviewButton
                    onClick={nextStep}
                    label={price.finalAMT != 0 ? "להמשך תשלום":"סיום"}
                    disabled={!checkBoxState}
                    checkBoxState={checkBoxState}
                />
            </ButtonWrapper>
        </MainContainer>
    );
};

const ReviewModify = ({ firstName, lastName, day, hour }) => {
    return (
        <MainContainer>
            <Box m={2}>
                <Title>
                    <Text>אישור פרטי שינוי מפגש:</Text>
                </Title>
            </Box>
            <ContentContainer>
                <ContentWrapper>
                    <EmojiPeopleIcon style={{ color: LEAD_BLUE }} />
                    <Box mr={3}>
                        <Typography>
                            מפגש עם: {firstName} {lastName}
                        </Typography>
                    </Box>
                </ContentWrapper>
                <ContentWrapper>
                    <DateRangeIcon style={{ color: LEAD_BLUE }} />
                    <Box mr={3}>
                        <Text>
                            {day}, {hour.start} - {hour.end}
                        </Text>
                    </Box>
                </ContentWrapper>
            </ContentContainer>
        </MainContainer>
    );
};

const Review = ({
    nextStep, prevStep, type, day, hour, handleBack,
    firstName, lastName, price, modifyAnExistingMeeting, prefix, meetingAmount
}) => {
    const classes = useStyles();
    return (
        <>
            {
                modifyAnExistingMeeting ?
                    <ReviewModify
                        firstName={firstName}
                        lastName={lastName}
                        day={day}
                        hour={hour}
                    /> :
                    <ReviewNewMeeting
                        firstName={firstName}
                        lastName={lastName}
                        price={price}
                        type={type}
                        nextStep={nextStep}
                        handleBack={handleBack}
                        prevStep={prevStep}
                        classes={classes}
                        prefix={prefix}
                        meetingAmount={meetingAmount}
                    />
            }
        </>
    )
};

export default Review
