import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { openDialog } from "../../../../actions/DialogActions";
import {
  OPEN_QUIZ_DIALOG,
  LEAD_BLUE,
  DARK_BLUE,
} from "../../../../utils/globals";
import { routeQuery } from "../../../../utils/routes";
import { Link } from "react-router-dom";

const MainWrapper = styled(Box)`
  direction: rtl;
  font-family: "Rubik", sans-serif;
  padding: 15px;
`;

const Paragraph = styled(Box)`
  margin-top: 10px;
`;

const LinkWrapper = styled(Link)`
 color: ${DARK_BLUE};
  text-decoration: none;
  cursor: pointer;
 
`;

const StyledButton = styled.button`
  height: 25px;
  width: 75px;
  border-radius: 10%;
  cursor: pointer;
  color: ${DARK_BLUE};
  border-color: ${LEAD_BLUE};
  font-family: "Rubik", Sens-Serif, serif;
  background: transparent;

  &:hover {
    opacity: 0.85;
  }
`;
const Terms = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <MainWrapper align="right">
      <Paragraph>
        ניתן לבחור כל אחד מהמטפלים המופיעים כאן או לחילופין, לבקש את עזרתנו על
        ידי מילוי שאלון קצר ואנחנו נתאים לך מטפל. למילוי השאלון{" "}
        {isAuthenticated ? (
          <LinkWrapper
            onClick={() => {
              dispatch(openDialog(OPEN_QUIZ_DIALOG));
            }}
          >
            <StyledButton>לחץ כאן</StyledButton>
          </LinkWrapper>
        ) : (
          <LinkWrapper
            to={routeQuery({box:'login'})}
          >
            <StyledButton>לחץ כאן</StyledButton>
          </LinkWrapper>
        )}
      </Paragraph>
      <Paragraph>
        אם לאחר המפגש הראשון ברצונך להחליף מטפל, אנחנו נסייע לך למצוא מטפל
        חילופי ללא חיוב נוסף על המפגש הראשון.
      </Paragraph>
    </MainWrapper>
  );
};

export default Terms;
