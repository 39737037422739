import {
    GET_PROFESSIONALS,
    CLEAR_PROFESSIONALS,
    GET_CURRENT, 
    CLEAR_CURRENT,
    SET_PROFESSIONALS_LOADING,
    UPDATE_PROFESSIONAL,
    SET_PROFESSIONALS_ERROR,
    CLEAR_PROFESSIONALS_ERROR,
    PRESENT_PROFESSIONAL,
    CLEAR_PRESENTED_PROFESSIONAL,
} from '../actions/types';

const initalState = {
    allProfessionals: [],
    currentProfessional: {},
    professionalToPresent: {},
    error: false,
    professionalsLoading: false
};


export const professionalsReducer = (state = initalState, action) => {

    switch (action.type) {

        case GET_PROFESSIONALS:
            return {
                ...state, 
                allProfessionals: action.payload,
                professionalsLoading: false,
                error: false
            };

        case UPDATE_PROFESSIONAL:
            return {
                ...state, 
                allProfessionals: state.allProfessionals.map(item => 
                    item.id === action.payload.id ? action.payload : item 
                ),
                currentProfessional: action.payload,
                professionalsLoading: false,
            };

        // Getting current professional by filtering by id
        case GET_CURRENT:
            return {
                ...state,
                currentProfessional: action.payload
            };

        case PRESENT_PROFESSIONAL:
            return {
                ...state,
                professionalToPresent: action.payload
            };

        case CLEAR_PROFESSIONALS:
            return {
                allProfessionals: [],
                currentProfessional: {},
                professionalToPresent: {},
                error: false,
                professionalsLoading: false
            };

        case CLEAR_CURRENT:
            return {
                ...state,
                currentProfessional: {}
            };

        case CLEAR_PRESENTED_PROFESSIONAL:
            return {
                ...state,
                professionalToPresent: {}
            };

        case SET_PROFESSIONALS_LOADING:
            return {
                ...state,
                professionalsLoading: action.payload
            };

        case SET_PROFESSIONALS_ERROR:
            return {
                ...state,
                error: action.payload
            };

        case CLEAR_PROFESSIONALS_ERROR:
            return {
                ...state,
                error: false
            };
        
        default:
            return state;

    };
};