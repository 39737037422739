import React, { useCallback, useEffect, useState } from "react";
import {
  VIDEO_BOX,
  BlueGraident,
} from "../../../utils/globals";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Bold, VidButton } from "../Presentation";
import { Link } from "react-router-dom";
import { routeQuery } from "../../../utils/routes";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;
  background: ${BlueGraident};
  font-family: "Rubik", Sens-Serif, serif;
  min-height: 250px;
  color: white;
  @media (max-width: 815px) {
    grid-template-columns: none;
    display: flex;
  }
`;

const AssetsWrapper = styled.div`
  display: grid;
  background-image: url(${(props) => props.profImg && props.profImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  justify-items: center;
  align-items: flex-end;
  @media (max-width: 815px) {
    width: 150px;
    height: 150px;
    margin-top: 55px;
    margin-right: 15px;
    margin-bottom: 2px;
    border-radius: 5%;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  line-height: 16px;
  width: 100%;
  padding: 10px;
`;

const DescriptionWrapper = styled.div`
  width: 90%;
  line-height: 18px;
  margin: 0px 0;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VideoButtonWrapper = styled(Link)`
  padding: 5px;
  margin: 5px;
  text-decoration: none;
  color: black;
  @media (max-width: 578px) {
    padding: 0;
    margin: 0;
  } ;
`;

// const SmallScreenVideoButtonWrapper = styled.div`
//     display: none;
//     @media (max-width: 815px) {
//         display: flex;
//         justify-content: center;
//     }
// `;

const ArrowWrapper = styled.div`
  background-color: #0f1f3d;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
`;

const DownArrow = styled(KeyboardArrowDownIcon)`
  height: 25px;
  width: 25px;
  margin-right: 2px;
  margin-top: 2px;
  color: white;
`;

const UpArrow = styled(ExpandLessIcon)`
  height: 25px;
  width: 25px;
  margin-right: 2px;
  margin-top: 2px;
  color: white;
`;

const Name = styled.div`
  font-size: 20px;
  font-weight: 600;
`;
const ProfessionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
`;

const VideoButton = () => {
  return (
    <VideoButtonWrapper to={`${routeQuery({ box: VIDEO_BOX })}`}>
      <VidButton>לחץ כאן לצפייה בסרטון</VidButton>
    </VideoButtonWrapper>
  );
};

const ExpertiesMenu = ({
  handleClick,
  anchorEl,
  handleClose,
  experties,
  handleProfessionChange,
  currentExpertie,
}) => {

  if (experties.secondExpertie.header !== "" || experties.thirdExpertie.header !=="") {
    return (
      <div>
        <ArrowWrapper onClick={handleClick}>
          {!Boolean(anchorEl) ? <DownArrow /> : <UpArrow />}
        </ArrowWrapper>
        <StyledMenu
          style={{ direction: "rtl" }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {Object.keys(experties)
            .filter((curr) => experties[curr].header !== currentExpertie)
            .map(
              (expertie, index) =>
                !!experties[expertie].header && (
                  <MenuItem
                    onClick={() => handleProfessionChange(expertie)}
                    key={index}
                  >
                    {experties[expertie].header}
                  </MenuItem>
                )
            )}
        </StyledMenu>
      </div>
    );
  } else {
    return (
      <div>
        <ArrowWrapper onClick={handleClick}>
          {!Boolean(anchorEl) ? <DownArrow /> : <UpArrow />}
        </ArrowWrapper>
        <StyledMenu
          style={{ direction: "rtl" }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>אין התמחויות נוספות</MenuItem>
        </StyledMenu>
      </div>
    );
  }
};

const Screen = ({
  prefix,
  firstName,
  lastName,
  profession,
  picture,
  handleClick,
  handleClose,
  anchorEl,
  onExit,
  expertise,
  handleProfessionChange,
  video,
  isDuplicateFound,
  duplicatedField,
}) => {
  const isMobile = window.innerWidth <= 578;

  return (
    <HeaderWrapper>
      <ContentWrapper>
        <ArrowForwardIcon onClick={onExit} style={{ cursor: "pointer" }} />
        <Name>
          {prefix && prefix.prefix} {firstName} {lastName}
          <ProfessionTitle>
            {expertise && expertise["profession"]}
          </ProfessionTitle>
        </Name>

        <DescriptionWrapper>
          <div style={{marginBottom:'7px'}}>
            <span style={{ fontWeight: "bold" }} /> <Bold>התמחות : </Bold>
            {profession.subject}
          </div>
          {profession.description}
        </DescriptionWrapper>
        {isDuplicateFound && !!duplicatedField ? (
          <>{expertise[duplicatedField]?.description}</>
        ) : (
          ""
        )}

        <MenuWrapper>
          <Bold>התמחויות נוספות:</Bold>
          {expertise && (
            <ExpertiesMenu
              handleClick={handleClick}
              handleClose={handleClose}
              anchorEl={anchorEl}
              experties={expertise}
              currentExpertie={profession.subject}
              handleProfessionChange={handleProfessionChange}
            />
          )}
        </MenuWrapper>
      </ContentWrapper>
      {isMobile ? (
        <div style={{ marginLeft: "5px" }}>
          <AssetsWrapper profImg={picture} />
          {!!video ? <VideoButton /> : ""}
        </div>
      ) : (
        <AssetsWrapper profImg={picture}>
          {!!video ? <VideoButton /> : ""}
        </AssetsWrapper>
      )}
    </HeaderWrapper>
  );
};

const Header = ({ currentProfessioanl, picture, onExit }) => {
  const { firstName, lastName, prefix, expertise, languages, video } =
    currentProfessioanl;

  const [profession, setProfession] = useState({
    subject: expertise && expertise.firstExpertie.header,
    description: expertise && expertise.firstExpertie.description,
  });

  const [currentExpShowed, setcurrentExpShowed] = useState("firstExpertie");
  const [duplicatedField, setduplicatedField] = useState("");
  const [isDuplicatedFound, setisDuplicatedFound] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfessionChange = (expertieName) => {
    setProfession({
      subject: expertise[expertieName].header,
      description: expertise[expertieName].description,
    });
    handleClose();
    setcurrentExpShowed(expertieName);
  };

  const detectDuplicated = useCallback(() => {
    setisDuplicatedFound(false);
    for (const property in expertise) {
      if (property !== "profession" && property !== currentExpShowed) {
        //validate duplicate and return which one
        if (expertise[property].header === profession.subject) {
          setduplicatedField(property);
          setisDuplicatedFound(true);
        }
      }
    }
  }, [currentExpShowed, expertise, profession.subject]);
  useEffect(() => {
    detectDuplicated();
  }, [detectDuplicated, profession]);

  return (
    <>
      <Screen
        prefix={prefix}
        firstName={firstName}
        lastName={lastName}
        profession={profession}
        languages={languages}
        picture={picture}
        dispatch={dispatch}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorEl={anchorEl}
        expertise={expertise}
        onExit={onExit}
        duplicatedField={duplicatedField}
        isDuplicateFound={isDuplicatedFound}
        handleProfessionChange={handleProfessionChange}
        video={video?.presentationVideo?.displayURL}
      />
    </>
  );
};

export default Header;
