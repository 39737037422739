import React from 'react';
import styled from 'styled-components';
import Radio from './RadioInput';
import _ from 'lodash';
import SelectInput from './SelectInput';
import { Box } from '@material-ui/core';

const Wrapper = styled(Box)`
    height: 260px;
`;

const Quiz = ({ values, handleChange, page }) => {

    return (

        <Wrapper>
            {page === 1 &&
                <Radio 
                    title="מה המין שלך?"
                    name="gender"
                    options={["גבר", "אישה"]}
                    handleChange={handleChange}
                    value={values.gender}
                    
                />
            }
            {page === 2 &&
                <SelectInput 
                    title="מה הגיל שלך?"
                    name="age"
                    handleChange={handleChange}
                    value={values.age}
                    options={_.range(20,101)}
                />
            }
            {page === 3 &&
                <Radio 
                    title="מה סטטוס הזוגיות שלך?"
                    name="status"
                    options={["רווק/ה", "נשוי/נשואה", "גרוש/ה", "אלמן/ה"]}
                    handleChange={handleChange}
                    value={values.status}
                />
            }
            {page === 4 &&
                <Radio 
                    title="האם היית בעבר בייעוץ או בטיפול?"
                    name="been"
                    options={["לא", "כן"]}
                    handleChange={handleChange}
                    value={values.been}
                />
            }
            {page === 5 &&
                <Radio 
                    title="האם את\ה סובל מבדידות?"
                    name="lonely"
                    options={["לא", "כן"]}
                    handleChange={handleChange}
                    value={values.lonely}
                />
            }
            {page === 6 &&
                <Radio 
                    title="האם את/ה חווה כרגע עצבות, מתח פנימי או דכדוך?"
                    name="state"
                    options={["לא", "כן"]}
                    handleChange={handleChange}
                    value={values.state}
                />
            }
            {page === 7 &&
                <Radio 
                    title="האם קשה לך במצבים אינטימיים?"
                    name="intimy"
                    options={["לא", "כן"]}
                    handleChange={handleChange}
                    value={values.intimy}
                />
            }
            {page === 8 &&
                <Radio 
                    title="האם קשה לך לפתח קשרים חברתיים?"
                    name="friendly"
                    options={["לא", "כן"]}
                    handleChange={handleChange}
                    value={values.friendly}
                />
            }
            {page === 9 &&
                <Radio 
                    title="האם את/ה מרגיש/ה לא נוח בתקשורת בין-אישית?"
                    name="comfortable"
                    options={["לא", "כן"]}
                    handleChange={handleChange}
                    value={values.comfortable}
                />
            }
            {page === 10 &&
                <Radio 
                    title="האם פנייתך קשורה ל-"
                    name="relation"
                    options={["זוגיות",'משפחה', " יחסים בעבודה", " דיכאון או חרדה", " הפרעות אכילה", "אחר"]}
                    handleChange={handleChange}
                    value={values.relation}
                />
            }
        </Wrapper>

    );
};

export default Quiz
