import { 
    OPEN_PRIVACY,
    OPEN_TERMS,
    OPEN_CONTENT,
} from "./types";
import {
    PRIVACY,
    TERMS,
    CONTENT
} from '../utils/globals';

// Getting the entire meetings object of OpenMynd
export const setPolicy = policy => {

    switch (policy) {

        case PRIVACY:
            return {
                type: OPEN_PRIVACY,
                payload: PRIVACY
            };

        case TERMS: 
            return {
                type: OPEN_TERMS,
                payload: TERMS
            };  

        case CONTENT: 
            return {
                type: OPEN_CONTENT,
                payload: CONTENT
            };  
        
        default:
            return {};
    }
};