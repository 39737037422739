import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AdminChoice from "./adminStyles/AdminChoice";
import AddProfessional from "./adminFunctions/AddProfessional";
import ReviewForm from "./adminFunctions/reviewForm/ReviewForm";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import AddAdmin from "./adminFunctions/AddAdmin";
import { LEAD_BLUE } from "../../../utils/globals";

import ProfessionalsMangment from "./adminFunctions/ProfessionalsMangment";
import { useDispatch, useSelector } from "react-redux";
import { adminFetchAllProfessionals, setUpdate } from "../../../actions/adminActions"
import Spinner from "../../layout/Spinner";


const options = ["Approved", "Pending Approval", "In Progress", "Suspended", "Add Admin"];

const Wrapper = styled(Box)`
  margin: auto;
  text-align: center;
`;

const HeaderWrapper = styled(Box)`
  margin: auto;
  width: 30%;
  padding: 10px;
`;

const Panel = styled(Grid)`
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
`;

export const ChoiceButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100px;
  height: 50px;
  color: white;
  background-color: ${LEAD_BLUE};
  border-radius: 10%;
`;

const ToRender = ({ component, error, authLoading }) => {
   
    switch (component) {
        case "Add Professional":
            return <AddProfessional error={error} authLoading={authLoading} />;
        case "Pending Approval":
            return <ReviewForm
                isPending={true}
                title={component}
            />;
               case "Suspended":
                return <ProfessionalsMangment
                    isPending={'removed'}
                    title={component}
                />;
        case "Approved":
            return <ProfessionalsMangment
                isPending={false}
                title={component} />;
        case "Add Admin":
            return <AddAdmin error={error} />;

        case "In Progress":
            return <ProfessionalsMangment
                isPending={''} 
                title={component}
                />
        default:

            return null;
    }
};

const AdminHome = ({ error, authLoading }) => {
  
    const [render, setRender] = useState("");
    const dispatch = useDispatch();
    const adminReduxState = useSelector((state) => state.admin);
    const { Approved, Pending, inProgress, Suspended, update, loading } = adminReduxState;

    const setComponentToRender = (component) => {
        setRender(component);
    };



    //first time fetch data
    useEffect(() => {
        dispatch(adminFetchAllProfessionals())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
       if(update===true){
        dispatch(adminFetchAllProfessionals())
        dispatch(setUpdate(false))
       }
    }, [dispatch, update])



    function getPending (option) {
        switch (option) {
            case "Approved":
                return Approved.length
            case "Pending Approval":
                return Pending.length
            case "In Progress":
                return inProgress.length
            case "Suspended":
                return Suspended.length

            default:
                return;
        }
    }


    return (
        <Wrapper>
            <HeaderWrapper>
                <Typography>
                    Welcome to the admin panel
                </Typography>
            </HeaderWrapper>
            {loading ? <Spinner />
                : <Panel container>
                    <Grid item xs={12} sm={6} md={3}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            {options.map((option) => (
                                <AdminChoice
                                    key={option}
                                    choice={option}
                                    toRender={setComponentToRender}
                                    pendingAmount={getPending(option)}
                                    selected={render}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                        <ToRender
                            component={render}
                            error={error}
                            authLoading={authLoading}
                        />
                    </Grid>
                </Panel>}
        </Wrapper>
    );
};

export default AdminHome;
