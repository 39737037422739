import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CustomAlert from '../../../layout/CustomAlert';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import { CircularProgress } from '@material-ui/core';
import { addAdmin } from '../../../../actions/FunctionsActions';

const Wrapper = styled(Box)`
    margin: auto;
    height: auto;
`;

const TextFieldWrapper = styled(Grid)`
    margin: 10px;
`;

const ButtonWrapper = styled(Box)`
    margin: 5px;
`;

const AlertWrapper = styled(Box)`
    padding: 10px;
`;

const Loader = styled(CircularProgress)`
    margin: 10px;
`;

const AddAdmin = ({ error }) => {

    const [ timer, setTimer ] = useState('');

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [timer])

    const [ user, setUser ] = useState({
        email: '',
    });
    const { email } = user;
    const [ alert, setAlert ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const emailValidation = email => {
        //eslint-disable-next-line
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return filter.test(email);
    };

    const onChange = event => {
        const value = event.target.value;
        const form = event.target.name;
        setUser({
            ...user,
            [form]: value
        });
    };

    const onSubmit = async () => {
        setAlert('');
        if (emailValidation(email)) {
            setLoading(true);
            const res = await addAdmin(email);
            setLoading(false);
            setAlert({
                type: res.type,
                msg: res.message
            });
        } else {
            setAlert({
                type: 'error',
                msg: 'Invalid email address'
            });
        };
        const timerInstance = () => setTimeout(() => {setAlert('')}, 5000)
        const timerId = timerInstance();
        setTimer(timerId);
    };

    return (

        <Wrapper>
            <TextFieldWrapper
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        name="email"
                        value={email}
                        onChange={onChange}
                        label="Email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                                ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {!email ? null : 
                        loading ? <Loader /> :
                        <ButtonWrapper align="center">
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={onSubmit}
                            >
                                Add
                            </Button>
                        </ButtonWrapper>
                    }
                </Grid>
            </TextFieldWrapper>
            
            <AlertWrapper>
                {
                    !!error.message ?
                    <CustomAlert 
                        type={error.type}
                        msg={error.message}
                    />
                    :
                    alert.msg &&
                    <CustomAlert 
                        type={alert.type}
                        msg={alert.msg}
                    />
                }
            </AlertWrapper>

        </Wrapper>

    )
}

export default AddAdmin
