import {
    OPEN_AUTH,
    CLOSE_AUTH,
    OPEN_PROFESSIONAL,
    CLOSE_PROFESSIONAL,
    OPEN_VIDEO,
    CLOSE_VIDEO,
    OPEN_RESET,
    CLOSE_RESET,
    OPEN_PROFESSIONAL_ZONE,
    CLOSE_PROFESSIONAL_ZONE,
    OPEN_POLICIES,
    CLOSE_POLICIES,
    OPEN_QUIZ,
    CLOSE_QUIZ,
    OPEN_MESSAGES,
    CLOSE_MESSAGES,
    OPEN_OPTIONS,
    CLOSE_OPTIONS,
    OPEN_CONFIRMATION,
    CLOSE_CONFIRMATION,
    OPEN_ADMIN_EDIT,
    CLOSE_ADMIN_EDIT,
} from "./types";

import {
    OPEN_AUTH_DIALOG,
    OPEN_PROFESSIONAL_DIALOG,
    OPEN_VIDEO_DIALOG,
    OPEN_PROFESSIONAL_ZONE_DIALOG,
    OPEN_RESET_DIALOG,
    OPEN_POLICIES_DIALOG,
    OPEN_QUIZ_DIALOG,
    OPEN_MESSAGES_DIALOG,
    OPEN_OPTIONS_DIALOG,
    OPEN_CONFIRMATION_DIALOG,
    OPEN_ADMIN_DIALOG,
} from "../utils/globals";

// Opening a dialog
export const openDialog = (dialogName) => {
    switch (dialogName) {
        case OPEN_AUTH_DIALOG:
            return {
                type: OPEN_AUTH,
            };

        // Opening professional dialog
        case OPEN_PROFESSIONAL_DIALOG:
            return {
                type: OPEN_PROFESSIONAL,
            };

        // Opening video dialog
        case OPEN_VIDEO_DIALOG:
            return {
                type: OPEN_VIDEO,
            };

        // Opening professional zone's dialog
        case OPEN_PROFESSIONAL_ZONE_DIALOG:
            return {
                type: OPEN_PROFESSIONAL_ZONE,
            };

        // Opening reset password's dialog
        case OPEN_RESET_DIALOG:
            return {
                type: OPEN_RESET,
            };

        // Opening policies' dialog
        case OPEN_POLICIES_DIALOG:
            return {
                type: OPEN_POLICIES,
            };

        // Opening quiz's dialog
        case OPEN_QUIZ_DIALOG:
            return {
                type: OPEN_QUIZ,
            };

        // Opening messages' dialog
        case OPEN_MESSAGES_DIALOG:
            return {
                type: OPEN_MESSAGES,
            };
        case OPEN_ADMIN_DIALOG:
            return {
                type: OPEN_ADMIN_EDIT,
            };

        default:
            return {};
    }
};

// Closing a dialog
export const closeDialog = (dialogName) => {
    switch (dialogName) {
        case OPEN_AUTH_DIALOG:
            return {
                type: CLOSE_AUTH,
            };

        case OPEN_PROFESSIONAL_DIALOG:
            return {
                type: CLOSE_PROFESSIONAL,
            };

        case OPEN_VIDEO_DIALOG:
            return {
                type: CLOSE_VIDEO,
            };

        case OPEN_PROFESSIONAL_ZONE_DIALOG:
            return {
                type: CLOSE_PROFESSIONAL_ZONE,
            };

        case OPEN_RESET_DIALOG:
            return {
                type: CLOSE_RESET,
            };

        case OPEN_POLICIES_DIALOG:
            return {
                type: CLOSE_POLICIES,
            };

        case OPEN_QUIZ_DIALOG:
            return {
                type: CLOSE_QUIZ,
            };

        case OPEN_MESSAGES_DIALOG:
            return {
                type: CLOSE_MESSAGES,
            };

        case OPEN_OPTIONS_DIALOG:
            return {
                type: CLOSE_OPTIONS,
            };

        case OPEN_CONFIRMATION_DIALOG:
            return {
                type: CLOSE_CONFIRMATION,
            };
        case OPEN_ADMIN_DIALOG:
            return {
                type: CLOSE_ADMIN_EDIT,
            };

        default:
            return {};
    }
};

export const openOptionsDialog = (optionsObject) => {
    return {
        type: OPEN_OPTIONS,
        payload: optionsObject,
    };
};

// confirmationObject is an object that contains an exit function of a dialog
// that is open (such as privateZone dialog of login dialog)
// It also contains the warning text to display
export const openConfirmationDialog = (confirmationObject) => {
    return {
        type: OPEN_CONFIRMATION,
        payload: confirmationObject,
    };
};
