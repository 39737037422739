import {
    GET_MESSAGES,
    POST_MESSAGES,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
    SET_MESSAGES_ALERT,
    CLEAR_MESSAGES_ALERT,
    SET_MESSAGES_LOADING,
    UPDATE_SCREEN_TO_RENDER,
    UPDATE_UNREAD,
    UPDATE_INDEPENDENT,
    CLEAR_MESSAGES
} from '../actions/types';

const initalState = {
    screenToRender : '',
    messages: {},
    unreadMessages: '',
    independent: false,
    messagesAlert: {},
    messagesLoading: false
};

export const messagesReducer = (state = initalState, action) => {

    switch (action.type) {

        case CLEAR_MESSAGES:
            return{
                screenToRender : '',
                messages: {},
                unreadMessages: '',
                independent: false,
                messagesAlert: {},
                messagesLoading: false
            }

        case GET_MESSAGES:
            return {
                ...state,
                messages: action.payload
            };

        case POST_MESSAGES:
            return {
                ...state
            };

        case UPDATE_MESSAGE:
            return {
                ...state
            };

        case DELETE_MESSAGE:
            return {
                ...state
            };

        case UPDATE_UNREAD:
            return {
                ...state,
                unreadMessages: action.payload
            };

        case SET_MESSAGES_ALERT:
            return {
                ...state,
                messagesAlert: action.payload
            };

        case CLEAR_MESSAGES_ALERT:
            return {
                ...state,
                messagesAlert: {}
            };

        case SET_MESSAGES_LOADING:
            return {
                ...state,
                messagesLoading: action.payload
            };

        case UPDATE_SCREEN_TO_RENDER:
            return {
                ...state,
                screenToRender: action.payload
            };

        case UPDATE_INDEPENDENT:
            return {
                ...state,
                independent: action.payload
            };

        default:
            return state;
    };
};
