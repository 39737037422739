import React from 'react';
import CalanderHeader from '../../../calander/calendarUtils/CalanderHeader';
import styled from 'styled-components';
import {FormControl, FormGroup, FormControlLabel, Checkbox, TextField} from '@material-ui/core';
import {LEAD_BLUE} from '../../../../../utils/globals';
import {Label} from '../../Settings';


const firstLanguagesGroup = [
    {
        label: "עברית",
        name: "hebrew"
    },
    {
        label: "רוסית",
        name: "russian"
    },
    {
        label: "ערבית",
        name: "arabic"
    },
]

const socialNetworkGroup = [
    {
        label: "פייסבוק",
        name: "facebook"
    },
    {
        label: "לינקדאין",
        name: "linkedin"
    },
    {
        label: "אחר",
        name: "other"
    },
]

const secondLanguagesGroup = [
    {
        label: "אנגלית",
        name: "english"
    },
    {
        label: "צרפתית",
        name: "french"
    },
    {
        label: "ספרדית",
        name: "spanish"
    },
]

const MainWrapper = styled.div`
  display: grid;
  align-items: center;
`;


const SelectWrapper = styled(FormControl)`
  display: grid;
  justify-items: flex-start;
  align-items: center;
  grid-gap: 10px;
  padding: 5px;
  direction: rtl;
`;

const SocialNetWorkWrapper = styled.div`
  display: grid;
  grid-template-columns:25% 70%  5%;
  direction: rtl;
`
const OtherWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 16px;
`;
const MinHeader = styled.p`
  font-family: 'Rubik', Sens-Serif;
  font-size: 16px;
  text-align: right;
  margin: 5px;
  padding: 5px;
  color: #000000;
`;
const Header = styled.p`
  font-family: 'Rubik', Sens-Serif;
  font-size: 18px;

  text-align: right;
  margin: 5px;
  padding: 5px;
  color: ${LEAD_BLUE};
`;

const Description = () => {
    return (
        <CalanderHeader
            header="שפות ורשתות חברתיות"
            description="נא לציין רק שפות שמשמשות אותך לטיפולים."
        />
    )
};

const OtherLanguage = ({onChange, value}) => {
    return (
        <OtherWrapper>
            <Checkbox
                checked={!!value}
                style={{color: LEAD_BLUE}}
            />
            <Label>אחר</Label>
            <TextField
                name="other"
                onChange={event => onChange(event, "languagesState")}
                value={value}
                style={{width: '75px'}}
            />
        </OtherWrapper>
    );
};

const SingleCheckbox = ({checkboxName, checkboxValue, onChange, label}) => {
    return (
        <FormControlLabel
            labelPlacement="end"
            control={
                <Checkbox
                    checked={!!checkboxValue}
                    onChange={event => onChange(event, "languagesState")}
                    name={checkboxName}
                    style={{color: LEAD_BLUE}}
                />
            }
            label={label}
        />
    )
}

const SocialNetworks = ({onChange, socialNetworkState}) => {
    return (
        <>
            <Header>
                :רשתות חברתיות
            </Header>
            {
                socialNetworkGroup.map((socialNetwork, index) =>
                    <SocialNetWorkWrapper>
                        <MinHeader>
                            {socialNetwork.label}
                        </MinHeader>
                        <TextField
                            name={socialNetwork.name}
                            value={socialNetworkState[socialNetwork.name]}
                            onChange={(e) =>
                                onChange(e, 'socialNetworkState')
                            }
                            fullWidth
                        />

                    </SocialNetWorkWrapper>
                )
            }

        </>

    );
}

const SelectLanguages = (
{
    onChange, languageState
}
) =>
{
    return (
        <>
            <Header>
                :שפות
            </Header>
            <SelectWrapper>
                <FormGroup row={true}>
                    {
                        firstLanguagesGroup.map((language, index) =>
                            <SingleCheckbox
                                key={index}
                                checkboxName={language.name}
                                checkboxValue={languageState[language.name]}
                                onChange={onChange}
                                label={language.label}
                            />
                        )}
                </FormGroup>
                <FormGroup row={true}>
                    {
                        secondLanguagesGroup.map((language, index) =>
                            <SingleCheckbox
                                key={index}
                                checkboxName={language.name}
                                checkboxValue={languageState[language.name]}
                                onChange={onChange}
                                label={language.label}
                            />
                        )}
                    <OtherLanguage
                        value={languageState["other"]}
                        onChange={onChange}
                    />
                </FormGroup>
            </SelectWrapper>
        </>
    )
}
;

const LanguageForm = (
{
    languageState, onChange, socialNetworkState
}
) =>
{

    return (
        <MainWrapper>
            <Description/>
            <SelectLanguages
                languageState={languageState}
                onChange={onChange}
            />
            <SocialNetworks
                onChange={onChange}
                socialNetworkState={socialNetworkState}/>
        </MainWrapper>
    )
}

export default LanguageForm
