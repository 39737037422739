
import * as firebase from 'firebase/app';
import placeholder from '../static/images/geniePictures/placeholder.png'
import 'firebase/storage';

export const updateProfessionalAssets = (id, root, folder, fileName, file) => {
    // Create a root reference
    const storageRef = firebase.storage().ref();
    // Create a reference to 'folder/fileName.extension'
    const ref = storageRef.child(`${root}/${id}/${folder}/${fileName}`);
    ref.put(file).then(() => {
    }).catch(err => {console.log("An error in uploading photo: ", err)});
};

export const getProfessionalAssets = async (id, root, folder, fileName) => {

    
    // Create a root reference
    const storage = firebase.storage()
    // Create a reference to 'folder/fileName.extension'
    const path = `${root}/${id}/${folder}/${fileName}`;
   
    try {
        return await storage.ref(path).getDownloadURL();
    } catch (error) {
        console.log("Error while downloading file,placeholder was added");
        return placeholder;
    }
};

export const testStorage = async () => {

    try{
        const data = await firebase.firestore()
            .collection("professionals")
            .doc("pending.status").get()
        console.log(data)
    }catch (error) {
        console.log(error)
    }
}
