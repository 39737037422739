import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomAlert from '../../../../layout/CustomAlert';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { LEAD_BLUE } from '../../../../../utils/globals';
import { clearAlert } from '../../../../../actions/RecommendationsActions';

const RecoTextField = ({ onSaveClick=null, display=null, value, onChange=null, helperText=null, disabled=true }) => {

    const { alert, recommendationsLoading } = useSelector(state => state.recommendations);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            !!alert && dispatch(clearAlert);
        }, 3000);
        // eslint-disable-next-line
    }, [alert])

    return (

        <Box align='center'>
            <TextField
                fullWidth={true}
                rowsMax={5}
                value={value}
                multiline
                variant="filled"
                name="notes"
                onChange={onChange}
                helperText={helperText ? helperText : null}
                disabled={disabled}
            >
            </TextField>

            {display ? 
                <Box p={1}>
                    <Button 
                        variant="contained" 
                        onClick={onSaveClick}
                        style={{backgroundColor: LEAD_BLUE, color: 'white'}}
                    >
                        שמור
                    </Button>
                </Box>
                    : recommendationsLoading 
                        ? 
                            <CircularProgress /> 
                        :
                            alert.type &&
                                <Box p={1}>
                                    <CustomAlert 
                                        type={alert.type}
                                        msg={alert.msg}
                                    />
                                </Box>
            }
            
        </Box>
    );
};

export default RecoTextField
