import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getProfessionalAssets } from '../../actions/StorageActions';
import CustomAlert from '../layout/CustomAlert';
import Content from './presentation/Content';
import Header from './presentation/Header';
import { LEAD_BLUE } from '../../utils/globals';

export const Bold = styled.span`
    font-size: 16px;
    font-weight: 600;
    font-family: 'Arial', Sens-Serif;
    @media(max-width: 578px){
        font-size: 13px;
    };
`;


export const VidButton = styled.button`  
    width: 160px;
    height: 40px;
    font-weight: bold;
    cursor: pointer;
    background: ${LEAD_BLUE};
    border-radius: 5%;
    color: white;
    border: none;
    &:hover {
        opacity: 0.85;
    }
    @media (max-width: 578px){
        width: 150px;
        margin-right: 15px;
    }
`;

const AlertWrapper = styled.div`
    padding: 10px;
    margin: 10px;
`;

const MainContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    direction: rtl;
    min-width: 500px;

    @media (min-width: 1000px){
        min-width: 700px;
    }
    @media (max-width: 578px){
        font-size: 13px;
    };
`;

const Presentation = ({ currentProfessional, nextStep, onExit, meetingAmount, setMeetingAmount, userHasTokens, userId, sendMessageDisabled }) => {
    const { isAuthenticated, isProfessional } = useSelector(state => state.auth);
    const { recommendations, recommendationsLoading } = useSelector(state => state.recommendations);
    const { professionalsLoading } = useSelector(state => state.professionals);
    const [alert, setAlert] = useState({
        type: '',
        message: ''
    });
    const [asset, setAsset] = useState('');
    const [recoArray, setRecoArray] = useState([]);
    const isMounted = useRef(null);
    const { id, picture } = currentProfessional;

    useEffect(() => {
        isMounted.current = true;
        const urlParams = new URLSearchParams(window.location.search);
        const expertId = urlParams.get('expert');
        if (expertId === id && isMounted) {
            !!picture.profilePicture.stringName && getPicture();
        }
        return () => {
            isMounted.current = false;
        }
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        if (recommendations[id]) {
            if (recommendations[id].length > 3) {
                // Shuffle array
                const shuffled = recommendations[id].sort(() => 0.5 - Math.random());
                // Get sub-array of first n elements after shuffled
                let selected = shuffled.slice(0, 3);
                setRecoArray(selected);
            } else {
                setRecoArray(recommendations[id]);
            }
        }
        // eslint-disable-next-line
    }, [recommendations])

    const getPicture = async () => {
        try {
            const photoRef = picture.profilePicture.stringName;
            const photoURL =
                await getProfessionalAssets(id, "professionals", "picture", photoRef);
            isMounted.current && setAsset(photoURL);
        } catch (e) {
            console.log(e)
        }
    };
 

    return (
        <div>
            {asset && !recommendationsLoading && !professionalsLoading &&
                <MainContainer>
                    <Header
                        currentProfessioanl={currentProfessional}
                        picture={asset}
                        onExit={onExit}
                    />
                    <Content
                        userId={userId}
                        scalert={alert}
                        currentProfessioanl={currentProfessional}
                        isProfessional={isProfessional}
                        recommendations={recoArray}
                        isAuthenticated={isAuthenticated}
                        nextStep={nextStep}
                        setAlert={setAlert}
                        meetingAmount={meetingAmount}
                        setMeetingAmount={setMeetingAmount}
                        userHasTokens={userHasTokens}
                        sendMessageDisabled={sendMessageDisabled}
                    />
                    {alert.type && alert.type !== "unAuthWarning" &&
                        <AlertWrapper>
                            <CustomAlert
                                type={alert.type}
                                msg={alert.message}
                            />
                        </AlertWrapper>
                    }

                </MainContainer>
            }
        </div>
    );
};

export default Presentation