import {
    ADMIN_CLEAR_ALL_PROS,
    ADMIN_GET_ALL_PROS,
    ADMIN_SET_CAL_PRICE,
    ADMIN_SET_LOADING,
    ADMIN_SET_UPDATE,
} from "../actions/types";

const initalState = {
    Approved: [],
    Pending: [],
    inProgress: [],
    Suspended: [],
    update: false,
    loading: false,
    calculatedPrice: 0
};

export const adminReducer = (state = initalState, action) => {
    switch (action.type) {
        case ADMIN_GET_ALL_PROS:
            return {
                ...state,
                Approved: action.payload.Approved,
                Pending: action.payload.Pending,
                inProgress: action.payload.inProgress,
                Suspended: action.payload.Suspended,
            };

        case ADMIN_CLEAR_ALL_PROS:
            return {
                initalState,
            };

        case ADMIN_SET_UPDATE:
            return {
                ...state,
                update: action.payload,
            };

        case ADMIN_SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case ADMIN_SET_CAL_PRICE:
            return {
                ...state,
                calculatedPrice: action.payload
            }

        default:
            return state;
    }
};

export default adminReducer;
