import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { login, clearAuthError } from '../../../actions/AuthActions';
import { areFormFilled } from '../../../utils/basicFormValidation';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CustomAlert from '../../layout/CustomAlert';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled(Box)`
    padding-top: 230px;
    max-width: 300px;
    margin: auto;
`;

const Header = styled(Box)`
    text-align: center;
    background-color: #F0F0F0;
`;

const ButtonWrapper = styled(Button)`
    margin: 7px;
`;

const AdminForm = ({ authLoading, error }) => {

    const [form, setForm] = useState({
        email: '',
        password: ''
    });

    const [validationError, setValidationError] = useState({
        showError: false,
        msg: '' 
    });

    const { email, password } = form;

    // Getting all actions with use dispatch
    const dispatch = useDispatch()

    const onChange = event => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    };

    const onSubmit = event => {
        event.preventDefault()
        dispatch(clearAuthError());
        const validation = areFormFilled({email, password});
        if (!validation.showError) {
            dispatch(login(form));
        } else {
            setValidationError({
                showError: validation.showError,
                msg: validation.msg
            });
            setTimeout(() => {clearAll()}, 3000);
        };
    };

    const clearAll = () => {
        setForm({
            email: '',
            password: ''
        }); 
    };

    return (

        <Wrapper align="center">
                <Header>
                    <Typography>
                        Admin Panel
                    </Typography>
                </Header>

                <TextField
                    autoFocus
                    name="email"
                    label="Email Address"
                    type="email"
                    onChange={onChange}
                    value={email}
                    fullWidth
                />

                <TextField
                    autoFocus
                    name="password"
                    label="Password"
                    type="password"
                    onChange={onChange}
                    value={password}
                    fullWidth
                />

                {authLoading ? <CircularProgress /> : 
                    <ButtonWrapper onClick={onSubmit} 
                        color="primary" 
                        variant='contained'
                        type="submit"
                        >
                        Enter
                    </ButtonWrapper>
                }

                {
                    validationError.showError 
                    ? 
                        <CustomAlert 
                            type='error'
                            msg={validationError.msg}
                        />
                    :
                        error 
                    &&
                        <CustomAlert 
                            type='error'
                            msg={error}
                        />    
                }

        </Wrapper>
    )
}

export default AdminForm
