import {addNewDoucment, getEntireDocument} from '../../../actions/FirestoreActions';
import {MODIFY} from '../../../utils/globals';
import {removeExistingMeetingsFromSchedule} from '../../professionalZone/calander/calendarUtils/calendarHelpers';
import {createMeetingObject, removeMeetingFromObject} from './paymentActions';

// Removes date from schedule and returns the manipulated list.
const preformRemoval = (
    manipulatedSchedule, choosenDay, choosenDayHours, hoursObjectIndex
) => {
    // If this is the last hours available for the choosen day, we remove the entire day.
    if (choosenDayHours.length === 1) {
        const finalSchedule = manipulatedSchedule.filter(loopedDay =>
            Object.keys(loopedDay)[0] !== choosenDay
        );
        return finalSchedule;
    } else {
        // HoursObjectIndex is the index of the object that contains the choosen start hour.
        // dayHours is a list of all available hours for a given choosen day.
        // Since a person paid for this hour, we remove the entire object.
        choosenDayHours.splice(hoursObjectIndex, 1);
        return manipulatedSchedule;
    }
    ;
};

export const removeChoosenDate = (schedule, day, hour) => {
    // Duplicating the schedule state
    let newSchedule = [...schedule];
    newSchedule = removeExistingMeetingsFromSchedule(newSchedule);
    // Looping over the schedule and returning a date object {day: [{hours}, {hours}]}
    for (let dayObject = 0; dayObject < newSchedule.length; dayObject++) {
        // Checking if the looped day is the requested day by user
        if (Object.keys(newSchedule[dayObject])[0] === day) {
            // If it is, getting the list of hours object: [{hours}, {hours}]
            let choosenDayHours = Object.values(newSchedule[dayObject])[0];
            // Looping over this list of object
            for (let hoursObjectIndex = 0; hoursObjectIndex < choosenDayHours.length; hoursObjectIndex++) {
                // For every object, we check if the looped start hour equals to the requested start hour
                if (choosenDayHours[hoursObjectIndex].start === hour.start) {
                    // If it is, sending gathered information and returning new schedule
                    newSchedule = preformRemoval(newSchedule, day, choosenDayHours, hoursObjectIndex);
                    return newSchedule;
                }
                ;
            }
            ;
        }
        ;
    }
    ;
};

export const updateModifiedMeeting = async (state, professionalId,
                                            meetingObject, day, hour) => {
    const profRes = getEntireDocument("professionalMeetings", professionalId);
    const userRes = getEntireDocument("userMeetings", meetingObject.client.clientId);
    const documents = await Promise.all([profRes, userRes]);
    let meetings;
    if (state === MODIFY) {
        meetings = createMeetingObject(documents, meetingObject, day, hour.start, hour.end);
    } else {
        meetings = removeMeetingFromObject(documents, meetingObject, day);
    }
    const updateNewProf = addNewDoucment("professionalMeetings", professionalId, meetings.prof);
    const updateNewClient = addNewDoucment("userMeetings", meetingObject.client.clientId, meetings.client);
    await Promise.all([updateNewProf, updateNewClient]);
};

export const priceFormatter = receivedPrice => {
    // console.log(receivedPrice)
    let price = receivedPrice.toFixed(2);
    const priceLength = price.split(".")[0].length
    if (priceLength === 4) {
        const priceAsList = [...price]
        priceAsList.splice(1, 0, ',');
        priceAsList.join('');
        return priceAsList;
    }
    return price;
};
/**
 * Still not in used , make post body to send to server
 * @param price
 * @param email
 * @param fullName
 * @param phone
 * @param type
 * @returns {{UniqueId: string, Installments: {Type: string, MaxQuantity: string, MinQuantity: string}, Customer: {Email: {email: *}, Attributes: {HolderId: string, Email: string, PhoneNumber: string, Name: string}, PhoneNumber: {phone: *}, Name: {fullName: *}}, Local: string, CancelUrl: string, CreateInvoice: string, AdditionalText: string, CartItems: [{Description: string, IsTaxFree: string, Amount: {price: *}, Currency: string, Quantity: number, Name: {type: *}}], CallbackUrl: string, PaymentType: string, SuccessUrl: string, ShowCart: string, Key: string}}
 */
export const bodyRequestMaker = ({fullPrice, email, fullName, phone, userID}) => {


    const key = process.env.REACT_APP_ZREDIT_API_KEY;
    const callBackURL = 'https://us-central1-genie-81f1b.cloudfunctions.net/getZLinkCallback';

    return {
        "Key": key,
        "Local": "He",
        "UniqueId": {userID}.userID.id,
        "SuccessUrl": "",
        "CancelUrl": "",
        "CallbackUrl": callBackURL,
        "PaymentType": "regular",
        "CreateInvoice": "true",
        "AdditionalText": "",
        "ShowCart": "false",
        "Installments": {
            Type: "regular",
            MinQuantity: "1",
            MaxQuantity: "3"
        },
        "Customer": {
            "Email": {email}.email,
            "Name": {fullName}.fullName,
            "PhoneNumber": {phone}.phone,
            "Attributes": {
                "HolderId": "none",
                "Name": "required",
                "PhoneNumber": "required",
                "Email": "required"
            }
        },
        "CartItems": [{
            "Amount": {fullPrice}.fullPrice,
            "Currency": "ILS",
            "Quantity": 1,
            "IsTaxFree": "false"
        }
        ]
    };
}
export const getURL = async ({cartObject}) => {

    const response = await fetch('https://us-central1-jiniz-5fc71.cloudfunctions.net/getPayLink', {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(cartObject)
    })
    return await (response.json());
}

export const createEmailObject = (professionalURL, day, hour, user, proEmail, proFirstName, proLastName, oldMeetingObject) => {

    let mailObject;
    mailObject = {
        type: 'success',
        link: professionalURL,
        date: {
            day: day, start: hour.start, end: hour.end
        },
        details: {
            client: {
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            },
            professional: {
                email: proEmail,
                firstName: proFirstName,
                lastName: proLastName
            }
        }
    };
    //If the mail contains data about previous meetings add this to the mail
    if (oldMeetingObject !== undefined) {
        mailObject = {
            ...mailObject,
            oldDate: {
                day: oldMeetingObject.date.day,
                start: oldMeetingObject.date.hour.start,
                end: oldMeetingObject.date.hour.end
            },
        }
        return mailObject;
    }
    return mailObject;

}