import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { closeDialog, openConfirmationDialog } from '../../actions/DialogActions';
import { OPEN_OPTIONS_DIALOG, MODIFY, CANCEL } from '../../utils/globals';
import Calander from "../../components/professionalForm/calander/Calander";
import Payment from "../../components/professionalForm/payment/Payment";
import { getSchedule } from "../../actions/ScheduleActions";
import { getCurrent } from "../../actions/ProfessionalsActions";
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { getMeetings } from '../../actions/MeetingsActions';

// Styles
const MainWrapper = styled(Box)`
    width: auto;
    height: auto;
    background-color: #F0F0F0;
`;

const RenderCancel = ({ meetingObject, prevStep, 
    currentProfessional, onDialogClose, handleChangeState }) => {
    return (
        <Payment 
            day={meetingObject && meetingObject.date.day}
            hour={meetingObject && meetingObject.date.hour}
            prevStep={prevStep}
            currentProfessional={currentProfessional}
            onDialogClose={onDialogClose}
            modifyAnExistingMeeting={true}
            meetingObject={meetingObject}
            cancelMeeting={true} 
            handleChangeState={handleChangeState}
        />
    )
};


const RenderStep = ({ step, currentProfessional, day, hour, onFormChange,
    setDay, nextStep, prevStep, setCalanderForm, setHours, resetCalanderForms,
    calanderForm, hours, onDialogClose, meetingObject, handleChangeState,oldMeetingObject }) => {


    switch (step) {

        case (1):
            return (
                <Calander 
                    currentProfessional={currentProfessional}
                    day={day}
                    hour={hour}
                    onChange={onFormChange}
                    onDayChange={setDay}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    setForm={setCalanderForm}
                    setHours={setHours}
                    resetCalanderForms={resetCalanderForms}
                    form={calanderForm}
                    hours={hours}
                    modifyAnExistingMeeting={true}
                />
            )

        case (2): 
            return (
                <Payment 
                    day={day}
                    hour={hour}
                    prevStep={prevStep}
                    currentProfessional={currentProfessional}
                    onDialogClose={onDialogClose}
                    modifyAnExistingMeeting={true}
                    meetingObject={meetingObject}
                    oldMeetingObject={oldMeetingObject}
                    handleChangeState={handleChangeState}
                />
            )
        ; 

        default:
            return {};
        
        };
};


// Dialog
const OptionsModal = () => {
    
    const { isProfessional, user } = useSelector(state => state.auth);
    const { optionsDialog } = useSelector(state => state.dialogs);
    const { currentProfessional } = useSelector(state => state.professionals);
    const { meetingObject, screenToRender, show } = optionsDialog;
    const [ oldToRender, setOldToRender ] = useState('');
    const [ calanderForm, setCalanderForm ] = useState(false);
    const [ hours, setHours ] = useState({});
    const [oldMeetingObject,setOldMeetingObject] = useState(null)
    const [ change, setChange ] = useState(false);
    const [ form, setForm ] = useState({
        step: 1,
        day: '',
        hour: {},
    });
    useEffect(() => {
       setOldMeetingObject(meetingObject);
        // eslint-disable-next-line
    }, [meetingObject])

    const { step, day, hour } = form;
    
    useEffect(() => {
        setOldToRender(screenToRender);
        // eslint-disable-next-line
    }, [screenToRender])

    useEffect(() => {
        if (!!meetingObject && screenToRender !== oldToRender && show) {
            dispatch(getSchedule(meetingObject.professional.id));
            dispatch(getCurrent(meetingObject.professional.id));
        }
        // eslint-disable-next-line
    }, [show])

    const onFormChange = (name, value) => {
        setForm({
            ...form,
            [name]: value
        });
    };

    const setDay = day => {
        setForm({
            ...form,
            hour: '',
            day
        });
    };

    const resetAllForms = () => {
        resetCalanderForms();
        setForm({
            step: 1,
            day: '',
            hour: {},
        });
    };

    const resetCalanderForms = () => {
        setCalanderForm(false);
        setHours({});
    };

    const dispatch = useDispatch();

    const exitRoutine = () => {
        dispatch(closeDialog(OPEN_OPTIONS_DIALOG));
        resetAllForms();
        updateMeetingsState();
    };

    // Cleaning state and closing dialog.
    const onDialogClose = () => {
        if (change) {
            const confirmationInformation = {
                warningText: 'זיהינו שינויים שביצעת, במידה והחלון יסגר, השינויים לא ישמרו',
                exitFunction: exitRoutine,
                show: true,
            }
            dispatch(openConfirmationDialog(confirmationInformation));
        } else {
            exitRoutine();
        };
    }; 

    const updateMeetingsState = () => {
        if (isProfessional) {
            dispatch(getMeetings("professionalMeetings", user.id));
            dispatch(getSchedule(user.id));
        } else {
            dispatch(getMeetings("userMeetings", user.id));
        }
    };

    const nextStep = () => {
        setForm({
            ...form,
            step: step + 1
        });
    };

    const prevStep = () => {
        setForm({
            ...form,
            step: step - 1
        }); 
    };

    const handleChangeState = state => {
        setChange(state);
    };

    return (
        <Dialog 
            open={!!show}
            onClose={onDialogClose}
        >
            <MainWrapper> 
                {
                    screenToRender === MODIFY ? 
                        <RenderStep 
                            step={step}
                            currentProfessional={currentProfessional}
                            day={day}
                            hour={hour}
                            onFormChange={onFormChange}
                            setDay={setDay}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            setCalanderForm={setCalanderForm}
                            setHours={setHours}
                            resetCalanderForms={resetCalanderForms}
                            calanderForm={calanderForm}
                            hours={hours}
                            oldMeetingObject={oldMeetingObject}
                            onDialogClose={onDialogClose}
                            meetingObject={meetingObject}
                            handleChangeState={handleChangeState}
                        /> : screenToRender === CANCEL &&
                        <RenderCancel 
                            meetingObject={meetingObject}
                            prevStep={prevStep}
                            currentProfessional={currentProfessional}
                            onDialogClose={onDialogClose}
                            handleChangeState={handleChangeState}
                        />
                }
            </MainWrapper>
        </Dialog>
    );
};

export default OptionsModal;

