import React from 'react';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {Typography} from '@material-ui/core';
import {DARK_BLUE} from '../../../utils/globals';

const MainWrapper = styled(Container)`
    font-family: 'Suez One', serif;
`;



const BoldWord = styled.span`
    font-family: 'Arial', Sens-Serif;
    color: ${DARK_BLUE};
    font-weight: bold;
`;

const HebrewWrapper = styled(Typography)`
    direction: rtl;
    font-family: 'Rubik', sans-serif;
`;



const ParagraphWrapper = styled(Box)`
    margin-top: 15px;
`;
const BoldWordBlack = styled.span`
font-weight: bold;
`
const About = () => {

    return (
        <MainWrapper maxWidth="md">
            {/*    <HeaderWrapper>
                    <ImageWrapper>
                        <Image src={Logo}></Image>
                    </ImageWrapper>

                </HeaderWrapper>*/}
            <div style={{marginTop: '80px'}}>

                <HebrewWrapper>
                    <BoldWord>OpenMynd </BoldWord>
                    היא פלטפורמה ייחודית המאפשרת ליועצים ומטפלים לספק את שירותיהם באמצעות שיחות וידאו אישיות ומאובטחות. 
                </HebrewWrapper>
                <HebrewWrapper>
                    הרעיון העומד מאחורי <BoldWord>OpenMynd</BoldWord> הוא להנגיש לך את מיטב המטפלים שיקנו לך כלים להתמודדות עם מצבי 
                    לחץ ומצוקה או כל דבר אחר שמפריע לאושר ואיכות החיים שלך, כאן ועכשיו.
                    </HebrewWrapper>
                <ParagraphWrapper>
                    <HebrewWrapper>
                    המטפלים שלנו מגיעים מתחומים שונים: פסיכולוגיה פסיכותרפיה, עבודה סוציאלית, ייעוץ חינוכי, טיפול פרטני, זוגי ומשפחתי ומתחומים אחרים.
                    מטפלים ויועצים שהצטרפו לשירות, עברו בדיקה ואישור של מנהלי האתר. לכל מטפל/ת יש עמוד אישי ובו תמונה וסרטון וידאו קצר,
                    פירוט על תחומי מומחיות, השכלה, ניסיון מקצועי, עלות השירות וחוות דעת של לקוחות.
                    </HebrewWrapper>
                </ParagraphWrapper>
                <ParagraphWrapper>
                    <HebrewWrapper>
                    ההתקשרות עם המטפלים היא במפגשים חד-פעמיים או בסדרת מפגשים, בהתאם לסווג הייעוץ או הטיפול הנדרש, והם ניתנים 
                    במחיר מוזל ביחס לתעריף בקליניקה של המטפלים.
                      </HebrewWrapper>
                </ParagraphWrapper>

                <ParagraphWrapper>
                    <HebrewWrapper>
                        <BoldWordBlack>איך השירות עובד?</BoldWordBlack>
                    </HebrewWrapper>
                </ParagraphWrapper>
                <ParagraphWrapper>
                    <HebrewWrapper>
                    לאחר הליך רישום קצר, אפשר להיכנס לאתר באמצעות שם וסיסמה ולבחור את הקטגוריה שמעניינת אותך. תחת כל קטגוריה 
                    יופיעו כרטיסי הביקור של כל המטפלים הרלוונטיים. מכאן יש לך אפשרות לבחור את המטפל/ת שמתאים/ה לך, או לחילופין, 
                    ניתן להיעזר בנו ע״י מילוי שאלון קצר המסביר את  הצרכים שך, ואנחנו נתאים לך מטפל/ת בהתאם. 
                  </HebrewWrapper>
                </ParagraphWrapper>
                <ParagraphWrapper>
                    <HebrewWrapper>
                    הקלקה על כל כרטיס הביקור תכניס אותך לעמוד המטפל/ת. מכאן ניתן לקבוע מפגשים אישיים בתוך היומן שלו/ה, ומיד יישלח 
                    למייל שלך אישור עם קישור לחדר הפרטי של המטפל/ת. 
                   </HebrewWrapper>
                </ParagraphWrapper>

                 <ParagraphWrapper>
                     <HebrewWrapper>
                     את שיחות הוידאו ניתן לקיים מאתר האינטרנט שלנו, מטאבלטים או מטלפונים חכמים, ברמת אבטחה גבוהה ובאופן ידידותי להפעלה. 
                     את/ה יכול/ה לבחור את המטפל/ת שנכון/ה בשבילך ולקבוע מפגש וירטואלי בצורה פשוטה, בזמן שמתאים לך ועל הכורסה שנוחה לך. 
                     אין צורך בהבנה טכנולוגית או בהורדת תוכנה כלשהי לקיום המפגשים הווירטואליים.
                    </HebrewWrapper>
                 </ParagraphWrapper>

                 <ParagraphWrapper>
                     <HebrewWrapper>
                     לאחר המפגש רצוי לכתוב חוות דעת על השירות שקיבלת בעמוד המטפל/ת או באזור האישי שלך באתר.
                    </HebrewWrapper>
                 </ParagraphWrapper>
            </div>

        </MainWrapper>
    )
}

export default About;
