import React from 'react';

import Box from '@material-ui/core/Box';
import styled from 'styled-components';


const HeaderWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

// const LargeQuestionMark = styled(LiveHelpIcon)`
//     font-size: 50px;
//     color: ${LEAD_BLUE};
//     margin-left: 20px;
//     animation: ${rotateQuestionMark} 3s linear;
//     animation-iteration-count: infinite;
// `;

// const Title = styled(Typography)`
//     color: ${LEAD_BLUE};
//     font-size: 30px;
//     font-style: oblique;
//     font-family: 'Rubik', Sens-Serif;
// `;

const FaqHeader = () => {
    return (
        <HeaderWrapper>
            {/*<LargeQuestionMark />*/}
           {/* <Title>שאלות נפוצות</Title>*/}
        </HeaderWrapper>
    )
}

export default FaqHeader
