import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Bold } from "../Presentation";
import { LEAD_BLUE, OPEN_PROFESSIONAL_DIALOG } from "../../../utils/globals";
import { getSchedule } from "../../../actions/ScheduleActions";
import { routeQuery } from "../../../utils/routes";
import { closeDialog } from "../../../actions/DialogActions";
import ProfReco from "./ProfReco";
import { Link } from "react-router-dom";
import {
  extractLanguages,
  findIfUserHasMeetingWithExpert,
} from "../formUtils";
import Button from "@material-ui/core/Button";
import CustomAlert from "../../layout/CustomAlert";
import Checkbox from "@material-ui/core/Checkbox";

import {
  updateArray,
  setArray,
  getEntireDocument,
  updateArrayField,
  deleteArray
} from "../../../actions/FirestoreActions";

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;
  padding: 15px;
  font-family: "Rubik", Sens-Serif, serif;
  @media (max-width: 815px) {
    grid-template-columns: none;
  }
`;

const RightWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  min-width: 500px;
`;

const LeftWrapper = styled.div`
  /*position: relative;*/
  display: grid;
  justify-items: right;
  align-items: start;
  margin-right: 7px;
  @media (max-width: 815px) {
    display: none;
  }
`;

const PriceWrapper = styled.div`
  display: table;

  border-collapse: collapse;

  direction: rtl;
  /* position: absolute;
  bottom: 280px;
   right:2px;*/
  @media (max-width: 815px) {
    justify-items: center;
    margin: 0;
    padding: 0;
  }
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: black;
`;

const Paragraph = styled.div`
  display: grid;
  margin-bottom: 10px;
  width: 95%;
`;
const SmallParagraph = styled.div`
  display: grid;
  margin-bottom: 5px;
  width: 95%;
`;

const ScheduleStyle = styled.button`
  height: 50px;
  width: 100px;
  border-radius: 10%;
  cursor: pointer;
  color: white;
  font-family: "Rubik", Sens-Serif, serif;
  background: ${LEAD_BLUE};
  border: none;
  margin-left: 20px;
  font-size: 18px;

  &:hover {
    opacity: 0.85;
  }
`;

const AlertWrapper = styled.div`
  margin: 5px;
  padding: 5px;
`;

const MeetingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;

  @media (max-width: 450px) {
    padding: 0;
    margin: 10px 0;
  }
`;

//Talea
const inputStyle = {
  direction: "rtl",
  padding: "6px",
  fontSize: "14px",
  border: `1px solid ${LEAD_BLUE}`,
  borderRadius: "5px",
  width: "100px",
};

// const TableRow = styled.td`
//   text-align: right;
//   //   marginRight: '7px',
//   font-weight: normal;
// `;
//
// const TableMiniHeader = styled.span`
//   text-align: right;
//   padding-right: 35px;
//   font-weight: normal;
// `;

//Talea <span style={{ color: "red" }}>10%</span> הנחה

const Table = styled.table`
  font-family: "Rubik", Sens-Serif, serif;
  width: 70%;
  margin-right: -2px;
  @media (max-width: 578px) {
    width: 100% !important;
    min-width: 100% !important;
  } ;
`;

const CouponButton = ({
  userId,
  price,
  }) => {
  
  const [coupAmount, setCoupAmount] = useState(0);
  const [coupPercent, setCoupPercent] = useState(0);
  const [coupAmountDsc, setCoupAmountDsc] = useState("");
  const [coupPercentDsc, setCoupPercentDsc] = useState("");
  const [couponID, setV] = useState("");
  const dispatch = useDispatch();

  const checkCouponValidity = async (userId, couponName, couponInfo) => {
    const userCouponInfo = {
      balance: 0,
      status: '',
    };
  
    let currDate = new Date().getTime()
    let strCoupDate = couponInfo.expiry.toDate().toString()
    let couponDate = new Date(strCoupDate).getTime()
    if (couponInfo.status !== 'active' || couponDate < currDate) {
        alert("קוד הטבה לא פעיל")
        return false;
    }
  
    try{
      //Talea const res = await getEntireDocument('usercoupons',userId+'-'+couponName)
      const res = await getEntireDocument('usercoupons',userId)
      if (res.type === 'success'){
          userCouponInfo.balnace = res.data.balance
          userCouponInfo.status = res.data.status
  
          if (userCouponInfo.balnace === 3) {
            alert("קוד הטבה כבר נוצל")
            return false;
          };
  
          if (userCouponInfo.status !== 'active' ) {
            alert("קוד הטבה לא פעיל")
            return false;
          };
      }
      return true;
    }
    catch(error){
      alert(error.message + '\n' + 'בעיה בעת גישה לקוד הטבה')
      return false;
    }
  };

  const getCouponData = async (userId, couponName) => {
    const couponInfo = {
      amount: 0,
      expiry: '',
      percent: 0,
      status: '',
      description:'',
    };
  
    const coupBTN = document.getElementById("couponBTN");
    const coupFLD = document.getElementById("couponNAME");
    try{
        const res = await getEntireDocument('coupons',couponName)
        if (res.type === 'success'){
            couponInfo.amount = res.data.amount
            couponInfo.description = res.data.description
            couponInfo.expiry = res.data.expiry
            couponInfo.percent = res.data.percent
            couponInfo.status = res.data.status
  
            const sts = checkCouponValidity(userId, couponName, couponInfo)
            if (sts) {coupBTN.disabled     = true;          coupFLD.disabled     = true;
                      coupBTN.style.cursor = "not-allowed"; coupFLD.style.cursor = "not-allowed";
                      setCoupAmount(couponInfo.amount);
                      setCoupPercent(couponInfo.percent);
                      if (couponInfo.amount !=0){setCoupAmountDsc(couponInfo.amount+"₪ הנחה תינתן בעמוד התשלום")};
                      if (couponInfo.percent !=0){setCoupPercentDsc(couponInfo.percent+"% הנחה תינתן בעמוד התשלום")};

                      price.coupAMT = couponInfo.amount;                   
                      price.coupPRCT = couponInfo.percent;             
                      price.UserCoupID = userId+couponName;                 
                      price.finalAMT = 3*(price.normalPrice - couponInfo.amount);

                      if (couponInfo.percent != 0)
                        price.finalAMT = 3*(price.normalPrice-(price.normalPrice*couponInfo.percent*0.01));

                      const CoupchkBox1 = document.getElementById("chkBox1");
                      const CoupchkBox3 = document.getElementById("chkBox3");
                      if (CoupchkBox1 !== null)
                        CoupchkBox1.style.cursor = "not-allowed";
                      if (CoupchkBox3 !== null)
                        CoupchkBox3.style.cursor = "not-allowed";

                      return true;
            }
        }
        else {alert("קוד הטבה לא קיים")}
      }
      catch(error){alert(error.message + '\n' + 'בעיה בעת גישה לקוד הטבה')}

      return false;
  };

  return (
    <>
      {
        <PriceWrapper>
          <input
          type="text"
          id="couponNAME"
          name="couponNAME"
          value={couponID}
          style={inputStyle}
          placeholder={"הכנס קוד הטבה"}
          onChange={event => setV(event.target.value)}
        />
        <label> </label>
        { couponID &&
          <Button 
            type="button" 
            variant="contained"
            id="couponBTN"
            name="couponBTN"
            style={{background: LEAD_BLUE, color: 'white'}} 
            onClick={event => dispatch(getCouponData(userId, couponID))}
          >
            לחץ למימוש 
          </Button>
        }
        <label> </label>
        <label>{coupPercentDsc}{coupAmountDsc}</label>
        </PriceWrapper>
      }
    </>
  );
};

const ScheduleButton = ({
  onScheduleClick,
  onCancelClick,
  isAuthenticated,
  userHasMeeting,
}) => {
  return (
    <>
      {
        <MeetingWrapper>
          <ScheduleStyle onClick={onScheduleClick}>
            תיאום&nbsp;מפגש
          </ScheduleStyle>
          {isAuthenticated && userHasMeeting && (
            <LinkWrapper to={routeQuery({ privatePage: "info" })}>
              <ScheduleStyle onClick={onCancelClick}>
                שינוי&nbsp;מפגש
              </ScheduleStyle>
            </LinkWrapper>
          )}
        </MeetingWrapper>
      }
    </>
  );
};

const PriceComponent = ({
  price,
  meetingAmount,
  setMeetingAmount,
  userHasTokens,
  profId,
  userId,
}) => {

  const dispatch = useDispatch();

  let {normalPrice, threePackPrice, coupAMT, coupPRCT, UserCoupID, finalAMT} = price;
  price.coupAMT = 0;
  price.coupPRCT = 0;
  price.UserCoupID = "";
  price.finalAMT = 0;
  price.threePackPrice = normalPrice - normalPrice * 0.1; //Talea price.jinizPrice;

  //Talea Handling OLD Models
  //if (threePackPrice === undefined) {
  //  threePackPrice = normalPrice - normalPrice
  //}

  let discountedPirce = Number.parseFloat(
    normalPrice - normalPrice * 0.1
  ).toFixed(0);

  const { tokens } = useSelector((state) => state.tokens);

  return userHasTokens ? (
    <div>שילמת מראש עבור 3 מפגשים. נותרו לך עוד {tokens[profId]} מפגשים.</div>
  ) : (
    <>
      <Bold>מחיר: </Bold>
      <PriceWrapper>
        <Table>
          <tbody>
            <tr>
              <td>
                <Checkbox
                  id={"chkBox1"}
                  name={"chkBox1"}
                  style={{ margin: "-10px", color: LEAD_BLUE }}
                  checked={!meetingAmount}
                  onChange={setMeetingAmount}
                  color="primary"
                />
                הזמנת מפגש אחד: &#8362;{normalPrice} למפגש.
              </td>
            </tr>
            <tr>
              <td style={{ minWidth: "230px" }}>
                <Checkbox
                  id={"chkBox3"}
                  name={"chkBox3"}
                  style={{ margin: "-10px", color: LEAD_BLUE }}
                  checked={meetingAmount}
                  onChange={setMeetingAmount}
                  color="primary"
                />
                הזמנת 3 מפגשים : &#8362;{discountedPirce} למפגש.
              </td>
            </tr>
          </tbody>
        </Table>
      </PriceWrapper>
    </>
  );
};

const Languages = ({ languages }) => {
  const formattedLanguages = extractLanguages(languages);
  return (
    <>
      <div style={{ marginTop: "5px" }}>
        <span style={{ fontWeight: "bold" }}>שפות: </span> <br />
        {formattedLanguages}
      </div>
    </>
  );
};

/**
 * READ ME!
 * Due to professional form change added new function to handle both states
 * Old model of education and new.
 * Old models doesn't include title and establishment fields
 * New model does.
 * In the new model - show the highest rank degree only.
 * @param education
 * @returns {JSX.Element}
 * @constructor
 */
const Education = ({ education }) => {
  let title;
  let establishment;
  let description;
  //find the last degree and show it
  if (education["thirdDegree"]["title"] !== "") {
    title = education.thirdDegree.title;
    establishment = education.thirdDegree.establishment;
    description = "P.hd ";
  } else if (education["secondDegree"]["title"] !== "") {
    title = education.secondDegree.title;
    establishment = education.secondDegree.establishment;
    description = "M.A ";
  } else {
    title = education.firstDegree.title;
    establishment = education.firstDegree.establishment;
    description = "תואר ראשון";
  }

  return (
    <>
      {title ? (
        <Paragraph>
          <Bold>השכלה: </Bold>
          {description} ב{title} מ{establishment}
        </Paragraph>
      ) : null}
    </>
  );
};

const LicenseComponent = React.memo(({ license }) => {
  return (
    <div>
      <div>
        <Bold> הסמכות ורשיונות: </Bold>
      </div>
      <LiceseDisplay object={license?.defaultLicense} />
      <LiceseDisplay object={license?.secondLicense} />
      <LiceseDisplay object={license?.ordination} />
      <LiceseDisplay object={license?.secondOrdination} />
    </div>
  );
});

const LiceseDisplay = ({ object }) => {
  return (
    <>
      {object?.title !== "" && object?.title !== undefined ? (
        <SmallParagraph>
          {" "}
          {object?.title}, {object?.establishment}, {object?.year}
        </SmallParagraph>
      ) : (
        " "
      )}
    </>
  );
};

const Screen = ({
  education,
  license,
  experience,
  scalert,
  id,
  onScheduleClick,
  onCancelClick,
  price,
  recommendations,
  isAuthenticated,
  userHasMeeting,
  meetingAmount,
  setMeetingAmount,
  userHasTokens,
  languages,
  //Talea onCouponClick,
  userId,
}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 814px)",
  });

  return (
    <ContentWrapper>
      <RightWrapper>
        {languages && (
          <Paragraph>
            <Languages languages={languages} />
          </Paragraph>
        )}
        {education && <Education education={education} />}
        <LicenseComponent license={license} />

        {experience && (
          <Paragraph>
            <Bold>ניסיון: </Bold>
            {experience.defaultExperience}
          </Paragraph>
        )}
        {experience && (
          <Paragraph>
            <Bold>משך זמן מפגש: </Bold>50 דקות
          </Paragraph>
        )}

        {price && (
          <PriceComponent
            meetingAmount={meetingAmount}
            setMeetingAmount={setMeetingAmount}
            price={price}
            userHasTokens={userHasTokens}
            profId={id}
            userId={userId}
          />
        )}

        {scalert.type && (
          <AlertWrapper>
            <CustomAlert type={scalert.type} msg={scalert.message} />
          </AlertWrapper>
        )}
       
        {!userHasMeeting && (
          <CouponButton
            userId={userId}
            price={price}
          />
        )}

        <ScheduleButton
          onScheduleClick={onScheduleClick}
          onCancelClick={onCancelClick}
          isAuthenticated={isAuthenticated}
          userHasMeeting={userHasMeeting}
        />

        <div style={{ marginLeft: "15px" }}>
          {isTabletOrMobileDevice && (
            <>
              {/* <SocialPara>
                                {disabled === false ?
                                    <>
                                        <div>
                                            <Bold>צור קשר: </Bold>
                                        </div>
                                        <RenderMessages
                                            isAuthenticated={isAuthenticated}
                                            onMessagePress={onMessagePress}

                                        />
                                    </>
                                    : null
                                }
                                <div style={{ margin: 'auto' }}>
                                    <Bold>שתף: </Bold>
                                </div>
                                <AlignIcons>
                                    <SocialMedia
                                        userId={userId}
                                        id={id}
                                        profession={profession}
                                        expertise={expertise}
                                        firstName={firstName}
                                        lastName={lastName}
                                        prefix={prefix && prefix.prefix}
                                    />
                                </AlignIcons>
                            </SocialPara> */}

              <ProfReco recommendations={recommendations} />
            </>
          )}
        </div>
      </RightWrapper>
      <LeftWrapper>
        {/* <SocialPara>
                    {disabled === false ?
                        <>
                            <div>
                                <Bold>צור קשר: </Bold>
                            </div>
                            <RenderMessages
                                isAuthenticated={isAuthenticated}
                                onMessagePress={onMessagePress}

                            />
                        </>
                        : null
                    }
                    <div>
                        <Bold>שתף: </Bold>
                    </div>
                    <AlignIcons>
                        <SocialMedia
                            userId={userId}
                            id={id}
                            profession={profession}
                            expertise={expertise}
                            firstName={firstName}
                            lastName={lastName}
                            prefix={prefix && prefix.prefix}
                        />
                    </AlignIcons>
                </SocialPara> */}

        <ProfReco recommendations={recommendations} />
      </LeftWrapper>
    </ContentWrapper>
  );
};
const Content = ({
  currentProfessioanl,
  recommendations,
  isAuthenticated,
  scalert,
  setAlert,
  nextStep,
  isProfessional,
  meetingAmount,
  setMeetingAmount,
  userHasTokens,
  userId,
  sendMessageDisabled,
}) => {
  const { meetings } = useSelector((state) => state.meetings);
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const [userHasMeeting, setUserHasMeeting] = useState(false);
  const {
    price,
    education,
    license,
    id,
    firstName,
    lastName,
    prefix,
    experience,
    expertise,
    languages,
  } = currentProfessioanl;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!!meetings) {
      const hasMeetings = findIfUserHasMeetingWithExpert(
        meetings,
        currentProfessioanl.id
      );
      isMounted.current && setUserHasMeeting(hasMeetings);
    }
    // eslint-disable-next-line
  }, [meetings]);

  const alert = (type, message) => {
    setAlert({
      type,
      message,
    });

    setTimeout(() => isMounted.current && setAlert(false), 5000);
  };

  const onScheduleClick = () => {
    if (!isAuthenticated) {
      alert("unAuthWarning", "עליך להירשם תחילה כדי לתאם מפגש. לחץ כאן להרשמה");
    } else if (userHasMeeting){ //Talea
                alert("info", "ניתן לקבוע מפגש נוסף רק לאחר קיום מפגש שכבר תואם ובהתייעצות עם המטפל/ת")
           }
            else {
              dispatch(getSchedule(id));
              nextStep();
            }
  };

  const onCancelClick = () => {
    if (!isAuthenticated) {
      alert("warning", "עליך להכניס שם משתמש וסיסמה כדי לבטל פגישה");
    } else {
      dispatch(closeDialog(OPEN_PROFESSIONAL_DIALOG));
    }
  };

  const onMessagePress = () => {
    if (!isAuthenticated) {
      alert("warning", "עליך להכניס שם משתמש וסיסמה כדי לשלוח הודעה למטפל");
    }
  };

  return (
    <Screen
      userId={userId}
      scalert={scalert}
      alert={scalert}
      languages={languages}
      profession={expertise && expertise.profession}
      expertise={expertise && expertise.firstExpertie.header}
      isAuthenticated={isAuthenticated}
      isProfessional={isProfessional}
      recommendations={recommendations}
      onMessagePress={onMessagePress}
      onScheduleClick={onScheduleClick}
      onCancelClick={onCancelClick}
      license={license}
      experience={experience}
      id={id}
      education={education}
      price={price}
      firstName={firstName}
      lastName={lastName}
      prefix={prefix}
      userHasMeeting={userHasMeeting}
      meetingAmount={meetingAmount}
      setMeetingAmount={setMeetingAmount}
      userHasTokens={userHasTokens}
      disabled={sendMessageDisabled}
    />
  );
};

export default Content;
