import React, { useState, useEffect } from 'react';
import {Dialog, Box} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { closeDialog, openDialog } from '../../actions/DialogActions';
import {OPEN_MESSAGES_DIALOG, HOME, INBOX, WRITE, LEAD_BLUE} from '../../utils/globals';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { MessagesMenu } from '../messages/MessagesMenu';
import NewMessage from '../messages/pages/newMessage/NewMessage';
import Inbox from '../messages/pages/inbox/Inbox';
import { clearAlert, updateScreenToRender, getMessages, getUnReadMessages } from '../../actions/MessagesActions';
import MessagesHome from '../messages/pages/home/MessagesHome';
import SearchBar from '../professionalZone/info/table/searchBar/SearchBar';
import { withRouter } from 'react-router-dom';
import { routeQuery } from '../../utils/routes';
import Spinner from '../layout/Spinner';
import SettingsBar from "../professionalZone/calander/SettingsBar";

const Wrapper = styled(Box)`
    width: auto;
    margin: 0;
    background-color: #F0F0F0;
`;




const MenuOpenIcon = styled(MenuIcon)`
    color: white;
`;

const MenuCloseIcon = styled(CloseIcon)`
    color: white;
`;



const MobileHeader = ({ handleClick, anchorEl, page, openSearch,
                        handleSearchBar, handleChange,onExit }) => {
    return (
        <SettingsBar
        label={"הודעות"}
        onExit={onExit}>

            <Box>
                {page !== "Inbox" && "הודעות"}
                <IconButton onClick={event => handleClick(event)}>
                    {!Boolean(anchorEl) ? <MenuOpenIcon /> : <MenuCloseIcon />}
                </IconButton>
            </Box>
            {page === "Inbox" && <SearchBar 
                open={openSearch}
                setSearchBar={handleSearchBar}
                onChange={handleChange}
            />}
        </SettingsBar>
    )
};

const ScreenHeader = ({ handleClick, anchorEl, page, openSearch, handleSearchBar, handleChange,onExit }) => {

    return (
        <SettingsBar
        label={'הודעות'}
        onExit={onExit}
        >
            {page === INBOX && <SearchBar 
                open={openSearch}
                setSearchBar={handleSearchBar}
                onChange={handleChange}
            />}
        </SettingsBar>
    )
};


const CurrentPage = ({ page, user, filter,
    messagesAlert, messagesLoading, isProfessional, onDialogClose,onBackButton }) => {
    switch (page) {
        case (HOME):
            return (
                <MessagesHome
                    firstName={user && user.firstName}
                    isProfessional={isProfessional}
                    onDialogClose={onDialogClose}
                />
            )
        case (INBOX):
            return (
                <Inbox 
                    messageType="inbox"
                    filter={filter}
                />
            )
        case (WRITE):
            return (
                <NewMessage 
                    messagesAlert={messagesAlert}
                    messagesLoading={messagesLoading}
                    onDialogClose={onDialogClose}
                />
            )
        default: 
            return {};
    }
};

const MessagesModal = ({ history }) => {

    const [ page, setPage ] = useState("Home");
    const [ openSearch, setOpenSearch ] = useState(false);
    const [ filter, setFilter ] = useState('');
    const [ anchorEl, setAnchorEl ] = useState(null);
    const { messagesDialog } = useSelector(state => state.dialogs);
    const { user, isAuthenticated, isProfessional } = useSelector(state => state.auth);
    const { messages, screenToRender, messagesAlert, messagesLoading } = useSelector(state => state.messages);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (screenToRender) {
            setNewPage(screenToRender);
        };
        // eslint-disable-next-line
    }, [screenToRender])
    
    useEffect(() => {
        isAuthenticated && dispatch(getMessages(user.email));    
        // eslint-disable-next-line
    }, [isAuthenticated])
    
    useEffect(() => {
        isAuthenticated && dispatch(getUnReadMessages(user.email));    
        // eslint-disable-next-line
    }, [messages])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pageToRender = urlParams.get('box')?.toUpperCase().split('-')[1];
        pageToRender && setPage(pageToRender);
        user && !messagesDialog && pageToRender && dispatch(openDialog(OPEN_MESSAGES_DIALOG));
        // eslint-disable-next-line
    }, [window.location.search, user])
 
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDialogClose = () => {
        console.log(page)
        if(page==='HOME'){

            dispatch(closeDialog(OPEN_MESSAGES_DIALOG));
            dispatch(clearAlert());
            dispatch(updateScreenToRender(''));
            history.replace(routeQuery({box: undefined}));
        }else{
            setPage('HOME')
            history.replace(routeQuery({box: undefined}));
        }

    };

    const setNewPage = render => {
        setPage(render);
    };

    const handleSearchBar = state => {
        setOpenSearch(state);
        !state && setFilter('');
    };

    const handleChange = event => {
        setFilter(event.target.value);
    };

    return (

        <Dialog
            open={messagesDialog}
            onClose={onDialogClose}
        >
            <Wrapper>

                <AppBar position="static" style={{ backgroundColor: LEAD_BLUE }}>
                    {window.screen.availWidth < 780 ? 
                        <MobileHeader 
                            page={page}
                            handleClick={handleClick}
                            anchorEl={anchorEl}
                            openSearch={openSearch}
                            handleSearchBar={handleSearchBar}
                            handleChange={handleChange}
                            onExit={onDialogClose}
                        /> : 
                        <ScreenHeader 
                            page={page}
                            handleClick={handleClick}
                            anchorEl={anchorEl}
                            openSearch={openSearch}
                            handleSearchBar={handleSearchBar}
                            handleChange={handleChange}
                            onExit={onDialogClose}
                        />
                    }
                    <MessagesMenu 
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        setNewPage={setNewPage}
                    />
                </AppBar>

                {
                    messagesLoading ? <Spinner /> :
                        <CurrentPage 
                            page={page}
                            user={user}
                            filter={filter}
                            messagesAlert={messagesAlert}
                            messagesLoading={messagesLoading}
                            isProfessional={isProfessional}
                            onDialogClose={onDialogClose}
                            onBackButton={()=>setPage(HOME)}
                        />
                }   

            </Wrapper>
        </Dialog>
    );
};

export default withRouter(MessagesModal);
