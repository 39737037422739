import React, { useState } from 'react';
import styled from 'styled-components';
import CustomAlert from '../../../layout/CustomAlert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import HomeIcon from '@material-ui/icons/Home';
import { CircularProgress, MenuItem, Select } from '@material-ui/core';
import { addProfessional, genericEmail, sendSMS } from '../../../../actions/FunctionsActions';
import { addNewDoucment, getEntireDocument } from '../../../../actions/FirestoreActions';
import { LEAD_BLUE, PSYCHOLOGY, MYSTICANS } from '../../../../utils/globals';

const Wrapper = styled(Box)`
    margin: auto;
    height: auto;
`;

const TextFieldWrapper = styled(Grid)`
    margin: 10px;
`;

const ButtonWrapper = styled(Box)`
    margin: 5px;
`;

const AlertWrapper = styled(Box)`
    padding: 10px;
`;

const sendConfirmationSMS = async id => {
    const data = await getEntireDocument('users', id);
    const message = `שלום ${data.data.firstName},\nאנחנו מתרגשים על הצטרפותך לצוות המטפלים של OpenMynd.\nשלחנו לך מייל על הצעד הבא.\nבהצלחה,\nצוות OpenMynd.`;
    await sendSMS({ phoneNumber: data.data.internationalPhone, message });
};

const AddProfessional = ({ error }) => {

    // const [ timer, setTimer ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ alert, setAlert ] = useState(false);
    const [ user, setUser ] = useState({
        email: '',
        firstName: '',
        lastName: '',
        type: '',
    });
    const { email, firstName, lastName, type } = user;

    // useEffect(() => {
    //     return () => {
    //         clearTimeout(timer);
    //     }
    //     // eslint-disable-next-line
    // }, [timer])

    const emailValidation = email => {
        // eslint-disable-next-line
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return filter.test(email);
    };

    const onChange = event => {
        const value = event.target.value;
        const form = event.target.name;
        setUser({
            ...user,
            [form]: value
        });
    };

    const onSubmit = async () => {
        setAlert('');
        if (emailValidation(email) && firstName && lastName && type) {
            setLoading(true);
            const res = await addProfessional(email);
            if (res.id) {
                const data = {
                    id: res.id,
                    firstName,
                    lastName,
                    email,
                    type,
                    firstApproval:false
                }
                const response = await addNewDoucment("professionals", res.id, data);
                const emailRef = genericEmail({email, firstName}, 'newProfessionalApproval');
                const smsRef = sendConfirmationSMS(res.id);
                await Promise.all([ emailRef, smsRef ]);
                setAlert({
                    type: response.type,
                    msg: response.message
                });
            } else {
                setAlert({
                    type: res.type,
                    msg: res.message
                });
            }
            setLoading(false);
        } else {
            setAlert({
                type: 'error',
                msg: 'Invalid data input'
            });
        };
    };

    return (

        <Wrapper>
            <TextFieldWrapper
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        name="email"
                        value={email}
                        onChange={onChange}
                        label="Email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                                ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        name="firstName"
                        value={firstName}
                        onChange={onChange}
                        label="First Name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmojiPeopleIcon />
                                </InputAdornment>
                                ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        name="lastName"
                        value={lastName}
                        onChange={onChange}
                        label="Last Name"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                                ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        name="type"
                        value={type}
                        onChange={onChange}
                        style={{ width: '227px', height: '48px' }}
                    >
                        <MenuItem value={PSYCHOLOGY}>Logic</MenuItem>
                        <MenuItem value={MYSTICANS}>Mystican</MenuItem>    
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    {!email ? null : 
                        loading ? <CircularProgress /> :
                        <ButtonWrapper align="center">
                            <Button
                                variant='contained'
                                style={{color: 'white', backgroundColor: LEAD_BLUE}}
                                onClick={onSubmit}
                            >
                                Add
                            </Button>
                        </ButtonWrapper>
                    }
                </Grid>
            </TextFieldWrapper>
            
            <AlertWrapper>
                {
                    !!error.message ?
                    <CustomAlert 
                        type={error.type}
                        msg={error.message}
                    />
                    :
                    alert.msg &&
                    <CustomAlert 
                        type={alert.type}
                        msg={alert.msg}
                    />
                }
            </AlertWrapper>

        </Wrapper>

    )
}

export default AddProfessional
