
const possibleLanguages = {
  english: "אנגלית",
  russian: "רוסית",
  arabic: "ערבית",
  france: "צרפתית",
  hebrew: "עברית",
  spanish: "ספרדית",
};

export const findIfUserHasMeetingWithExpert = (
  userMeetings,
  professionalId
) => {
  for (const meetings of Object.values(userMeetings)) {
    for (let meeting of meetings) {
      if (meeting.professional.id === professionalId) {
          const strMeetingDate = meeting.date.day + "T" + meeting.date.hour.end + ":00"
          const MeetingDate = new Date(strMeetingDate).getTime()
          const CurrDate = new Date().getTime()
        if (MeetingDate > CurrDate) //Talea
          return true;
      }
    }
  }
  return false;
};

const listToString = (languagesList) => {
  let languagesString = languagesList.toString();
  languagesString = languagesString.split(",").join(", ");
  return languagesString;
};

export const extractEducation = (education) => {
  const educationStrings = [];
  for (const value of Object.values(education)) {
    value && educationStrings.push(value);
  }
  return listToString(educationStrings);
};

export const extractLanguages = (languages) => {
  const chosenLanguagesInHebrew = [];
  for (const [language, value] of Object.entries(languages)) {
    if (language === "other" && !!value) {
      chosenLanguagesInHebrew.push(value);
    } else {
      if (possibleLanguages[language] && !!value) {
        chosenLanguagesInHebrew.push(possibleLanguages[language]);
      }
    }
  }
  return listToString(chosenLanguagesInHebrew);
};

export const publicDetailsForm = (prof, privateDetails, id) => {
  const {
    picture,
    price,
    video,
    license,
    education,
    languages,
    experience,
    prefix,
    isLegalDocConfirmed,
    socialNetwork,
    pending,
  } = prof;

  const { firstName, lastName, email } = privateDetails;

  let { expertise } = prof;

  //take expertise from the register form
  if (!expertise) {
    expertise = {
      profession: privateDetails.profession,
      firstExpertie: {
        header: privateDetails.expertie,
        experienceYears: "",
        description: "",
      },
      secondExpertie: {
        header: "",
        experienceYears: "",
        description: "",
      },
      thirdExpertie: {
        header: "",
        experienceYears: "",
        description: "",
      },
    };
  }

  const form = {
    idState: id,
    lastName: !lastName ? "" : lastName,
    firstName: !firstName ? "" : firstName,
    email: !email ? "" : email,
    isLegalDocConfirmedState: !isLegalDocConfirmed
      ? false
      : isLegalDocConfirmed,
    professionalMeetingURLState: "https://meet.jit.si/" + id,
    prefixState: {
      prefix: !prefix ? "" : prefix.prefix,
    },
    socialNetworkState: {
      linkedin: !socialNetwork ? "" : socialNetwork.linkedin,
      facebook: !socialNetwork ? "" : socialNetwork.facebook,
      other: !socialNetwork ? "" : socialNetwork.other,
    },
    expertiseState: {
      profession: !expertise ? "" : expertise.profession,
      firstExpertie: {
        header: !expertise ? "" : expertise.firstExpertie.header,
        experienceYears: !expertise
          ? ""
          : expertise.firstExpertie.experienceYears,
        description: !expertise ? "" : expertise.firstExpertie.description,
      },
      secondExpertie: {
        header: !expertise ? "" : expertise.secondExpertie.header,
        experienceYears: !expertise
          ? ""
          : expertise.secondExpertie.experienceYears,
        description: !expertise ? "" : expertise.secondExpertie.description,
      },
      thirdExpertie: {
        header: !expertise ? "" : expertise.thirdExpertie.header,
        experienceYears: !expertise
          ? ""
          : expertise.thirdExpertie.experienceYears,
        description: !expertise ? "" : expertise.thirdExpertie.description,
      },
    },
    pictureState: {
      profilePicture: {
        stringName: "profilePicture.png",
        displayURL: !picture ? "" : picture.profilePicture.displayURL,
      },
    },
    videoState: {
      presentationVideo: {
        stringName: "video.mp4",
        displayURL: !video ? "" : video.presentationVideo.displayURL,
      },
    },
    educationState: {
      firstDegree: {
        establishment: !education ? "" : education.firstDegree.establishment,
        title: !education ? "" : education.firstDegree?.title,
        year: !education ? "" : education.firstDegree.year,
      },
      secondDegree: {
        establishment: !education
          ? ""
          : education.secondDegree
          ? education.secondDegree.establishment
          : "",
        title: !education ? "" : education.secondDegree?.title,
        year: !education ? "" : education.secondDegree?.year,
      },
      thirdDegree: {
        establishment: !education ? "" : education.thirdDegree?.establishment,
        title: !education ? "" : education.thirdDegree?.title,
        year: !education ? "" : education.thirdDegree?.year,
      },
    },
    licenseState: {
      defaultLicense: {
        title: !license
          ? ""
          : license.defaultLicense
          ? license.defaultLicense.title
          : "",
        year: !license
          ? ""
          : license.defaultLicense
          ? license.defaultLicense.year
          : "",
        establishment: !license
          ? ""
          : license.defaultLicense
          ? license.defaultLicense.establishment
          : "",
      },
      secondLicense: {
        title: !license ? "" : license.secondLicense?.title,
        year: !license
          ? ""
          : license.secondLicense
          ? license.secondLicense.year
          : "",
        establishment: !license
          ? ""
          : license.secondLicense
          ? license.secondLicense.establishment
          : "",
      },
      ordination: {
        title: !license
          ? ""
          : license.ordination
          ? license.ordination.title
          : "",
        year: !license ? "" : license.ordination ? license.ordination.year : "",
        establishment: !license
          ? ""
          : license.ordination
          ? license.ordination.establishment
          : "",
      },
      secondOrdination: {
        title: !license
          ? ""
          : license.secondOrdination
          ? license.secondOrdination.title
          : "",
        year: !license
          ? ""
          : license.secondOrdination
          ? license.secondOrdination.year
          : "",
        establishment: !license
          ? ""
          : license.secondOrdination
          ? license.secondOrdination.establishment
          : "",
      },
    },
    pending: {
      msg: pending?.msg,
      status: pending?.status,
    },
    languagesState: {
      hebrew: !languages ? "" : languages.hebrew,
      english: !languages ? "" : languages.english,
      russian: !languages ? "" : languages.russian,
      arabic: !languages ? "" : languages.arabic,
      french: !languages ? "" : languages.french,
      spanish: !languages ? "" : languages.spanish,
      other: !languages ? "" : languages.other,
    },
    experienceState: {
      defaultExperience: !experience ? "" : experience.defaultExperience,
    },
    priceState: {
      normalPrice: !price ? "" : price.normalPrice,
      threePackPrice: !price ? "" : price.threePackPrice,
      overWriteMsg: !price?.overWriteMsg? "" : price?.overWriteMsg
    },
  };
  return validateUndefiends(form);
};

/**
 *
 * @param {settingsform object} obj
 * @returns the object without undefiends to avoid crashed replace them with empty sting
 */
const validateUndefiends = (obj) => {
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] == "object") validateUndefiends(obj[property]);
      else {
        if (obj[property] === undefined) {
          obj[property] = "";
        }
      }
    }
  }
  return obj;
};

