import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Quiz from '../quiz/Quiz';
import { closeDialog } from '../../actions/DialogActions';
import { LEAD_BLUE, OPEN_QUIZ_DIALOG } from '../../utils/globals';
import CustomAlert from '../layout/CustomAlert';

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { MobileStepper, CircularProgress } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { genericEmail } from '../../actions/FunctionsActions';

const useStyles = makeStyles({
    root: {
        maxWidth: 250,
        flexGrow: 1,
    },
})

const QuizWrapper = styled(Box)`
    padding: 10px;
    width: 270px;
    font-family: 'Rubik',sans-serif;
    line-height: 1.6;
`;

const StepperWrapper = styled.div`
    display: flex;
    justify-items: center;
    align-items: 10px;
`;

const ButtonWrapper = styled(Box)`
    margin: 5px;
`;

const QuizModal = () => {

    const FINAL_QUIZ_PAGE = 10;

    const classes = useStyles();
    const theme = useTheme();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState('');
    const [alert, setAlert] = useState({
        type: '',
        message: ''
    });
    const [answers, setAnswers] = useState({
        gender: '',
        age: '',
        status: '',
        been: '',
        lonely: '',
        state: '',
        intimy: '',
        friendly: '',
        comfortable: '',
        relation: ''
    });

    const { quizDialog } = useSelector(state => state.dialogs);
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [timer])

    const onDialogClose = () => {
        clearAlert();
        setAnswers({
            gender: '',
            age: '',
            status: '',
            been: '',
            lonely: '',
            state: '',
            intimy: '',
            friendly: '',
            comfortable: '',
            relation: ''
        });
        dispatch(closeDialog(OPEN_QUIZ_DIALOG));
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleBack = () => {
        setPage(page - 1);
    };

    const handleChange = event => {

        const name = event.target.name;
        const value = event.target.value;
        setAnswers({
            ...answers,
            [name]: value
        });
        // handleNext()
    };

    const onSubmit = async () => {
        clearAlert();
        const check = Object.keys(answers).filter(answer =>
            answers[answer] === '' && answer
        );
        if (check.length) {
            setAlert({
                type: 'warning',
                message: "אנא מלאו את כל השדות"
            });
            setTimeout(() => clearAlert(), 5000)
        } else {
            setLoading(true);
            const res = await genericEmail(
                {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    answers
                },
                'sendQuizEmail',
            );
            setLoading(false);
            if (res.type === 'success') {
                setAlert({
                    type: 'success',
                    message: "המידע התקבל, נחזור אלייך עם ההתאמה הטובה ביותר!"
                });
                const timerInstance = () => setTimeout(() => { onDialogClose() }, 3000);
                const timerId = timerInstance();
                setTimer(timerId);
            } else {
                setAlert({
                    type: 'error',
                    message: 'תקלה פנימית, אנא נסה שוב.'
                })
            }
        }
    };

    const clearAlert = () => {
        setAlert({
            type: '',
            message: ''
        });
    };

    return (

        <Dialog
            open={quizDialog}
            onClose={onDialogClose}
            align='right'
        >
            <QuizWrapper>

                <Quiz
                    page={page}
                    handleChange={handleChange}
                    values={answers}

                />

                <StepperWrapper>
                    <MobileStepper
                        variant="dots"
                        steps={10}
                        position="static"
                        activeStep={page - 1}
                        className={classes.root}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={page === 10}>
                                הבא
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={page === 1}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                הקודם
                            </Button>
                        }
                    >
                    </MobileStepper>
                </StepperWrapper>


                {page === FINAL_QUIZ_PAGE ? <ButtonWrapper align="center">
                    {loading ? <Box align='center'><CircularProgress /></Box> :
                        <Button
                            style={{ backgroundColor: LEAD_BLUE, color: 'white' }}
                            variant='contained'
                            onClick={onSubmit}
                        >
                            שלח
                        </Button>
                    }
                </ButtonWrapper> : ''}

                {
                    !!alert.message &&
                    <CustomAlert
                        type={alert.type}
                        msg={alert.message}
                    />
                }

            </QuizWrapper>
        </Dialog>

    );
};

export default QuizModal
