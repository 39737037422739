import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Box';
import styled from 'styled-components';
import { LEAD_BLUE } from '../../../../utils/globals';

const MainWrapper = styled(Box)`
    
    direction: rtl;
    font-family: 'Rubik', Sens-Serif,sans-serif;
    width: 100%;
    @media (min-width: 1050px){
        min-width:780px !important;
    }
`;

const Header = styled.p`
    font-family: 'Rubik', Sens-Serif,sans-serif;
    font-size: 20px;
    text-align: right;

    margin: 5px;
    padding: 2px;
    color: ${LEAD_BLUE};
`;

const TextWrapper = styled(Box)`
    margin: 4px;
    padding: 4px;
`;

const Text = styled(Typography)`
    font-family: 'Rubik', Sens-Serif,sans-serif;
    text-align: center;
`;


const CalanderHeader = ({ header, description, style }) => {

    return (
        <MainWrapper>
            <Header>
                {header}
            </Header>
            <TextWrapper>
                <Text style={style}> 
                    {description}
                </Text>
            </TextWrapper>
        </MainWrapper>
    )
}

export default CalanderHeader
