import React from 'react';
import Toggler from '../Toggler';
import HomeHeader from './HomeHeader';
import styled from 'styled-components';
import SettingsBar from '../calander/SettingsBar';
import {closeDialog} from "../../../actions/DialogActions";
import {OPEN_PROFESSIONAL_ZONE_DIALOG} from "../../../utils/globals";
import {useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {routeQuery} from "../../../utils/routes";

const MainWrapper = styled.div`
    display: grid;
    align-items: 10px;
    justify-items: center;
    @media(min-width: 1050px){

        min-width: 780px;
    };
`;

const Home = ({
                  changePage, currentPage, onExit,
                  fetchedPrivateDetails, isProfessional, history
              }) => {
    const dispatch = useDispatch()
    const closePrivateZone = () => {
        dispatch(closeDialog(OPEN_PROFESSIONAL_ZONE_DIALOG))
        history.replace(routeQuery({privatePage: undefined}));
    }

    return (
        <MainWrapper>
            <SettingsBar label="אזור אישי" onExit={closePrivateZone}/>
            <HomeHeader
                firstName={fetchedPrivateDetails.firstName}
                lastName={fetchedPrivateDetails.lastName}
                prefix={fetchedPrivateDetails.prefix}
                isProfessional={isProfessional}
            />
            <Toggler
                changePage={changePage}
                currentPage={currentPage}
                isProfessional={isProfessional}
            />
        </MainWrapper>
    );
};

export default withRouter(Home)
