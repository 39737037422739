import React, { useState } from "react";
import { DARK_BLUE, LEAD_BLUE } from "../../utils/globals";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../static/images/geniePictures/Logo.png";

import {
  AppBar,
  Button,
  Box,
  Hidden,
  Grid,
  Menu,
  MenuItem,
  ClickAwayListener,
  MenuList,
} from "@material-ui/core";
import styled from "styled-components";
import NavButton from "./navbar/NavButton";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { routeQuery } from "../../utils/routes";
import { useMediaQuery } from "react-responsive";
import SearchIcon from "@material-ui/icons/Search";
import "../../App.css";

import ReactTooltip from "react-tooltip";

const NavWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 999;
  @media (max-width: 600px) {
    height: min-content;
    padding-top: 0px;
  } ;
`;


const LogoStyle = styled.img`

width:200px;
height: 100px;

@media(max-width : 600px){
  width: 100px;
  height: 50px;
}
  
`;
const LinksWrapper = styled(Box)`
  display: flex;
  align-items: center;
  direction: rtl;
`;

const ButtonWrapper = styled(Box)`
  width: auto;
  padding-left: 8px;
`;

const StyledButton = styled(Button)`
  font-family: "Rubik", Sans-Serif;
  font-size: 16px;
`;

const LoginButton = styled(Button)`
 
  background-color: rgb(100, 130, 0) !important;
  color: white;
  font-family: "Rubik", Sans-Serif;
  font-weight: bold;
  &:hover {
    color: black;
    background-color: rgba(225, 225, 225, 0) !important;
  }
`;

const RegisterButton = styled(Button)`
  margin-right: 10px;
  border-color: ${LEAD_BLUE};
  font-family: "Rubik", Sans-Serif;
  color: white;
  background-color: ${DARK_BLUE};

  &:hover {
    color: black;
  }
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  direction: rtl;
`;

const LogoLinkWrapper = styled.a`
  margin: 20px;

  @media (max-width: 587px) {
    margin-top: 0px;
  } ;
`;
const BoxWrapper = styled.div`
  display: flex;
`;
const NavLogo = () => {
  return (
    <LogoLinkWrapper href="/">
      <LogoStyle
      src={Logo}
      alt="Logo"/>
    </LogoLinkWrapper>
  );
};
const SearchContainer = styled.form`
  position: relative;
  float: right;
  margin-left: 12px;
  margin-right: 8px;
  @media (max-width: 1224px) {
    margin: 0;
  }
`;

const inputStyle = {
  direction: "rtl",
  padding: "6px",
  fontSize: "17px",
  border: `1px solid ${LEAD_BLUE}`,
  borderRadius: "5px",
  width: "130px",
};

const Text = styled.p`
  margin-right: 4px;
  padding: 0;
  font-family: "Ariel", Sens-Serif;
`;

const iconStyle = {
  marginLeft: "2px",
  color: DARK_BLUE,
  fontSize: "30px",
  position: "absolute",
  bottom: 2,
  left: -1,
};

const NavButtonWrapper = ({
  user,
  anchorEl,
  handleClick,
  handleClose,
  isProfessional,
}) => {
  return (
    <>
      {user && (
        <BoxWrapper>
          <Box p={1}>
            <NavButton
              firstName={user.firstName}
              lastName={user.lastName}
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              isProfessional={isProfessional}
            />
          </Box>
        </BoxWrapper>
      )}
    </>
  );
};

const LoginRegisterWrapper = () => {
  
  return (
    <>
      <LinksWrapper>
        <ButtonWrapper>
          <LinkWrapper to={routeQuery({ box: "login" })}>
            <LoginButton variant="outlined">התחבר כאן</LoginButton>
          </LinkWrapper>
        </ButtonWrapper>
        {/* <ButtonWrapper>
                    <LinkWrapper to={routeQuery({box: 'register'})}>
                        <RegisterButton
                            variant="outlined"
                        >
                            התחל&nbsp;כאן
                        </RegisterButton>
                    </LinkWrapper>
                </ButtonWrapper> */}
      </LinksWrapper>
    </>
  );
};

const Screen = ({
  isAuthenticated,
  links,
  user,
  anchorEl,
  handleClick,
  handleClose,
  isProfessional,
  dispatch,
}) => {
  return (
    <AppBar position="static">
      <NavWrapper>
        <Box m={1}>
          {isAuthenticated ? (
            <NavButtonWrapper
              user={user}
              anchorEl={anchorEl}
              handleClick={handleClick}
              handleClose={handleClose}
              isProfessional={isProfessional}
            />
          ) : (
            <LoginRegisterWrapper dispatch={dispatch} />
          )}
        </Box>
        {links}
      </NavWrapper>
    </AppBar>
  );
};

const Mobile = ({
  isAuthenticated,
  links,
  user,
  anchorEl,
  handleClick,
  handleClose,
  isProfessional,
}) => {
  return (
    <AppBar position="static">
      <NavWrapper>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            {isAuthenticated ? (
              <NavButtonWrapper
                user={user}
                anchorEl={anchorEl}
                handleClick={handleClick}
                handleClose={handleClose}
                isProfessional={isProfessional}
              />
            ) : (
              <Box style={{ display: "flex" }}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <svg viewBox="0 0 100 80" width="30" height="30">
                    <rect width="80" height="10"></rect>
                    <rect y="30" width="80" height="10"></rect>
                    <rect y="60" width="80" height="10"></rect>
                  </svg>
                </Button>

                <Menu
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  handleClick={handleClick}
                  handleClose={handleClose}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <LinkWrapper to={routeQuery({ box: "login" })}>
                        <MenuItem onClick={handleClose}>כניסה</MenuItem>
                      </LinkWrapper>
                      <LinkWrapper to={routeQuery({ box: "register" })}>
                        <MenuItem onClick={handleClose}>הרשמה</MenuItem>
                      </LinkWrapper>
                      <LinkWrapper to="/about">
                        <MenuItem onClick={handleClose}>אודות</MenuItem>
                      </LinkWrapper>
                      <LinkWrapper to="/faq">
                        <MenuItem onClick={handleClose}>שאלות נפוצות</MenuItem>
                      </LinkWrapper>
                    </MenuList>
                  </ClickAwayListener>
                </Menu>
                <SearchForm />
              </Box>
            )}
          </Grid>
        </Grid>
        {links}
      </NavWrapper>
    </AppBar>
  );
};

const SearchForm = () => {
  const [fireRedirect, setRedirect] = useState(false);
  const [input, setInput] = useState("");

  const submitForm = (e) => {
    e.preventDefault();

    setRedirect(true);
  };
  return (
    <LinksWrapper>
      <div style={{ paddingBottom: "3px" }}>
        <a data-tip data-for="searchBar" href="/#" >
          <SearchContainer
            onSubmit={(e) => {
              submitForm(e);
            }}
          >
            <input
              type="text"
              name="search"
              style={inputStyle}
              placeholder={"חפש "}
              onChange={(e) => {
                setInput(e.target.value);
                setRedirect(false);
              }}
            />

            <SearchIcon style={iconStyle} onClick={(e) => submitForm(e)} />
          </SearchContainer>
        </a>
        {input === "" ? (
          <ReactTooltip
            id="searchBar"
            type="info"
            className="tooltip"
            scrollHide={false}
            place="top"
          >
            <Text style={{ margin: "0px", padding: "0px", width: "150px" }}>
              ניתן לחפש לפי שם מטפל, עיסוק, התמחות או שפה.
            </Text>
          </ReactTooltip>
        ) : (
          ""
        )}
      </div>
      {fireRedirect && <Redirect to={"/search?name=" + input} />}
    </LinksWrapper>
  );
};

const Navbar = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  // const [page, setPage] = useState(window.location.pathname);

  // useEffect(() => {
  //   setPage(window.location.pathname);
  //   // eslint-disable-next-line
  // }, [window.location.pathname]);

  const dispatch = useDispatch();
  const { isAuthenticated, isProfessional, user } = useSelector(
    (state) => state.auth
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const links = (
    <LinksWrapper>
      <NavLogo />
      <Hidden xsDown>
        <ButtonWrapper>
          <StyledButton href="/about">אודות</StyledButton>
        </ButtonWrapper>
      </Hidden>

      <Hidden xsDown>
        <ButtonWrapper>
          <StyledButton href="/faq">שאלות&nbsp;נפוצות</StyledButton>
        </ButtonWrapper>
      </Hidden>
      {isTabletOrMobileDevice ? null : <SearchForm />}
      <Hidden xsDown>
        <ButtonWrapper>
          {isAuthenticated ? null : (
            <LinkWrapper to={routeQuery({ box: "pro_registeration" })}>
              <RegisterButton variant="outlined">הרשמת מטפלים</RegisterButton>
            </LinkWrapper>
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          {isAuthenticated ? null : (
            <LinkWrapper to={routeQuery({ box: "user_registeration" })}>
              <RegisterButton variant="outlined">הרשמת לקוחות</RegisterButton>
            </LinkWrapper>
          )}
        </ButtonWrapper>
      </Hidden>
    </LinksWrapper>
  );

  return window.screen.availWidth > 600 ? (
    <Screen
      isAuthenticated={isAuthenticated}
      links={links}
      user={user}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      isProfessional={isProfessional}
      dispatch={dispatch}
    />
  ) : (
    <Mobile
      isAuthenticated={isAuthenticated}
      links={links}
      user={user}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleClose={handleClose}
      isProfessional={isProfessional}
      dispatch={dispatch}
    />
  );
};

export default Navbar;
