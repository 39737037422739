import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routeQuery } from "../../../../utils/routes";
import { RED } from "../../../../utils/globals";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

const iconStyle = {
    width: "32px",
    height: "32px",
};

const links = [
    {
        icon: "https://img.icons8.com/ios/50/000000/send-mass-email.png",
        route: { box: "messages-write" },
        text: "כתוב הודעה",
    },
    {
        icon: "https://img.icons8.com/ios/50/000000/email-open.png",
        route: { box: "messages-inbox" },
        text: "קרא הודעות",
    },
];

const ScreenWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
  direction: rtl;
  font-family: "Rubik", Sens-Serif;
  @media (max-width: 600px) {
    display: grid;
    justify-items: center;
  }
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: black;
`;

const Paragraph = styled.div`
  padding: 10px;
  margin: 10px;
`;

const MessagesLinksWrapper = styled.div`
  display: grid;
  justify-items: flex-start;
  align-items: center;
  padding: 10px;
  grid-gap: 10px;
`;

const MessageLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Text = styled.p`
  margin-right: 4px;
  padding: 0;
  font-family: "Rubik", Sens-Serif;
`;

const Notification = styled(Box)`
  position: absolute;
  z-index: 0;
  margin-right: -15px;
  margin-bottom: 20px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: white;
  text-align: center;

  background-color: ${RED};
  font-family: "Arial", serif;
`;

const MessagesLinks = () => {
    const { unreadMessages } = useSelector((state) => state.messages);
    return (
        <MessagesLinksWrapper>
            {links.map((link) => (
                <LinkWrapper key={link.text} to={routeQuery(link.route)}>
                    <MessageLinkWrapper>
                        <img src={link.icon} alt="icon" style={iconStyle} />

                        <Text>{link.text}</Text>
                        {link.text === "קרא הודעות" && unreadMessages > 0 ? (
                            <Notification>{unreadMessages}</Notification>
                        ) : (
                            ""
                        )}
                    </MessageLinkWrapper>
                </LinkWrapper>
            ))}
        </MessagesLinksWrapper>
    );
};

const Screen = ({ changePage, currentPage, isProfessional, onDialogClose }) => {
    return isProfessional ? (
        <ScreenWrapper>
            <div onClick={onDialogClose}></div>

            <Paragraph>
                רק הלקוחות שלך יכולים לשלוח לך הודעה ומכאן ניתן לתקשר איתם. אנו מבהירים
                לכל הלקוחות שאנחנו מכבדים את זמנם של המטפלים וייתכן שהמענה שלהם לא יהיה
                מיידי.
            </Paragraph>
            <MessagesLinks />
        </ScreenWrapper>
    ) : (
        <ScreenWrapper>
            <div onClick={onDialogClose}></div>

            <Paragraph>
                מכאן ניתן לכתוב למטפלים שלך ולקבל מהם הודעות. אנו מכבדים את זמנם של
                המטפלים וייתכן שהמענה שלהם לא יהיה מיידי.
            </Paragraph>
            <MessagesLinks />
        </ScreenWrapper>
    );
};

const MessagesHome = ({
    changePage,
    currentPage,
    isProfessional,
    onDialogClose,
}) => {
    return (
        <>
            <Screen
                changePage={changePage}
                currentPage={currentPage}
                isProfessional={isProfessional}
                onDialogClose={onDialogClose}
            />
        </>
    );
};

export default MessagesHome;
