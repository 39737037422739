import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateSchedule} from '../../../actions/ScheduleActions';
import {getMessagesObjects, getSMSContent} from './paymentActions';
import {postMessage} from '../../../actions/MessagesActions';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {getEntireDocument} from '../../../actions/FirestoreActions';
import {
    sendModifyURLEmail,
    sendSMS,
    sendCancelURLEmail,
    sendProModifyURLEmail
} from '../../../actions/FunctionsActions';
import styled from 'styled-components';
import {MODIFY, CANCEL, LEAD_BLUE,} from '../../../utils/globals';
import {openOptionsDialog} from '../../../actions/DialogActions';
import {createEmailObject, removeChoosenDate, updateModifiedMeeting} from './paymentHelpers';
import CancelMeeting from './paymentOptions/CancelMeeting';
import ModifyMeeting from './paymentOptions/ModifyMeeting';
import {getMeetings} from "../../../actions/MeetingsActions";
import NewMeeting from "./paymentOptions/newMeeting/NewMeeting";

export const Wrapper = styled(Box)`
  display: grid;
  direction: rtl;

  font-family: 'Rubik', Sens-Serif;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  margin: 5px;
`;

export const ButtonStyle = styled(Button)`
  margin: 5px;
  padding: 5px;
  color: white;
  background-color: ${LEAD_BLUE};
`;

export const PaymentBoiler = ({
                                  prevStep,
                                  schedule,
                                  day,
                                  hour,
                                  professionalId,
                                  price,
                                  proFirstName,
                                  proLastName,
                                  proEmail,
                                  modifyAnExistingMeeting,
                                  meetingObject,
                                  type,
                                  cancelMeeting,
                                  onExit,
                                  handleChangeState,
                                  handleNext,
                                  handleBack,
                                  resetStepper,
                                  professionalURL,
                                  meetingAmount,
                                  setAppointment,
                                  sendConfirmationEmails,
                                  oldMeetingObject
                              }) => {


    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const {user, isProfessional, isNormalUser} = useSelector(state => state.auth);
    const {firstName, lastName, email, id} = user;
    const [userPhone, setUserPhone] = useState('');
    const [confirmationState, setConfirmationState] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [ongoingPayment, setOngoingPayment] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        const getPhone = async () => {
            if (isNormalUser) {
                setLoading(true);
                const user = await getEntireDocument('users', id);
                user.data.localPhone && setUserPhone(user.data.localPhone);
                setLoading(false);
            }
        }
        getPhone();
        // eslint-disable-next-line
    }, [])

    const sendConfirmationMessages = messages => {
        const {clientMessage, profMessage} = messages;
        dispatch(postMessage(profMessage.receiver.email, profMessage));
        dispatch(postMessage(clientMessage.receiver.email, clientMessage));
    };

    const sendSMSmessages = async state => {
        const messages = getSMSContent(meetingObject, state, day, hour);
        const clientRef = getEntireDocument("users", meetingObject.client.clientId);
        const professionalRef = getEntireDocument("users", meetingObject.professional.id);
        const res = await Promise.all([clientRef, professionalRef]);
        const clientPhoneNumer = res[0].data.internationalPhone;
        const professionalPhoneNumber = res[1].data.internationalPhone;
        const clientSMSRef = sendSMS({phoneNumber: clientPhoneNumer, message: messages.clientContent});
        const professionalSMSRef = sendSMS({phoneNumber: professionalPhoneNumber, message: messages.profContent});
        await Promise.all([clientSMSRef, professionalSMSRef]);
    };


    const onModifyClick = async (oldMeetingObject) => {
        setLoading(true);
        handleChangeState(true);
        //notify emails
        const confirmationObject = await createEmailObject(professionalURL, day, hour, oldMeetingObject.client, proEmail, proFirstName, proLastName, oldMeetingObject)
        try {
            //if the professional has made the change
            if (isProfessional) {
                //Pro confirm && user notify
                await sendProModifyURLEmail(confirmationObject)
            } else {
                //user has made a change,notify the professional
                await sendModifyURLEmail(confirmationObject);
            }
            const messages = getMessagesObjects(meetingObject, MODIFY);
            //sends messages in site mailbox system
            sendConfirmationMessages(messages);
            await sendSMSmessages(MODIFY);
            await updateModifiedMeeting(MODIFY, professionalId,
                meetingObject, day, hour);
            const newSchedule = removeChoosenDate(schedule, day, hour);
            dispatch(updateSchedule(professionalId, newSchedule));

            setLoading(false);
            handleChangeState(false);

            setNewAlert('success','המפגש שונה בהצלחה')

            const optionsDialogObject = {
                show: false,
            }

            setTimeout(() => {
                dispatch(getMeetings('userMeetings', user.id))
                dispatch(openOptionsDialog(optionsDialogObject));
            }, 2000)
            //   nextStep();
        } catch (error) {
            console.error("An error took place while trying to modify: ", error);
            setLoading(false);
            handleChangeState(false);
            setError({
                type: 'error',
                msg: error,
                display: true
            });
        }
        ;
    };

    const onCancelClick = async () => {
        setLoading(true);
        handleChangeState(true);
        try {
            await updateModifiedMeeting(CANCEL, professionalId,
                meetingObject, day, hour);
            await sendCancelURLEmail(meetingObject);

            const messages = getMessagesObjects(meetingObject, CANCEL);
            await sendConfirmationMessages(messages);
            //  await sendSMSmessages(CANCEL);
            setLoading(false);
            handleChangeState(false);

        } catch (error) {
            console.error("An error took place while trying to modify: ", error);
            setLoading(false);
            handleChangeState(false);
            setError({
                type: 'error',
                msg: error,
                display: true
            });
        }

        const optionsDialogObject = {
            show: false,
        }
        dispatch(openOptionsDialog(optionsDialogObject));
        dispatch(getMeetings('userMeetings', user.id))

    };

    const onModifyMove = () => {
        const optionsDialogObject = {
            show: true,
            meetingObject: meetingObject,
            screenToRender: MODIFY
        }
        dispatch(openOptionsDialog(optionsDialogObject));
    }

    const handleConfirmation = () => {
        setConfirmationState(!confirmationState)
    };


    const setNewAlert = (type, message) => {
        setLoading(false);
        setError({
            type,
            message
        });
    };

    return (
        <>
            {
                modifyAnExistingMeeting ?
                    cancelMeeting ?
                        <CancelMeeting
                            onCancelClick={onCancelClick}
                            loading={loading}
                            error={error}
                            onModifyMove={onModifyMove}
                            handleConfirmation={handleConfirmation}
                            confirmationState={confirmationState}
                            sendConfirmationEmails={sendConfirmationEmails}
                        /> :
                        <ModifyMeeting
                            isProfessional={isProfessional}
                            proFirstName={proFirstName}
                            proLastName={proLastName}
                            firstName={firstName}
                            lastName={lastName}
                            modifyAnExistingMeeting={modifyAnExistingMeeting}
                            onModifyClick={onModifyClick}
                            prevStep={prevStep}
                            loading={loading}
                            error={error}
                            day={day}
                            hour={hour}
                            sendConfirmationEmails={sendConfirmationEmails}
                            oldMeetingObject={oldMeetingObject}
                        /> :
                    <NewMeeting
                        onPayClick={setAppointment}
                        prevStep={prevStep}
                        handleNext={handleNext}
                        loading={loading}
                        error={error}
                        type={type}
                        handleBack={handleBack}
                        price={price}
                        onExit={onExit}
                        ongoingPayment={ongoingPayment}
                        fullName={`${firstName} ${lastName}`}
                        email={email}
                        phone={userPhone}
                        userID={user}
                        resetStepper={resetStepper}
                        meetingAmount={meetingAmount}
                        sendConfirmationEmails={sendConfirmationEmails}
                    />
            }
        </>

    )
};

export default PaymentBoiler
