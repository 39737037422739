import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid';
import { TextField, Box, Typography, Button } from '@material-ui/core'
import CustomAlert from '../../../layout/CustomAlert';
import Spinner from '../../../layout/Spinner';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import {LEAD_BLUE} from '../../../../utils/globals';
import { useDispatch, useSelector } from 'react-redux';
import {clearAlert, postMessage} from '../../../../actions/MessagesActions';
import { getMeetings } from '../../../../actions/MeetingsActions';
import { getRelevantContacts } from '../inbox/InboxHelpers';

const Wrapper = styled(Box)`
    direction: rtl;
    font-family: 'Rubik', Sens-Serif;
    min-width: 490px;
    @media (max-width: 600px) {
        min-width: 300px;
    }
`;

const MenuWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const TextFieldWrapper = styled(Box)`
    margin: 10px;
    padding: 10px;
`;

const Label = styled(Typography)`
    font-size: 16px;
    font-family: 'Rubik', Sens-Serif;
    color: ${LEAD_BLUE};
    padding: 8px;
`;

const ButtonsWrapper = styled(Box)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    padding: 10px;
`;

const AlertWrapper = styled(Box)`
    margin: 16px;
    padding: 16px;
`;

const ContactOptions = ({ isNormalUser, handleClick, anchorEl,
    handleClose, contacts }) => {

    return (

        <>
            {

                    <>
                        <Button onClick={handleClick}>
                            <AccountCircle />
                        </Button>
                        <Menu
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                        >
                            {
                                contacts.map(contact => 
                                    <MenuItem 
                                        onClick={() => handleClose(contact)}
                                        key={contact.id}
                                        style={{direction: 'rtl'}}
                                    >
                                        {contact.name}
                                    </MenuItem>
                                )
                            }
                        </Menu>
                    </>
            }
        </>
    );
};

const To = ({ name, to, contacts, isNormalUser, handleChange,
    handleClose, handleClick, anchorEl }) => {
    return (
        <TextFieldWrapper>
            <Label>אל</Label>
            <MenuWrapper>
                <TextField
                    name="to"
                    variant="outlined"
                    value={isNormalUser ? name : name}
                    onChange={isNormalUser ? null : event => handleChange(event)}
                    disabled={isNormalUser ? true : false}
                />
                <ContactOptions 
                    contacts={contacts}
                    isNormalUser={isNormalUser}
                    handleClick={handleClick}
                    handleClose={handleClose}
                    anchorEl={anchorEl}
                />
            </MenuWrapper>
        </TextFieldWrapper>
    );
};

const NewMessage = ({ messagesAlert, messagesLoading,onDialogClose }) => {

    const { user, isAuthenticated, isNormalUser } = useSelector(state => state.auth);
    const { professionalToPresent } = useSelector(state => state.professionals);
    const { meetings } = useSelector(state => state.meetings);
    const [ contacts, setContacts ] = useState([]);
    const [ message, setMessage ] = useState({
        to: professionalToPresent.email ? professionalToPresent.email : '',
        name: '',
        subject: '',
        content: ''
    });
    const [ alert, setAlert ] = useState({
        message: '',
        type: ''
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const { to, subject, content, name } = message;
    const dispatch = useDispatch();

    useEffect(() => {
        if (professionalToPresent.email) {
            setMessage({
                ...message,
                name: `${professionalToPresent.prefix && professionalToPresent.prefix.prefix} ${professionalToPresent.firstName} ${professionalToPresent.lastName}`
            })
        } else {
            if (isNormalUser) {
                dispatch(getMeetings("userMeetings", user.id));
            }else{
                dispatch(getMeetings("professionalMeetings", user.id));
            }
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (Object.keys(meetings).length) {
            const contact = getRelevantContacts(meetings,isNormalUser);
            setContacts(contact);
        }
        // eslint-disable-next-line
    }, [meetings])

    const handleChange = event => {
        const value = event.target.value;
        const name = event.target.name;
        setMessage({
            ...message,
            [name]: value
        });
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = contact => {
        !!contact && setMessage({
            ...message,
            to: contact.email,
            name: contact.name,
        })
        setAnchorEl(null);
    };

    const emailValidation = to => {
        // eslint-disable-next-line
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return filter.test(to);
    };

    const validateForms = () => {
        const emailCheck = emailValidation(to);
        if (!!emailCheck) {
            if (content !== '' && subject !== '') {
                return true
            }
        }
        setAlert({
            type: 'error',
            message: 'עלייך למלא את שורת הנושא ואת תוכן הודעתך'
        })
        return false;
    };

    const sendMessage = async () => {
        setAlert({type: '', message: ''});
        dispatch(clearAlert());
        if (validateForms()) {
            const messageObject = {
                message: {
                    id: v4(),
                    data: {
                        subject,
                        content
                    },
                    date: {
                    day: new Date().toISOString().slice(0, 10),
                    hour: new Date().toLocaleTimeString()
                    },
                    isOpened: false,
                    inInbox: "inbox",
                    isReplyable: true
                },
                sender: {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email
                },
            }
            dispatch(postMessage(to, messageObject));
            onDialogClose();

        };
    };



    return (
        <Wrapper>
            <To 
                contacts={contacts}
                name={name}
                to={to}
                anchorEl={anchorEl}
                isNormalUser={isNormalUser}
                handleChange={handleChange}
                handleClick={handleClick}
                handleClose={handleClose}
            />
            <TextFieldWrapper>
                <Label>נושא</Label>
                <TextField
                    name="subject"
                    onChange={event => handleChange(event)}
                    variant="outlined"
                    value={subject}
                />
            </TextFieldWrapper>
            <TextFieldWrapper>
                <Label>הודעה</Label>
                <TextField
                    name="content"
                    variant="outlined"
                    multiline
                    rows={8}
                    fullWidth={window.screen.availWidth > 780 ? true : false}
                    onChange={event => handleChange(event)}
                    value={content}
                  /*  InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MessageIcon />
                            </InputAdornment>
                        ),
                    }}*/
                />
            </TextFieldWrapper>

            {!isAuthenticated ? 
                <AlertWrapper>
                    <CustomAlert 
                        type="error" 
                        msg="בבקשה התחבר על מנת לשלוח הודעה"
                    /> 
                </AlertWrapper> :
             messagesLoading ? <Spinner /> :
                <ButtonsWrapper align="center">
                    <Button
                        onClick={sendMessage}
                        variant="contained"
                        style={{backgroundColor: LEAD_BLUE, color: 'white'}}
                    >
                        שלח
                    </Button>
                </ButtonsWrapper>
            }
            {
                !!messagesAlert.message &&
                    <AlertWrapper>
                        <CustomAlert 
                            type={messagesAlert.type}
                            msg={messagesAlert.message}
                        />
                    </AlertWrapper>
            }
            {
                !!alert.message &&
                    <AlertWrapper>
                        <CustomAlert 
                            type={alert.type}
                            msg={alert.message}
                        />
                    </AlertWrapper>
            }
            
        </Wrapper>
    );
};

export default NewMessage
