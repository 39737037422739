import React from 'react';
import styled from 'styled-components';
import { AVAILABLE_TODAY, DARK_BLUE, DEPRESSIONANXIETY, EATINGDISORDERS, FAMILY, GERIATRICS, KIDSANDYOUTH, OTHER, WORKCAREER } from '../../../utils/globals';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { motion } from "framer-motion";
import Grid from "@material-ui/core/Grid";

const TitleWrapper = styled.div`
  direction: rtl;
  display: flex;
  font-weight: bold;
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  text-align: center;
  justify-content: center;
  color: ${DARK_BLUE};

  @media (max-width: 600px) {
    align-content: center;
    font-size: 18px;
    margin-right: 0px;

  }
`;

const Title = styled.p`
  
  color: RGB(100, 130,0);
  opacity:0.8;
  

  //color:#3E72E6;
  @media (max-width: 600px) {
    margin-block-end: 0px;
  }
`;

const SubTitle = styled(Typography)`
  direction: rtl;
  justify-content: center;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  color: white;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;


const CardStyle = styled.a`

  display: grid;
  justify-items: center;
  direction: rtl;
  border-radius: 10%;
  align-items: center;
  width: 125px;
  height: 80px;
  max-width: 100%;
  max-height: 100%;
  margin: 10px;
  text-decoration: none;
  background-color: ${DARK_BLUE};
  opacity:0.8;
  /*    box-shadow: 
          0 5px 5px 0px rgba(64,44,23,1),
          -5px 5px 0 0 rgba(128,113,92,1); */
  @media (max-width: 600px) {
    width: 90px;
    height: 70px;
    margin: 0px;
    margin-top: 15px;


  }

`;

const TextWrapper = styled.div`
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;

`;


const ScreenMainWrapper = styled.div`

  width: 100%;


  @media (max-width: 600px) {
    justify-content: space-between;
    align-items: start;
    margin: 0 0px 0 0;
    padding: 0;

  }


`;
const SubBold = styled.p`
  direction: rtl;
  font-size: 24px;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  padding: 5px;
  margin: 0;
  color: white;

  @media (max-width: 600px) {
    font-size: 17px;
    padding: 0;
  };
`;

const ButtonStyle = styled(motion.button)`

  direction: ltr;
  background-color: rgb(30, 55, 100);
  padding: 15px;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  margin: 20px 0;
  /*   box-shadow: 
         0px 3px 5px 0px rgba(64,44,23,1),
         -4px 0px 0px 0px rgba(128,113,92,1);*/
  @media only screen and (max-width: 600px) {
    height: 90px;
    width: 90px;
    color: #fdfdfd;
    margin: 0;


  }
`;

const subStyle = {
  //  backgroundColor: "rgb(110,110,0)"
  backgroundColor: "rgb(100,130,0)"
}

const JoinButton = () => {

  return (

    <div style={{
      display: 'grid',
      justifyContent: 'center',
      maxHeight: '30px',

    }}>
      <a href={'experts/AvailableToday'}
      >
        <ButtonStyle
          style={subStyle}
          initial="hidden"
          animate="visable"
        >
          <SubBold>
            מי פנוי היום?
          </SubBold>
        </ButtonStyle>
      </a>
    </div>

  );

};

const TitleAnimation = () => {
  return (
    <TitleWrapper>
      <Title>איזה מטפל מתאים לך?</Title>
    </TitleWrapper>
  )
};

const Card = ({ categoryName, to }) => {
  return (
    <div style={{
      display: "flex",
      justifyContent: 'center',
      textAlign: 'center',
      padding: '5px'
    }}>
      <CardStyle
        href={to}
      >
        <TextWrapper>
          <SubTitle>{categoryName}</SubTitle>{" "}
        </TextWrapper>
      </CardStyle>
    </div>
  )
};

//Links
const baseURL = 'experts/'
const Screen = ({ isAuthenticated }) => {
  return (
    <ScreenMainWrapper>
      <Grid container
        direction="row"
        justify="space-evenly"
        alignItems="center"
      >
        <Grid item xs={1} />
        <Grid item xs={5} >
          <Card
            categoryName={"משפחה וזוגיות"}
            to={baseURL + FAMILY}
          />
        </Grid>
        <Grid item xs={5}>
          <Card
            categoryName={"ילדים ונוער"}
            to={baseURL + KIDSANDYOUTH}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Grid container
        alignContent='center'
      >
        <Grid item xs={5}>
          <Card
            categoryName={"דיכאון וחרדה"}
            to={baseURL + DEPRESSIONANXIETY}
          />
        </Grid>

        <Grid item xs={2}>
          <JoinButton
            isAuthenticated={isAuthenticated}
            categoryName={" מי פנוי היום"}
            to={baseURL + AVAILABLE_TODAY}
          />
        </Grid>
        <Grid item xs={5}>
          <Card
            categoryName={"הפרעות אכילה"}
            to={baseURL + EATINGDISORDERS}
          />
        </Grid>

      </Grid>
      <Grid container
        direction="row"
        justify="space-evenly"
        alignItems="center"

      >
        <Grid item xs={1} />
        <Grid item xs={5}>
          <Card
            categoryName={"עבודה וקריירה"}
            to={baseURL + WORKCAREER}
          />
        </Grid>
        <Grid item xs={5}>
          <Card
            categoryName={"פסיכו גריאטריה"}
            to={baseURL + GERIATRICS}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid container
        direction="row"
        justify="center"
        alignItems="center"

      >
        <Grid item xs={12} style={{marginTop:'-20px'}}>
        <Card
            categoryName={"מטפלים נוספים"}
            to={baseURL + OTHER}
          />
        </Grid>
      </Grid>

    </ScreenMainWrapper>


  )
};


const Categories = ({ isAuthenticated }) => {

  return (

    <Container maxWidth="md" id="categories" style={{ marginTop: window.innerWidth < 420 ? '25px' : '0px' }}>
      <TitleAnimation />
      <Screen
        isAuthenticated={isAuthenticated}
      />
    </Container>
  );
};


export default Categories
