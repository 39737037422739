import {
    GET_USER_TOKENS,
    SET_TOKENS_LOADING,
    CLEAR_TOKENS
} from "./types";
import {getEntireDocument} from "./FirestoreActions";


export const getUserToken = (userID) => async dispatch => {
    dispatch(setTokensLoading(true))

    try {
        const res = await getEntireDocument('usersMeetingTokens', userID);
        if (res.type === 'success') {
            dispatch({
                type: GET_USER_TOKENS,
                payload: res.data
            })
        }
        dispatch(setTokensLoading(false))
    } catch (e) {
        dispatch(setTokensLoading(false))
        console.log("error loading tokens " + e)
    }
}




export const setTokensLoading = loadingState => {
    return {
        type: SET_TOKENS_LOADING,
        payload: loadingState
    }
}
export const clearTokensState = loadingState => {
    return {
        type: CLEAR_TOKENS

    }
}