import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { closeDialog, openDialog } from '../../actions/DialogActions';
import Privacy from '../../components/policy/Privacy';
import Content from '../../components/policy/Content';
import Terms from '../../components/policy/Terms';
import {
    PRIVACY,
    CONTENT,
    TERMS,
    OPEN_POLICIES_DIALOG
} from '../../utils/globals';

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { setPolicy } from '../../actions/PoliciesActions';
import { withRouter } from 'react-router-dom';

const paths = {
    '/privacy-policy': PRIVACY,
    '/terms-of-use': TERMS,
}

const PoliciesWrapper = styled(Box)`
    padding: 20px;
    height: auto;
    width: auto;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
`;

const PolicyModal = ({ history }) => {

    const { current } = useSelector(state => state.policies);
    const { policiesDialog } = useSelector(state => state.dialogs);
    useEffect(() => {
        for (const [key, value] of Object.entries(paths)) {
            if (window.location.pathname === key) {
                dispatch(setPolicy(value))
                dispatch(openDialog(OPEN_POLICIES_DIALOG));
                break;
            } 
        }
        // eslint-disable-next-line
    }, [window.location.pathname])
    const dispatch = useDispatch();

    const onDialogClose = () => {
        dispatch(closeDialog(OPEN_POLICIES_DIALOG));
        history.replace("/");
    };

    const displayPolicy = () => {
        switch (current) {

            case PRIVACY:
                return <Privacy />

            case CONTENT:
                return <Content />

            case TERMS:
                return <Terms />

            default:
                return {};
        };
    };

    return (

        <Dialog
                open={policiesDialog}
                onClose={onDialogClose}
                align='left'
            >
            <PoliciesWrapper>
                
                {displayPolicy()}

            </PoliciesWrapper>
        </Dialog>

    );
};

export default withRouter(PolicyModal)
