import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  makeStyles,
} from "@material-ui/core";
import TableHeader from "./TableHeader";
import styled from "styled-components";
import ShowReco from "./ShowReco";
import { motion } from "framer-motion";
import { LEAD_BLUE, TAX, INFO } from "../../../../utils/globals";
import ShowNotes from "./notesEdit/ShowNotes";
import ManipulateReco from "./recoEdit/ManipulateReco";
import { TableButton } from "../../../modals/ProfessionalZone";
import OptionsCell from "./OptionsCell";
import moment from "moment";

const useStyles = makeStyles(() => ({
  tableRoot: {
    direction: "rtl",
  },
}));

// const ProfessionalMeetingReviewHeaders = [
//     {id: "name", label: "מקבל השירות"},
//     {id: "hours", label: "שעות"},
//     {id: "clientReview", label: "חוות דעת"},
//     {id: "notes", label: "הערות"},
// ];

const ProfessionalFinacialHeaders = [
  { id: "name", label: "מקבל השירות" },
  { id: "totalPrice", label: "₪ סך הכל" },
  { id: "yourCut", label: "₪ חלקי" },
];

// const UserMeetingReviewHeaders = [
//   { id: "name", label: "שם המטפל" },
//   { id: "hours", label: "שעה" },
//   { id: "link", label: "קישור" },
//   { id: "price", label: "מחיר ₪ " },
//   { id: "review", label: "חוות דעת" },
//   { id: "options", label: "שינוי / ביטול " },
// ];

const TableBodyStyle = styled(TableBody)``;

const TableCellStyle = styled(TableCell)``;

const ReviewButton = styled(motion.button)`
  border: 1px solid ${LEAD_BLUE};
  min-width: 70px;
  min-height: 30px;
  border-radius: 10%;
  cursor: pointer;
`;

const StyledTitle = styled.p`
  font-size: 18px;
  text-align: right;
`;

const Bold = styled.span`
  color: ${LEAD_BLUE};
  font-family: "Rubik", Sens-Serif;
`;

const Cell = ({ text, classes }) => {
  return (
    <TableCell className={classes.tableCell} align="right">
      {text}
    </TableCell>
  );
};

// const LinkCell = ({text, classes, title}) => {
//     return (
//         <TableCell
//             className={classes.tableCell}
//             align="right"
//         >
//
//
//             <a href={text}
//                style={{color: 'black', textUnderlinePosition: 'none'}}
//                target={"noopener"}>
//                 {title}
//             </a>
//
//         </TableCell>
//     );
// };

const onLinkClick = (link) => {
  window.open(link, "_blank");
};

const UserHoursTable = ({
  tableSize,
  headersBg,
  meetingsInDay,
  onRecoClick,
  isRecoOpen,
  classes,
}) => {

  const passedMeeting = () => {
    const meetingDate = moment(meetingsInDay[0].date.day).format("DD-MM-YYYY");
    const today = new moment().format("DD-MM-YYYY");
    return meetingDate < today;
  };

  return (
    <TableRow>
      <TableCell colSpan={6}>
        {/* <StyledTitle>
                    <Bold>{moment(meetingDay).format("DD-MM-YYYY")}</Bold>
                </StyledTitle> */}
        <Table size={tableSize} className={classes.tableRoot}>
          {meetingsInDay.map((meeting, index) => (
            <TableBodyStyle key={index}>
              <TableRow>
                <Cell text={meetingsInDay[0].date.day} classes={classes} />
                <Cell
                  text={`${meeting.professional.proFirstName} ${meeting.professional.proLastName}`}
                  classes={classes}
                />
                <Cell
                  text={`${meeting.date.hour.start} - ${meeting.date.hour.end}`}
                  classes={classes}
                />
                <Cell
                  text={`${meeting.price.finalAMT || '0'} ₪`} //Talea
                  classes={classes}
                />

                {passedMeeting() ? (
                  ""
                ) : meeting.date.meetingLink === "" ? (
                  <TableCell classes={classes.tableCell}>
                    <ReviewButton whileHover={{ y: "4px" }}>
                      קישור לא תקין
                    </ReviewButton>
                  </TableCell>
                ) : (
                  <TableCell classes={classes.tableCell}>
                    <ReviewButton
                      onClick={() => onLinkClick(meeting.date.meetingLink)}
                      whileHover={{ y: "4px" }}
                    >
                      קישור למפגש
                    </ReviewButton>
                  </TableCell>
                )}
                <Cell text={meeting.price.jinizPrice} classes={classes} />
                <TableCell classes={classes.tableCell}>
                  {passedMeeting() ? (
                    <ReviewButton
                      onClick={() => onRecoClick(index)}
                      whileHover={{ y: "4px" }}
                    >
                      חוות&nbsp;דעת
                    </ReviewButton>
                  ) : (
                    ""
                  )}
                  
                </TableCell>
                <OptionsCell
                  meetingDay={meetingsInDay}
                  index={index}
                  meetingDate={meeting.date}
                  meeting={meeting}
                />
              </TableRow>
              <TableRow>
                <TableCellStyle colSpan={12}>
                  <ManipulateReco
                    meeting={meeting}
                    isRecoOpen={isRecoOpen}
                    lineId={index}
                  />
                </TableCellStyle>
              </TableRow>
            </TableBodyStyle>
          ))}
        </Table>
      </TableCell>
    </TableRow>
  );
};

const ProfessionalHoursTable = ({
  tableSize,
  headersBg,
  classes,
  meetingsInDay,
  onNotesClick,
  onRecoClick,
  isRecoOpen,
  opti,
  isOpen,
  changeOptiState,
  isNotesOpen,
}) => {
  const meetingDate = moment(meetingsInDay[0].date.day).format("DD-MM-YYYY");
  const today = new moment().format("DD-MM-YYYY");
  const passMeeting = (meetingDate < today)
  const isProfessional = true;
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Collapse in={isOpen}>
          <StyledTitle>
            <Bold>{meetingDate}</Bold>
          </StyledTitle>
          <Table size={tableSize} className={classes.tableRoot}>
            {/* <TableHeader
                            headers={ProfessionalMeetingReviewHeaders}
                            bgColor={headersBg}
                        /> */}
            {meetingsInDay.map((meeting, index) => (
              <TableBodyStyle key={index}>
                <TableRow>
                  <TableCell align="right">
                    {meeting.client.firstName} {meeting.client.lastName}
                  </TableCell>
                  <TableCell align="right">{`${meeting.date.hour.start} - ${meeting.date.hour.end}`}</TableCell>
                  <TableCell>
                    <ReviewButton
                      onClick={() => onLinkClick(meeting.date.meetingLink)}
                      whileHover={{ y: '-4px' }}
                      disabled={passMeeting}
                    >
                      קישור למפגש
                    </ReviewButton>
                  </TableCell>
                  <TableCell>
                    <TableButton
                      onClick={() => onNotesClick(index)}
                      whileHover={{ y: "-4px" }}
                    >
                      הערות
                    </TableButton>
                  </TableCell>
                  <OptionsCell
                    isProfessional={isProfessional}
                    meetingDay={meetingsInDay}
                    index={index}
                    meetingDate={meeting.date}
                    meeting={meeting}
                  />
                </TableRow>
                <TableRow>
                  <TableCell colSpan={12} style={{ direction: "rtl" }}>
                    <ShowReco
                      firstName={meeting.client.firstName}
                      meetingId={meeting.meetingId}
                      professionalId={meeting.professional.id}
                      isRecoOpen={isRecoOpen}
                      lineId={index}
                      opti={opti}
                      setOpti={changeOptiState}
                    />
                    <ShowNotes
                      firstName={meeting.client.firstName}
                      meeting={meeting}
                      isNotesOpen={isNotesOpen}
                      lineId={index}
                    />
                  </TableCell>
                </TableRow>
              </TableBodyStyle>
            ))}
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

const ProfessionalFinanceTable = ({
  tableSize,
  headersBg,
  meetingsInDay,
  isOpen,
  classes,
}) => {
  const meetingDay = meetingsInDay[0].date.day;

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Collapse in={isOpen}>
          <StyledTitle>
            <Bold>{meetingDay}</Bold>
          </StyledTitle>
          <Table size={tableSize} className={classes.tableRoot}>
            <TableHeader
              headers={ProfessionalFinacialHeaders}
              bgColor={headersBg}
            />
            {meetingsInDay.map((meeting, index) => (
              <TableBodyStyle key={index}>
                <TableRow>
                  <TableCell align="right">
                    {meeting.client.firstName} {meeting.client.lastName}
                  </TableCell>
                  <TableCell align="right">
                    {meeting.price.normalPrice}
                  </TableCell>
                  <TableCell align="right">
                    {(meeting.price.normalPrice * TAX).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBodyStyle>
            ))}
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export const RenderTable = ({
  requestedTable,
  isProfessional,
  tableSize,
  headers,
  headersBg,
  meetingsInDay,
  onNotesClick,
  onRecoClick,
  isRecoOpen,
  opti,
  isOpen,
  changeOptiState,
  isNotesOpen,
}) => {
  const classes = useStyles();

  return (
    <>
      {requestedTable === INFO ? (
        isProfessional ? (
          <ProfessionalHoursTable
            isProfessional={isProfessional}
            tableSize={tableSize}
            headers={headers}
            headersBg={headersBg}
            meetingsInDay={meetingsInDay}
            onNotesClick={onNotesClick}
            onRecoClick={onRecoClick}
            isRecoOpen={isRecoOpen}
            opti={opti}
            isOpen={isOpen}
            changeOptiState={changeOptiState}
            isNotesOpen={isNotesOpen}
            classes={classes}
          />
        ) : (
          <>
            <UserHoursTable
              tableSize={tableSize}
              headersBg={headersBg}
              meetingsInDay={meetingsInDay}
              onRecoClick={onRecoClick}
              isRecoOpen={isRecoOpen}
              classes={classes}
            />
          </>
        )
      ) : (
        <ProfessionalFinanceTable
          meetingsInDay={meetingsInDay}
          headersBg={headersBg}
          tableSize={tableSize}
          isOpen={true}
          classes={classes}
        />
      )}
    </>
  );
};
