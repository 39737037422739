import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  clearError,
  updateSchedule,
  postSchedule,
  setFetchedFlag,
} from "../../../actions/ScheduleActions";
import { getSchedule } from "../../../actions/ScheduleActions";
import { getMeetings } from "../../../actions/MeetingsActions";
import CustomAlert from "../../layout/CustomAlert";
import Toggler from "../Toggler";
import CalanderToggler from "./calendarUtils/CalanderToggler";
import CalanderHeader from "./calendarUtils/CalanderHeader";
import CalanderItem from "./calendarUtils/CalanderItem";
import ModifyCalander from "./CalanderForms/ModifyCalander";
import { MODIFY, LEAD_BLUE } from "../../../utils/globals";
import Spinner from "../../layout/Spinner";
import {
  removeExistingMeetingsFromSchedule,
  mergeMeetingsAndSchedule,
  sortByHour,
} from "./calendarUtils/calendarHelpers";
import SettingsBar from "./SettingsBar";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveIcon from "@material-ui/icons/Remove";

const AddIcon = styled(AddCircleIcon)`
  height: 30px;
  width: 30px;
  color: ${LEAD_BLUE};
  border-radius: 50%;
`;

const RemoveButtonStyle = styled(RemoveIcon)`
  color: white;
  background-color: #ffb9b3;
  border-radius: 50%;
`;
const MainWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  direction: rtl;
  grid-gap: 10px;
`;
const Paragraph = styled.div`
  padding: 0 10px 10px;
`;

const SmallDot = styled(FiberManualRecordIcon)`
  font-size: 15px;
  margin-left: 20px;
  color: ${LEAD_BLUE};
`;

const BulletListWrapper = styled.div``;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

// Returning a form to show based on toggle press.
const FormToRender = ({
  page,
  scheduleState,
  setSchedule,
  onSubmit,
  disabled,
}) => {
  switch (page) {
    case MODIFY:
      return disabled === "" ? (
        <ModifyCalander
          scheduleState={scheduleState}
          setScheduleState={setSchedule}
          onSubmit={onSubmit}
        />
      ) : (
        ""
      );
    default:
      return null;
  }
};

const RenderCalendar = ({
  scheduleLoading,
  meetingsLoading,
  data,
  scheduleState,
  setSchedule,
  disabled,
}) => {
  const state = useSelector((state) => state.schedule);
  const { fetched } = state;

  return (
    <>
      {data.current !== null && fetched === true ? (
        <CalanderItem
          scheduleState={scheduleState}
          setScheduleState={setSchedule}
          disabled={disabled}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};

const Alerts = ({ error }) => {
  return (
    <>{!!error.msg && <CustomAlert type={error.type} msg={error.msg} />}</>
  );
};

const Calander = ({
  changePage,
  currentPage,
  onExit,
  currentProfessional,
  isProfessional,
}) => {
  const { schedule, scheduleLoading } = useSelector((state) => state.schedule);
  const { meetings, meetingsLoading } = useSelector((state) => state.meetings);

  const fetchInfo = async () => {
    dispatch(getSchedule(currentProfessional.id));
    dispatch(getMeetings("professionalMeetings", currentProfessional.id));
    dispatch(setFetchedFlag(true));
  };
  useEffect(() => {
    fetchInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!schedule && Object.keys(meetings).length) {
      let mergedSchedules = mergeMeetingsAndSchedule(schedule, meetings);
      mergedSchedules = sortByHour(mergedSchedules);
      const newSchedule = {
        ...data,
        original: mergedSchedules,
        current: mergedSchedules,
      };
      setSchedule("data", newSchedule);
    } else {
      const newSchedule = {
        ...data,
        original: schedule,
        current: schedule,
      };
      setSchedule("data", newSchedule);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetings, schedule]);
  const [page, setPage] = useState(MODIFY);

  const [scheduleState, setScheduleState] = useState({
    data: {
      original: null,
      current: null,
      date: {
        day: "",
        hours: [],
      },
    },
  });


  const { data } = scheduleState;
  const { original, current } = data;
  const dispatch = useDispatch();

  // Changing page
  const changeCalanderPage = (event, page) => {
    setPage(page);
  };

  // Manipulating the schedule state
  const setSchedule = (name, value) => {
    setScheduleState({
      ...scheduleState,
      [name]: value,
    });
  };

  // Handling submit
  const onSubmit = () => {
    dispatch(clearError());

    const scheduleToUpdate = removeExistingMeetingsFromSchedule(current);
    // If there is no original.length it means that this is an empty calendar
    if (!original.length) { 
      dispatch(postSchedule(currentProfessional.id, scheduleToUpdate));
    }
    // This is not an empty list, so we update it.
    else {
      //Talea dispatch(updateSchedule(currentProfessional.id, current));
      dispatch(updateSchedule(currentProfessional.id, scheduleToUpdate));
    }
    setTimeout(() => {
      //clear message after 3 seconds
      dispatch(clearError());
    }, 3000);
  };

  const [err, setErr] = useState("");

  useEffect(() => {
    try {
      //try to read info from the object
      //if it fails it means it hasn't been created yet
    } catch (e) {
      setErr({
        type: "error",
        msg: " לא ניתן למלא את היומן לפני סיום מילוי הפרופיל שלך",
      });
    }
  }, []);

  const calendarDescription = () => {
    return (
      <MainWrapper align="right">
        <BulletListWrapper>
          <Paragraph>זהו יומן המפגשים שלך ומכאן ניתן :</Paragraph>
          <ListItem>
            <SmallDot />
            <Paragraph>
              לסמן את הימים והשעות שברצונך להקצות ללקוחות.
              <span>
                <AddIcon />
              </span>
            </Paragraph>
          </ListItem>

          <ListItem>
            <SmallDot />
            <Paragraph>
              להוסיף או לגרוע שעות שלא תואמו בהן מפגשים. <RemoveButtonStyle />
            </Paragraph>
          </ListItem>

          <ListItem>
            <SmallDot />
            <Paragraph style={{ paddingTop: "10px" }}>
              לשנות מפגשים מתואמים עד 24 שעות לפני המועד, בתנאי ששלחת הודעה
              ללקוח וקיבלת אשרור.
            </Paragraph>
          </ListItem>
        </BulletListWrapper>
      </MainWrapper>
    );
  };

  return (
    <MainWrapper>
      <SettingsBar label="יומן " onExit={onExit} />
      <Toggler
        currentPage={currentPage}
        changePage={changePage}
        schedule={schedule}
        isProfessional={isProfessional}
      />
      <CalanderHeader description={calendarDescription} />
      <Alerts error={err} />

      <RenderCalendar
        scheduleLoading={scheduleLoading}
        meetingsLoading={meetingsLoading}
        data={data}
        scheduleState={scheduleState}
        setSchedule={setSchedule}
        disabled={err}
      />
      <CalanderToggler
        changePage={changeCalanderPage}
        currentPage={page}
        disabled={err}
      />

      <FormToRender
        page={page}
        scheduleState={scheduleState}
        setSchedule={setSchedule}
        onSubmit={onSubmit}
        disabled={err}
      />
    </MainWrapper>
  );
};

export default Calander;
