import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./actions/AuthActions";
import "./App.css";
import "./firebase";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";

import Home from "./components/pages/home/Home";
import About from "./components/pages/about/About";
import Admin from "./components/pages/admin/Admin";
import NotFound from "./components/pages/NotFound";
import FAQ from "./components/pages/home/faq/FAQ";
import ContactUs from "./components/pages/contact/ContactUs";
import Modals from "./utils/Modals";
import AvailableTodayPage from "./components/pages/categories/AvailableToday/AvailableTodayPage";
import SearchPage from "./components/pages/categories/Search/SearchPage";
import { getUserToken } from "./actions/TokensActions";

import LandingPage from "./components/pages/landingPage/LandingPage";

import OthersPage from "./components/pages/categories/Others/OthersPage";
import {
  AVAILABLE_TODAY,
  DEPRESSIONANXIETY,
  EATINGDISORDERS,
  FAMILY,
  GERIATRICS,
  KIDSANDYOUTH,
  OTHER,
  WORKCAREER,
} from "./utils/globals";
import FamilyRelationships from "./components/pages/categories/FamilyRelationships/FamilyRelationshipsPage";
import KindsYouthPage from "./components/pages/categories/Kinds&Youth/Kinds&YouthPage";
import EatingDisordersPage from "./components/pages/categories/EatingDisorders/EatingDisordersPage";
import WorkCareerPage from "./components/pages/categories/Work&Career/Work&CareerPage";
import DepressionAnxietyPage from "./components/pages/categories/Depression&Anxiety/DepressionAnxietyPage";
import GeriatricsPage from "./components/pages/categories/GeriatricsPage/GeriatricsPage";

const App = () => {
  // const {authLoading, isAuthenticated, authProcessDone} = useSelector(state => state.auth);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let pathname = window.location.pathname;
  const [pathName, setPathName] = useState(pathname);
  const landingPagePath = "/welcome";

  useEffect(() => {
    setPathName(pathname);
  }, [pathname]);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  //load user tokens
  useEffect(() => {
    if (user !== null)
      try {
        dispatch(getUserToken(user.id));
      } catch (e) {
        console.log("Couldn't get user tokens");
      }
  }, [dispatch, user]);
 

  return (
    <>
      <>
        {pathName === landingPagePath ? (
          <Switch>
            <Route exact path={`/welcome`} component={LandingPage} />
          </Switch>
        ) : (
          <>
            <Navbar />
            <div className={"content"} style={{ minHeight: "75%" }}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/faq" component={FAQ} />
                <Route exact path="/contact" component={ContactUs} />
                <Route exact path="/privacy-policy" component={Home} />
                <Route exact path="/terms-of-use" component={Home} />
                <Route exact path={`/search`} component={SearchPage} />
                <Route
                  path="/experts"
                  render={({ match: { url: prefix } }) => (
                    <Switch>
                      <Route
                        exact
                        path={`${prefix}/${FAMILY}`}
                        component={FamilyRelationships}
                      />
                      <Route
                        exact
                        path={`${prefix}/${KIDSANDYOUTH}`}
                        component={KindsYouthPage}
                      />
                      <Route
                        exact
                        path={`${prefix}/${GERIATRICS}`}
                        component={GeriatricsPage}
                      />
                      <Route
                        exact
                        path={`${prefix}/${DEPRESSIONANXIETY}`}
                        component={DepressionAnxietyPage}
                      />
                      <Route
                        exact
                        path={`${prefix}/${EATINGDISORDERS}`}
                        component={EatingDisordersPage}
                      />
                      <Route
                        exact
                        path={`${prefix}/${WORKCAREER}`}
                        component={WorkCareerPage}
                      />
                      <Route
                        exact
                        path={`${prefix}/${OTHER}`}
                        component={OthersPage}
                      />
                      <Route
                        exact
                        path={`${prefix}/${AVAILABLE_TODAY}`}
                        component={AvailableTodayPage}
                      />
                    </Switch>
                  )}
                />
                <Route exact path="/admin" component={Admin} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <Footer />
          </>
        )}
        <Modals />
      </>
    </>
  );
};

const AppProvided = () => {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
};

export default AppProvided;
