import React, { useEffect, Fragment, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Message from './Message';
import DisplayMessage from './DisplayMessage';
import EmptyInbox from './EmptyInbox';
import Pagination from '@material-ui/lab/Pagination';
import { deleteMessages, getMessages } from '../../../../actions/MessagesActions';

const MainWrapper = styled(Grid)`  
    font-family: 'Roboto', sans-serif;
    direction: rtl;
    min-height: 480px;
    height: auto;
   
`;

const PaginationWrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 10px;
`;

const Inbox = ({ messageType, filter }) => {
    
    const { messages, messagesAlert, messagesLoading } = useSelector(state => state.messages);
    const [ relevantMessages, setRelevantMessages ] = useState({
        original: [],
        filtered: []
    });
    const [ page, setPage ] = useState(1);
    const [ showMessage, setShowMessage ] = useState({
        display: false,
        subject: '',
        content: '',
        firstName: '',
        lastName: '',
        day: '',
        destEmail: '',
        messageId: '',
        isReplyable: ''
    });
    const { display, subject, content, 
            firstName, lastName, day, 
            destEmail, messageId, isReplyable } = showMessage;
    const dispatch = useDispatch();

    useEffect(() => {
        let filtered = Object.keys(messages).map(message => 
            messages[message].message.inInbox === messageType && messages[message]
        )
        filtered = filtered.filter(item => !!item)
        sortByDate(filtered);
        setRelevantMessages({
            original: filtered,
            filtered
        });
        // eslint-disable-next-line
    }, [messages])

    useEffect(() => {
        if (filter) {
            onSearchBar();
        } else {
            resetSearchBar();
        }
        // eslint-disable-next-line
    }, [filter])

    const handleShowMessage = messageObject => {
        if (messageObject.display) {
            setShowMessage({
                display: true,
                subject: messageObject.subject,
                content: messageObject.content,
                firstName: messageObject.firstName,
                lastName: messageObject.lastName,
                day: messageObject.day,
                destEmail: messageObject.destEmail,
                messageId: messageObject.messageId,
                isReplyable: messageObject.isReplyable
            })
        } else {
            setShowMessage({
                display: false,
                subject: '',
                content: '',
                firstName: '',
                lastName: '',
                day: '',
                destEmail: '',
                messageId: '',
                isReplyable: ''
            })
        }
    };

    const craftDateObject = (day, hour) => {
        const date = Date.parse(day + ' ' + hour);
        return new Date(date);
    };

    const sortByDate = messages => {
        messages.sort((a, b) => {
            let aDate = craftDateObject(a.message.date.day, a.message.date.hour);
            let bDate = craftDateObject(b.message.date.day, b.message.date.hour);
            return bDate - aDate   
        })
    };

    const handleDeleteMessage = (currentUserEmail, messageId) => {
        dispatch(deleteMessages(currentUserEmail, messageId));
        dispatch(getMessages(currentUserEmail));
    };

    const setNewPage = (event, value) => {
        setPage(value);
    };

    // If search value is an empty string, resetting meetings to original.
    const resetSearchBar = () => {
        if (filter === '') {
            relevantMessages.original.length && setRelevantMessages({
                ...relevantMessages,
                filtered: relevantMessages.original
            });
        };
    };

    // Algorithem of the search bar, displays different results based on search.
    const onSearchBar = () => {
        page !== 1 && setPage(1);
        // eslint-disable-next-line
        const filteredMessages = relevantMessages.original.filter(message => {
            // eslint-disable-next-line
            for (const [rootKey, rootValue] of Object.entries(message)) {
                // eslint-disable-next-line
                for (const [key, value] of Object.entries(rootValue)) {
                    if (String(value).indexOf(filter) > -1 ) {
                        return message
                    };
                    if (typeof(value) === 'object') {
                        // eslint-disable-next-line
                        for (const [nestedKey, nestedValue] of Object.entries(value)) {
                            if (String(nestedValue).indexOf(filter) > -1 ) {
                                return message
                            };
                        };
                    };
                };
            };
        });
        setRelevantMessages({
            ...relevantMessages,
            filtered: filteredMessages
        });
    };

    return (
        <Fragment>
            {
            !display ? 
                <Box>
                    {!relevantMessages.original.length ? <EmptyInbox /> :
                        <Box>
                            <MainWrapper 
                                container
                                direction="column"
                                justify="flex-start"
                                alignItems="center"
                            >

                                {relevantMessages.filtered
                                    .slice((page - 1) * 3, (page - 1) * 3 + 3)
                                    .map(message => 
                                        <Message 
                                            key={message.message.id}
                                            message={message}
                                            setShowMessage={handleShowMessage}
                                            handleDeleteMessage={handleDeleteMessage}
                                        />
                                )}

                            </MainWrapper>
                            <PaginationWrapper>
                                <Pagination 
                                    count={relevantMessages.filtered.length / 3 % 3 === 0 ? 
                                           relevantMessages.filtered.length / 3 : 
                                           Math.ceil(relevantMessages.filtered.length / 3)}     
                                    color="standard" 
                                    page={page}
                                    onChange={setNewPage}
                                />
                            </PaginationWrapper>
                        </Box>
                    }
                </Box>
            : 
                <DisplayMessage 
                    subject={subject}
                    content={content}
                    setShowMessage={handleShowMessage}
                    handleDeleteMessage={handleDeleteMessage}
                    firstName={firstName}
                    lastName={lastName}
                    day={day}
                    destEmail={destEmail}
                    messagesLoading={messagesLoading}
                    messagesAlert={messagesAlert}
                    messageId={messageId}
                    isReplyable={isReplyable}
                />
            }
        </Fragment>
    );
};

export default Inbox
