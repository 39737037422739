import React, { useState } from 'react';
import styled from 'styled-components';
import { LEAD_BLUE } from '../../../../../utils/globals';
import { Bold } from "../../../../professionalForm/Presentation";
import * as firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../layout/Spinner";
import { setError } from "../../../../../actions/ProfessionalsActions";
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from "@material-ui/core/Button";
import { updateFieldInCollection } from "../../../../../actions/FirestoreActions";


const FormWrapper = styled.div`
  margin: 16px;
  min-height: 102px;
  height: auto;
  @media (max-width: 450px) {
    display: grid;
    justify-items: center;
    min-width: 250px;
    margin: 0;
  }
`;


const UpperFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 450px) {
    display: grid;
    grid-gap: 10px;
    justify-items: center;
  }
`;

const pictureFields = [
    {
        name: "profilePicture",
        label: "תמונה"
    },
]

const videoFields = [
    {
        name: "presentationVideo",
        label: "וידאו"
    },
]

const MainWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  direction: rtl;
`;

const ResizedImage = styled.img`
  width: 130px;
  height: 100px;
  object-fit: cover;
  margin: 10px;
`;

const ResizedVideo = styled.video`
  margin: 10px;
  width: 130px;
  height: 100px;
  object-fit: cover;
`;

const DescriptionMainWrapper = styled.div`
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25),
  0 2px 2px rgba(0, 0, 0, 0.20),
  0 4px 4px rgba(0, 0, 0, 0.15),
  0 8px 8px rgba(0, 0, 0, 0.10),
  0 16px 16px rgba(0, 0, 0, 0.05);
  direction: rtl;
  font-family: 'Rubik', Sens-Serif, sans-serif;
`;

const Header = styled.p`
  font-family: 'Rubik', Sens-Serif, sans-serif;
  font-size: 20px;
  text-align: right;
  margin: 5px;
  padding: 5px;
  color: ${LEAD_BLUE};
`;

const TextWrapper = styled.div`
  margin: 4px;
  padding: 4px;
`;

const Text = styled.p`
  font-family: 'Rubik', Sens-Serif, sans-serif;
`;

const Description = () => {
    return (
        <DescriptionMainWrapper>
            <Header>
                תמונת פרופיל וסרטון וידאו
            </Header>
            <TextWrapper>
                <Text>
                    <Bold>תמונת פרופיל</Bold> - יש להעלות תמונה מקצועית ובאיכות טובה. חשוב מאד להצטלם מגובה החזה ועד כ-5
                    ס״מ מעל
                    הראש, עם רקע בהיר ואחיד מאחור (לבן עד אפור) ותאורה חזקה.
                </Text>
                <Text>
                    <Bold>סרטון וידאו</Bold> -
                    עליך לתאר בקצרה את שמך, עיסוקך, התמחויות וניסיון מעשי, בסרטון באורך של כ-30 שניות. יש
                    להקפיד להציב את המצלמה במקום יציב (מומלץ על חצובה), במרחק של כמטר ממך ובתאורה חזקה כך שיראו את תווי
                    פניך, ולדבר בקול רם וברור.


                </Text>
                {/*      <p style={{fontFamily: "'Rubik', Sens-Serif", fontWeight: 'bold'}}>
                    לחץ על + להעלאת תמונה וסרטון מהמחשב שלך. המידע יופיע לאחר לחיצה על "שמור".
                </p>*/}
            </TextWrapper>
        </DescriptionMainWrapper>
    )
};

const Data = ({ value, isPhoto, onclick }) => {

    return (
        <>
            {
                isPhoto ?
                    <ResizedImage
                        src={!!value ? value : "https://img.icons8.com/ios/100/000000/portrait.png"}
                        alt="Image"
                        onClick={() => onclick()}
                    /> : !!value ?

                        <ResizedVideo
                            src={value}
                            controls
                            onClick={() => onclick()}
                        />
                        :
                        <ResizedImage
                            src={"https://img.icons8.com/ios/100/000000/video.png"}
                            alt="Image"
                            onClick={() => onclick()}
                        />

            }
        </>
    );
}
    ;

const Form = (
    {
        isPhoto, onChange,
        displayURL    }
) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    }

    const dropZoneText = (isPhoto) => {
        if (isPhoto)
            return "גרור או לחץ כאן להעלאת תמונה"
        else
            return "גרור או לחץ כאן להעלאת סרטון"
    }

    return (
        <FormWrapper>
            {/*<Label>{label}</Label>*/}
            <UpperFormWrapper>
                <div>
                    <Button style={{

                        background: LEAD_BLUE,
                        color: 'white'
                    }} onClick={() => setOpen(true)}>
                        {isPhoto ? 'הוסף תמונה' : 'הוסף סרטון'}
                    </Button>

                    <DropzoneDialog
                        cancelButtonText={"חזור"}
                        submitButtonText={""}
                        maxFileSize={50000000}
                        previewText={"תצוגה מקדימה"}
                        dialogTitle={""}
                        dropzoneText={dropZoneText(isPhoto)}
                        open={open}
                        onClose={() => setOpen(false)}
                        onDrop={(files) => {
                            onChange(files, isPhoto)
                            handleOpen()
                        }}
                        showPreviews={false}
                    />
                </div>

                <Data
                    isPhoto={isPhoto}
                    value={displayURL}
                    onclick={handleOpen}
                />
            </UpperFormWrapper>
        </FormWrapper>
    )
}

const PhotoField = (
    {
        onChange, originalPicture
    }
) => {
    return (
        <>
            {
                pictureFields.map((field, index) =>
                    <Form
                        key={index}
                        label={field.label}
                        isPhoto={true}
                        fieldName="pictureState"
                        onChange={onChange}
                        stringName={originalPicture[field.name].stringName}
                        displayURL={originalPicture[field.name].displayURL}
                        name={field.name}
                    />
                )
            }

        </>
    );
}
    ;

const VideoField = (
    {
        onChange, originalVideo
    }
) => {
    return (
        <>
            {
                videoFields.map((field, index) =>
                    <Form
                        key={index}
                        label={field.label}
                        isPhoto={false}
                        fieldName="videoState"
                        onChange={onChange}
                        stringName={originalVideo[field.name].stringName}
                        displayURL={originalVideo[field.name].displayURL}
                        name={field.name}

                    />
                )
            }
        </>
    );
}
    ;


const ProgressBar = (
    {
        progress
    }
) => {
    return (
        <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>
            <Spinner />
            {progress}%
        </div>
    );
}


const Forms = (
    {
        onChange, originalPicture, originalVideo    }
) => {

    const { user } = useSelector(state => state.auth)
    const [pictureProgress, setPictureProgress] = useState(null)
    const [videoProgress, setVideoProgress] = useState(null)
    const dispatch = useDispatch();

    /**
     * Uploading a file to firebase storage
     * once done uploading sending the form and updating the ui
     * @param event on change
     * @param isPhoto boolean value if the field is a photo
     */
    const uploadFile = (event, isPhoto) => {
        //all files should have the same name whether is a photo or video
        const fileName = isPhoto ? "profilePicture.png" : "video.mp4"
        //upload file
        // Create a root reference
        const storageRef = firebase.storage().ref();
        const root = "/professionals/"
        const id = user.id + "/"
        const folder = isPhoto ? "picture/" : "video/"
        const file = event[0];


        // Create a reference to 'folder/fileName.extension'
        const ref = storageRef.child(`${root}/${id}/${folder}/${fileName}`);

        const task = ref.put(file);
        task.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            if (isPhoto)
                setPictureProgress(Math.trunc(progress))
            else
                setVideoProgress(Math.trunc(progress))

        }, (error) => {
            //show error
            console.log(error)
            dispatch(setError({
                type: 'error',
                msg: 'לא הצלחנו להעלות את הקובץ,אנא נסה שוב.'
            }));
        }, () => {

            //get download url from upload task
            task.snapshot.ref.getDownloadURL().then((url) => {
                onChange(isPhoto, url) //update local state
                //manually update DB state
                /**
                 * Important note!
                 * Because of aysnc set of the form we update the photo\video url manually in the firestore
                 * The local state doesn't sync with the new URLS before updating the state
                 * So we update the firestore manually and the before updateForm() it will get
                 * the assets from the storage.
                 */
                if (isPhoto) {
                    updateFieldInCollection('professionals', id, "picture.profilePicture.displayURL", url)
                    setPictureProgress(null)
                } else {
                    updateFieldInCollection('professionals', id, "video.presentationVideo.displayURL", url)
                    setVideoProgress(null)
                }
            })

            //Notify the user the file has been saved
            dispatch(setError({
                type: 'success',
                msg: 'הקובץ הועלה בהצלחה'
            }));
            //clear message
            setTimeout(() => {
                dispatch(setError(''))
            }, 2500)
        })
    }


    return (
        <>

            {pictureProgress !== null ?
                <ProgressBar
                    progress={pictureProgress}
                />
                :
                <PhotoField
                    onChange={uploadFile}
                    originalPicture={originalPicture}
                />
            }
            {videoProgress !== null ?
                <ProgressBar
                    progress={videoProgress}
                />
                :
                <VideoField
                    onChange={uploadFile}
                    originalVideo={originalVideo}

                />
            }

        </>
    )
}
    ;

const MediaForm = (
    {
        originalPicture, originalVideo, onChange, updateForm
    }
) => {

    return (
        <MainWrapper>
            <Description />
            <Forms
                originalPicture={originalPicture}
                originalVideo={originalVideo}
                onChange={onChange}
                updateForm={updateForm}
            />
        </MainWrapper>
    )
}

export default MediaForm;
