// Filter by past date
export const FilterByPastDates = (setMyMeetings, myMeetings) => {
    const now = new Date();
    // eslint-disable-next-line
    const filteredMeetings = myMeetings.original.filter(meeting => {
        for (let obj = 0; obj < meeting.length; obj++) {
            if(new Date(meeting[obj].date.day).getTime() < now.getTime()) {
                return meeting
            };
        };
    })
    setMyMeetings({
        ...myMeetings,
        filtered: filteredMeetings
    });
};

// Filter by future date
export const FilterByUpcomingDates = (setMyMeetings, myMeetings) => {
    const now = new Date();
    // eslint-disable-next-line
    const filteredMeetings = myMeetings.original.filter(meeting => {
        for (let obj = 0; obj < meeting.length; obj++) {
            if(new Date(meeting[obj].date.day).getTime() > now.getTime()) {
                return meeting
            };
        };
    })
    setMyMeetings({
        ...myMeetings,
        filtered: filteredMeetings
    });
};

// Function checks if a given date had passed
export const compareDates = date => {
    const now = new Date();
    const recoTime = new Date(date);
    if (now.getTime() > recoTime.getTime()) {
        return true;
    };
    return false;
};

// Function checks if a given meeting has not passed
export const hasTimeNotPassed = (day, hour, toCompare) => {
    const date = Date.parse(day + ' ' + hour.start);
    const meetingStartingDate = new Date(date).getTime();
    const now = new Date().getTime();
    if (meetingStartingDate - now < toCompare) {
        return true
    } else {
        return false
    }
};