import { HOURS } from "../../../../utils/globals";
import moment from "moment";

const convertMeetingsFormatToScheduleFormat = (meetings) => {
  const formattedMeetingsList = {};
  let extractedDays = [];
  for (const [day, meetingsInDay] of Object.entries(meetings)) {
    // eslint-disable-next-line
    meetingsInDay.forEach((meeting) => {
      let meetingObj = {
        start: meeting.date.hour.start,
        end: meeting.date.hour.end,
        meetingDetails: {
          meetingId: meeting.meetingId,
          clientName: `${meeting.client.firstName} ${meeting.client.lastName}`,
          professionalId: meeting.professional.id,
        },
      };
      extractedDays.push(meetingObj);
    });
    formattedMeetingsList[day] = extractedDays;
    extractedDays = [];
  }
  return formattedMeetingsList;
};

export const mergeMeetingsAndSchedule = (scheduleState, meetings) => {
  const schedule = [...scheduleState];
  const formattedMeetings = convertMeetingsFormatToScheduleFormat(meetings);
  for (let dayInSchdule = 0; dayInSchdule < schedule.length; dayInSchdule++) {
    for (const day of Object.keys(schedule[dayInSchdule])) {
      if (formattedMeetings[day]) {
        schedule[dayInSchdule][day].push(...formattedMeetings[day]);
        delete formattedMeetings[day];
      }
    }
  }
  // Adding new days that are not exists at schedule right now
  for (const [day, meetingsInDay] of Object.entries(formattedMeetings)) {
    schedule.push({ [day]: meetingsInDay });
  }
  return schedule;
};

export const removeExistingMeetingsFromSchedule = (currentSchedule) => {
  let schedule = [...currentSchedule];
  let newDayList = [];
  let filteredSchedule = [];

  for (let day = 0; day < schedule.length; day++) {
    for (const [meetingsDay, meetingInDay] of Object.entries(schedule[day])) {
      for (let meeting = 0; meeting < meetingInDay.length; meeting++) {
        if (!!!meetingInDay[meeting].meetingDetails) {
          newDayList.push(meetingInDay[meeting]);
        }
      }
      const date = moment(meetingsDay, "DD-MM-YYYY").format("DD-MM-YYYY");
      const today = moment().format("DD-MM-YYYY");
      if (newDayList.length) {
        if (date >= today) {
          filteredSchedule.push({ [meetingsDay]: newDayList });
          newDayList = [];
        }
      }
    }
  }
  return filteredSchedule;
};

export const sortByHour = (fullSchedule) => {
  const schedule = [...fullSchedule];
  for (let day = 0; day < schedule.length; day++) {
    for (const meetings of Object.values(schedule[day])) {
      meetings.sort((a, b) => {
        let aDate = a.start.split(":")[0];
        let bDate = b.start.split(":")[0];
        return aDate - bDate;
      });
    }
  }
  return schedule;
};

// Function is used at DateField.jsx
// When user clicks on add button
// Function returns default start and end date for the new field
export const getStartHour = (hours) => {
  if (!hours.length) {
    return HOURS[0];
  }
  let match = false;
  for (let anyHour = 0; anyHour < HOURS.length; anyHour++) {
    for (let usedHour = 0; usedHour < hours.length; usedHour++) {
      if (hours[usedHour].start === HOURS[anyHour]) {
        match = false;
        break;
      } else {
        match = true;
      }
    }
    if (match) {
      return {
        startHour: HOURS[anyHour],
        endHour: anyHour + 3 < HOURS.length ? HOURS[anyHour + 4] : "",
      };
    }
  }
};

// Function returns if the current day has a meeting inside it or not.
export const checkIfCurrentDayHasMeetings = (currentSchedule, day) => {
  for (let dayObj = 0; dayObj < currentSchedule.length; dayObj++) {
    if (Object.keys(currentSchedule[dayObj])[0] === day) {
      for (const hours of Object.values(currentSchedule[dayObj])) {
        for (let hour = 0; hour < hours.length; hour++) {
          if (hours[hour].meetingId) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

// Removes date from schedule and returns the manipulated list.
export const preformRemoval = (
  manipulatedSchedule,
  choosenDay,
  choosenDayHours,
  hoursObjectIndex
) => {
  // If this is the last hours available for the choosen day, we remove the entire day.
  if (choosenDayHours.length === 1) {
    const finalSchedule = manipulatedSchedule.filter(
      (loopedDay) => Object.keys(loopedDay)[0] !== choosenDay
    );
    return finalSchedule;
  } else {
    // HoursObjectIndex is the index of the object that contains the choosen start hour.
    // dayHours is a list of all available hours for a given choosen day.
    // Since a person paid for this hour, we remove the entire object.
    choosenDayHours.splice(hoursObjectIndex, 1);
    return manipulatedSchedule;
  }
};

// Function returns the hours object when creating a new day
export const setNewHoursObject = (position, hour, prevValue, hours, index) => {

  const hoursDuplicate = [...hours];

  let givenHour = hoursDuplicate[index][position];
  if (givenHour === prevValue) {
    hoursDuplicate[index][position] = hour;
    if (position !== "end") {
      hoursDuplicate[index]["end"] = HOURS[HOURS.indexOf(hour) + 2];
    }
    return hoursDuplicate;
  }
};

// Function returns the hours object when creating a new day
export const getRelevantMeeting = (meetings, meetingId) => {
  for (const meetingsList of Object.values(meetings)) {
    for (let meeting = 0; meeting < meetingsList.length; meeting++) {
      if (meetingsList[meeting].meetingId === meetingId) {
        return meetingsList[meeting];
      }
    }
  }
};
