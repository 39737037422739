import React, {useState} from 'react'

import LandingHeader from "./LandingHeader";
import LandingBody from "./LandingBody";
import LandingFoot from "./LandingFoot";
import {genericEmail} from "../../../actions/FunctionsActions";
import Grid from "@material-ui/core/Grid";


const LandingPage = () => {


    const [formState, setFormState] = useState(
        {
            name: '',
            phone: '',
            email: ''
        }
    )
    const handleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: [e.target.value]
        })
    }
    const handleSubmit = async (e) => {
        await genericEmail(formState, 'welcomePageLead');
        window.alert("תודה על הפנייה , נשיב בהקדם")
    }
    return (
        <>
            <Grid container
                  justify={"center"}>
                <Grid item xs={1} md={2} lg={3}/>
                <Grid item xs={10} md={8} lg={6}>
                    <LandingHeader
                        form={formState}
                        handleChange={handleChange}
                        submit={handleSubmit}
                    />
                    <LandingBody/>
                    <LandingFoot
                        form={formState}
                        handleChange={handleChange}
                        submit={handleSubmit}/>
                </Grid>
                <Grid item xs={1} md={2} lg={3}/>
            </Grid>
        </>
    );
};

export default LandingPage;
