import {
    GET_MEETINGS,
    SET_MEETINGS,
    UPDATE_MEETINGS,
    SET_MEETINGS_LOADING,
    SET_MEETINGS_ALERT,
    CLEAR_MEETINGS_ALERT,
} from '../actions/types';

const initalState = {
    meetings: [],
    alert: false,
    meetingsLoading: false,
};

export const meetingsReducer = (state = initalState , action) => {

    switch (action.type) {

        case GET_MEETINGS:
        case SET_MEETINGS:
        case UPDATE_MEETINGS:
            return {
                ...state,
                alert: false,
                meetings: action.payload,
            };

        case SET_MEETINGS_ALERT:
            console.error("An error took place while trying to get schedule: ", action.payload);
            return {
                ...state,
                alert: action.payload
            };

        case CLEAR_MEETINGS_ALERT:
            return {
                ...state,
                alert: false
            };

        case SET_MEETINGS_LOADING:

            return {
                ...state,
                meetingsLoading: action.payload
            };

        default:
            return state;

    }
}
