const mustUsersFields = ["firstName", "lastName", "localPhone", "email"];
const formNamesToHebrew = {
  firstName: "שם פרטי",
  lastName: "שם משפחה",
  email: 'דוא"ל',
  idNumber: "תעודת זהות",
  homePhone: "טלפון",
  localPhone: "מס' טלפון נייד",
  address: "כתובת מגורים",
  accountName: " מספר חשבון בנק",
  bankName: "שם בנק",
  branchNumber: "מספר סניף",
  accountNumber: "מספר חשבון",
  languagesState: "שפות",
  experienceState: "ניסיון",
  priceState: "מחיר",
  pictureState: "תמונה",
  videoState: "וידאו",
  profession: "עיסוק",
  educationFirstDegree: "תואר ראשון",
  educationSecondDegree:'תואר שני',
  expertiseState:'התמחות'
};

// Function receives an object and check that every key has a value
export const areFormFilled = (forms) => {
  // eslint-disable-next-line
  for (const [form, value] of Object.entries(forms)) {
    if (!value) {
      return {
        showError: true,
        msg: "Invalid data",
      };
    }
  }
  return {
    showError: false,
  };
};

// Function receives two values and check if they match
export const registerPasswordMatches = (password1, password2) => {
  if (password1 === password2) {
    return {
      showError: false,
    };
  }
  return {
    showError: true,
    msg: "Passwords does not match",
  };
};

// Function is a wrapper for the basic validation of registration form
export const validateRegisterForm = (forms) => {
  const areFormFilledCheck = areFormFilled(forms);
  // If there is no need to show error at this stage since all forms filled
  if (!areFormFilledCheck.showError) {
    const arePasswordsMatch = registerPasswordMatches(
      forms.password1,
      forms.password2
    );
    if (!arePasswordsMatch.showError) {
      return {
        showError: false,
      };
    } else {
      return arePasswordsMatch;
    }
  } else {
    return areFormFilledCheck;
  }
};

export const validateUsersSettingsForm = (values) => {
  for (const [form, value] of Object.entries(values)) {
    if (mustUsersFields.includes(form) && !value) {
      return false;
    }
  }
  return true;
};

// Function checks that the settings form is filled
export const validatePublicSettingsForm = (values, errors) => {

  // eslint-disable-next-line
  for (const [form, value] of Object.entries(values)) {
    if (form === "languagesState" || form === "experienceState") {
      errors = checkSingleNestedForm(form, value, errors);
    } else if (form === "pictureState") {
      errors = checkForPictureAndVideoExistOnStorage(form, value, errors);
    } else if (form === "expertiseState" ) {
      errors = expertiesValidation(form, value, errors);
    } else if (form === "educationState") {
      errors = educationValidation(form, value, errors);
    }
  }

  return errors;
};

// Function checks that the settings form is filled
export const validatePrivateSettingsForm = (values, errors) => {
  // eslint-disable-next-line
  for (const [form, value] of Object.entries(values)) {
    if (!value) {
      return (errors = addMissingField(errors, form));
    }
  }
  return errors;
};

// Function receives a list that contains required fields that are empty
// And a new empty form that is needed
const addMissingField = (errors, form) => {
  for (const [key, value] of Object.entries(formNamesToHebrew)) {
    form === key && errors.push(value);
  }
  return errors;
};

// Function creates a nicely formatted alert message
export const createAlertMessage = (errors) => {
  const msg = `עליך להשלים את הנתונים הבאים על מנת שנוכל להכין את עמוד המטפל שלך: ${errors.join(
    ", "
  )} לעזרה ניתן לפנות אלינו בעמוד צור קשר `;
  return msg;
};

const checkSingleNestedForm = (form, value, errors) => {
  for (const keyValue of Object.values(value)) {
    if (keyValue) {
      return errors;
    }
  }

  return addMissingField(errors, form);
};

/**
 * Checking for a storage path for the video or and photo
 * @param form
 * @param value
 * @param errors
 * @returns {*}
 */
const checkForPictureAndVideoExistOnStorage = (form, value, errors) => {
  for (const val of Object.values(value)) {
    if (val.displayURL) return errors;
  }
  return addMissingField(errors, form);
};

const expertiesValidation = (form, value, errors) => {
  for (const val of Object.values(value)) {
    if (!val) {
      return addMissingField(errors, "profession");
    }
 
    for(const val of Object.values(value.firstExpertie)){
        if(!val){
            return addMissingField(errors, form);
        }
    }
  }
  return errors;
};

const educationValidation = (form, value, errors) => {
  for (const val of Object.values(value.firstDegree)) {
    if (!val) {
      return addMissingField(errors, "educationFirstDegree");
    }
  }
  for (const val of Object.values(value.secondDegree)) {
    if (!val) {
      return addMissingField(errors, "educationSecondDegree");
    }
  }
return errors;
};
