import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { MODIFY, RecoBackground } from '../../../../utils/globals';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const editButtonAnimation = {
    initial: { x: '-100vh' },
    animate: {
        x: 0,
        transition: {
            duration: .5,
            delay: .3 
        }
    }
}


const Edit = styled(motion.custom(ToggleButton))`
    background-color: ${RecoBackground};
`;

const CalanderToggler = ({ changePage, currentPage,disabled}) => {
  
    return (
        <ToggleButtonGroup
            value={currentPage}
            exclusive
            onChange={changePage}
        >
            {currentPage !== MODIFY &&
                <Edit 
                    value={MODIFY}
                    variants={editButtonAnimation}
                    initial="initial"
                    animate="animate"
                >
                  
                </Edit>
            }
        </ToggleButtonGroup>
      );
}

export default CalanderToggler
