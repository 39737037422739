import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { Link, Link as RouterLink } from "react-router-dom";
import {
  DEPRESSIONANXIETY,
  EATINGDISORDERS,
  FAMILY,
  KIDSANDYOUTH,
  OTHER,
  WORKCAREER,
} from "../../utils/globals";

const Bar = styled.div`
  background-color: white;
  color: black;
  flex-shrink: 0;
  text-align: center;
`;

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: center;
`;

const MainWrapper = styled(Grid)`
  font-family: "Rubik", Sens-Serif, serif;
  height: min-content;
  direction: rtl;
`;

const MenuWrapper = styled(Box)`
  display: grid;
  align-items: start;
  justify-items: start;
  text-align: start;
`;

const GroupContainer = styled(Grid)`
  height: auto;
  height: 90px;

  padding: 10px;
  @media (max-width: 880px) {
    display: ${(props) => props.display === "none" && "none"};
  }
`;
const DoubleGroupContainer = styled(Grid)`
  grid-template-columns: 50% 50%;
  height: auto;
  height: 90px;
  text-align: right;
  width: 100%;
  @media (max-width: 880px) {
    display: ${(props) => props.display === "none" && "none"};
  }
`;

const Label = styled(Typography)`
  font-size: 20px;
  @media (max-width: 587px) {
    font-size: 18px;
  } ;
`;

const LabelItem = styled(Link)`
  font-size: 14px;
  color: gray;
  text-decoration: none;
  cursor: pointer;
  margin: 0px 5px 0px;
`;

const LinkWrapper = styled(RouterLink)`
  font-size: 14px;
  color: gray;
  text-decoration: none;
  cursor: pointer;
`;

const SubjectWrapper = styled(Grid)`
  width: 100%;
`;

const baseURL = "/experts/";

const Screen = () => {
  return (
    <MainWrapper
      container
      alignItems="center"
      direction="row"
      justify="space-around"
      align="center"
    >
      <GroupContainer>
        <MenuWrapper>
          <Label>החברה</Label>
          <LabelItem to="/about">אודות</LabelItem>
        </MenuWrapper>
      </GroupContainer>
      <GroupContainer display="none">
        <MenuWrapper>
          <Label>משפטי</Label>
          <LinkWrapper to="/privacy-policy">שמירת&nbsp;פרטיות</LinkWrapper>
          <LinkWrapper to="/terms-of-use">תנאי&nbsp;שימוש</LinkWrapper>
        </MenuWrapper>
      </GroupContainer>
      <GroupContainer display="none">
        <Label>מטפלים</Label>
        <DoubleGroupContainer>
          <Grid item xs={12}>
            <LabelItem to={baseURL + FAMILY}>משפחה וזוגיות</LabelItem>
            <LabelItem to={baseURL + KIDSANDYOUTH}>ילדים ונוער</LabelItem>
            <LabelItem to={baseURL + DEPRESSIONANXIETY}>דיכאון וחרדה</LabelItem>
          </Grid>
          <Grid item xs={12}>
            <LabelItem to={baseURL + EATINGDISORDERS}>הפרעות אכילה</LabelItem>
            <LabelItem to={baseURL + WORKCAREER}>עבודה וקריירה</LabelItem>
            <LabelItem to={baseURL + OTHER}>מטפלים נוספים</LabelItem>
          </Grid>
        </DoubleGroupContainer>
      </GroupContainer>

      <GroupContainer>
        <MenuWrapper>
          <Label>מידע</Label>
          <LabelItem to="/contact">צור קשר</LabelItem>
          <LabelItem to="/faq">שאלות&nbsp;נפוצות</LabelItem>
        </MenuWrapper>
      </GroupContainer>
    </MainWrapper>
  );
};

const Footer = () => {
  return (
    <Bar>
      <FooterWrapper>
        <SubjectWrapper>
          <Screen />
        </SubjectWrapper>
      </FooterWrapper>
    </Bar>
  );
};

export default Footer;
