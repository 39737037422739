import React from 'react'
import styled from 'styled-components'
import { Box, Link } from '@material-ui/core';
import { LEAD_BLUE, RED } from '../../../utils/globals';
import OptionsInput from './OptionsInput';
import TextInputs from './TextInputs';

const Wrapper = styled.div`
    direction: rtl;
    font-family: 'Rubik', Sans-Serif;
`;

const Title = styled(Box)`
    font-size: 20px;
    color: ${LEAD_BLUE};
`;

const SubTitle = styled(Box)`
    font-size: 18px;
    color: ${RED};
    margin-top: 10px;
`;

const Paragraph = styled(Box)`
    margin-top: 10px;
`;

const LinkWrapper = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    color: ${LEAD_BLUE};
`;

const BulletListWrapper = styled(Box)`
    padding: 10px 20px 20px 20px;
`;

const ContactContent = ({ handleChange, contactState,
                          setCheckState, checkState, newProfessional }) => {
    return (
        <Wrapper>
            <Title align="center">
                צור קשר
            </Title>
            <Paragraph>
                יש לך שאלה? נתקלת בבעיה? תרצה להציע הצעה? עבור לעמוד 
                {' '}
                <LinkWrapper
                    href="/faq"
                >
                    שאלות נפוצות
                </LinkWrapper>
                {' '}
                לקבלת תשובות מהירות.
            </Paragraph>
            <Paragraph>
                אם לא מצאת שם מענה, ואתה עדיין זקוק לסיוע, אנא מלא את הפרטים הבאים ונעשה את מירב המאמצים כדי להשיב בהקדם.
            </Paragraph>
            <SubTitle>
                סיבת הפניה:
            </SubTitle>
            <BulletListWrapper>
                <OptionsInput 
                    handleChange={setCheckState}
                    checkState={checkState}
                />
                <TextInputs 
                    handleChange={handleChange}
                    contactState={contactState}
                    newProfessional={newProfessional}
                />
            </BulletListWrapper>
        </Wrapper>
    )
}

export default ContactContent
