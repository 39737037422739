import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
    getAllProfessionals,
    presentCurrentProfessional
} from '../../../../actions/ProfessionalsActions';
import CategoriesHeader from '../CategoriesHeader';
import Professionals from '../../professionals/Professionals';
import Spinner from '../../../layout/Spinner';
import {OPEN_PROFESSIONAL_DIALOG} from '../../../../utils/globals';
import BlackLogicIcon from '../../../../static/images/geniePictures/BlackLogicIcon.png';
import {openDialog} from '../../../../actions/DialogActions';
import {getCurrentRecommendations} from '../../../../actions/RecommendationsActions';
import {getAllProfessionalsSechdule} from "../../../../actions/ScheduleActions";
import styled from "styled-components";
//import {Container} from "@material-ui/core";


const Paragraph = styled.div`
    padding: 10px;
    margin: 10px;
`;
const Container = styled.p`
    margin-right: 4px;
    padding: 0;
    font-family: 'Rubik', Sens-Serif;
    justify-content: center;
    align-content: center;
     display: grid;
    direction: rtl;
`;

const AvailableTodayPage = ({location}) => {


    const {allProfessionals, professionalsLoading} = useSelector(state => state.professionals)
    const {professionalId, scheduleLoading} = useSelector(state => state.schedule)
    const dispatch = useDispatch();

    useEffect(() => {
        //Fetch all the professionals list
        dispatch(getAllProfessionals());
        dispatch(getAllProfessionalsSechdule())
    }, [dispatch])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has("expert")) {
            const professionalId = searchParams.get("expert");
            onLearnMorePress(professionalId);
        }
        // eslint-disable-next-line
    }, [location.search])
    


    const onLearnMorePress = professionalId => {
        dispatch(presentCurrentProfessional(professionalId));
        dispatch(getCurrentRecommendations(professionalId));
        dispatch(openDialog(OPEN_PROFESSIONAL_DIALOG));
    };

    /**
     * This function filter the professionals based on ID.
     * Inputs : inside redux schedule we have available today professionals based on IDs
     * And we have all the professionals list.
     * This function just matches the IDs and return a new filtered array to show.
     * @returns {[filterProfessionals]}
     */
    const filterProfessionals = () => {

        const filtedArray = [];
        //for each ID which is available,push it to new array
        console.log(professionalId)

        professionalId.forEach(id => {
            const currentID = id[0]
            console.log(id)
            allProfessionals.forEach(professional => {
                //if the id matches add to filtered array
                if (professional.id === currentID) {
                    filtedArray.push(professional)
                }
            })
        })

        console.log(allProfessionals)
       
        return filtedArray;
    }


    return (
        <div>
            <CategoriesHeader
                icon={BlackLogicIcon}
                label="מטפלים פנויים היום"
                // discription={LOGIC}
            />

            {professionalsLoading || scheduleLoading ? <Spinner/> :
                //if there are no professionals today
                professionalId.length === 0 ?
               <>
                   <Container>
                       <Paragraph>
                        מצטערים, אף מטפל לא פנוי היום.
                       </Paragraph>
                   </Container>
                   <Container>
                       <img src="https://img.icons8.com/wired/64/000000/id-not-verified.png" style={{justifyContent:'center'}} alt={''}/>
                   </Container>
               </>
                    :
                    <Professionals
                        profObject={filterProfessionals()}
                    />
            }
        </div>
    )
}

export default AvailableTodayPage
