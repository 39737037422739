import React from 'react';
import FaqHeader from './FaqHeader';
import FaqContent from './FaqContent';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import {Fab} from "@material-ui/core";
import {LEAD_BLUE} from "../../../../utils/globals";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const FaqWrapper = styled(Container)`
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.20), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.10),
              0 16px 16px rgba(0,0,0,0.05);
    margin: 10px;
`;

const FAQ = () => {

    return (
        <>
            <Box align="center">
                <FaqWrapper maxWidth="md">
                    <FaqHeader />
                    <FaqContent />
                </FaqWrapper>
                <div>
                    <Fab size="medium" style={{color: LEAD_BLUE, margin: '5px'}} href={"/"} >
                        <ArrowBackIcon/>

                    </Fab>
                </div>
            </Box>
            </>
    );
};

export default FAQ
