import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { closeDialog, openDialog } from '../../actions/DialogActions';
import {
    OPEN_PROFESSIONAL_ZONE_DIALOG, SETTINGS, INFO, HOME, CALANDER,
    MESSAGES, LEAD_BLUE, FINANCIAL
} from '../../utils/globals';
import { getCurrent, clearError as clearProfError } from '../../actions/ProfessionalsActions'
import Home from '../professionalZone/home/Home';
import Settings from '../professionalZone/settings/Settings';
import Calander from '../professionalZone/calander/Calander';
import Meetings from '../professionalZone/info/Meetings';
import Spinner from '../layout/Spinner';
import Dialog from '@material-ui/core/Dialog';
import { clearError } from '../../actions/ScheduleActions';
import { withRouter } from 'react-router';
import { routeQuery } from '../../utils/routes';
import { getEntireDocument } from '../../actions/FirestoreActions';
import { motion } from 'framer-motion';
import Grid from "@material-ui/core/Grid";


// Styles
const MainWrapper = styled(Grid)`
  background-color: #F0F0F0;
`;

export const TableButton = styled(motion.button)`
  border: 1px solid ${LEAD_BLUE};
  cursor: pointer;
  width: 70px;
  height: 30px;
  border-radius: 10%;
`;

const RenderStep = ({
    step, changePage, currentProfessional,
    professionalsLoading, error, isProfessional, id, onExit,
    fetchedPrivateDetails, setUpdate, handleDetectingChanges, changeState, reFetchPrivateDetails
}) => {
    switch (step) {
        case (HOME):
            return (
                <Home
                    changePage={changePage}
                    currentPage={step}
                    onExit={onExit}
                    fetchedPrivateDetails={fetchedPrivateDetails}
                    isProfessional={isProfessional}
                />
            );
        case (SETTINGS):
            return (
                <Settings
                    id={id}
                    changeState={changeState}
                    changePage={changePage}
                    currentPage={step}
                    currentProfessional={currentProfessional}
                    professionalLoading={professionalsLoading}
                    error={error}
                    isProfessional={isProfessional}
                    fetchedPrivateDetails={fetchedPrivateDetails}
                    reFetchPrivateDetails={reFetchPrivateDetails}
                    setUpdate={setUpdate}
                    onExit={onExit}
                    handleDetectingChanges={handleDetectingChanges}
                />
            );
        case (CALANDER):
            return (
                <Calander
                    changePage={changePage}
                    currentPage={step}
                    onExit={onExit}
                    currentProfessional={currentProfessional}
                    isProfessional={isProfessional}
                />
            );
        case (INFO):
            return (
                <Meetings
                    changePage={changePage}
                    currentPage={step}
                    onExit={onExit}
                    requestedTable={INFO}
                />
            );
        case (FINANCIAL):
            return (
                <Meetings
                    changePage={changePage}
                    currentPage={step}
                    onExit={onExit}
                    requestedTable={FINANCIAL}
                />
            );
        case (MESSAGES):
            return (
                <Home
                    changePage={changePage}
                    currentPage={step}
                    onExit={onExit}
                    currentProfessional={currentProfessional}
                    isProfessional={isProfessional}
                />
            );
        default:
            return {};
    }
};

// Dialog
const ProfessionalZone = ({ history }) => {
    const { user, isProfessional } = useSelector(state => state.auth);
    const { professionalZonelDialog } = useSelector(state => state.dialogs);
    const {
        professionalsLoading,
        currentProfessional, error
    } = useSelector(state => state.professionals);

    const dispatch = useDispatch()
    const [step, setStep] = useState(HOME);
    const [update, setUpdate] = useState(true);
    const [change, setChange] = useState(false);
    const [fetchedPrivateDetails, setFetchedPrivateDetails] = useState({});

   
    useEffect(() => {
        // We check the obvious because this modal requests on mount with id
        if (isProfessional) {
            update && dispatch(getCurrent(user.id));
        }
    }, [isProfessional, update, user, dispatch])


    /**
     * Current pro has changed , check if his legal form is not signed
     * Usually on first login.
     * Show legal form if it's false
     */
    useEffect(() => {
        if (currentProfessional !== undefined) {
            if (currentProfessional.isLegalDocConfirmed === false) {
                history.replace(routeQuery({ privatePage: 'settings' }));
            }
        }
    }, [currentProfessional, history])

    const exitRoutine = useCallback(() => {
        dispatch(closeDialog(OPEN_PROFESSIONAL_ZONE_DIALOG));
        dispatch(clearError());
        dispatch(clearProfError());
        setStep(HOME);
        setChange(false);
        history.replace(routeQuery({ privatePage: undefined }));
    }, [history, dispatch]);

    /**
     * Fetch user details , updates when the user changes
     */
    const fetchedDetails = useCallback(async () => {
        if (!!user) {
            const res = await getEntireDocument("users", user.id);
            setFetchedPrivateDetails(res.data)
            setUpdate(true);
            return res;
        } else {
            exitRoutine()
            return false;
        }
    }, [exitRoutine, user]);

    useEffect(() => {
        fetchedDetails()
            .catch(e => console.log(e));
    }, [fetchedDetails])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pageToRender = urlParams.get('privatePage')?.toUpperCase();
        pageToRender && setStep(pageToRender);
        !professionalZonelDialog && pageToRender && dispatch(openDialog(OPEN_PROFESSIONAL_ZONE_DIALOG));
        // isProfessional && !professionalZonelDialog && pageToRender && dispatch(openDialog(OPEN_PROFESSIONAL_ZONE_DIALOG));
        // eslint-disable-next-line
    }, [window.location.search, isProfessional])


    // Cleaning state and closing dialog.
    //
    const onDialogClose = () => {
        // if (!!change) {
        //     const confirmationInformation = {
        //         warningText: 'זיהינו שינויים שביצעת, במידה והחלון יסגר, השינויים לא ישמרו',
        //         exitFunction: exitRoutine,
        //         show: true,
        //     }
        //     dispatch(openConfirmationDialog(confirmationInformation));
     //   } else {
            exitRoutine();
     //   }
    };

    const changePage = (event, newPage) => {
        setStep(newPage);
    };

    const backButton = () => {
        setStep(HOME);
        history.replace(routeQuery({ box: undefined }));
    }

    const handleDetectingChanges = state => {
        setChange(state);
    };
    return (
        <>
            <Dialog
                open={professionalZonelDialog}
                onClose={onDialogClose}
                maxWidth='md'
                fullWidth={true}

            >
                <MainWrapper>
                    {!!!fetchedPrivateDetails ? <Spinner /> :
                        <RenderStep
                            id={user ? user.id : ''}
                            isProfessional={isProfessional}
                            step={step}
                            changePage={changePage}
                            currentProfessional={!currentProfessional ? '' : currentProfessional}
                            professionalsLoading={professionalsLoading}
                            error={error}
                            fetchedPrivateDetails={fetchedPrivateDetails}
                            setUpdate={setUpdate}
                            onExit={backButton}
                            reFetchPrivateDetails={fetchedDetails}
                            handleDetectingChanges={handleDetectingChanges}
                            changeState={change}
                        />
                    }
                </MainWrapper>
            </Dialog>
        </>
    );
};

export default withRouter(ProfessionalZone);
