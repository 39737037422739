import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RecoBackground } from "../../../utils/globals";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setIndepedent } from "../../../actions/MessagesActions";
import { routeQuery } from "../../../utils/routes";

const ImageStyle = {
  height: "60px",
  width: "60px",
  borderRadius: "50%",
};

const CardWrapper = styled.div`
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 50% 50%;
  max-height: 200px;

  margin: 5px;
  cursor: pointer;
  @media only screen and (max-width: 450px) {
    grid-template-columns: none;
    grid-template-rows: 100% 0%;
    height: 100%;
  }
`;

const ContentWrapper = styled.div`
  padding: 0 10px;
  background-color: ${RecoBackground};
  height: 210px;

  overflow: auto;
  text-align: right;
`;

const Image = styled(motion.div)`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${RecoBackground};
  object-fit: cover;
  height: auto;
`;

const Description = styled.p`
  direction: rtl;
  font-family: "Rubik", Sens-Serif;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
`;

const NameWrapper = styled.div`
  @media (max-width: 450px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
  }
`;

const MobileImage = styled.img`
  display: none;
  @media (max-width: 450px) {
    display: inline-block;
  }
`;

const Name = styled.p`
  text-align: center;
  font-size: 18px;
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  direction: rtl;
  font-family: "Rubik", Sens-Serif;
  color: black;
`;

const Content = ({ prefix, firstName, lastName, expertise, picture }) => {
  const professionalPrefix = prefix.prefix;
  const mainProfession = expertise.profession;
  const description = expertise.firstExpertie.description;
  const mainExpertise = expertise.firstExpertie.header;
  return (
    <ContentWrapper>
      <NameWrapper>
        <Name>
          {professionalPrefix} {firstName} {lastName}
        </Name>
        <MobileImage src={picture} style={ImageStyle} alt="pic" />
      </NameWrapper>
      <p>{mainProfession}</p>
      <p>התמחות: {mainExpertise}</p>
      <Description>{description}</Description>
    </ContentWrapper>
  );
};

const ProfessionalsItem = ({ professionalObject, picture }) => {
  const dispatch = useDispatch();
  const { id, firstName, lastName, prefix, discription, expertise, languages } =
    professionalObject;

  return (
    <LinkWrapper
      to={routeQuery({ expert: id })}
      replace
      onClick={() => dispatch(setIndepedent(true))}
    >
      <CardWrapper>
        <Content
          description={discription}
          prefix={prefix}
          firstName={firstName}
          lastName={lastName}
          expertise={expertise}
          languages={languages}
          picture={picture}
        />
        <Image image={picture} whileHover={{ scale: 1.1 }} />
      </CardWrapper>
    </LinkWrapper>
  );
};

export default ProfessionalsItem;
