import {
    SET_AUTH_LOADING,
    LOGOUT,
    AUTH_ERROR,
    USER_LOADED,
    CLEAR_AUTH_ERROR,
    PROFESSIONAL_LOADED,
    ADMIN_LOADED,
    SET_AUTH_PROCESS, AUTH_SUCCESS, SET_AUTH_ERROR
} from '../actions/types';

const initialState = {
    isAuthenticated: null,
    isAdmin: false,
    isProfessional: false,
    isNormalUser: false,
    authLoading: false,
    authProcessDone: false,
    user: null,
    error: ''
};

export const authReducer = (state = initialState, action) => {

    switch (action.type) {

        // action.payload is the user data received from loadUser func.
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isNormalUser: true,
                user: action.payload
            };

        case AUTH_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case AUTH_SUCCESS:
            return {
                ...state,
                success: action.payload
            };

        case LOGOUT:
            return {
                isAuthenticated: null,
                isAdmin: false,
                isProfessional: false,
                isNormalUser: false,
                authLoading: false,
                authProcessDone: false,
                user: null,
                error: ''
            };

        case SET_AUTH_LOADING:
            return {
                ...state,
                authLoading: action.payload
            };

        case SET_AUTH_ERROR:
            return{
                ...state,
                error:action.payload
            }

        case SET_AUTH_PROCESS:
            return {
                ...state,
                authProcessDone: action.payload
            };

        case CLEAR_AUTH_ERROR:
            return {
                ...state,
                error: ''
            };

        case ADMIN_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isAdmin: true,
                user: action.payload
            };

        case PROFESSIONAL_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isProfessional: true,
                user: action.payload
            };

        default:
            return state;

    };
};

export default authReducer;