import React from "react";
import styled from "styled-components";
import { MobileStepper } from "@material-ui/core";
import { updateProfessionalAssets } from "../../../actions/StorageActions";
import {
  professionalsIcons,
  usersIcons,
} from "./settingsForm/forms/SettingsHome";
import {
  validatePublicSettingsForm,
  validatePrivateSettingsForm,
  validateUsersSettingsForm,
} from "../../../utils/basicFormValidation";
import { getEntireDocument } from "../../../actions/FirestoreActions";
import { prefixArray } from "../../../utils/globals";

const Stepper = styled(MobileStepper)`
  width: 50%;
  margin: 15px;
  background-color: #f0f0f0;
`;

const StepperBtn = styled.button`
  color: ${(props) => (!props.disabled ? "rgb(0, 107, 179)" : "gray")};
  cursor: ${(props) => (!props.disabled ? "pointer" : "")};
  width: 50px;
  outline: none;
  height: 25px;
  border: none;

  &:hover {
    background: black;
    opacity: 0.1;
  }
`;

const areProfessionalsFormFilled = (privateDetails, settingsForm, errors) => {
  errors = validatePrivateSettingsForm(privateDetails, errors); //validating without bank info
  errors = validatePublicSettingsForm(settingsForm, errors); //

  if (errors.length === 0) {
    return false;
  } else {
    return errors;
  }
};

const areUsersFormFilled = (privateDetails) => {
  const privateForm = validateUsersSettingsForm(privateDetails);
  if (privateForm) {
    return true;
  } else {
    return false;
  }
};

export const areFormsFilled = (
  isProfessional,
  privateDetails,
  settingsForm
) => {
  const errors = [];
  const validate = isProfessional
    ? areProfessionalsFormFilled(privateDetails, settingsForm, errors)
    : areUsersFormFilled(privateDetails, errors);

  return validate;
};

// Function receives the entire publib form and the id of the professional
// picture form is: picture = {profilePicture: { stringName: '', displayURL: '' }}
// we check for video and picture if their stringName, which usually holds a
// Reference to the picture or video ("Picture.png") is now a File
// If it is, we update the professional's Assets folder
// We then set the picture or video "stringName" field to the name of the file
// In order to be able to fetch it later on
export const getAssets = (form, id) => {
  let picture = form.picture.profilePicture;
  let video = form.video.presentationVideo;

  if (typeof video.stringName !== "string" && !!video) {
    updateProfessionalAssets(
      id,
      "professionals",
      "video",
      video.stringName.name,
      video.stringName
    );
    video = video.stringName.name;
    form.video["presentationVideo"].stringName = video;
  }
  if (typeof picture.stringName !== "string" && !!picture) {
    updateProfessionalAssets(
      id,
      "professionals",
      "picture",
      picture.stringName.name,
      picture.stringName
    );
    picture = picture.stringName.name;
    form.picture["profilePicture"].stringName = picture;
  }
  return form;
};

export const getPendingState = async (id) => {
  try {
    const res = await getEntireDocument("professionals", id);
    if (res.type === "success") {
      return res.data.pending;
    } else {
      return undefined;
    }
  } catch (e) {
    console.log("error trying to get pending state");
    console.log(e);
  }
};

const StepperButton = ({ onClick, label, activeStep, disabled }) => {
  return (
    <StepperBtn onClick={onClick} disabled={activeStep === disabled}>
      {label}
    </StepperBtn>
  );
};

export const StepperComponent = ({
  activeStep,
  handleNext,
  handleBack,
  isProfessional,
}) => {
  return (
    <Stepper
      variant="dots"
      steps={
        isProfessional ? professionalsIcons.length + 1 : usersIcons.length + 1
      }
      position="static"
      activeStep={activeStep}
      nextButton={
        <StepperButton
          onClick={handleNext}
          label="הבא"
          activeStep={activeStep}
          disabled={
            isProfessional ? professionalsIcons.length : usersIcons.length
          }
        />
      }
      backButton={
        <StepperButton
          onClick={handleBack}
          label="חזור"
          activeStep={activeStep}
          disabled={0}
        />
      }
    />
  );
};

const covertNaN = (int) => {
  var number = parseInt(int);
  return isNaN(number) ? 0 : number;
};

export const evaluatePrice = (obj) => {



  const { education, expertise, prefix } = obj;
  const { secondDegree, thirdDegree } = education;
  const { firstExpertie, secondExpertie, thirdExpertie } = expertise;


  var caledPrice = 0;
  let first = covertNaN(firstExpertie.experienceYears);
  let second = covertNaN(secondExpertie.experienceYears);
  let third = covertNaN(thirdExpertie.experienceYears);
  let maxXp = Math.max(first, second, third);



  if (secondDegree.title === "") {
    caledPrice = 0; //no second degree
  } else {
    if (thirdDegree.title !== "") {
      //check prefix
      if (prefix.prefix === prefixArray[3] || maxXp >= 20) caledPrice = 450;
      else caledPrice = 360;
    } else {
      //only second degree -> check xp
      if (maxXp < 20 && maxXp > 10) {
        caledPrice = 360;
      } else if (maxXp < 10) {
        caledPrice = 270;
      } else {
        caledPrice = 450;
      }
    }
  }
 return caledPrice;

};
