import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Carousel from 'react-material-ui-carousel'
import samPortrait from '../../../static/images/geniePictures/SamPortrait.jpg'
import alternatives from '../../../static/images/geniePictures/alternatives.png'
import psychology from '../../../static/images/geniePictures/Mentors.png'
import card1 from '../../../static/images/geniePictures/card1.jpg'
import card2 from '../../../static/images/geniePictures/card2.jpg'
import card3 from '../../../static/images/geniePictures/card3.jpg'
import ReactPlayer from "react-player"
import {VIDEO_LINK} from "../../../utils/globals";
import {Card} from "@material-ui/core";
import {CardContent} from "semantic-ui-react";

const Wrapper = styled(Grid)`
  display: flex;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  direction: rtl;
  margin-top: 25px;
  margin-bottom: 15px;
  background: ${props => props.backgroundColor ? props.backgroundColor : ''};

`

const Title = styled.div`
  color: #243e88;
  font-size: ${props => props.fontSize ? props.fontSize : '1.35em'};
  letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : ''};
  text-shadow: 1px 0 #797171;
  text-align: ${props => props.textAlign ? props.textAlign : ''};
  font-weight: bold;
  height: auto;
  margin: 15px 0 15px 25px;
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : ''};
  @media(max-width: 560px){
    font-size: 1.25em;
  }
`
const TitleWrapper = styled.div``

function Header() {
    return (

        <Wrapper>
            <TitleWrapper>
                <Grid item xs={12}>
                    <Title fontSize={"1.4em"} letterSpacing={"1px"} paddingBottom={"4px"}>
                        למרות כל המאמצים וההוצאות,עדיין קשה לגייס לקוחות חדשים?
                    </Title>
                </Grid>
                <Grid item xs={12} style={{marginTop: "-20px"}}>
                    <Title fontSize={"1.4em"} letterSpacing={"1px"}>
                        רוצה למלא את הקליניקה, אבל הלקוחות לא
                        מגיעים?
                    </Title>
                </Grid>
            </TitleWrapper>
        </Wrapper>

    )
}

const ContentWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 70% 30%;
  direction: rtl;
  @media (max-width: 560px) {
    grid-template-columns: 100%;
  };
`
const Paragraph = styled.div`
  margin-bottom: 10px;
  font-size: 1em;
`;

const Bold = styled.span`
  font-weight: bold;
`

const ImageWrapper = styled(Grid)`

  align-items: center;
  justify-content: center;
`

const Portrait = styled.img`

  width: 175px;
  height: 175px;
  border-radius: 90%;

`

const UpperImage = styled.div`
  display: grid;
  justify-content: center;
  margin-bottom: 15px;
`


function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function Content() {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    /**
     * In order for the portrait to show first thing in a mobile scree
     * measure the port view width and condition render the view
     * */
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Wrapper>
            <ContentWrapper>
                <Grid container>
                    {windowDimensions.width < 560 ?
                        <Grid item xs={12}>
                            <UpperImage>
                                <ImageWrapper>
                                    <Portrait
                                        src={samPortrait}
                                    />
                                </ImageWrapper>
                            </UpperImage>
                        </Grid>
                        : null}
                    <Grid item xs={12}>
                        <Paragraph>
                            היי, שמי סם ואני רוצה לפתוח בפניך עולם חדש שנקרא <Bold>OpenMynd</Bold> - פלטפורמה ייחודית
                            המאפשרת
                            למטפלים
                            ויועצים לתת ללקוחות שירות מרחוק באמצעות טכנולוגיית וידאו מתקדמת, ידידותית למשתמש וברמת אבטחה
                            הגבוהה
                            ביותר בשוק.
                        </Paragraph>
                        <Paragraph>
                            לכל מטפל יש ב - <Bold>OpenMynd</Bold> עמוד מטפל מקצועי וחדר טיפול פרטי וכל לקוח יכול לבחור את
                            המטפל
                            הנכון
                            עבורו, בזמן שמתאים לו ועל הכורסה שנוחה לו ולקבל כלים כדי לפתור את מה שמטריד אותו כאן ועכשיו,
                            בשיחות
                            וידאו
                            אישיות ומאובטחות, ללא צורך בהורדת תוכנה כלשהיא.
                        </Paragraph>
                        <Paragraph>
                            <Bold>OpenMynd</Bold> תחשוף אותך לקהל יעד חדש ללא צורך כל הוצאה מצידך, תייצר לך ערוץ הכנסה נוסף
                            ותחסוך
                            לך
                            התעסקות וכסף בשיווק, אדמיניסטרציה, גבייה ועוד.
                        </Paragraph>

                    </Grid>
                </Grid>
                {windowDimensions.width > 560 ?
                    <Grid container
                          direction={"column"}
                          alignItems={"center"}
                          justify={"center"}>
                        <ImageWrapper>
                            <Portrait
                                src={samPortrait}
                            />
                        </ImageWrapper>
                    </Grid>
                    :
                    null}
            </ContentWrapper>
        </Wrapper>
    );
}

function WhyNowSection() {
    return (
        <Wrapper backgroundColor={'#cad2df'}>
            <Grid container style={{padding:"10px"}}>
                <Grid item xs={1}/>
                <Grid item xs={11}>
                    <Title textAlign={"right"}>למה דווקא עכשיו?</Title>
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={10}>
                    <Paragraph>
                        שנת 2020 יצרה מציאות חדשה של חוסר ודאות והתמודדות עם אתגרים חדשים בכל התחומים -
                        בריאות, זוגיות, הורות
                        ועבודה.
                        יחד עם זאת, טכנולוגיות התקשורת קפצו שנות דור ותופסות חלק הולך וגדל בחיינו.
                    </Paragraph>
                    <Paragraph>
                        מטפלים ויועצים בתחום הגוף והנפש נאלצים, כמו כולם, להתמודד עם השלכות הקורונה ולתת מזור וסיוע
                        למצבים חדשים - סגר וריחוק חברתי, התפרצויות כעס ואלימות במשפחה, פוביות כתוצאה
                        מאיבוד בודה והתמודדות עם בעיות קיומיות וכלכליות.
                    </Paragraph>
                    <Grid item xs={12}>
                        <Color>
                            אנחנו ב - <Bold>OpenMynd</Bold> מאמינים שצריך לנצל את הקידמה הטכנולוגית, לאתגר את שיטות הטיפול
                            הקיימות ולמצוא פתרונות המתאימים למציאות החדשה.
                        </Color>
                    </Grid>
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        </Wrapper>
    );
}

const Logo = styled.img`
  width: 40%;
  height: 40%;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const WholeItem = styled.div`

  height: 100%;

`
const LogoTitle = styled.div`
  color: #243e88;
  font-size: 22px;
  text-shadow: 1px 0 #797171;
  text-align: ${props => props.textAlign ? props.textAlign : ''};
  font-weight: bold;
  height: 35px;
  margin-top: 10px;

  /**
  Decrease font size to fit smaller screens
   */
  @media (max-width: 550px) {
    font-size: 16px;
  }
  /**
  This condition is to met with only one line description
  To match the underline,used only on one title but can be expanded.
   */
  ${props => props.title === 'מיסטיקנים' && `
     position: relative;
     top: 30px;
     right 5px;
     margin: 0px 0 0px 0px;
  `}

`

function CategoriesLogo({src, title}) {
    return (
        <>
            <WholeItem className={"underline--magical"}>
                <LogoWrapper>
                    <Logo src={src}/>
                </LogoWrapper>
                <LogoWrapper>
                    <LogoTitle
                        textAlign={"center"}
                        title={title}
                    >{title}</LogoTitle>
                </LogoWrapper>
            </WholeItem>
        </>

    );
}


function Categories() {
    return (
        <>
            <Wrapper>
                <Grid container justify={"center"}>
                    <Title textAlign={"center"}>זו ההזדמנות שלך להצטרף לצוות המטפלים שלנו</Title>
                </Grid>
            </Wrapper>
            <Wrapper>
                <Grid container justify={"space-evenly"} alignItems={"center"}>
                    <Grid item xs={5} md={3}>
                        <CategoriesLogo src={alternatives} title={"פסיכותרפיסטים"}/>
                    </Grid>
                    <Grid item xs={5} md={3}>
                        <CategoriesLogo src={psychology} title={"פסיכולוגים "}/>
                    </Grid>

                </Grid>
            </Wrapper>
        </>
    );
}

function CardsCarousel() {
    const items = [
        {src: card1},
        {src: card2},
        {src: card3}
    ]

    return (
        <Wrapper>
            <Grid container
                  justify={"center"}>
                <Carousel
                    animation={"slide"}
                >
                    {
                        items.map((item, i) => <Item key={i} item={item}/>)
                    }
                </Carousel>
            </Grid>
        </Wrapper>
    )
}

const CardImage = styled.img`
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 350px;
`

function Item(props) {
    return (
        <CardImage src={props.item.src}/>
    )
}


function YoutubeVideo() {
    return (
        <Grid container
              alignItems={"center"}
              justify={"center"}>
            <ReactPlayer url={VIDEO_LINK} width={"100%"} controls={true}/>
        </Grid>
    );
}

const InfoCard = styled(Card)`
  background: ${props => props.color} !important;
  width: 100%;
  height: 350px;
  margin-top: 10px;
  box-shadow: 6px 5px 5px #817f7f !important;
  @media (max-width: 1000px) {
    height: 100%;
  }
`


const Color = styled.span`
  color: #243e88;
  font-weight: bold;
`
const Bigger = styled.span`
  font-size: 20px;
`
const CardPadding = styled.div`
  padding: 20px;
`

function WhatDoIGet() {
    return (
        <>
            <Wrapper>
                <Grid container
                      justify={"center"}
                      alignItems={"center"}>
                    <Grid item xs={12} md={5}>
                        <InfoCard color={'#cfd5b1'}>
                            <CardPadding>
                                <CardContent>
                                    <Title>
                                        מה אני מקבל מ-OpenMynd ?
                                    </Title>
                                    <Paragraph>&#62; חשיפה לקהל יעד חדש, ללא כל הוצאה כספית או מאמץ שיווקי
                                        מצידך</Paragraph>
                                    <Paragraph> &#62; הרחבת מעגל הלקוחות שלך מעבר למגבלת המיקום הפיזי שלך והזמן
                                        בקליניקה</Paragraph>
                                    <Paragraph>&#62; ערוץ הכנסה חדש, בין אם יש לך "חור" ביומן ובין אם החלטת לתת טיפולים
                                        נוספים</Paragraph>
                                    <Paragraph>&#62; גמישות מלאה ובחירה מתי ומאיפה תרצה להיפגש עם לקוחות </Paragraph>
                                    <Paragraph>&#62; תוכל להתמקד בלקוח מבלי להתעסק במנהלות - חשבוניות, גביה, שירות
                                        לקוחות
                                        ותמיכה טכנית </Paragraph>
                                </CardContent>
                            </CardPadding>
                        </InfoCard>
                    </Grid>
                    <Grid item md={1}/>
                    <Grid item xs={12} md={5}>
                        <InfoCard color={"#cad2df"}>
                            <CardPadding>
                                <CardContent>
                                    <Title>
                                        כמה זה עולה לי?
                                    </Title>
                                    <Paragraph>&#62; 100 המטפלים הראשונים שיצטרפו ל-OpenMynd יקבלו <Color>קוד קופון בשווי
                                        500 ₪
                                        להצטרפות חינם</Color> </Paragraph>
                                    <Paragraph>&#62; תקבלו מ-OpenMynd ללא תשלום: עמוד מטפל מקצועי, חשיפה ללקוחות חדשים,
                                        קביעת
                                        מפגשים, גבייה, ניהול ושירות הלקוח, הוצאות חשבוניות דיגיטליות ועוד </Paragraph>
                                    <Paragraph>&#62; ההתחייבות היחידה שלכם היא עמלה הנגזרת מהתשלום של הלקוח זאת אומרת
                                        - </Paragraph>
                                    <Title fontSize={"1.125em"}> <Bigger>לא הרווחת - לא
                                        שילמת!</Bigger></Title>
                                </CardContent>
                            </CardPadding>
                        </InfoCard>
                    </Grid>

                </Grid>
            </Wrapper>
        </>
    );
}

const Banner = styled.div`
  background: #6e6e2e;
  color: white;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;;
  letter-spacing: 1px;
  width: 70%;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 20px;
    letter-spacing: normal;

  }
`

function JoinNow() {
    return (
        <Wrapper>
            <Grid container
                  justify={"center"}
                  alignItems={"center"}>
                <Banner>
                    הצטרפו עכשיו ל-OpenMynd וקחו חלק בקידמה!
                </Banner>
            </Grid>
        </Wrapper>
    );
}

const LandingBody = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={1}/>
                <Grid item xs={10}>
                    <Header/>
                    <Content/>
                    <YoutubeVideo/>
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={12}>
                    <WhyNowSection/>
                    <Categories/>
                    <CardsCarousel/>
                    <WhatDoIGet/>
                    <JoinNow/>
                </Grid>
            </Grid>
        </>
    );
};

export default LandingBody;
