import React from 'react';
import { useSelector } from 'react-redux';
import CustomAlert from '../../../../layout/CustomAlert';
import { LEAD_BLUE } from '../../../../../utils/globals';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

const NoteTextField = ({ onSaveClick, display, newNotes, onChange, helperText=null }) => {




    const { alert, meetingsLoading } = useSelector(state => state.meetings);

    return (

        <Box align='center'>
            <TextField
                fullWidth={true}
                rowsMax={5}
                value={newNotes}
                multiline
                variant="filled"
                name="notes"
                onChange={onChange}
                style={{direction: 'rtl'}}
                helperText={helperText ? helperText : null}
            >
            </TextField>

            {display ? 
                <Box p={1}>
                    <Button 
                        variant="contained" 
                        onClick={onSaveClick}
                        style={{ backgroundColor: LEAD_BLUE, color: 'white'}}
                    >
                        Save
                    </Button>
                </Box>
                    : meetingsLoading 
                        ? 
                            <CircularProgress /> 
                        :
                            alert.type &&
                                <Box p={1}>
                                    <CustomAlert 
                                        type={alert.type}
                                        msg={alert.msg}
                                    />
                                </Box>
            }
            
        </Box>
    );
};

export default NoteTextField
