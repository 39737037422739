import React, {useState} from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import {MobileStepper, Button} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const variants = {
    initial: {
        x: '-100vh',
    },
    animate: {
        x: 0,
        transition: {
            x: {duration: .5}
        }
    }
}

const RecoWrapper = styled.div`
    width: 200px;
    height: auto;
`;

const HeaderWrapper = styled.div`
    display: grid;
    justify-items: flex-start;
    height: 50%;
`;

const Content = styled.div`
    display: grid;
    grid-template-rows: auto;
   grid-gap: 5px;
    direction: rtl;
   // position: absolute;
  //  margin-top: 20px;
   
    
`;

const RecoWritter = styled.div`
    font-size: 16px;
    margin-bottom: 5px;
`;

const RecoDate = styled.div`
    font-size: 12px;
    margin-bottom: 5px;
`;

const Stepper = styled(MobileStepper)`
    background: rgb(230, 243, 255);
`;

const RecoContent = ({content}) => {
    return (
        <TextField
            value={content}
            rowsMax={12}
            multiline
        />
    )
}

const RecoHeader = ({name, date}) => {
    return (
        <HeaderWrapper>
            <div>
                <RecoWritter>נכתב ע"י: {name.split(' ')[0]}</RecoWritter>
                <RecoDate>{date.split("-").reverse().join("-")}</RecoDate>
            </div>
        </HeaderWrapper>
    )
}

const RecoStepper = ({recosAmount, activeStep, handleNext, handleBack}) => {
    return (
        <Stepper
            variant="dots"
            steps={recosAmount}
            position="static"
            activeStep={activeStep}
            nextButton={
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === recosAmount - 1}
                >
                    <KeyboardArrowLeft/>
                </Button>
            }
            backButton={
                <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                >
                    <KeyboardArrowRight/>
                </Button>
            }
        />
    );
};

const ProfReco = ({recommendations}) => {

    const [reco, setReco] = useState(recommendations[0]);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setReco(recommendations[activeStep + 1]);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setReco(recommendations[activeStep - 1]);
    };

    return (
        <>
            {reco && <RecoWrapper>
                <Content
                    variants={variants}
                    initial="initial"
                    animate="animate"
                >
                    <RecoContent content={reco.content}/>
                    <RecoHeader name={reco.name} date={reco.dayDate}/>
                    <RecoStepper
                        recosAmount={recommendations.length}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        activeStep={activeStep}
                    />
                </Content>
            </RecoWrapper>}
        </>
    )
}

export default ProfReco
