import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LEAD_BLUE } from '../../../../utils/globals';

const StyledIcon = styled(ExpandMoreIcon)`
    color: ${LEAD_BLUE}
`;

const MenuItemStyle = styled(MenuItem)`
    direction: rtl;
`;  

const FilterMenu = ({ menu, handleMenuClick, 
    handleOptionClick, handleMenuClose }) => {
    return (
        <Box>
            <Button size="small" onClick={handleMenuClick}>
                <StyledIcon />
            </Button>
            <Menu
                open={Boolean(menu)}
                anchorEl={menu}
                keepMounted
                onClose={handleMenuClose}
            >
                <MenuItemStyle onClick={() => {handleOptionClick("All")}}>
                    הכל
                </MenuItemStyle>
                <MenuItemStyle onClick={() => {handleOptionClick("Past")}}>
                    מפגשים שקיימתי
                </MenuItemStyle>
                <MenuItemStyle onClick={() => {handleOptionClick("Upcoming")}}>
                    מפגשים מתואמים
                </MenuItemStyle>
            </Menu>
        </Box>
    );
};

export default FilterMenu
