import React from 'react'
import { Checkbox, FormControlLabel, Grid, Box } from '@material-ui/core'
import styled from 'styled-components';

const Wrapper = styled(Grid)`
    direction: rtl;
    font-family: 'Rubik', Sans-Serif;
`;

const OptionsInput = ({ handleChange, checkState }) => {

    const { service, clientSupport, professionalSupport,
            business, payment } = checkState;

    return (
        <Wrapper
            container
            direction="column"
            justify="center"
        >
            <Box>
                <FormControlLabel 
                    control={
                        <Checkbox
                            checked={!!service}
                            onChange={event => handleChange(event)}
                            name="service"
                            color="primary"
                            value={service}
                        />
                    }
                    label="יש לי שאלה לגבי השירות"
                />
            </Box>
            <Box>
                <FormControlLabel 
                    control={
                        <Checkbox
                            checked={!!clientSupport}
                            onChange={event => handleChange(event)}
                            name="clientSupport"
                            color="primary"
                            value={clientSupport}
                        />
                    }
                    label="אני לקוח רשום ואני זקוק לתמיכה"
                />
            </Box>
            <Box>
                <FormControlLabel 
                    control={
                        <Checkbox
                            checked={!!professionalSupport}
                            onChange={event => handleChange(event)}
                            name="professionalSupport"
                            color="primary"
                            value={professionalSupport}
                        />
                    }
                    label="אני מטפל רשום ואני זקוק לתמיכה"
                />
            </Box>
            <Box>
                <FormControlLabel 
                    control={
                        <Checkbox
                            checked={!!business}
                            onChange={event => handleChange(event)}
                            name="business"
                            color="primary"
                            value={business}
                        />
                    }
                    label="יש לי שאלה בנושא עסקי"
                />
            </Box>
            <Box>
                <FormControlLabel 
                    control={
                        <Checkbox
                            checked={!!payment}
                            onChange={event => handleChange(event)}
                            name="payment"
                            color="primary"
                            value={payment}
                        />
                    }
                    label="יש לי שאלה בקשר לחיוב"
                />
            </Box>
        </Wrapper>
    )
}

export default OptionsInput
