import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HOURS, RED, LEAD_BLUE, RecoBackground, MODIFY } from '../../../../utils/globals';
import SelectForm from './SelectForm';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import { motion } from 'framer-motion';
import { getRelevantMeeting, preformRemoval, setNewHoursObject } from '../calendarUtils/calendarHelpers';
import { openOptionsDialog, openConfirmationDialog } from '../../../../actions/DialogActions';
import { useDispatch } from 'react-redux';
import { getEntireDocument } from '../../../../actions/FirestoreActions';
import CheckIcon from '@material-ui/icons/Check';

const HoursFieldWrapper = styled(motion.div)`

  display: grid;
  grid-template-columns: 40% 40% 20%;
  align-items: center;
  justify-items: center;
  margin: 10px;
  padding: 10px;
  background-color: ${props => props.ismeeting && RecoBackground};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25),
  0 2px 2px rgba(0, 0, 0, 0.20),
  0 4px 4px rgba(0, 0, 0, 0.15),
  0 8px 8px rgba(0, 0, 0, 0.10),
  0 16px 16px rgba(0, 0, 0, 0.05);
  @media (max-width: 600px) {
    grid-template-columns: none;
    grid-gap: 10px;
  }
`;

const MeetingHoursFieldWrapper = styled(motion.div)`
  display: grid;
  align-items: center;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const InfoIconBackgrond = styled(motion.button)`
  cursor: pointer;
  width: 35px;
  height: 35px;

  background-color: ${props => props.iconbgcolor && props.iconbgcolor};
  border-radius: 60%;
  border: none;
  margin: 5px;
`;

const ClientName = styled.p`
  margin: 0;
  padding: 0;
  text-align: right;
  font-weight: bold;
  font-family: 'Rubik', Sens-Serif, serif;
`;

const Icon = ({ icon, onClick, iconBackGround, disabled }) => {
    return (
        <InfoIconBackgrond
            onClick={disabled ? null : onClick}
            iconbgcolor={iconBackGround}
            whileHover={{ scale: 1.1 }}
        >
            {icon}
        </InfoIconBackgrond>
    );
};

const RegularHoursField = ({
    startHour, onSelectedHour, endHour,
    availableEndHours, disable, onRemoveClick, onSubmit, isSaved, setIsSaved, index
}) => {


    const [disabled, setDisabled] = useState(true);

    const buttonColor = () => {
        return disable ? '#00800078' : 'Green'
    }


    return (
        <HoursFieldWrapper>
            <SelectForm
                label="התחלה"
                value={startHour}
                onChange={async (event) => {
                    onSelectedHour(event)
                    await onSubmit(index)
                    setDisabled(false)
                }}
                name="start"
                fields={HOURS}
            />
            <SelectForm
                label="סיום"
                value={endHour}
                name="end"
                fields={availableEndHours}
                isDisabled={true}
            />
            {isSaved ?
                <Icon
                    icon={<RemoveIcon style={{ color: RED }} />}
                    onClick={() => {
                        onRemoveClick();
                    }
                    }
                    iconBackGround={'#ffb9b3'}
                />
                :
                <Icon
                    icon={<CheckIcon style={{ color: "white" }} />}
                    onClick={async () => {

                        await onSubmit(index)
                        setIsSaved(true)
                    }}
                    disabled={disabled}
                    iconBackGround={`${buttonColor()}`}
                />}
        </HoursFieldWrapper>
    );
};

const MeetingHoursField = ({
    startHour, onSelectedHour, endHour,
    availableEndHours, disable, clientName, onEditClick
}) => {
    return (
        <MeetingHoursFieldWrapper>
            <ClientName>פגישה עם: {clientName}</ClientName>
            <HoursFieldWrapper ismeeting={true}>
                <SelectForm
                    label="התחלה"
                    value={startHour}
                    onChange={event => onSelectedHour(event)}
                    name="start"
                    fields={HOURS}
                />
                <SelectForm
                    label="סיום"
                    value={endHour}
                    onChange={event => onSelectedHour(event)}
                    name="end"
                    fields={availableEndHours}
                    isDisabled={disable}
                />
                <IconsWrapper>
                    <Icon
                        icon={<EditIcon style={{ color: '#66c2ff' }} />}
                        onClick={onEditClick}
                        iconBackGround={LEAD_BLUE}
                    />
                </IconsWrapper>
            </HoursFieldWrapper>
        </MeetingHoursFieldWrapper>
    );
};

const RenderField = ({
    isMeeting,
    startHour,
    onSelectedHour,
    endHour,
    availableEndHours,
    disable,
    onRemoveClick,
    onEditClick,
    onSubmit,
    data,
    date,
    index,
    isSaved,
    setIsSaved
}) => {

    return (
        <>
            {
                !!isMeeting ?
                    <MeetingHoursField
                        startHour={startHour}
                        onSelectedHour={onSelectedHour}
                        endHour={endHour}
                        disable={disable}
                        availableEndHours={availableEndHours}
                        clientName={isMeeting.clientName}
                        onEditClick={onEditClick}
                    /> :
                    <RegularHoursField
                        startHour={startHour}
                        onSelectedHour={onSelectedHour}
                        endHour={endHour}
                        disable={disable}
                        data={data}
                        index={index}
                        isSaved={isSaved}
                        date={date}
                        setIsSaved={setIsSaved}
                        availableEndHours={availableEndHours}
                        onRemoveClick={onRemoveClick}
                        onSubmit={onSubmit}
                    />
            }
        </>
    );
};

const HoursField = ({
    data, startHour, endHour,
    setScheduleState, index, isMeeting, onSubmit
}) => {
    const [disable, setDisable] = useState(true);
    const [availableEndHours, setAvailableEndHours] = useState(HOURS);
    const { date, current } = data;
    const { day, hours } = date;
    const dispatch = useDispatch();

    const [isSaved, setIsSaved] = useState(data.date.isSaved === undefined)


    const getPrevValue = position => {
        if (position === 'start') {
            return startHour;
        }
        ;
        return endHour;
    };

    const manipulateHours = startHour => {
        const indexOfStartHour = HOURS.indexOf(startHour);
        const shortenedList = HOURS.slice(indexOfStartHour);
        setAvailableEndHours(shortenedList);
    };

    const onRemoveClick = async () => {
        const newHoursList = hours.filter(loopedHour =>
            loopedHour.start !== startHour
        );
        let newCurrentList = [...current];
        for (let dayObject = 0; dayObject < newCurrentList.length; dayObject++) {
            if (Object.keys(newCurrentList[dayObject])[0] === day) {
                let relevantHours = Object.values(newCurrentList[dayObject])[0];
                for (let hoursObject = 0; hoursObject < relevantHours.length; hoursObject++) {
                    if (relevantHours[hoursObject].start === startHour) {
                        newCurrentList = preformRemoval(newCurrentList, day, relevantHours, hoursObject);
                    }
                    ;
                }
            }
            ;
        }
        ;

        const newDataObject = {
            ...data,
            current: newCurrentList,
            date: {
                day,
                hours: newHoursList
            }
        };

        await setScheduleState("data", newDataObject);
        //need to send to server 
        onSubmit(); //Talea update DB
        setIsSaved(true) //Talea
        const confirmationInformation = {
            warningText: 'היומן עודכן בהצלחה',
            exitFunction: () => { },
            show: true,
        }
        dispatch(openConfirmationDialog(confirmationInformation));
    };

    const onSelectedHour = event => {
        const newValue = event.target.value;
        const position = event.target.name;


        const prevValue = getPrevValue(position);

        if (position === "start") {
            setDisable(false);
            manipulateHours(newValue);
        }
        ;
        const hoursObject = setNewHoursObject(position, newValue, prevValue, hours, index);

        const newDateObject = {
            day,
            hours: hoursObject,
            isSaved: false,
        };
        const newDataObject = {
            ...data,
            date: newDateObject,
        };
        //local
        setScheduleState('data', newDataObject);
        //server

    };

    const onEditClick = async () => {
        const professionalMeetings =
            await getEntireDocument(
                "professionalMeetings",
                isMeeting.professionalId,
            );
        const meetingObject = getRelevantMeeting(
            professionalMeetings.data, isMeeting.meetingId
        );
        const optionsDialogObject = {
            show: true,
            meetingObject,
            screenToRender: MODIFY
        }
        dispatch(openOptionsDialog(optionsDialogObject))
    };

    const onSave = () => {

        const newDateObject = {
            day: data.date.day,
            hours: data.date.hours,
        };

        const newDataObject = {
            ...data,
            date: newDateObject,
        };

        setScheduleState('data', newDataObject); //change local state to saved
        onSubmit(); //update DB
        setIsSaved(true)
        const confirmationInformation = { //Talea
            warningText: 'היומן עודכן בהצלחה',
            exitFunction: () => { },
            show: true,
        }
        dispatch(openConfirmationDialog(confirmationInformation));
    }

    return (
        <RenderField
            isMeeting={isMeeting}
            startHour={startHour}
            onSelectedHour={onSelectedHour}
            endHour={endHour}
            data={data}
            date={date}
            index={index}
            isSaved={isSaved}
            setIsSaved={setIsSaved}
            availableEndHours={availableEndHours}
            disable={disable}
            onRemoveClick={onRemoveClick}
            onEditClick={onEditClick}
            onSubmit={onSave}
        />
    );
};

export default HoursField;
