import React, {useState} from 'react';
import { Formik  } from 'formik';
import {useSelector} from "react-redux";
import { 
    AuthAlertsManager,
    emailConfirmationValidation,
    checkName,
    emailValidation, 
    FormWrapper, 
    MainWrapper, 
    MoveTo, 
    passwordValidation, 
    StyledInput, 
    AgeGroupSelector,
    SubmitButton, FormHeader 
} from '../AuthHelpers';
import { LOGIN } from '../../../../utils/globals';
import ValidationDialog from "./ValidationDialog";
import styled from "styled-components";

const Navigations = () => {
    return (
        <>
            <MoveTo 
                to={LOGIN}
                text="יש לך משתמש? התחבר" 
            />
        </>
    );
};

const Text = styled.div`
  margin: auto;


  //margin: -5px 0;
  color: gray;
  font-size: 14px;
  /* margin: 5px;*/
  font-family: 'Rubik', Sens-Serif, serif;
`;

const UserRegisteration = ({ onUserRegister }) => {
    const [open,setOpen] = useState(false);
    const { error } = useSelector(state => state.auth);
    const [show, setShow] = useState(true);


    const hidePassHelper = (bool) => {
        setShow(bool);
    }

    return (
        <MainWrapper>
            <FormHeader text="הרשמת לקוחות" />
            <Formik 
                initialValues={{ 
                    firstName: '', 
                    lastName: '',
                    ageGroup: '',
                    city: '',
                    email: '',
                    confirmEmail: '',
                    password: '',
                }} 
                validate={values => {
                    const errors = {};
                    checkName(errors, values.firstName, values.lastName);
                    emailValidation(errors, values.email);
                    emailConfirmationValidation(errors, values.email, values.confirmEmail);
                    passwordValidation(errors, values.password);
                    hidePassHelper(false);
                    return errors;
                }}
                onSubmit={data => {
                    if(error){
                        setOpen(open);
                    }
                    onUserRegister(data)
                }}
            >

                {({errors}) => (
                    <FormWrapper>
                        <StyledInput name="firstName" type="input" placeholder="שם פרטי" label="שם פרטי" />
                        <StyledInput name="lastName" type="input" placeholder="שם משפחה" label="שם משפחה" />
                        <AgeGroupSelector name="ageGroup" type="input" placeholder="קבוצת גיל" label="קבוצת גיל" />
                        <StyledInput name="city" type="input" placeholder="יישוב" label="יישוב" />
                        <StyledInput name="email" type="input" placeholder="אימייל" label='דוא"ל' />
                        <StyledInput name="confirmEmail" type="input" placeholder="אשר אימייל" label='אשר דוא"ל' />
                        <StyledInput name="password" type="password" placeholder="סיסמה" label="סיסמה" />
                        {show ? <>
                                <Text>הסיסמה צריכה להחיל 8 תווים עם </Text>
                                <Text>לפחות ספרה אחת ואות אחת באנגלית</Text>
                            </>
                            :
                            null
                        }

                        <SubmitButton name="submit" text="שמור" />

                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                        <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    </FormWrapper>
                )}

            </Formik>
            {open && <ValidationDialog openDialog={open} error={error.msg}/> }
            <Navigations />

            <AuthAlertsManager />
        </MainWrapper>
    )
}

export default UserRegisteration
