import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import CalanderItem from './CalanderItem';
import CalanderHeader from './CalanderHeader';
import {removeExistingMeetingsFromSchedule} from '../../professionalZone/calander/calendarUtils/calendarHelpers';
import Spinner from '../../layout/Spinner';
import styled from 'styled-components';

const MainWrapper = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    direction: rtl;
    grid-gap: 10px;
`;

const Calander = ({
                      currentProfessional, day, hour,
                      onChange, nextStep, prevStep, onDayChange, onExit,
                      setForm, setHours, form, hours, resetCalanderForms,
                      modifyAnExistingMeeting, handleDetectingChanges
                  }) => {

    const {schedule, scheduleLoading} = useSelector(state => state.schedule);
    const [scheduleWithoutMeetings, setScheduleWithoutMeetings] = useState([]);
    const {firstName, lastName} = currentProfessional;

    useEffect(() => {

        if (schedule.length) {
            let cleanSchedule = [...schedule];
            cleanSchedule = removeExistingMeetingsFromSchedule(cleanSchedule);
            setScheduleWithoutMeetings(cleanSchedule);
        }
        // eslint-disable-next-line
    }, [schedule])

    return (

        <MainWrapper>
            <>
                {!modifyAnExistingMeeting && <CalanderHeader
                    firstName={firstName}
                    lastName={lastName}
                    onExit={onExit}
                />}
            </>

            {
                !!!scheduleWithoutMeetings ? <Spinner/> :
                    <CalanderItem
                        loading={scheduleLoading}
                        scheduleObject={scheduleWithoutMeetings}
                        day={day}
                        hour={hour}
                        onChange={onChange}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        resetCalanderForms={resetCalanderForms}
                        onDayChange={onDayChange}
                        setForm={setForm}
                        setHours={setHours}
                        form={form}
                        hours={hours}
                        modifyAnExistingMeeting={modifyAnExistingMeeting}
                        handleDetectingChanges={handleDetectingChanges}
                    />
            }
        </MainWrapper>
    );
};

export default Calander
