import _ from 'lodash';
import moment from "moment";

export const getRelevantContacts = (meetings, isNormalUser) => {

    let relevantContacts = [];
    if (isNormalUser) {
        for (const meeting of Object.values(meetings)) {
            for (let data = 0; data < meeting.length; data++) {
                let meetingData = meeting[data].professional;
                const contactDetails = {
                    name: `${meetingData.proFirstName} ${meetingData.proLastName}`,
                    email: meetingData.proEmail,
                    id: meetingData.id,
                }

                let date = moment(meeting[data].date.day)
                let now = moment()

                if (date > now) {
                    relevantContacts.push(contactDetails);
                }

            }
        }
        ;
    } else {
        for (const meeting of Object.values(meetings)) {
            for (let data = 0; data < meeting.length; data++) {
                let meetingData = meeting[data].client;
                const contactDetails = {
                    name: `${meetingData.firstName} ${meetingData.lastName}`,
                    email: meetingData.email,
                    id: meetingData.clientId,
                }
                relevantContacts.push(contactDetails);
            }
        }
        ;
    }

    relevantContacts = _.uniqBy(relevantContacts, 'id');
    return relevantContacts;
};
