import React, {  } from 'react';
import ProfessionalsMangment from '../ProfessionalsMangment';




const ReviewForm = ({isPending}) => {


return(
  <>
  <div style={{
      textAlign:'right',
      fontSize:'20px'
  }}>

    :מטפלים אשר מחכים לאישור
    <br></br>
    מחק - מוציא את המטפל מהמתנה לאישור
    <br></br>
    עריכה - ניתן לערוך את הפרטים,לאשר מטפל או לשלוח לו חזרה לתיקון
    <br></br>
    ניתן ללחוץ על כרטיס המטפל כדי לראות איך הכרטיס יוצג באתר
  </div>

    <ProfessionalsMangment
        isPending={isPending}
        title={''}
    />
  </>
)

}

    
export default ReviewForm
