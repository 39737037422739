import React from 'react';
import MediaForm from './MediaForm';
import ExpertiseForm from './ExpertiseForm';
import EconomyForm from './EconoomyForm';
import SettingsHome from './SettingsHome';
import ContactForm from './ContactForm';
import LanguageForm from './LanguageForm';
import AcademicForm from './AcademicForm';
import ExperienceForm from './ExperienceForm';

const professionalFields = [
    {
        formName: 'firstName',
        label: 'שם פרטי:',
        disabled: true,
    },
    {
        formName: 'lastName',
        label: 'שם משפחה:',
        disabled: true,
    },
    {
        formName: 'prefixState',
        label: 'תואר: ',
    },
    {
        formName: 'idNumber',
        label: 'תעודת זהות:'
    },
    {
        formName: 'address',
        label: 'כתובת:'
    },
    {
        formName: 'email',
        label: 'דוא"ל:',
        disabled: true,
    },
    {
        formName: 'localPhone',
        label: "טלפון נייד"
    },
]

const userFields = [
    {
        formName: 'firstName',
        label: 'שם פרטי:',
        disabled: true,
    },
    {
        formName: 'lastName',
        label: 'שם משפחה:',
        disabled: true,
    },
    {
        formName: 'email',
        label: 'דוא"ל:',
        disabled: true,
    },
    {
        formName: 'localPhone',
        label: "טלפון נייד"
    },
];

const UsersForm = ({
                       activeStep, setActiveStepManually, privateDetails,
                       onPrivateDetailsChange, isProfessional
                   }) => {

    switch (activeStep) {
        case 1:
            return (
                <ContactForm
                    privateDetails={privateDetails}
                    onChange={onPrivateDetailsChange}
                    isProfessional={isProfessional}
                    fields={isProfessional ? professionalFields : userFields}
                />
            )
        default:
            return {}
    }
};

const ProfessionalsForm = ({
                               activeStep, setActiveStepManually,
                               pictureState, videoState, onFileChange, licenseState, prefixState,
                               experienceState, educationState, languagesState, onExpertiesChange,
                               expertiseState, priceState, isProfessional, privateDetails,
                               onPrivateDetailsChange, onPublicDetailsChange,updateForm,trainingState,socialNetworkState,onPriceChange
                           }) => {


                           


    switch (activeStep) {
        case 0:
            return (
                <SettingsHome
                    onClick={setActiveStepManually}
                    isProfessional={isProfessional}

                />
            )
        case 1:
            return (
                <ContactForm
                    privateDetails={privateDetails}
                    onChange={onPrivateDetailsChange}
                    onPrefixChange={onPublicDetailsChange}
                    isProfessional={isProfessional}
                    prefixState={prefixState}
                    fields={isProfessional ? professionalFields : userFields}
                />
            )
        case 2:
            return (
                <ExpertiseForm
                    originalExperties={expertiseState}
                    onChange={onExpertiesChange}
                />
            )
        case 3:
            return (
                <LanguageForm
                    onChange={onPublicDetailsChange}
                    languageState={languagesState}
                    socialNetworkState={socialNetworkState}
                />
            )
        case 4:
            return (
                <AcademicForm
                    licenseState={licenseState}
                    educationState={educationState}
                    onChange={onPublicDetailsChange}
                />
            )
        case 5:
            return (
                <ExperienceForm
                    experienceState={experienceState}
                    onChange={onPublicDetailsChange}
                />
            )
        case 6:
            return (
                <EconomyForm
                    originalPrice={priceState}
                    onPriceChange={onPriceChange}
                   
                />
            )
        case 7:
            return (
                <MediaForm
                    originalPicture={pictureState}
                    originalVideo={videoState}
                    onChange={onFileChange}
                    updateForm={updateForm}
                />
            )

        default:
            return {}
    }
};

const RenderForm = ({
                        activeStep, setActiveStepManually, firstName, lastName,
                        prefix, pictureState, videoState, onFileChange, onExpertiesChange,
                        licenseState, experienceState, educationState, prefixState,
                        languagesState, expertiseState, priceState, onPublicDetailsChange,
                        privateDetails, onPrivateDetailsChange, isProfessional, goBack,updateForm,socialNetworkState,onPriceChange

                    }) => {
    return (
        <>
            {
                isProfessional ?
                    <ProfessionalsForm
                        isProfessional={isProfessional}
                        activeStep={activeStep}
                        setActiveStepManually={setActiveStepManually}
                        firstName={firstName}
                        lastName={lastName}
                        prefix={prefix}
                        pictureState={pictureState}
                        videoState={videoState}
                        socialNetworkState={socialNetworkState}
                        onFileChange={onFileChange}
                        licenseState={licenseState}
                        experienceState={experienceState}
                        educationState={educationState}
                        prefixState={prefixState}
                        languagesState={languagesState}
                        expertiseState={expertiseState}
                        priceState={priceState}
                        privateDetails={privateDetails}
                        onPrivateDetailsChange={onPrivateDetailsChange}
                        onPublicDetailsChange={onPublicDetailsChange}
                        onExpertiesChange={onExpertiesChange}
                        goBack={goBack}
                        updateForm={updateForm}
                        onPriceChange={onPriceChange}
                       
                    /> :
                    <UsersForm
                        activeStep={1}
                        setActiveStepManually={setActiveStepManually}
                        firstName={firstName}
                        lastName={lastName}
                        privateDetails={privateDetails}
                        onPrivateDetailsChange={onPrivateDetailsChange}
                        isProfessional={isProfessional}
                        goBack={goBack}
                    />
            }
        </>
    );
};

const Forms = ({
                   activeStep, setActiveStepManually, firstName, lastName,
                   prefix, pictureState, videoState, onFileChange, onExpertiesChange,
                   licenseState, experienceState, educationState, prefixState,
                   languagesState, expertiseState, priceState, onPublicDetailsChange,
                   privateDetails, onPrivateDetailsChange, isProfessional,updateForm,trainingState,socialNetworkState,onPriceChange
               }) => {

    return (
        <>
            <RenderForm
                trainingState={trainingState}
                isProfessional={isProfessional}
                activeStep={activeStep}
                setActiveStepManually={setActiveStepManually}
                firstName={firstName}
                lastName={lastName}
                prefix={prefix}
                pictureState={pictureState}
                videoState={videoState}
                onFileChange={onFileChange}
                licenseState={licenseState}
                experienceState={experienceState}
                educationState={educationState}
                prefixState={prefixState}
                languagesState={languagesState}
                socialNetworkState={socialNetworkState}
                expertiseState={expertiseState}
                priceState={priceState}
                privateDetails={privateDetails}
                onPrivateDetailsChange={onPrivateDetailsChange}
                onPublicDetailsChange={onPublicDetailsChange}
                onExpertiesChange={onExpertiesChange}
                updateForm={updateForm}
                onPriceChange={onPriceChange}
            

            />
        </>
    )
}

export default Forms
