/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
    setError,
    updateProfessionalForm,
    updateProfessionals,
} from "../../../actions/ProfessionalsActions";
import { getProfessionalAssets } from "../../../actions/StorageActions";
import CustomAlert from "../../layout/CustomAlert";
import Spinner from "../../layout/Spinner";
import { objectExtractor } from "../../../utils/objectManipulation";
import Toggler from "../Toggler";
import styled from "styled-components";
import { motion } from "framer-motion";
import Forms from "./settingsForm/forms/Forms";
import {
    addNewDoucment,
    getEntireDocument,
    removeDocument,
    updateDocument,
} from "../../../actions/FirestoreActions";
import {
    areFormsFilled,
    getAssets,
    StepperComponent,
} from "./settingsUtils";
import { createAlertMessage } from "../../../utils/basicFormValidation";
import { CATAGORIES_ARRAY, LEAD_BLUE } from "../../../utils/globals";
import SettingsBar from "../calander/SettingsBar";
import { ButtonsWrapper } from "../../professionalForm/payment/PaymentBoiler";
import { routeQuery } from "../../../utils/routes";
import { withRouter } from "react-router-dom";
import { genericEmail } from "../../../actions/FunctionsActions";
import LegalForm from "./settingsForm/forms/LegalForm";
import { publicDetailsForm } from "../../professionalForm/formUtils";
import { openConfirmationDialog } from "../../../actions/DialogActions";

export const Label = styled(motion.span)`
  font-size: 18px;
  padding: 5px;
  margin: 5px;
  font-family: "Rubik", Sens-Serif, sans-serif;
  direction: rtl;
`;
export const HeadLabel = styled(motion.div)`
  color: ${LEAD_BLUE};
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding: 5px;
  margin: 5px;
  font-family: "Rubik", Sens-Serif, sans-serif;
  direction: rtl;
`;
const MainWrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-gap: 10px;
`;

const SaveBtn = styled(motion.button)`
  background-color: ${LEAD_BLUE};
  color: white;
  border: none;
  margin: 10px;
  border-radius: 10%;
  cursor: pointer;
  max-width: 100px;
  height: 40px;
`;

export const SaveButton = ({
    professionalLoading,
    spinner,
    title,
    onClick,
    disabled,
}) => {
    if (disabled === "denied" || disabled === true) {
        return null;
    } else {
        return (
            <ButtonsWrapper>
                {professionalLoading || spinner ? (
                    <Spinner />
                ) : (
                    <SaveBtn onClick={onClick} whileHover={{ scale: 1.1 }}>
                        {title}
                    </SaveBtn>
                )}
            </ButtonsWrapper>
        );
    }
};

const DisplayStatus = ({ pending }) => {
    return (
        <>
            {pending && pending.status === true && (
                <CustomAlert
                    type="info"
                    msg="המידע נשלח לבדיקה, נעדכן בהקדם"
                ></CustomAlert>
            )}
            {pending && pending.status === "fix" && (
                <CustomAlert type="warning" msg={pending.message}></CustomAlert>
            )}
            {pending && pending.status === "denied" && (
                <CustomAlert type="info" msg={pending.message}></CustomAlert>
            )}
        </>
    );
};

const Settings = ({
    changePage,
    currentPage,
    currentProfessional,
    professionalLoading,
    error,
    isProfessional,
    fetchedPrivateDetails,
    setUpdate,
    handleDetectingChanges,
    id,
    onExit,
    history,
    changeState,
    reFetchPrivateDetails,
}) => {
    const [alert, setAlert] = useState({
        type: "",
        msg: "",
    });

    const [activeStep, setActiveStep] = useState(0);
    //detect fields that hav effect on the price -> approved prof changing this will move to pending state.
    const [detectPriceAfftecdFields, setdetectPriceAfftecdFields] = useState(false)


    const { picture, pending, prefix, isLegalDocConfirmed } = currentProfessional;

    const {
        localPhone,
        idNumber,
        address,
        accountName = '',
        bankName = '',
        branchNumber = '',
        accountNumber = '',
        firstName,
        lastName,
        email,
        signDate,
    } = fetchedPrivateDetails;

    const dispatch = useDispatch();
    const [spinner, setSpinner] = useState(false);

    const [privateDetails, setPrivateDetails] = useState({
        firstName,
        lastName,
        email,
        idNumber,
        localPhone,
        address,
        signDate,
        accountName: accountName,
        bankName,
        branchNumber,
        accountNumber,
        prefix,
    });
    const [settingsForm, setSettingsForm] = useState(
        publicDetailsForm(currentProfessional, privateDetails, id)
    );


    const {
        pictureState,
        discriptionState,
        typeState,
        expertiseState,
        licenseState,
        priceState,
        videoState,
        educationState,
        languagesState,
        experienceState,
        prefixState,
        socialNetworkState,
    } = settingsForm;

    privateDetails.prefix = settingsForm.prefixState.prefix; //take from public details

    /**
     * Load URLS from storage
     * @type {function(): Promise<void>}
     */
    const loadURLs = useCallback(async () => {
        if (currentProfessional.picture !== undefined) {
            const userPhoto = currentProfessional.picture.profilePicture.displayURL;
            const userVideo = currentProfessional.video.presentationVideo.displayURL;

            if (userPhoto !== "" && !!userPhoto) {
                const pictureRef = await getProfessionalAssets(
                    id,
                    "professionals",
                    "picture",
                    "profilePicture.png"
                );
                onFileChange(true, pictureRef);
            }
            if (userVideo !== "" && !!userVideo) {
                const videoRef = await getProfessionalAssets(
                    id,
                    "professionals",
                    "video",
                    "video.mp4"
                );
                onFileChange(false, videoRef);
            }
        }
    }, [id, settingsForm]);

    useEffect(() => {
        if (currentProfessional) {
            !!picture && loadURLs();
        }
    }, [currentProfessional]);

    const onPrivateDetailsChange = (name, value) => {
        handleDetectingChanges(true);
        setPrivateDetails({
            ...privateDetails,
            [name]: value,
        });
    };

    const onPriceChange = (updatedPrice) => {
        handleDetectingChanges(true);
        setSettingsForm({
            ...settingsForm,
            price: {
                normalPrice: updatedPrice,
                threePackPrice: updatedPrice * 3 - updatedPrice * 0.1 * 3,
            },
        });
    };


    const onPublicDetailsChange = (event, formName, secondName) => {

        if (formName === 'educationState') {
            setdetectPriceAfftecdFields(true)
        }
        handleDetectingChanges(true);
        if (secondName !== undefined) {
            //handle nested fields
            setSettingsForm({
                ...settingsForm,
                [formName]: {
                    ...settingsForm[formName],
                    [secondName]: {
                        ...settingsForm[formName][secondName],
                        [event.target.name]: event.target.value,
                    },
                },
            });
        } else {
            //handle basic fields
            setSettingsForm({
                ...settingsForm,
                [formName]: {
                    ...settingsForm[formName],
                    [event.target.name]: event.target.checked || event.target.value,
                },
            });
        }
    };

    /**
     * On file change update the local state to point to download URL
     */
    const onFileChange = (isPhoto, url) => {
        if (isPhoto) {
            setSettingsForm({
                ...settingsForm,
                pictureState: {
                    profilePicture: {
                        ...settingsForm.pictureState["profilePicture"],
                        displayURL: url,
                    },
                },
            });
        } else {
            setSettingsForm({
                ...settingsForm,
                videoState: {
                    presentationVideo: {
                        ...settingsForm.videoState["presentationVideo"],
                        displayURL: url,
                    },
                },
            });
        }
        handleDetectingChanges(false);
    };

    const onExpertiesChange = (event, formLocation) => {

        handleDetectingChanges(true);
        // updatePrice()
        setdetectPriceAfftecdFields(true)
        let value = event.target.value;
        let name = event.target.name;


        //special case for selectors
        if (name === undefined) {
            name = formLocation
            formLocation = 'header'
            //change value to the right catagorie
            value = CATAGORIES_ARRAY[value]
        }



        if (name === "profession") {
            setSettingsForm({
                ...settingsForm,
                expertiseState: {
                    ...settingsForm["expertiseState"],
                    [name]: value,
                },
            });
            return;
        }

        setSettingsForm({
            ...settingsForm,
            expertiseState: {
                ...settingsForm["expertiseState"],
                [name]: {
                    ...settingsForm["expertiseState"][name],
                    [formLocation]: value,
                },
            },
        });
    };

    const submitPublicForm = () => {
        let form = objectExtractor(settingsForm);
        try {
            form = getAssets(form, id);
        } catch (e) {
            console.log(e);
        }

        form = {
            ...form,
            pending: {
                status: true,
                message: "",
            },
        };
        dispatch(updateProfessionals(id, form));
    };
    const updateForm = async () => {


        let form = objectExtractor(settingsForm);
        try {
            form = getAssets(form, id);
        } catch (e) {
            console.log(e);
        }

        if (pending.status === false && detectPriceAfftecdFields) {
          
            //If approved professional is editing his details
            //notify admin 
            await genericEmail(form, "approvedProUpdatedDetails");
            form = {
                ...form,
                pending: {
                    ...pending,
                    status:true
                },
                isApprovedProWhoMadeAChange:true
            }
        }
        handleDetectingChanges(false);
        await dispatch(updateProfessionalForm(id, form));

    };
    const updatePrivateDetailsForm = async () => {

        //update private details
        const res = await updateDocument("users", id, privateDetails);
        if (res.type === "success") {
            const confirmationInformation = {
                warningText: 'השינויים נשמרו בהצלחה!',
                exitFunction: () => { },
                show: true,
            }
            dispatch(openConfirmationDialog(confirmationInformation));

        }
    };

    const manageAlerts = (errors) => {
        if (errors.length) {
            const msg = createAlertMessage(errors);
            setAlert({
                type: "warning",
                msg,
            });
        }
    };

    const submitProfessionalForm = async () => {
        let areThereErrors = areFormsFilled(
            isProfessional,
            privateDetails,
            settingsForm
        );

        manageAlerts(areThereErrors);

        if (!areThereErrors) {
            submitPublicForm();
            await updateDocument("users", id, privateDetails);
            //sending email to notify sam a user has submitted for approval
            await genericEmail(settingsForm, "notifyProfessioanlApplicationDone");
            //send to professional that the application has been sent
            await genericEmail(privateDetails, "proNotifyApplicationReceived");
            await removeDocument("strugglingProfessionals", id);
            handleDetectingChanges(false);
            setUpdate(true);
            manageAlerts(areThereErrors);
        } else {
            //Check that the pro doesn't already is in the collection
            const res = await getEntireDocument("strugglingProfessionals", id);
            if (!res.type || res.type === "info") {
                //if no document has been found
                //Add this pro to new collection
                const strugglingProfessional = {
                    firstName: privateDetails.firstName,
                    lastName: privateDetails.lastName,
                    email: privateDetails.email,
                    id: id,
                };
                await addNewDoucment(
                    "strugglingProfessionals",
                    id,
                    strugglingProfessional
                );
                //Send him an Email
                await genericEmail(privateDetails, "strugglingProfessional");
            }
        }
    };

    const submitUsersForm = async () => {
        const { firstName, lastName, localPhone, email } = privateDetails;
        const areThereErrors = areFormsFilled(isProfessional, privateDetails);
        const userPrivateDetails = {
            firstName,
            lastName,
            localPhone,
            email,
        };
        await updateDocument("users", id, userPrivateDetails);
        handleDetectingChanges(false);
        setUpdate(true);
        manageAlerts(areThereErrors);
    };

    const submitForm = async () => {
        setAlert({ type: "", msg: "" });
        setSpinner(true);
        isProfessional ? await submitProfessionalForm() : await submitUsersForm();

        setSpinner(false);
    };


    const handleNext = () => {
        if (changeState) {
            if (activeStep === 1) {
                updatePrivateDetailsForm()
                    .catch((e) => console.log(e))
                    .then(() => reFetchPrivateDetails())
                    .finally(() => setActiveStep(activeStep + 1));
                //In case the user has changed the prefix -> need to update to publicdetails on the server aswell
                //currentpro is from redux -> pulled from the server
                if (settingsForm.prefixState.prefix !== fetchedPrivateDetails.prefix) {
                    console.log("UPDATING ALSO THE PUBLIC")
                    updateForm().catch((e) => console.log(e));
                }
            } else {
                updateForm(setActiveStep)
                    .catch((e) => console.log(e))
                    .finally(() => setActiveStep(activeStep + 1));
            }
        } else {
            setActiveStep(activeStep + 1);
            // dispatch(setError(""));
        }

    };

    const handleBack = () => {
        if (changeState) {
            if (activeStep === 1) {
                updatePrivateDetailsForm()
                    .catch((e) => console.log(e))
                    .then(() => reFetchPrivateDetails())
                    .finally(() => setActiveStep(activeStep - 1));
            } else {
                updateForm().catch((e) => console.log(e))
                    .finally(() => setActiveStep(activeStep - 1));
            }
        } else {
            setActiveStep(activeStep - 1);
            dispatch(setError(""));
        }

    };

    const setActiveStepManually = (step) => {
        setActiveStep(step);
        dispatch(setError(""));
    };

    const goBack = () => {
        setActiveStep(0);
        history.replace(routeQuery({ box: undefined }));
    };

    return (
        <MainWrapper>
            <SettingsBar label="פרופיל" onExit={onExit} onBackButton={goBack} />
            {!isLegalDocConfirmed && isProfessional ? null : (
                <Toggler
                    currentPage={currentPage}
                    changePage={changePage}
                    isProfessional={isProfessional}
                    goBack={goBack}
                />
            )}

            {/*Break it down from professional and user*/}
            {isProfessional ? (
                //Professional
                <>
                    {!isLegalDocConfirmed ? (
                        <>
                            <LegalForm
                                privateDetails={privateDetails}
                                updatePrivateDetailsLocally={setPrivateDetails}
                                error={error}
                                updatePublicDetailsState={setSettingsForm}
                                settingsForm={settingsForm}
                            />
                        </>
                    ) : (
                        <>
                            <DisplayStatus pending={pending} />
                            <Forms
                                isProfessional={isProfessional}
                                activeStep={activeStep}
                                setActiveStepManually={setActiveStepManually}
                                onFileChange={onFileChange}
                                onExpertiesChange={onExpertiesChange}
                                onPrivateDetailsChange={onPrivateDetailsChange}
                                onPublicDetailsChange={onPublicDetailsChange}
                                firstName={firstName}
                                prefix={prefix}
                                pictureState={pictureState}
                                videoState={videoState}
                                discriptionState={discriptionState}
                                licenseState={licenseState}
                                experienceState={experienceState}
                                educationState={educationState}
                                socialNetworkState={socialNetworkState}
                                typeState={typeState}
                                prefixState={prefixState}
                                expertiseState={expertiseState}
                                priceState={priceState}
                                privateDetails={privateDetails}
                                lastName={lastName}
                                languagesState={languagesState}
                                updateForm={updateForm}
                                onPriceChange={onPriceChange}
                            />

                            {error.msg && !!!alert.msg && (
                                <CustomAlert type={error.type} msg={error.msg} />
                            )}
                            {alert.msg && <CustomAlert type={alert.type} msg={alert.msg} />}
                            <StepperComponent
                                handleNext={handleNext}
                                handleBack={handleBack}
                                activeStep={activeStep}
                                isProfessional={isProfessional}
                            />

                            {activeStep === 7 ? (
                                <SaveButton
                                    title={" שלח לבדיקה"}
                                    professionalLoading={professionalLoading}
                                    onClick={submitForm}
                                    disabled={pending && pending.status}
                                    spinner={spinner}
                                />
                            ) : null}
                        </>
                    )}
                </>
            ) : (
                //User
                <>
                    <DisplayStatus pending={pending} />
                    <Forms
                        isProfessional={isProfessional}
                        activeStep={activeStep}
                        setActiveStepManually={setActiveStepManually}
                        onFileChange={onFileChange}
                        onExpertiesChange={onExpertiesChange}
                        onPrivateDetailsChange={onPrivateDetailsChange}
                        onPublicDetailsChange={onPublicDetailsChange}
                        firstName={firstName}
                        prefix={prefix}
                        pictureState={pictureState}
                        videoState={videoState}
                        discriptionState={discriptionState}
                        licenseState={licenseState}
                        experienceState={experienceState}
                        educationState={educationState}
                        typeState={typeState}
                        prefixState={prefixState}
                        expertiseState={expertiseState}
                        priceState={priceState}
                        privateDetails={privateDetails}
                        lastName={lastName}
                        languagesState={languagesState}
                        updateForm={updateForm}
                    />

                    {error.msg && !!!alert.msg && (
                        <CustomAlert type={error.type} msg={error.msg} />
                    )}
                    {alert.msg && <CustomAlert type={alert.type} msg={alert.msg} />}
                </>
            )}
        </MainWrapper>
    );
};

export default withRouter(Settings);
