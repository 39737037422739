import React, {Fragment, useState, useEffect} from 'react'
import {v4} from 'uuid';
import {Grid, Box, Typography, TextField, Button} from '@material-ui/core'
import styled from 'styled-components'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FaceIcon from '@material-ui/icons/Face';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {RED, LEAD_BLUE} from '../../../../utils/globals';
import {useSelector, useDispatch} from 'react-redux';
import {postMessage, clearAlert, updateMessage, getMessages} from '../../../../actions/MessagesActions';
import Spinner from '../../../layout/Spinner';
import CustomAlert from '../../../layout/CustomAlert';


const MainWrapper = styled(Grid)`
  font-family: 'Rubik', Sens-Serif;
  direction: rtl;
  min-width: 490px;
  @media (max-width: 600px) {
    min-width: 300px;
  }
`;

const TrashCan = styled(DeleteForeverIcon)`
  color: ${RED};
  cursor: pointer;
`;

const Nav = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px;
`;

const NameWrapper = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  direction: rtl;
  padding: 10px;
  margin: 10px
`;

const SubjectWrapper = styled(TextField)`
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  width: 70%;
`;

const ComponentWrapper = styled(Grid)`
  width: 100%;
`;

const BackArrow = styled(ArrowBackIosIcon)`
  cursor: pointer;
`;

const Label = styled(Typography)`
  font-size: 12px;
  font-family: 'Rubik', Sens-Serif;
  color: gray;
  display: flex;
  justify-content: flex-start;
`;

const Text = styled(Typography)`
  font-size: 12px;
  font-family: 'Rubik', Sens-Serif;
  margin-right: 10px;
`;

const Content = styled(Box)`
  width: 80%;
  padding: 10px;
  margin: 10px;
  overflow: auto;
`;

const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  padding: 10px;
`;

const ButtonStyle = styled(Button)`
  padding: 10px;
  margin: 10px;
  color: white;
`;
const UserContent = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  height: auto;
  margin: 0 auto;
  max-width: 800px;
  padding: 10px;
  background: #006bb3;
  color: #fff;
  overflow: scroll;
`

const AlertWrapper = styled(Box)`
  padding: 10px;
  margin: 10px;
`;

const DisplayMessage = ({
                            subject, content, setShowMessage,
                            firstName, lastName, day, receiver,
                            destEmail, isReplyable, messageId,
                            messagesAlert, messagesLoading, handleDeleteMessage
                        }) => {

    const {user} = useSelector(state => state.auth);
    const [reply, setReply] = useState({
        newSubject: "מענה - " + subject,
        newContent: "\n\n----------\n" + content,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        !!messagesAlert.message && dispatch(clearAlert());
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        return () => {
            dispatch(updateMessage(user.email, messageId, "message.isOpened", true));
            dispatch(getMessages(user.email));
        }
        // eslint-disable-next-line
    }, [])

    const sendMessage = () => {
        dispatch(clearAlert());
        const messageObject = {
            message: {
                id: v4(),
                data: {
                    subject: reply.newSubject,
                    content: reply.newContent
                },
                date: {
                    day: new Date().toISOString().slice(0, 10),
                    hour: new Date().toLocaleTimeString()
                },
                isOpened: false,
                inInbox: "inbox",
                isReplyable: true
            },
            sender: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email
            },
        }
        dispatch(postMessage(destEmail, messageObject));
    }

    const removeMessage = async () => {
        handleDeleteMessage(user.email, messageId);
        setShowMessage({display: false})
    };

    const handleChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        setReply({
            ...reply,
            [name]: value
        });
    };

    return (
        <Fragment>
            <MainWrapper
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <ComponentWrapper item>
                    <Nav>
                        <TrashCan onClick={removeMessage}/>
                        <BackArrow onClick={() => setShowMessage({display: false})}/>
                    </Nav>
                </ComponentWrapper>


                <ComponentWrapper item>
                    <NameWrapper>
                        <FaceIcon size="large"/>
                        <Text>
                            {firstName} {lastName}
                        </Text>
                    </NameWrapper>
                </ComponentWrapper>


                <ComponentWrapper item>
                    <NameWrapper>
                        <CalendarTodayIcon size="large"/>
                        <Text>
                            {day}
                        </Text>
                    </NameWrapper>
                </ComponentWrapper>

                <ComponentWrapper item>
                    <Content align="right">
                        <UserContent>
                            <p>{content}</p>
                        </UserContent>
                    </Content>
                </ComponentWrapper>

                {isReplyable ?
                <>
                <ComponentWrapper item>
                    <SubjectWrapper
                        value={reply.newSubject}
                        onChange={event => handleChange(event)}
                        name="newSubject"
                    />
                </ComponentWrapper>

                <ComponentWrapper item>
                
                    <Content align="center">

                        <Label>השב:</Label>

                        <TextField
                            multiline
                            rows={8}
                            variant="filled"

                            fullWidth
                            onChange={event => handleChange(event)}
                            name="newContent"
                        />
                    </Content>
                </ComponentWrapper>

                <ComponentWrapper item>
                    {messagesLoading ? <Spinner/> :
                        <ButtonsWrapper>
                            <ButtonStyle
                                variant="contained"
                                onClick={sendMessage}
                                style={{backgroundColor: LEAD_BLUE}}
                            >
                                השב
                            </ButtonStyle>
                            <ButtonStyle
                                variant="contained"
                                onClick={removeMessage}
                                style={{backgroundColor: RED}}
                            >
                                מחק
                            </ButtonStyle>
                        </ButtonsWrapper>
                    }
                </ComponentWrapper>
                </> :  
                <div>לא ניתן להשיב להודעה זו</div>
                }

                <ComponentWrapper>
                    {!!messagesAlert.message &&
                    <AlertWrapper>
                        <CustomAlert
                            type={messagesAlert.type}
                            msg={messagesAlert.message}
                        />
                    </AlertWrapper>
                    }
                </ComponentWrapper> 
               

            </MainWrapper>
        </Fragment>
    );
};

export default DisplayMessage
