import React from 'react'

const NotFound = () => {
    return (
        <div>
            <h1>Not Found</h1>
            <p>The page you are looking for does not exists. </p>
        </div>
    );
};

export default NotFound;
