import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const MainWrapper = styled.div`
  display: grid;
  justify-items: flex-start;
  align-items: center;
  grid-gap: 5px;
  padding: 10px;
  direction: rtl;
`;

const NameWrapper = styled(motion.div)`
  font-family: 'Rubik', Sens-Serif;
`;

const Bold = styled.span`
  text-decoration: underline;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-family: 'Rubik', Sens-Serif;
  text-align: right;
  @media (max-width: 1050px) {
    margin: 3px;
  }
`;

const Name = ({ firstName, lastName, prefix }) => {
    return (
        <NameWrapper
            initial={{ x: 0 }}
            transition={{ duration: 1, delay: .3 }}
        >

            {
                prefix === undefined ? <span>שלום <Bold>{firstName} {lastName}</Bold></span> : ''
            }


        </NameWrapper>
    )
};

const ProfessionalsPreview = () => {
    return (
        <Paragraph>
            זה האזור האישי שיצרנו עבורך. זהו למעשה מרכז התקשורת שלך – כאן נקלטים כל הנתונים האישיים שלך ומכאן ניתן לעדכן
            אותם מעת לעת; מכאן ניתן להקצות, לקבוע ולשנות מפגשים אישיים ולשלוח הודעות למשתמשי השירות; ולא פחות חשוב – כאן
            מוצגות ההכנסות שצברת. כדי להקל עליך את ניהול האזור האישי שלך, חילקנו אותו לנושאים עם הסברים והנחיות לצד כל
            נושא
        </Paragraph>
    );
};

const UsersPreview = () => {
    return (
        <>
            <Paragraph>
                זה האזור האישי שיצרנו עבורך. זהו למעשה מרכז התקשורת שלך - כאן נקלטים כל הפרטים שהכנסת בעת הרישום ומכאן
                ניתן לעקוב אחר המפגשים שלך, לראות הודעות מהמטפלים שלך ולכתוב חוות דעת על השירות שקיבלת.
            </Paragraph>
            <Paragraph>
                הודעות למטפלים ניתן לשלוח מכאן או מעמוד המטפל האישי. לתשומת ליבך, אנחנו מכבדים את זמנם של המטפלים
                וייתכן שהמענה שלהם לא יהיה מיידי.
            </Paragraph>
            <Paragraph>
                כדי להקל עליך את ניהול האזור האישי שלך, חילקנו אותו לשלושה חלקים:
            </Paragraph>
        </>
    );
};

const Preview = ({ isProfessional }) => {
    return (
        <>
            {
                isProfessional ?
                    <ProfessionalsPreview /> :
                    <UsersPreview />
            }
        </>
    );
};

const SettingsHome = ({ isProfessional, firstName, lastName, prefix }) => {
    return (
        <MainWrapper>
            <Name
                firstName={firstName}
                lastName={lastName}
                prefix={prefix}
            />
            <Preview isProfessional={isProfessional} />
        </MainWrapper>
    )
}

export default SettingsHome
