import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { PURPLE } from "../../../../utils/globals";

const SmallArrow = styled(ArrowBackIosIcon)`
  font-size: 15px;
  transform: ${(props) => (props.isOpen ? "rotate(-90deg)" : "rotate(0)")};
  transition: transform 0.2s;
`;

const ContentWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  text-align: right;
  cursor: pointer;
  margin-bottom: 8px;
`;

const HebrewWrapper = styled(Box)`
  direction: rtl;
  font-family: "Arial", sans-serif;
  margin-bottom: 8px;
`;

const ParagraphWrapper = styled(HebrewWrapper)`
  max-height: ${(props) => (props.isOpen ? "430px" : "0px")};
  transition: max-height 0.5s;
  overflow: hidden;
  text-align: right;
`;

const Bold = styled(Typography)`
  font-weight: 700;
  display: inline;
  direction: rtl;
`;

const Paragraph = styled(Typography)`
  margin-top: 5px;
`;

const SmallDot = styled(FiberManualRecordIcon)`
  font-size: 12px;
  margin-left: 20px;
  color: ${PURPLE};
`;

const ListItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const OpenMynd = styled.span`
  font-weight: bold;
  font-family: "Arial", sans-serif;
  font-size: 16px;
`;

const BoldNum = styled.span`
  font-weight: bold;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  margin-left: 20px;
`;

const FaqContent = () => {
  const [open, setOpen] = useState({
    whatIs: false,
    whoAre: false,
    choose: false,
    service: false,
    match: false,
    serviceMatch: false,
    contact: false,
    price: false,
    replace: false,
    prepare: false,
    duty: false,
    privacy: false,
    annonymous: false,
    reset: false,
    modifyMeeting: false,
    cancelMeeting: false,
    unlike: false,
    pickExpert: false,
    changeExpert: false,
    changeDate: false,
    signup:false,
    privateZone:false
  });

  const onClick = (name) => {
    setOpen({
      ...open,
      [name]: !open[name],
    });
  };

  return (
    <Box>
      <Box>
        <ContentWrapper onClick={() => onClick("whatIs")}>
          <SmallArrow isOpen={open.whatIs} />
          <Bold>
            מה זה <OpenMynd>OpenMynd?</OpenMynd>
          </Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.whatIs}>
          <OpenMynd>OpenMynd </OpenMynd>היא פלטפורמה ייחודית שבה אתם יכולים לקבל טיפול/ייעוץ קצר וממוקד להתמודדות עם מצבי לחץ או מצוקה או כל דבר 
          אחר שמפריע לאושר ואיכות החיים שלכם כאן ועכשיו, באמצעות שיחות וידאו אישיות ודיסקרטיות במחיר מוזל. בעמוד המטפלים ניתן לבחור 
          את המטפל/ת שנכון/ה בשבילכם ולקבוע שיחת וידאו דרך המחשב, הטאבלט או הטלפון החכם, בזמן שמתאים לכם מהכורסה שנוחה לכם.
           </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("whoAre")}>
          <SmallArrow isOpen={open.whoAre} />
          <Bold>
            {" "}
            מיהם המטפלים ב- <OpenMynd>OpenMynd?</OpenMynd>
          </Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.whoAre}>
          <Paragraph>
          המטפלים כוללים, בין השאר, פסיכולוגים, פסיכותרפיסטים, עובדים סוציאליים, יועצים חינוכיים, מגשרים, מנטורים וקואצ׳רים, המגיעים מתחומים שונים, 
          כולל משפחה וזוגיות, חרדה ודיכאון, ילדים ונוער, הפרעות אכילה, עבודה וקריירה. לכל מטפל/ת יש לפחות תחום התמחות אחד 
          וכולם צברו לפחות 3 שנים ו-500 שעות של ניסיון מעשי.  
          </Paragraph>
        </ParagraphWrapper>
      </Box>

      <Box>
        <ContentWrapper onClick={() => onClick("service")}>
          <SmallArrow isOpen={open.service} />
          <Bold>
            האם השירות של <OpenMynd>OpenMynd</OpenMynd> מתאים לי?
          </Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.service}>
          <Paragraph>
          מטרת השירות שלנו היא להנגיש לכם מטפלים שיתנו לכם כלים להתמודדות עם מצבים שונים שמטרידים אתכם ועלולים לפגוע בבריאות 
          הפיזית ו/או הנפשית שלכם, כמו למשל - דמוי עצמי ירוד, חוסר בטחון עצמי, לחץ וחוסר תפקוד במצבים חברתיים או במפגשים אישיים, 
          בעיות בזוגיות, בהורות או בעבודה. OPENMYND אינה תחליף לטיפול קליני ארוך טווח ואינה הפתרון המתאים אם אחד מהדברים הבאים 
          נכון לגביכם:
          </Paragraph>

          <ListItem>
            <SmallDot />
            <Paragraph>יש לך מחשבות לפגוע בעצמך או באחרים</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>אתם קטינים או נמצא תחת השגחתו של אפוטרופוס חוקי</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>אתם נמצאים במשבר דחוף או במצב חירום</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>אובחנתם כחולים במחלת נפש קשה או הומלץ לכם להיות תחת פיקוח פסיכולוגי או טיפול פסיכיאטרי</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>נדרשתם לקבל טיפול או ייעוץ בצו בית משפט או על ידי כל רשות אחרת</Paragraph>
          </ListItem>
          <Paragraph>בכל אחד מהמקרים הנ״ל אנו ממליצים לפנות לחדר מיון או להתקשר לקו החם של עמותת ער״ן.</Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("pickExpert")}>
          <SmallArrow isOpen={open.pickExpert} />
          <Bold>כיצד נבחרים מטפלים לשירות?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.pickExpert}>
          <Paragraph>
          מטפלים יכולים להצטרף לשירות רק לאחר בדיקת נאותות ואישור של מנהלי האתר. בדיקה זו כוללת, בין השאר, אימות תואר אקדמי,
          רישיון עיסוק והכשרות מקצועיות, ובנוסף גם ראיון בשיחת וידאו.
          </Paragraph>
          <Paragraph>
          לכל מטפל/ת יש באתר ״עמוד מטפל/ת״ - זהו עמוד אישי שבו המטפל/ת מציג פרטים על תחום המומחיות, השכלה, ניסיון מקצועי,
          שפות, תמונת פרופיל וסרטון וידאו קצר. כאן גם מוצגים המחיר המוזל למשתמשי OPENMYND וחוות דעת שכתבו לקוחות אחרים על השירות
          של המטפל/ת. כל זאת, על מנת שתוכלו לבצע בדיקה מקיפה משלכם על המטפל/ת ולקבל החלטה מושכלת.
          </Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("match")}>
          <SmallArrow isOpen={open.match} />
          <Bold>איזה מטפל/ת מתאים/ה לי?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.match}>
          <Paragraph>יש שלוש אפשרויות לבחירת מטפל/ת:</Paragraph>
          <ListItem>
            <BoldNum>1.</BoldNum>
            <Paragraph>היכנסו לעמוד המטפלים. מכאן תוכלו לבחור את המטפל/ת שנראה לכם מתאים ולהיכנס לעמוד המטפל/ת שלו/ה.</Paragraph>
          </ListItem>
          <ListItem>
            <BoldNum>2.</BoldNum>
            <Paragraph>אנחנו נתאים לכם מטפל/ת - לחצו על הכפתור בראש עמוד המטפלים למילוי שאלון קצר שבאמצעותו נוכל ללמוד על ההעדפות
והצרכים שלכם ולהתאים את המטפל/ת בהתאם. ברגע שנמצא התאמה, נשלח לכם קישור לעמוד של אותו/ה מטפל/ת.</Paragraph>
          </ListItem>
          <ListItem>
            <BoldNum>3.</BoldNum>
            <Paragraph>לחצו על כפתור ״מי פנוי היום״ בעמוד הבית, ותוכלו לבחור מטפל/ת מתוך מגוון המטפלים שפנויים מעכשיו ועד סוף היום.</Paragraph>
          </ListItem>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("signup")}>
          <SmallArrow isOpen={open.signup} />
          <Bold>איך נרשמים?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.signup}>
          <Paragraph>
          לחצו על כפתור ״הרשמת לקוחות״ בראש עמוד הבית וזה יפתח חלון שבו תתבקשו להכניס את שמכם
          ואת כתובת הדוא״ל שלכם ולבחור סיסמה. זהו! עכשיו תוכלו להיכנס לעמודי המטפלים ולקבוע מפגשים. שימו לב שבכניסתכם הבאה המערכת כבר תזהה אתכם, ובפינה
          השמאלית העליונה של עמוד הבית יופיע עיגול כחול עם האות הראשונה של שמכם. לחיצה עליו תוביל אתכם לאזור האישי שלכם.
          </Paragraph>
        </ParagraphWrapper>
      </Box>

      <Box>
        <ContentWrapper onClick={() => onClick("privateZone")}>
          <SmallArrow isOpen={open.privateZone} />
          <Bold>מהו האזור האישי?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.privateZone}>
          <Paragraph>האזור האישי הוא מרכז התקשורת שלכם - כאן נקלטים הנתונים שהכנסתם בעת ההרשמה ומכאן אתם יכולים:</Paragraph>
          <ListItem>
            <SmallDot />
            <Paragraph>
            לעקוב אחר המפגשים שלכם עם המטפל/ת שלכם
            </Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>
            לכתוב חוות דעת, על איכות השירות
            </Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>
            לשלוח ולקבל הודעות מהמטפל/ת שלכם
            </Paragraph>
          </ListItem>
        </ParagraphWrapper>
      </Box>
   
      <Box>
        <ContentWrapper onClick={() => onClick("price")}>
          <SmallArrow isOpen={open.price} />
          <Bold>לכמה מפגשים אני מתחייב ומה אורך כל מפגש?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.price}>
          <Paragraph>
          המטרה שלנו היא לעזור לכם כאן ועכשיו ולכן אין לכם התחייבות לטיפול ארוך-טווח. אתם יכולים לבחור בין מפגש ייעוץ בודד או סדרה של שלושה מפגשים - המפגש הראשון מוקדש לאבחון והערכה, ובמהלכו המטפל/ת יוביל אתכם דרך סדרת שאלות מנחות; במפגש השני המטפל/ת ייתן לכם כלים לשינוי והצלחה, וכיצד ליישם אותם בשטח; המפגש השלישי ייוחד למשוב, ניתוח והסקת מסקנות לעתיד. מכאן, ההחלטה להמשיך או להפסיק את המפגשים היא רק שלכם. אורך כל מפגש הוא כ-50 דקות.
          </Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("unlike")}>
          <SmallArrow isOpen={open.unlike} />
          <Bold>כמה עולה כל מפגש וכיצד משלמים?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.unlike}>
          <Paragraph>
          מחיר המפגשים משתנה בין מטפל/ת למטפל/ת בהתאם לעיסוק, להתמחות ולניסיון. בעמוד של כל מטפל/ת מוצג המחיר עבור מפגש בודד והמחיר המוזל עבור שלושה מפגשים. לאחר בחירת המטפל/ת וקביעת מועד למפגש הראשון, תועברו לחלון התשלום ושם תתבקשו להכניס את פרטי האשראי שלכם. ניתן לשלם בתשלומים. מייד לאחר החיוב תשלח הודעת אישור וחשבונית לכתובת הדוא״ל שלכם. 
          </Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("replace")}>
          <SmallArrow isOpen={open.replace} />
          <Bold>איך קובעים מפגש עם המטפל/ת שבחרתי?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.replace}>
          <Paragraph>
          בחלק התחתון של עמוד המטפל/ת תוכלו להקליק על כפתור ״תיאום מפגש״ ומיד תקושרו ליומן של המטפל/ת. התאריכים הפנויים צבועים בכחול והקלקה על אחד מהם תציג את השעות הפנויות באותו יום. סמנו את הזמן המתאים לכם ותקבלו אישור לכתובת הדוא״ל שלכם יחד עם קישור למפגש בחדר הפרטי שלכם. 
          </Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("prepare")}>
          <SmallArrow isOpen={open.prepare} />
          <Bold>מה צריך להכין לקראת שיחת הוידאו?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.prepare}>
          <Paragraph>
          היות ומדובר בתקשורת דיגיטלית, חשוב מאד שהמטפל/ת יראה אתכם בצורה כמה שיותר ברורה. שפת הגוף, הלבוש והדיבור מסייעים למטפלים לאבחן אתכם. לכן, לפני המפגש איתם, עליכם לוודא את הדברים הבאים:
          </Paragraph>
          <ListItem>
            <SmallDot />
            <Paragraph>בדקו שהמצלמה והקול במחשב/בטאבלט/בטלפון הנייד תקינים ובאיכות גבוהה</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>אם אתם משתמשים בטאבלט או טלפון נייד, הניחו אותו במקום יציב ובטוח כשעינית המצלמה נמצאת בגובה העיניים שלכם</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>התרחקו כמטר מהמצלמה על מנת שהמטפל/ת יוכל לראות לפחות את פלג הגוף העליון שלכם</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>שבו במקום נוח וודאו שאתם במרכז התמונה ושהמצלמה בפוקוס עליכם</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>וודאו שיש תאורה מספיק חזקה מול ומעל הפנים והגוף שלכם</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>אם ברצונכם לדבר על סיטואציה מסוימת, הקפידו להופיע במפגש כאילו שאתם נמצאים באותה סיטואציה (דייט, מפגש עסקי...)</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>אם אתם משתמשים במחשב למפגש, כנסו לאתר SpeedTest ובדקו את מהירות הגלישה באינטרנט של המכשיר שלכם. אם המהירות במחשב שלכם איטית, מומלץ להשתמש בטלפון חכם. או בטאבלט.</Paragraph>
          </ListItem>
        </ParagraphWrapper>
      </Box>

      <Box>
        <ContentWrapper onClick={() => onClick("contact1")}>
          <SmallArrow isOpen={open.contact1} />
          <Bold>מה קורה אם המטפל/ת שבחרתי התנהגו בצורה לא אתית?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.contact1}>
          <Paragraph>
          במקרה ששילמתם מראש עבור סדרת מפגשים ולאחר המפגש הראשון אתם מרגישים שהמטפל/ת עבר/ה עבירה אתית ולא מקצועית שפגעה בכם, באפשרותכם לפנות אלינו בתוך 24 שעות. אנו מיד נבדוק את התלונה, ובמידת הצורך, נתאים לכם מטפל/ת חלופי ולא נחייב אתכם על המפגש הראשון איתו/ה.
          </Paragraph>
        </ParagraphWrapper>
      </Box>

      <Box>
        <ContentWrapper onClick={() => onClick("contact2")}>
          <SmallArrow isOpen={open.contact2} />
          <Bold>כיצד אוכל ליצור קשר עם המטפל/ת שלי?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.contact2}>
          <Paragraph>
          כנסו לאזור האישי שלכם. אחד משלושת האייקונים הוא ״הודעות״. הקליקו עליו ולחצו על ״הודעה חדשה״. השם של המטפל/ת שלכם יופיע שם בשורת ״אל:״.
          </Paragraph>
        </ParagraphWrapper>
      </Box>

      <Box>
        <ContentWrapper onClick={() => onClick("contact3")}>
          <SmallArrow isOpen={open.contact3} />
          <Bold>איך אפשר לשנות מועד של מפגש?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.contact3}>
          <Paragraph>יש שתי אפשרויות לשינוי מועד מפגש:</Paragraph>

          <ListItem>
          <BoldNum>1.</BoldNum>
            <Paragraph>
            כנסו לאזור האישי שלכם. אחד משלושת האייקונים הוא ״מפגשים״. הקליקו וכנסו לשורת המפגש שברצונכם לשנות. לחצו על ״שינוי״ ותאמו מועד חדש ביומן המטפל/ת.
            </Paragraph>
          </ListItem>
          <ListItem>
          <BoldNum>2.</BoldNum>
            <Paragraph>
            אחרי המפגש הראשון כנסו לדף המטפל/ת. לצד כפתור ״תיאום מפגש״ למטה יופיע גם כפתור ״שינוי מפגש״.  לחצו עליו והיכנסו ליומן המטפל/ת. היכנסו למועד המפגש שקבעתם ושנו אותו למועד אחר. 
            </Paragraph>
          </ListItem>

          <Paragraph>
          שימו לב – אנו מכבדים את הזמן של המטפלים ולכן ניתן לשנות מועד עד 24 שעות לפני המועד שנקבע.
          </Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("contact4")}>
          <SmallArrow isOpen={open.contact4} />
          <Bold>איך אפשר לבטל מפגש?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.contact4}>
          <Paragraph>
          ניתן לבטל מפגש באותן שתי דרכים שבהן ניתן לשנות מפגש. שימו לב – אנו מכבדים את הזמן של המטפלים ולכן ניתן לשנות מועד עד 48 שעות לפני המועד שנקבע.
          </Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("contact5")}>
          <SmallArrow isOpen={open.contact5} />
          <Bold>
            האם <OpenMynd>OpenMynd </OpenMynd> יכול להוות תחליף למפגש פיזי?
          </Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.contact5}>
          <Paragraph>
          על אף שכיום מקובל לעשות מפגשים באמצעות שיחות וידאו כמעט בכל תחום שהוא, השירות של OPENMYND לא תמיד יכול להחליף טיפול מסורתי בקליניקה של המטפל/ת. שימו לב - המטפל/ת שלכם ב- OPENMYND לא יוכל לתת אבחנה רשמית כלשהי, למלא צו בית משפט או לתת מרשמים לתרופות.
          </Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("contact6")}>
          <SmallArrow isOpen={open.contact6} />
          <Bold>
            מה האחריות של <OpenMynd>OpenMynd </OpenMynd> כלפיי?
          </Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.contact6}>
          <Paragraph>
          OPENMYND היא פלטפורמה טכנולוגית שנותנת מעטפת שירותים שיווקיים, אדמיניסטרטיביים ופיננסיים למטפלים. תוכן המפגשים הוא חסוי ואנחנו לא חשופים למידע ו/או לנאמר במפגשים שלכם עם המטפל/ת בחדר הפרטי של המטפלים. שירות המטפלים ניתן על ידי מטפלים ויועצים עצמאיים העובדים ישירות מולכם והם מחויבים לתת שירות מקצועי ואדיב. מטפלים אלו אינם עובדים של OPENMYND והיחסים בינינו הם של מקבל שירותים ונותן שירותים. 
          </Paragraph>
        </ParagraphWrapper>
      </Box>

      <Box>
        <ContentWrapper onClick={() => onClick("privacy")}>
          <SmallArrow isOpen={open.privacy} />
          <Bold>כיצד אתם מגנים על הפרטיות שלי?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.privacy}>
          <Paragraph>
          אנו אובססיביים לגבי אבטחת פרטיותכם וסודיותכם. פיתחנו את הפלטפורמה ואת הגישה לחדרים הפרטיים תוך מחשבה על דבר אחד: שמירה על פרטיותכם ושמירה על המידע שאתם מספקים. תקני הפרטיות והסודיות שלנו מתקדמים בהרבה מהנדרש בחוק או בתקנות, כך שתוכלו להרגיש בטוחים ונינוחים. להלן מספר דוגמאות:
          </Paragraph>
          <ListItem>
            <SmallDot />
            <Paragraph>אינכם צריכים להזדהות (ראו מטה מידע נוסף לגבי שמירה על אנונימיות) </Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>כל מה שאתם אומרים למטפל/ת שלכם מוגן על ידי חוק הגנת הפרטיות ותקנות הגנת הפרטיות (אבטחת מידע)</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>איננו משתפים פעולה או עובדים עם אף חברת ביטוח או מעסיק כלשהם, לכן אין צורך לשתף אותם, לדווח או להגיש להם דבר</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>תוכלו תמיד ללחוץ על כפתור ה-"גריסה" המופיע ליד כל הודעה ששלחתם כדי שהיא לא תופיע עוד בחשבונכם</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>כל ההודעות ביניכם לבין המטפל/ת שלכם מאובטחות ומוצפנות באמצעות הצפנת 256 סיביות ברמה המשמשת מוסדות בנקאיים</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>השרתים שלנו מתארחים במתקן בדירוג A grade, דבר המבטיח את האבטחה וההגנה החזקות ביותר </Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>מערכת הצפנת הגלישה שלנו (SSL) משתמשת בשיטות העבודה המתקדמות ביותר ומספקת אבטחה והצפנה מקוונת</Paragraph>
          </ListItem>
          <ListItem>
            <SmallDot />
            <Paragraph>מאגרי המידע שלנו מוצפנים ומשובשים כך שהם בעצם הופכים חסרי תועלת במקרה המאוד לא סביר שהם נגנבים או שמשתמשים בהם באופן לא הולם</Paragraph>
          </ListItem>
          <Paragraph>
          אם אתם מעוניינים שהמידע או הרשומות שלכם ישוחררו לצד שלישי, אנא הודיעו לשירות הלקוחות שלנו. נציגנו ישלח לכם טופס הרשאה שתצטרכו למלא ולחתום עליו לפני שניתן יהיה לשחרר את המידע שלכם.
          </Paragraph>
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("annonymous")}>
          <SmallArrow isOpen={open.annonymous} />
          <Bold>האם אוכל שמור על אנונימיות?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.annonymous}>
        כשאתם נרשמים לשירות באפשרותכם לבחור כל כינוי שישמש לזיהוי שלכם. יחד עם זאת, חשוב למלא כתובת דוא״ל אמיתית כדי שנוכל לשלוח לכם הודעות על אישור מפגש, אישור תשלום, הודעות מהמטפל/ת שלכם וכד׳. רק לאחר שתיאמתם מפגש עם מטפל/ת ועברתם לחלון התשלום, תתבקשו למלא את מספר הטלפון הנייד על מנת ליצור איתכם קשר מיידי במידה שיש בעיה בסליקת כרטיס האשראי שלכם וכדי לשלוח לכם הודעות הנוגעות לשינוי וביטול מפגשים.
        </ParagraphWrapper>
      </Box>
      <Box>
        <ContentWrapper onClick={() => onClick("reset")}>
          <SmallArrow isOpen={open.reset} />
          <Bold>שכחתי סיסמה. כיצד מאתחלים את הקוד הסודי?</Bold>
        </ContentWrapper>
        <ParagraphWrapper isOpen={open.reset}>
        הכניסו את כתובת הדוא״ל שלכם במסך הכניסה ולחצו על כפתור ״שכחתי סיסמה״. סיסמה זמנית חדשה תישלח לכתובת הדוא״ל שמסרתם, ובעזרתה תוכלו להיכנס מחדש לחשבונכם ולשנות לסיסמה קבועה.
        </ParagraphWrapper>
      </Box>
    </Box>
  );
};

export default FaqContent;
