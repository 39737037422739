import React from 'react';
import styled from 'styled-components';

import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { LEAD_BLUE } from '../../../../utils/globals';

const UpArrowWrapper = styled(KeyboardArrowUpIcon)`
    background-color: ${LEAD_BLUE};
    color: white;
`;

const DownArrowWrapper = styled(KeyboardArrowDownIcon)`
    background-color: ${LEAD_BLUE};
    color: white;
`;

const CollapseIcon = ({ isOpen, onIconClick }) => {

    return (
        <TableCell>
            <IconButton
                onClick={onIconClick}
            >
                {isOpen ? <UpArrowWrapper /> : <DownArrowWrapper />}
            </IconButton>
        </TableCell>
    );
};

export default CollapseIcon
