import React from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import lady from '../../../static/images/geniePictures/lady.jpg'
import Categories from './Categories';
import { Typography } from '@material-ui/core';
import { HOME_BACKGROUND } from '../../../utils/globals';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';




const MainWrapper = styled(Box)`
   display: flex;
    justify-content: space-between;
    font-family: 'Rubik',sans-serif;
    direction: rtl;
 
`;

const ContentMainWrapper = styled(Box)`

    /*background-color: ${HOME_BACKGROUND};*/
    width: 100%;
    margin-top: 50px;
    direction: rtl;
    @media only screen and (max-width: 1280px) {
      //background: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url}) no-repeat fixed;
      //background-size: initial;
      //background-position: center top;
       /* background: linear-gradient(90deg, rgba(37,131,203,1) 0%, rgba(14,66,124,1) 100%);*/
    }
    @media only screen and (max-width: 600px) {
        
        /*background-color: rgb(0, 75, 120);*/
    }
   
    
`;
const BigScreensImage = styled.div`
  /*  background-image: url(${lady});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    @media (max-width: 1280px) {
        display: none;
    }
`;

const ContentSubWrapper = styled(Box)`
  display: grid;
  margin-top:20px;

  
  // grid-gap: 10px 10px; 
  justify-items: center;
  align-items: center;
  @media (max-width: 1100px) {
    grid-gap: 0;
  }
  @media (max-width: 600px) {
    width: 100vw;
    grid-gap: 0;
  }

`;

/*const animatedButton = {
    hidden: {
        scale: 0.7,
    },
    visable: {
        scale: 1.1,
        transition: {
            scale: {duration: 1.5}
        }
    }
}*/

/*const LogoWrapper = styled(motion.img)`
    height: 110px;
    width: 240px;
    @media (max-width: 600px) {
        display: none;
    }
`;*/


const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 900;
  font-family: 'Rubik', sans-serif;

  width: 95%;
  @media (max-width: 600px) {
    width: 90%;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  };
  @media (max-height: 980px){
      margin:0;
  };
`;

const Title = styled(Typography)`
  font-weight: bold;
  direction: rtl;
  font-size: 22px;
  display: flex;

  justify-content: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;

  //color: white;
  color: rgb(30, 55, 100);
  @media (max-width: 600px) {
    padding-right: 0;
    line-height: 1.1;
    font-size: 18px;
  }
`;

// const MobileTitle = styled.p`
//     margin: 0;
//     padding: 10px;
//     font-family: 'Rubik',sans-serif;
//     direction: rtl;
//     color: #001a4d;
//     font-size: 18px;
//     width: 35%;
//     text-align: right;
// `;





/*const   BoldStyle = styled.p`
    font-size: 26px;
      @media only screen and (max-width: 600px) {
        font-size: 12px;
        padding: 0;
      
    }
`*/
/*Talea
                            <strong style={{ color: "orangered" }}>מגויסים עבורכם בלב שלם</strong><br></br>
                            <strong style={{ fontSize: '24px' , color: "orangered" }}>ללא תמורה</strong>

                            <strong style={{ color: "orangered" }}>מגויסים עבורכם בלב שלם</strong><br></br>
                            <strong style={{ fontSize: '26px' , color: "orangered" }}>ללא תמורה</strong>

*/
/*
const MainLogo = () => {
    return (
       /!* <LogoWrapper src={whiteLogo}></LogoWrapper>*!/
    )
};*/

const LeadText = () => {
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1008px)'
    })
    return (
        <TitleWrapper>
            <Title>
                {isTabletOrMobileDevice ?
                    <span style={{ marginBlockEnd: '0px' }}>מתאימים לכם <strong style={{ fontSize: "24px" }}>מטפלים ויועצים</strong><br></br> שייתנו לכם כלים כדי
                        לפתור את<br></br> מה שמטריד אתכם <strong style={{ fontSize: "24px" }}>כאן ועכשיו <br></br></strong>ב<strong
                            style={{ fontSize: "24px" }}>שיחות וידאו </strong>מאובטחות <br></br>
                    </span>
                    :
                    <span style={{ marginBlockEnd: '0px' }}>מתאימים לכם <strong style={{ fontSize: "24px", marginBlockEnd: '0px' }}>מטפלים ויועצים</strong> שייתנו לכם כלים<br></br> כדי
                        לפתור את מה שמטריד אתכם <strong style={{ fontSize: "26px" }}>כאן ועכשיו </strong><br></br> ב<strong
                            style={{ fontSize: "26px" }}>שיחות וידאו </strong>מאובטחות <br></br>
                    </span>
                }

            </Title>
        </TitleWrapper>
    )
};

/*const MobileLeadText = () => {
    return (
        <MobileTitle>מתאימים לך את מיטב המטפלים במפגשי וידאו אישיים</MobileTitle>
    )
};*/


const Screen = ({ isAuthenticated }) => {
    return (
        <MainWrapper>
            {/*<ImageWrapper><MobileLeadText /></ImageWrapper>*/}
            <ContentMainWrapper>
                <ContentSubWrapper>
                    {/* <MainLogo />*/}
                    <LeadText />
                    <Categories
                        isAuthenticated={isAuthenticated}
                    />

                </ContentSubWrapper>
            </ContentMainWrapper>
            <BigScreensImage></BigScreensImage>
        </MainWrapper>
    )
}

const Header = () => {
    const { isAuthenticated } = useSelector(state => state.auth);


    return (
        <Screen className="header" isAuthenticated={isAuthenticated} />
    )
}

export default Header
