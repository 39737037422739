import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

const middleware = [thunk];

// Creating a store.
// First argument is the top reducer who contains all app reducer.
// Second variable is the initialState of the top reducer.
// Third variable is any middleware we use, applyMiddleware in order to use any middleware with redux and passing the middlware list.
const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(
            ...middleware
            )
        )
    );


export default store;