import React from 'react';
import { Formik } from 'formik';
import { 
    AuthAlertsManager,
    emailValidation, 
    FormHeader, 
    FormWrapper, 
    MainWrapper, 
    MoveTo, 
    StyledInput, 
    SubmitButton 
} from '../AuthHelpers';
import { USER_REGISTER } from '../../../../utils/globals';

const Navigations = () => {
    return (
        <MoveTo 
            to={USER_REGISTER}
            text="אין לך משתמש? הרשם" 
        />
    );
};

const ResetPassword = ({ onResetPassword }) => {
    return (
        <MainWrapper>
            <FormHeader text="איפוס סיסמה" />
            <Formik 
                initialValues={{ email: '' }} 
                validate={values => {
                    const errors = {};
                    emailValidation(errors, values.email);
                    return errors;
                }}
                onSubmit={(data, {setSubmitting}) => {
                    setSubmitting(true);
                    onResetPassword(data.email);
                    setSubmitting(false);
                }}
            >
                {() => (
                    <FormWrapper>
                        <StyledInput name="email" type="input" placeholder='דוא"ל' label='דוא"ל' />
                        <SubmitButton name="submit" text="איפוס" />
                    </FormWrapper>
                )}
            </Formik>
            <Navigations />
            <AuthAlertsManager />
        </MainWrapper>
    )
}

export default ResetPassword
