import React, { useState, useEffect } from 'react';
import { LEAD_BLUE } from '../../../../utils/globals';
import { RenderTable } from './Tables';

const sortByHour = allMeetings => {
    allMeetings.sort((a, b) => {
        let aDate = a.date.hour.start.split(':')[0];
        let bDate = b.date.hour.start.split(':')[0];
        return aDate - bDate   
    })
};

const DetailedTable = ({ isOpen, meetingDay, isProfessional, requestedTable }) => {
    const [ meetingsSortedByHours, setMeetingsSortedByHours ] = useState(meetingDay);
    const [ opti, setOpti ] = useState(true);
    // State takes care of opening and closing notes collapse.
    const [isNotesOpen, setIsNotesOpen] = useState({
        show: false,
        line: null
    });
    const [isRecoOpen, setIsRecoOpen] = useState({
        show: false,
        line: null
    });

    useEffect(() => {
        let isMounted = true;
        const meetingsDedup = meetingsSortedByHours;
        sortByHour(meetingsDedup);
        isMounted && setMeetingsSortedByHours(meetingsDedup);
        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line
    }, [])

    const changeOptiState = state => {
        setOpti(state);
    };

    const onNotesClick = line => {
        setIsRecoOpen(false);
        setIsNotesOpen({
            show: !isNotesOpen.show,
            line: line
        });
    };

    const onRecoClick = line => {
        setIsNotesOpen(false);
        setIsRecoOpen({
            show: !isRecoOpen.show,
            line: line
        });
    };

  

    return (
        
        <RenderTable 
            requestedTable={requestedTable}
            isProfessional={isProfessional}
            tableSize="small"
            headersBg={LEAD_BLUE}
            meetingsInDay={meetingDay}
            onNotesClick={onNotesClick}
            onRecoClick={onRecoClick}
            isRecoOpen={isRecoOpen}
            opti={opti}
            isOpen={isOpen}
            changeOptiState={changeOptiState}
            isNotesOpen={isNotesOpen}
        />
    );
};

export default DetailedTable
