import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const SelectForm = ({
                        label, value,
                        onChange, name, fields, isDisabled
                    }) => {
    const onOptionSelect = event => {
        onChange(event);
    };

    return (

        <FormControl style={
            {
                minWidth: 150,
                marginLeft: '16px',
                justifySelf: "right"
            }}>

            <InputLabel
                id={label}
                style={{
                    position: 'sticky',
                }}
            >
                {label}
            </InputLabel>
            <Select
                labelId={label}
                id={`mainId${label}`}
                input={<Input name={name}/>}
                value={value ? value : ''}
                onChange={event => onOptionSelect(event)}
                displayEmpty
                autoWidth
                required
                autoFocus
                disabled={isDisabled ? true : false}
            >
                {fields.map((field, index) =>
                    <MenuItem
                        value={field}
                        key={index}
                        style={{direction: 'rtl'}}
                    >
                        {field}
                    </MenuItem>)}

            </Select>
        </FormControl>

    )
}

export default SelectForm