import React from 'react';
import styled from 'styled-components';
import { Box, Fab } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { AVAILABLE_TODAY, LEAD_BLUE } from '../../../utils/globals';
import AvailableTodayDescription from "./AvailableToday/AvailableTodayDescription";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import OthersDescription from './Others/OthersDescription';

const Wrapper = styled(Box)`
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.20), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.10),
              0 16px 16px rgba(0,0,0,0.05);
  
 
`;

const Name = styled.div`
    display: grid;
    direction: rtl;
  padding-top: 10px;
    font-size: 24px;
    font-family: 'Rubik', sans-serif;
  justify-content: center;
`;

const CategoriesHeader = ({ icon, label, discription }) => {

    return (
        <Wrapper>

            <Container>
               

                    <Name>

                        {label}{' '}

                    </Name>
                    <Fab size="medium" align='left' style={{ color: LEAD_BLUE, margin: '5px',position:'absolute' }} href={"/"} >
                        <ArrowBackIcon />

                    </Fab>
              
              

                 
                        {

                            discription === AVAILABLE_TODAY ?
                                <AvailableTodayDescription /> : <OthersDescription />
                        }
                

               
            </Container>
        </Wrapper>
    );
};

export default CategoriesHeader
