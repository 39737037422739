import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CustomAlert from '../../../layout/CustomAlert';
import Spinner from '../../../layout/Spinner';

import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { getEntireDocument } from '../../../../actions/FirestoreActions';
import { LEAD_BLUE } from '../../../../utils/globals';

const Bold = styled.span`
    color: ${LEAD_BLUE};
`;

const Text = styled(Typography)`
    direction: rtl;
    font-family: 'Rubik', Sens-Serif;
    text-align: right;
`;

const Wrapper = styled(Collapse)`
    direction: rtl;
`;

const ShowReco = ({ firstName, meetingId, isRecoOpen, 
    lineId, professionalId, isProfessional }) => {

    const { show, line } = isRecoOpen;
    const [ loading, setLoading ] = useState(false);
    const [ reco, setReco ] = useState({});

    useEffect(() => {
        const getReco = async () => {
            setLoading(true);
            const res = await getEntireDocument("recommendations", professionalId);
            if (res.type === "success") {
                // eslint-disable-next-line
                res.data[professionalId].map(meeting => {
                    meeting.meetingId === meetingId && setReco(meeting)
                });
            }
            setLoading(false);
        };
        show && getReco();
        // eslint-disable-next-line
    }, [show])

    return (

        <Wrapper
            in={show && line === lineId ? true : false}
        >
            <Text>
                הביקורת של <Bold>{firstName}</Bold>{' '}על פגישתכם
            </Text>
                {loading ? <Spinner /> :
                    reco.content ?
                        <TextField
                            fullWidth={true}
                            key={meetingId}
                            rowsMax={5}
                            disabled
                            style={{direction: 'rtl'}}
                            value={reco.content}
                            multiline
                            variant="filled"
                        >
                        </TextField>
                        : 
                        <CustomAlert 
                            type="info" 
                            msg="מקבל השירות טרם כתב חוות דעת"
                        />
                }
        </Wrapper>

    );
};

export default ShowReco
