import {v4} from 'uuid';
import {addNewDoucment, getEntireDocument} from '../../../actions/FirestoreActions';
import {MODIFY, CANCEL} from '../../../utils/globals';


const dayFormat = day => {
    const formattedDay = day.split("-").reverse().join("-");
    return formattedDay;
};

const getISOdate = dayObject => {
    const day = dayFormat(dayObject.day);
    // Formatting the received information
    let start = day.concat(' ', dayObject.start, ':00');
    let end = day.concat(' ', dayObject.end, ':00');
    // Converting to ISO format which supported by Google
    start = new Date(start).toISOString();
    end = new Date(end).toISOString();
    return {
        start,
        end
    }
};

const craftMeetingObject = (id, day, startHour, endHour,
                            clientId, firstName, lastName,
                            email, price, proFirstName, proLastName, proEmail,professionalURL) => {

    //Meeting link has to start null , ONLY after google auth the link
    //is created , and then it should be updated to working link.

    const meeting = {
        meetingId: v4(),
        professional: {
            id,
            proFirstName,
            proLastName,
            proEmail
        },
        date: {
            day,
            hour: {
                start: startHour,
                end: endHour
            },
            meetingLink: professionalURL

        },
        client: {
            clientId,
            firstName,
            lastName,
            email
        },
        price,
    }
    return meeting;
};

// We have loopedDay, which is the current looped day received from the document
// We have currentLoopedDay, which is the current day received from user's change
const manipulateUserDocument = (clientDocument, loopedDay, updatedMeeting, meetingIndex, meetingsInDate, currentLoopedDay) => {
    if (loopedDay !== currentLoopedDay) {
        // If the new day already has a field at the user's document 
        if (clientDocument.data[currentLoopedDay]) {
            clientDocument.data[currentLoopedDay].push(updatedMeeting);
            // If the user does not have a field with this date yet
        } else {
            clientDocument.data[currentLoopedDay] = [updatedMeeting]
        }
        // Removing the old date from the document
        meetingsInDate.splice(meetingIndex, 1);
        if (!clientDocument.data[loopedDay].length) delete clientDocument.data[loopedDay];
        // If a user changed the meeting to ocourre in an another hours the same day
    } else {
        meetingsInDate[meetingIndex] = updatedMeeting;
        clientDocument.data[currentLoopedDay] = meetingsInDate;
    }
    return clientDocument.data;
};

// We remove meeting from meetings in date by index
// We assign the updated into the document
// If it was the last meeting, we remove the day
const removeMeetingFromDocument = (document, meetingsInDate, meetingIndex, loopedDay) => {
    meetingsInDate.splice(meetingIndex, 1);
    if (!document.data[loopedDay].length) delete document.data[loopedDay];
    return document.data;
};

const updateSpecificMeeting = (clientDocument, meetingId, updatedMeeting, currentLoopedDay, state) => {
    for (const [date, meetingsInDate] of Object.entries(clientDocument.data)) {
        for (let meeting = 0; meeting < meetingsInDate.length; meeting++) {
            if (meetingsInDate[meeting].meetingId === meetingId) {
                // If the chosen new day is not the original day
                if (state === MODIFY) {
                    return manipulateUserDocument(clientDocument, date, updatedMeeting, meeting, meetingsInDate, currentLoopedDay);
                } else {
                    return removeMeetingFromDocument(clientDocument, meetingsInDate, meeting, currentLoopedDay);
                }
            }
        }
    }
};

export const removeMeetingFromObject = (documents, meetingObject, day) => {
    day = dayFormat(day);
    const prof = updateSpecificMeeting(documents[0], meetingObject.meetingId, meetingObject, day, CANCEL)
    const client = updateSpecificMeeting(documents[1], meetingObject.meetingId, meetingObject, day, CANCEL)
    return {
        prof,
        client
    };
};

export const createMeetingObject = (documents, meetingObject, day, startHour, endHour) => {
    const currentLoopedDay = day
    const updatedMeeting = {
        ...meetingObject,
        date: {
            day: dayFormat(day),
            hour: {
                end: endHour,
                start: startHour
            }
        }
    }
    const prof = updateSpecificMeeting(documents[0], updatedMeeting.meetingId, updatedMeeting, currentLoopedDay, MODIFY)
    const client = updateSpecificMeeting(documents[1], updatedMeeting.meetingId, updatedMeeting, currentLoopedDay, MODIFY)
    return {
        prof,
        client
    };
};

export const updateMeetingObject = (professionalId, day, startHour, endHour,
                                    clientId, firstName, lastName, email,
                                    price, proFirstName, proLastName, proEmail,professionalURL) => {
    day = dayFormat(day);
    const meeting = craftMeetingObject(professionalId, day, startHour, endHour,
        clientId, firstName,
        lastName, email, price, proFirstName, proLastName, proEmail,professionalURL);
    return meeting;
};

export const getMeetingURL = (dateObject, details) => {


    const date = getISOdate(dateObject);

    return new Promise((resolve, reject) => {

        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                apiKey: process.env.REACT_APP_GAPI_CALENDAR_API_KEY,
                clientId: process.env.REACT_APP_GAPI_CALENDAR_CLIENT_ID,
                discoveryDocs: process.env.REACT_APP_GAPI_CALENDAR_DISCOVERY_DOCS,
                scope: process.env.REACT_APP_GAPI_CALENDAR_SCOPES
            }).then(() => console.log("Successfully inited!")
            ).catch(err => console.log({msg: err, type: 'error'}))

            window.gapi.client.load('calendar', 'v3', () => {
                console.log("Successfully Loaded!")
            });

            window.gapi.auth2.getAuthInstance().signIn()
                //in case of error in sign in
                .catch((error) => {
                    return reject({
                        type: 'Auth Failed',
                        details: error
                    })
                })
                .then(() => {
                    var event = {
                        'summary': 'jiniz Video Meeting',
                        'description': `A video meeting with ${details.professional.firstName} ${details.professional.lastName}
                             and ${details.client.firstName} ${details.client.lastName} via OpenMynd platform`,
                        'start': {
                            'dateTime': date.start,
                            'timeZone': 'Asia/Jerusalem'
                        },
                        'end': {
                            'dateTime': date.end,
                            'timeZone': 'Asia/Jerusalem'
                        },
                        'attendees': [{
                            'email': details.client.email
                        },
                            {
                                'email': details.professional.email
                            }
                        ],
                        'reminders': {
                            'useDefault': false,
                            'overrides': [{
                                'method': 'email',
                                'minutes': 24 * 60
                            },
                                {
                                    'method': 'popup',
                                    'minutes': 30
                                }
                            ]
                        },
                        "conferenceData": {
                            "createRequest": {
                                "conferenceSolutionKey": {
                                    "type": "hangoutsMeet"
                                },
                                "requestId": "randomString"
                            }
                        }
                    };

                    var request = window.gapi.client.calendar.events.insert({
                        calendarId: 'primary',
                        resource: event,
                        conferenceDataVersion: 1,
                    })

                    request.execute(event => {
                        console.log("Event: ", event.htmlLink);
                        resolve({
                            type: 'success',
                            link: event.htmlLink,
                            date: dateObject,
                            details
                        })

                    })
                }).catch(reason => reject({
                type: 'failed',
                details: reason
            }))
        });
    });
};

const modifyMessageToClient = (meetingObject, day, hour) => {
    const {client, professional} = meetingObject;
    const content = `היי ${client.firstName} ${client.lastName},
                     תאריך הפגישה עם ${professional.proFirstName} ${professional.proLastName} שונה ל- ${day}, ${hour.start} - ${hour.end}.                     
                     ניתן לשנות או לבטל את תאריך הפגישה במידה והוא לא מתאים דרך מסך הפגישות.
                     תודה על שיתוף הפעולה, OpenMynd.
    `
    return content;
};

const modifyMessageToProfessional = (meetingObject, day, hour) => {
    const {client, professional} = meetingObject;
    const content = `היי ${professional.proFirstName} ${professional.proLastName},
                     תאריך הפגישה עם ${client.firstName} ${client.lastName} שונה ל- ${day}, ${hour.start} - ${hour.end}.
                     ניתן לשנות או לבטל את תאריך הפגישה במידה והוא לא מתאים דרך מסך הפגישות.
                     תודה על שיתוף הפעולה, OpenMynd.
    `
    return content;
};

const cancelMessageToProfessional = meetingObject => {
    const {client, professional, date} = meetingObject;
    const content = `היי ${professional.proFirstName} ${professional.proLastName},
                     הפגישה עם ${client.firstName} ${client.lastName} בתאריך- ${date.day}, ${date.hour.start} - ${date.hour.end} בוטלה.
                     ניתן ליצור קשר עם הלקוח דרך שירות ההודעות שלנו באתר בכתובת ${client.email}.
                     תודה על שיתוף הפעולה, OpenMynd.
    `
    return content;
};

const cancelMessageToClient = meetingObject => {
    const {client, professional, date} = meetingObject;
    const content = `היי ${client.firstName} ${client.lastName}, יש לנו עדכון עבורך.
                     הפגישה עם ${professional.proFirstName} ${professional.proLastName} בתאריך ${date.day}, ${date.hour.start} - ${date.hour.end} בוטלה.
                     ניתן לפנות אל המטפל דרך שירות ההודעות שלנו באתר, בכתובת ${professional.proEmail}.
                     תודה על שיתוף הפעולה, OpenMynd.
    `
    return content;
};

export const getMessageObject = (email, firstName, lastName, content, subject) => {
    const messageObject = {
        message: {
            id: v4(),
            data: {
                subject,
                content
            },
            date: {
                day: new Date().toISOString().slice(0, 10),
                hour: new Date().toLocaleTimeString()
            },
            isOpened: false,
            inInbox: "inbox",
            isReplyable: false
        },
        sender: {
            email: "info@open-mynd.com",
            firstName: "Open-Mynd",
            lastName: ""
        },
        receiver: {
            email,
            firstName,
            lastName
        }
    }
    return messageObject
};

export const getMessagesObjects = (meetingObject, state) => {
    let clientContent;
    let professionalContent;
    let subject;
    const day = meetingObject.date.day;
    const hour = meetingObject.date.hour;
    if (state === MODIFY) {
        clientContent = modifyMessageToClient(meetingObject, day, hour);
        professionalContent = modifyMessageToProfessional(meetingObject, day, hour);
        subject = "שינוי מועד פגישה"
    } else if (state === CANCEL) {
        clientContent = cancelMessageToClient(meetingObject);
        professionalContent = cancelMessageToProfessional(meetingObject);
        subject = "ביטול פגישה"
    }
    const profMessage = getMessageObject(meetingObject.professional.proEmail,
        meetingObject.professional.proFirstName,
        meetingObject.professional.proLastName,
        professionalContent,
        subject);
    const clientMessage = getMessageObject(meetingObject.client.email,
        meetingObject.client.firstName,
        meetingObject.client.lastName,
        clientContent,
        subject)
    return {
        profMessage,
        clientMessage
    }
};

// State is either "Created", "Modified" or "Cancelled"
export const getSMSContent = (meetingObject, state, day, hour) => {
    if (state === MODIFY) {
        const profContent = modifyMessageToProfessional(meetingObject, day, hour);
        const clientContent = modifyMessageToClient(meetingObject, day, hour);
        return {profContent, clientContent};
    } else if (state === CANCEL) {
        const profContent = cancelMessageToProfessional(meetingObject)
        const clientContent = cancelMessageToClient(meetingObject)
        return {profContent, clientContent};
    }
};

// Function is needed when a user purchase a meeting with an expert.
// We first check if a phone number already exists for a user
// If it doesn't, we get the user document and updates it
// localPhone is something like "05264..." while internationalPhone "+97252..."
export const updatePhoneNumber = async (formLocalPhone, userId) => {
    const userDoc = await getEntireDocument('users', userId);
    const internationalPhone = formLocalPhone.replace(formLocalPhone[0], '+972');
    const updatedDoc = {
        ...userDoc.data,
        localPhone: formLocalPhone,
        internationalPhone,
    }
    await addNewDoucment('users', userId, updatedDoc);
};