import {
    GET_RECOMMENDATIONS,
    POST_RECOMMENDATIONS,
    CLEAR_RECOMMENDATIONS,
    SET_RECOMMENDATIONS_LOADING,
    SET_RECOMMENDATIONS_ALERT,
    CLEAR_RECOMMENDATIONS_ALERT,
} from './types';
import { setArray, updateArray, getEntireDocument } from './FirestoreActions';

export const getCurrentRecommendations = id => async dispatch => {

    dispatch(setRecommendationsLoading(true));

    try {
        const res = await getEntireDocument("recommendations", id);
        if (res.type === 'success') {
            dispatch({
                type: GET_RECOMMENDATIONS,
                payload: res.data
            });
        } else {
            dispatch ({
                type: SET_RECOMMENDATIONS_ALERT,
                payload: {
                    type: res.type,
                    msg: res.messgae
                }
            });
        }
        dispatch(setRecommendationsLoading(false));   

    } catch (error) {
        dispatch(setRecommendationsLoading(false));
        // In case no response from backend
        console.error(`Error while trying to get professionals: ${error}`);
        dispatch ({
            type: SET_RECOMMENDATIONS_ALERT,
            payload: {
                type: 'error',
                msg: error
            }
        });
    };
};

export const postRecommendations = (professionalId, data) => async dispatch => {

    dispatch(setRecommendationsLoading(true));

    try {
        let res;
        res = await setArray("recommendations", professionalId, professionalId, data);
        dispatch(setRecommendationsLoading(false));
        if (res.type === 'success') {
            dispatch ({
                type: POST_RECOMMENDATIONS,
                payload: res.data
            });   
        } else {
            dispatch ({
                type: SET_RECOMMENDATIONS_ALERT,
                payload: {
                    type: res.type,
                    msg: res.message
                }
            });
        }

    } catch (error) {
        dispatch(setRecommendationsLoading(false));
        console.error(`Error while trying to get professionals: ${error}`);
        dispatch ({
            type: SET_RECOMMENDATIONS_ALERT,
            payload: {
                type: 'error',
                msg: error
            }
        });
    };
};

export const updateRecommendations = (professionalId, data) => async dispatch => {



    dispatch(setRecommendationsLoading(true));

    try {

        let res;
        res = await updateArray("recommendations", professionalId, professionalId, data);
        console.log(res)
        dispatch(setRecommendationsLoading(false));
        if (res.type === 'success') {
            dispatch ({
                type: POST_RECOMMENDATIONS,
                payload: res.data
            });   
        } else {
            dispatch ({
                type: SET_RECOMMENDATIONS_ALERT,
                payload: {
                    type: res.type,
                    msg: res.message
                }
            });
        }   

    } catch (error) {
        dispatch(setRecommendationsLoading(false));
        console.error(`Error while trying to get professionals: ${error}`);
        dispatch ({
            type: SET_RECOMMENDATIONS_ALERT,
            payload: {
                type: 'error',
                msg: error
            }
        });
    };
};

export const clearCurrentRecommendations = () => {
    return {
        type: CLEAR_RECOMMENDATIONS
    };
}; 

export const clearAlert = () => {
    return {
        type: CLEAR_RECOMMENDATIONS_ALERT
    }
};

export const setRecommendationsLoading = loadingState => {
    return {
        type: SET_RECOMMENDATIONS_LOADING,
        payload: loadingState
    };
};

