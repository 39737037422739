import React from 'react';
import { useSelector } from 'react-redux';
import AdminForm from './AdminForm';
import AdminHome from './AdminHome';

import Container from '@material-ui/core/Container';


const Admin = () => {

    const { isAdmin, error, authLoading } = useSelector(state => state.auth);

    return (

        <Container maxWidth="md">

            {
                isAdmin ?
                    <AdminHome 
                        error={error}
                        authLoading={authLoading}
                    />
                        :
                    <AdminForm 
                        error={error} 
                        authLoading={authLoading}
                    />
            }
            
        </Container>

    )
}

export default Admin
