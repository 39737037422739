import React from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { LEAD_BLUE } from '../../utils/globals';

const Title = styled(FormLabel)`
    direction: rtl;
    font-size: 20px;
    color: ${LEAD_BLUE};
`;

const RadioInput = ({title, name, options, value, handleChange}) => {
    return (
        <FormControl component="fieldset">
            <Title 
                component="legend"
            >
                {title}
            </Title>
            <Select
                name={name}
                value={value}
                onChange={event => handleChange(event)}
            >
                {options.map((option, index) => 
                    <MenuItem 
                        key={index}
                        value={option}
                    >
                        {option}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default RadioInput
