import React, {useState} from 'react';
import styled from 'styled-components';
import CalanderHeader from '../../../calander/calendarUtils/CalanderHeader';
import {TextField} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";

const experienceFields = [
    {
        name: "defaultExperience"
    },
]
const Paragraph = styled(Grid)`
  justify-self: left;
  margin-left: 35px;
`;

const MainWrapper = styled.div`
    display: grid;
    align-items: center;
    grid-gap: 10px;
    justify-items: flex-start;
    direction: rtl;
`;

const Description = () => {
    return (
        <CalanderHeader
            header="ניסיון מקצועי"
            description={"זה המקום להרחיב על מסלול הצמיחה המקצועית ולהוסיף קורטוב אישי (למשל תחביבים מיוחדים, הישג משמעותי בחייך וכד׳), כל זאת בלא יותר מ-500 תווים."}
        />
    )
};

const ExperienceFields = ({onChange, experienceState}) => {
    const [length,setLength] = useState(experienceState['defaultExperience'].length);

    return (
        <>

            {
                experienceFields.map((field, index) =>
                    <TextField
                        key={index}
                        multiline
                        fullWidth
                        rows={3}
                        style={{width: '90%', margin: 'auto'}}
                        name={field.name}
                        value={experienceState[field.name]}
                        variant="filled"
                        inputProps={{maxLength: 500}}
                        onChange={event => {
                            onChange(event, "experienceState");
                            setLength(event.target.value.length);
                        }}
                    />
                )
            }

            <Paragraph>{length}/500</Paragraph>
        </>
    );
};

const ExperienceForm = ({onChange, experienceState}) => {
    return (
        <MainWrapper>
            <Description/>
            <ExperienceFields
                onChange={onChange}
                experienceState={experienceState}
            />
        </MainWrapper>
    )
}

export default ExperienceForm
