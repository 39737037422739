import React from 'react';
import styled from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import { Wrapper, ButtonsWrapper, ButtonStyle } from '../PaymentBoiler';
import CustomAlert from '../../../layout/CustomAlert';
import { LEAD_BLUE, RED } from '../../../../utils/globals';
import Spinner from '../../../layout/Spinner';

const TextWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin: 10px;
`;

const ErrorIconWrapper = styled.div`
    background-color: #edd4d4;
    height: 40px;
    width: 50px;
    border-radius: 50%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ErrorIconStyle = styled(ErrorIcon)`
    color: #bc5f5f;
`;

const Text = styled.p`
    font-family: 'Rubik', Sens-Serif;
    font-size: 18px;
    padding: 5px;
    margin: 5px;
`;

const WarningText = styled.p`
    font-family: 'Rubik', Sens-Serif;
    font-size: 18px;
    color: red;
    padding: 5px;
    margin: 5px;
`;

const CancelMeetingPayment = ({ onCancelClick, loading, 
    error, onModifyMove, handleConfirmation, confirmationState,sendConfirmationEmails }) => {
    return (
        <Wrapper>
            <TextWrapper>
                <ErrorIconWrapper>
                    <ErrorIconStyle />
                </ErrorIconWrapper>
                <Text>לחיצה על המשך תוביל לביטול המפגש.</Text>
            </TextWrapper>
            {confirmationState && <WarningText>האם אתה בטוח?</WarningText>}
            <ButtonsWrapper>   
                {
                    !confirmationState ? 
                        <>
                            <ButtonStyle
                                variant='contained'
                                style={{backgroundColor: RED}}
                                onClick={handleConfirmation}
                            >
                                בטל פגישה
                            </ButtonStyle>
                            <ButtonStyle
                                style={{backgroundColor: LEAD_BLUE}}
                                variant='contained'
                                onClick={onModifyMove}
                            >
                                שנה תאריך
                            </ButtonStyle>
                        </>
                :
                    !loading && <>
                        <ButtonStyle
                            variant='contained'
                            style={{backgroundColor: RED}}
                            onClick={onCancelClick}
                        >
                            אישור
                        </ButtonStyle>
                        <ButtonStyle
                            variant='contained'
                            style={{backgroundColor: LEAD_BLUE}}
                            onClick={handleConfirmation}
                        >
                            הקודם
                        </ButtonStyle>
                    </>
                }
            </ButtonsWrapper>

                {
                    loading ? <Spinner /> : !!error.message &&
                        <CustomAlert 
                            type={error.type}
                            msg={error.message}    
                        />
                }
        </Wrapper>
    );
};

const CancelMeeting = ({ onCancelClick, loading, 
    error, onModifyMove, handleConfirmation, confirmationState ,sendConfirmationEmails}) => {
    return (
        <CancelMeetingPayment
            onCancelClick={onCancelClick}
            loading={loading}
            error={error}
            onModifyMove={onModifyMove}
            handleConfirmation={handleConfirmation}
            confirmationState={confirmationState}
            sendConfirmationEmails={sendConfirmationEmails}
        />
    )
}

export default CancelMeeting
