import React from 'react'
import { LEAD_BLUE} from '../../../utils/globals';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Box';
import styled from 'styled-components';
import SettingsBar from '../../professionalZone/calander/SettingsBar';


const MainWrapper = styled(Box)`
    display: grid;
    justify-items: flex-start;
    align-items: center;
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.20), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.10),
              0 16px 16px rgba(0,0,0,0.05);
`;

const TextWrapper = styled(Box)`
    margin: 4px;
    padding: 4px;
`;

const Text = styled(Typography)`
    font-family: 'Rubik', Sens-Serif;
`;  

const Bold = styled.span`
    color: ${LEAD_BLUE};
`;

const CalanderHeader = ({ firstName, lastName, onExit }) => {
    return (
        <MainWrapper>
            <SettingsBar label="יומן מטפל" onExit={onExit} />
            <TextWrapper>
                <Text>  
                    זהו יומן הטיפולים של <Bold>{firstName} {lastName}.</Bold>
                </Text>
                <TextWrapper>
                    <Text>  
                        ניתן לקבוע מפגש רק בימים המסומנים בכחול. לחיצה על היום מהבוקש תפתח את השעות הפנויות.
                    </Text>
                </TextWrapper>
                <TextWrapper>
                    <Text> 
                        לחץ על היום שרלוונטי עבורך, תפריט יפתח ובו תוכלו לבחור את השעה שמתאימה לך מתוך השעות שהמטפל מציע.
                    </Text>
                </TextWrapper>
            </TextWrapper>
        </MainWrapper>
    )
}

export default CalanderHeader
