// Dialog actions
export const OPEN_AUTH = 'OPEN_AUTH';
export const CLOSE_AUTH = 'CLOSE_AUTH';
export const OPEN_PROFESSIONAL = 'OPEN_PROFESSIONAL';
export const OPEN_PROFESSIONAL_ZONE = 'OPEN_PROFESSIONAL_ZONE';
export const OPEN_VIDEO = 'OPEN_VIDEO';
export const OPEN_RESET = 'OPEN_RESET';
export const CLOSE_VIDEO = 'CLOSE_VIDEO';
export const CLOSE_REGISTER = 'CLOSE_REGISTER';
export const CLOSE_LOGIN = 'CLOSE_LOGIN';
export const CLOSE_PROFESSIONAL = 'CLOSE_PROFESSIONAL';
export const CLOSE_PROFESSIONAL_ZONE = 'CLOSE_PROFESSIONAL_ZONE';
export const CLOSE_RESET = 'CLOSE_RESET';
export const OPEN_POLICIES = 'OPEN_POLICIES';
export const CLOSE_POLICIES = 'CLOSE_POLICIES';
export const OPEN_QUIZ = 'OPEN_QUIZ';
export const CLOSE_QUIZ = 'CLOSE_QUIZ';
export const OPEN_MESSAGES = 'OPEN_MESSAGES';
export const CLOSE_MESSAGES = 'CLOSE_MESSAGES';
export const OPEN_OPTIONS = 'OPEN_OPTIONS';
export const CLOSE_OPTIONS = 'CLOSE_OPTIONS';
export const OPEN_CONFIRMATION = 'OPEN_CONFIRMATION';
export const CLOSE_CONFIRMATION = 'CLOSE_CONFIRMATION';
export const OPEN_ADMIN_EDIT = 'OPEN_ADMIN_EDIT'
export const CLOSE_ADMIN_EDIT = 'CLOSE_ADMIN_EDIT'

// Auth actions
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_AUTH_ERROR='SET_AUTH_ERROR'
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const ADMIN_LOADED = 'ADMIN_LOADED';
export const SET_AUTH_PROCESS = 'SET_AUTH_PROCESS';
export const PROFESSIONAL_LOADED = 'PROFESSIONAL_LOADED';

// Professionals actions
export const GET_PROFESSIONALS = 'GET_PROFESSIONALS';
export const CLEAR_PROFESSIONALS = 'CLEAR_PROFESSIONALS';
export const GET_CURRENT = 'GET_CURRENT';
export const PRESENT_PROFESSIONAL = 'PRESENT_PROFESSIONAL';
export const CLEAR_PRESENTED_PROFESSIONAL = 'CLEAR_PRESENTED_PROFESSIONAL';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const SET_PROFESSIONALS_LOADING = 'SET_PROFESSIONALS_LOADING';
export const UPDATE_PROFESSIONAL = 'UPDATE_PROFESSIONAL';
export const SET_PROFESSIONALS_ERROR = 'SET_PROFESSIONALS_ERROR';
export const CLEAR_PROFESSIONALS_ERROR = 'CLEAR_PROFESSIONALS_ERROR';

// Recommendations actions
export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const POST_RECOMMENDATIONS = 'POST_RECOMMENDATIONS';
export const CLEAR_RECOMMENDATIONS = 'CLEAR_RECOMMENDATIONS';
export const SET_RECOMMENDATIONS_LOADING = 'SET_RECOMMENDATIONS_LOADING';
export const SET_RECOMMENDATIONS_ALERT = 'SET_RECOMMENDATIONS_ALERT';
export const CLEAR_RECOMMENDATIONS_ALERT = 'CLEAR_RECOMMENDATIONS_ALERT';

// Schedule actions
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const GET_All_SCHEDULE = 'GET_All_SCHEDULE';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const SET_SCHEDULE_ERROR = 'SET_SCHEDULE_ERROR';
export const CLEAR_SCHEDULE_ERROR = 'CLEAR_SCHEDULE_ERROR';
export const SET_SCHEDULE_LOADING = 'SET_SCHEDULE_LOADING';
export const CLEAR_SCHEDULE_STATE = 'CLEAR_SCHEDULE_STATE';
export const SET_FETCHED_FLAG = 'SET_FETCHED_FLAG';

// Meetings actions
export const GET_MEETINGS = 'GET_MEETINGS';
export const SET_MEETINGS = 'SET_MEETINGS';
export const UPDATE_MEETINGS = 'UPDATE_MEETINGS';
export const SET_MEETINGS_ALERT = 'SET_MEETINGS_ALERT';
export const CLEAR_MEETINGS_ALERT = 'CLEAR_MEETINGS_ALERT';
export const SET_MEETINGS_LOADING = 'SET_MEETINGS_LOADING';

// Policies actions
export const OPEN_PRIVACY = 'OPEN_PRIVACY';
export const OPEN_TERMS = 'OPEN_TERMS';
export const OPEN_CONTENT = 'OPEN_CONTENT';

// Messages actions
export const GET_MESSAGES = 'GET_MESSAGES';
export const POST_MESSAGES = 'POST_MESSAGES';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const SET_MESSAGES_ALERT = 'SET_MESSAGES_ALERT';
export const CLEAR_MESSAGES_ALERT = 'CLEAR_MESSAGES_ALERT';
export const SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING';
export const UPDATE_SCREEN_TO_RENDER = 'UPDATE_SCREEN_TO_RENDER';
export const UPDATE_UNREAD = 'UPDATE_UNREAD';
export const UPDATE_INDEPENDENT = 'UPDATE_INDEPENDENT';
export const  CLEAR_MESSAGES=' CLEAR_MESSAGES'


//Tokens actions
export const GET_USER_TOKENS = 'GET_USER_TOKENS';
export const DECREMENT_TOKEN = 'DECREMENT_TOKEN';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_TOKENS_LOADING = 'SET_TOKENS_LOADING';
export const CLEAR_TOKENS='CLEAR_TOKENS'

//admin actions
export const ADMIN_GET_ALL_PROS = 'ADMIN_GET_ALL_PROS'
export const ADMIN_CLEAR_ALL_PROS = 'ADMIN_CLEAR_ALL_PROS'
export const ADMIN_SET_UPDATE = 'ADMIN_SET_UPDATE'
export const ADMIN_SET_LOADING = 'ADMIN_SET_LOADING'
export const ADMIN_SET_CAL_PRICE = 'ADMIN_SET_CAL_PRICE'

