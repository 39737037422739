import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Box} from '@material-ui/core';
import ProfessionalsItem from './ProfessionalsItem';
import Pagination from '@material-ui/lab/Pagination';
import {Container} from '@material-ui/core';
import {getProfessionalAssets} from '../../../actions/StorageActions';
import {getMeetings} from '../../../actions/MeetingsActions';
import {useDispatch, useSelector} from 'react-redux';

const ComponentsWrapper = styled(Box)`
  margin-top: 16px;
`;

const PaginationWrapper = styled(Box)`
  margin: 16px;
`;

const ProfessionalsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px 30px;
  @media only screen and (max-width: 1289px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
`;

const Professionals = ({profObject}) => {


    const [page, setPage] = useState(1);
    const [assets, setAssets] = useState([]);
    const {isNormalUser, user} = useSelector(state => state.auth);
    const isMounted = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        if (isNormalUser) {
            dispatch(getMeetings("userMeetings", user.id));
        }
        // eslint-disable-next-line
    }, [isNormalUser])


    useEffect(() => {
        const req = [];
        isMounted.current = true;
        const currentPresented = profObject.slice(page > 0 && (page - 1), (page - 1) + 9);
        currentPresented.forEach(profObject => {
            let photoRef = profObject.picture.profilePicture.stringName;
            let promise = getProfessionalAssets(profObject.id, "professionals", "picture", photoRef);
            req.push(promise);
        });
        const getPictures = async () => {
            const res = await Promise.all(req);
             isMounted.current && setAssets(res);
        }
        getPictures();
        return () => {
            isMounted.current = false;
        }
        // eslint-disable-next-line
    }, [page, profObject])

    // Changing the current page on page change event.
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (

        <Container>
            <ComponentsWrapper align='center'>
                <ProfessionalsWrapper>
                    {!assets.length ? null : profObject
                        .slice(page > 0 && (page - 1) * 1, (page - 1) * 1 + 9)
                        .map((item, index) =>
                            item.pending.status === false &&
                            <ProfessionalsItem
                                professionalObject={item}
                                key={item.id}
                                picture={assets[index]}
                            />
                        )}
                </ProfessionalsWrapper>
            </ComponentsWrapper>
            {Math.floor(profObject.length / 9) <= 0 ? null :
                <PaginationWrapper>
                    <Pagination
                        count={Math.floor(profObject.length)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </PaginationWrapper>}
        </Container>

    )
}

export default Professionals
