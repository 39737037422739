import React from 'react';
import styled from 'styled-components';

import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';

const Bold = styled(Typography)`
    font-weight: 700;
    display: inline;
`;



const TitleWrapper = styled(Box)`
    margin: 20px;
`;

const Paragraph = styled(Box)`
    margin-top: 40px;
`;




const Container = styled.div`
   text-align:end;
`;




const Terms = () => {

    return (

        <Container
        >

            <TitleWrapper>
                <Bold> OpenMynd תנאי שימוש באתר</Bold>
            </TitleWrapper>
            .תקנון השימוש באתר הנ"ל נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים כאחד
            <Paragraph>
                <Paragraph><Bold>הקדמה</Bold></Paragraph>
                אתר OpenMynd " (להלן "האתר") הוא אתר המשמש כאתר ייצוגי עבור OpenMynd IL והנך מוזמן לקחת בו חלק בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן. בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו, עשויים להשתנות מעת לעת או בהתאם לסוג התוכן. הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת וללא התראה או אזכור מיוחד בערוצי האתר השונים

            </Paragraph>
            <Paragraph>
                <Paragraph><Bold>קניין רוחני</Bold></Paragraph>
                האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה לרבות גרפיקות, סרטונים, תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר אחר אשר מוצג באתר שייכים במלואם לאתר ומהווים קניין רוחני בלעדי של האתר ואין לעשות בהם שימוש ללא אישור כתוב מראש מהנהלת האתר. בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד פיסות קוד, גרפיקות, סרטונים, סימנים מסחריים או כל מדיה ותוכן אחר מבלי שיש ברשותכם אישור כתוב מראש.
            </Paragraph>
            <Paragraph><Paragraph><Bold>תוכן האתר</Bold></Paragraph>
                אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך ייתכנו, בשל שיקולים טכניים, תקלות צד ג׳ או אחרים, הפרעות בזמינות האתר. ולכן איננו יכולים להתחייב כי האתר יהיה זמין לכם בכל עת ולא יינתן כל פיצוי כספי או אחר בשל הפסקת השירות / הורדת האתר. קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים בטוחים, איכותיים או אמינים וביקור בהם נעשה על דעתכם האישית בלבד ונמצאים בתחום האחריות הבלעדי של המשתמש באתר. התכנים המוצעים באתר הינם בבעלותה הבלעדית של  OpenMynd IL ואין לעשות בהם שימוש אשר נוגד את האמור בתקנון זה למעט במקרים בהם צוין אחרת או במקרים בהם צוין כי זכויות היוצרים שייכים לגוף חיצוני. במקרים אלו יש לבדוק מהם תנאי השימוש בקישור המצורף ולפעול על פי המצוין באתר החיצוני לו שייכים התכנים
            </Paragraph>
            <Paragraph><Paragraph><Bold>ניהול משתמשים ומבקרים באתר</Bold></Paragraph>
                הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש בין אם על ידי חסימת כתובת ה-IP  של המחשב שלו, כתובת ה MACID   של המחשב שלו או בהתאם למדינת המוצא, ללא צורך לספק תירוץ אשר מקובל על הגולש. הנהלת האתר תעשה כל שביכולתה כדי להגן על פרטי המשתמשים הרשומים באתר. במקרים בהם יעלה בידיו של צד שלישי להשיג גישה למידע, מוסכם בזאת כי לגולשים, משתמשים וחברים באתר לא תהה כל תביעה, טענה או דרישה כלפי צוות האתר
            </Paragraph>
            <Paragraph><Paragraph><Bold>גילוי נאות</Bold></Paragraph>
                באתר זה עשוי לעשות שימוש בקבצי עוגיות (במיוחד עבור משתמשים רשומים) ובממשקי סטטיסטיקה פנימיים בכדי לשמור תיעוד סטטיסטי אנונימי של גולשים וניתוח תנועת הגולש/ים, הרגלי גלישה באתר וניתוח קליקים וזמן שהייה. בכל עת המידע הנשמר הוא אנונימי לחלוטין ואין בו את שם הגולש או כל פרט מזהה אחר
            </Paragraph>
            <Paragraph><Paragraph><Bold>אזור שיפוט</Bold></Paragraph>
              בעת שאתם עושים שימוש באתר ובמקרה בו התגלעה כל מחולקת אתם מסכימים להלן כי האמור לעיל נמצא תחת סמכות השיפוט הבלעדי של החוק הישראלי תוך שימוש במערכת בתי המשפט הישראליים בלבד במחוז תל אביב
            </Paragraph>
        </Container>

    );
};

export default Terms
