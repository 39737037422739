import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useField, Form } from "formik";
import { Link } from "react-router-dom";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { routeQuery } from "../../../utils/routes";
import { LEAD_BLUE, DARK_BLUE } from "../../../utils/globals";
import CustomAlert from "../../../components/layout/CustomAlert";

const useStyles = makeStyles(() => ({
  root: {
    "& label.Mui-focused": {
      color: DARK_BLUE,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: DARK_BLUE,
    },
  },
  helperText: {
    "& .MuiFormHelperText-root": {
      textAlign: "right",
    },
  },
  label: {
    "& .MuiInputLabel-formControl": {
      left: "auto",
    },
  }
}));

export const MainWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  grid-template-rows: auto auto 30px 30px auto;
  /* Hidden by default */

  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  /*    left: 50%;
        
      top: 20%;  
      transform: translateX(-50%);*/
  width: 100%;
  /* Full width */
  /* height: auto;*/
  //    min-width: 400px;
  min-height: 500px;
  //width:500px;
  // height: 500px;
  background: #fff;
  @media (max-width: 578px) {
    width: 100%;
    height: 500px;
  }
`;

export const FormWrapper = styled(Form)`
  display: grid;
  justify-items: center;
  align-items: center;
`;

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: black;
`;

const InputFieldWrapper = styled.div`
  padding: 1px;
`;

const Submit = styled.button`
  font-family: "Rubik", Sens-Serif, serif;
  font-size: 16px;
  cursor: pointer;
  background-color: ${DARK_BLUE};
  border: none;
  color: white;
  width: 70px;
  height: 35px;
  margin-top: 20px;
  border-radius: 5px;
`;

const NavText = styled.span`
  margin: auto;

  //margin: -5px 0;
  color: gray;
  font-size: 14px;
  /* margin: 5px;*/
  font-family: "Rubik", Sens-Serif, serif;
`;

const NavSpan = styled(Link)`
  cursor: pointer;
  color: ${LEAD_BLUE};
  display: inline-block;
  text-decoration: none;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 10px;
`;

const HeaderText = styled.p`
  padding: 0;
  margin: 0;
  font-family: "Arial", Sens-Serif, sans-serif;
  font-size: 20px;
  color: ${LEAD_BLUE};
`;

export const checkName = (errors, firstName, lastName) => {
  const containsHebrew = /[\u0590-\u05FF]/;
  if (!containsHebrew.test(firstName)) {
    errors.firstName = "השם חייב להיות בעברית";
  }
  if (!containsHebrew.test(lastName)) {
    errors.lastName = "השם חייב להיות בעברית";
  }
};

/*
 * Validate not empty string
 * @param {*} string
 * @returns
 */
export const validateNotEmpty = (errors, string) => {
  if (string === " " || string === undefined) {
    errors.occupation = "שדה חובה ";
  }
};

export const checkAddress = (errors, address) => {
  const containsHebrew = /[\u0590-\u05FF]/;
  if (!containsHebrew.test(address)) {
    errors.address = "הכתובת חייבת להיות בעברית";
  }
};

export const checkProForms = (
  errors,
  degree,
  profession,
  experience,
  expertie
) => {
  // I have no idea why this is all mixed up... but fuck that shit im not changing it
  if (!degree) {
    errors.profession = "שדה חובה";
  }
  if (!experience) {
    errors.expertie = "שדה חובה";
  }
  if (!expertie) {
    errors.experience = "שדה חובה";
  }
};

export const emailValidation = (errors, email) => {
  if (!email) {
    errors.email = "שדה חובה";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = "כתובת האימייל אינה חוקית";
  }
};

export const emailConfirmationValidation = (errors, email, confirmEmail) => {
  if (email !== confirmEmail) {
    errors.confirmEmail = "כתובות האימייל אינן תואמות";
  }
};

export const phoneValidation = (errors, phoneNum) => {
  if (!phoneNum) {
    errors.localPhone = "שדה חובה";
  } else if (phoneNum.length !== 10 || !phoneNum.startsWith("05"))
    errors.localPhone = "נא להכניס מספר חוקי";
};

export const idValidation = (errors, idNumber) => {
  if (!idNumber) {
    errors.idNumber = "שדה חובה";
  } else if (idNumber.length !== 9) {
    errors.idNumber = "נא להכניס תעודת זהות חוקית";
  }
};

export const passwordValidation = (errors, password) => {
  if (!password) {
    errors.password = "שדה חובה";
  } else if (!/[a-z]\d|\d[a-z]/i.test(password) || password.length < 8) {
    errors.password =
      "הסיסמה צריכה להחיל 8 תווים עם לפחות ספרה אחת ואות אחת באנגלית";
  }
};

export const FormHeader = ({ text }) => {
  return (
    <HeaderWrapper>
      <HeaderText>{text}</HeaderText>
    </HeaderWrapper>
  );
};

export const StyledInput = ({
  placeholder,
  label,
  type,
  multiline,
  width,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const classes = useStyles();
  return (
    <InputFieldWrapper>
      <TextField
        inputProps={{ maxLength: 150 }}
        {...field}
        helperText={errorText}
        placeholder={placeholder}
        type={type}
        label={label}
        error={!!errorText}
        fullWidth
        style={{ width: width ? width : "230px" }}
        multiline={multiline}
        rows={multiline && 2}
        variant={multiline && "standard"}
        className={clsx(classes.helperText, classes.label, classes.root)}
      />
    </InputFieldWrapper>
  );
};

export const AgeGroupSelector = ({
  placeholder,
  label,
  type,
  width,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const classes = useStyles();
  return (
    <FormControl
      error={!!errorText}
      className={clsx(classes.helperText, classes.label, classes.root)}
    >
      <InputFieldWrapper>
        <InputLabel>{label}</InputLabel>
        <Select
          inputProps={{ maxLength: 150 }}
          {...field}
          placeholder={placeholder}
          type={type}
          fullWidth
          style={{ width: width ? width : "230px" }}
        >
          <MenuItem value="18-19">18-19</MenuItem>
          <MenuItem value="20-29">20-29</MenuItem>
          <MenuItem value="30-39">30-39</MenuItem>
          <MenuItem value="40-49">40-49</MenuItem>
          <MenuItem value="50-59">50-59</MenuItem>
          <MenuItem value="70-79">70-79</MenuItem>
          <MenuItem value="80-89">80-89</MenuItem>
        </Select>
        <FormHelperText>{errorText}</FormHelperText>
      </InputFieldWrapper>
    </FormControl>
  );
};

export const SubmitButton = ({ text, disabled, ...props }) => {
  const [field] = useField(props);
  return (
    <Submit
      {...field}
      type="submit"
      disabled={disabled}
      whileHover={{ scale: 1.1 }}
      initial={{ x: "0" }}
    >
      {text}
    </Submit>
  );
};

export const MoveTo = ({ to, text }) => {
  return (
    <NavText>
      {text} <NavSpan to={() => routeQuery({ box: to })}>כאן</NavSpan>
    </NavText>
  );
};

export const AuthAlertsManager = () => {
  const { error } = useSelector((state) => state.auth);
  const { success } = useSelector((state) => state.auth);
  return (
    <>
      {!!error && <CustomAlert type="error" msg={error} />}
      {!!success && <CustomAlert type="success" msg={success} />}
    </>
  );
};
