import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { postRecommendations, updateRecommendations } from '../../../../../actions/RecommendationsActions';
import styled from 'styled-components';
import RecoTextField from './RecoTextField';
import { getEntireDocument } from '../../../../../actions/FirestoreActions';
import CustomAlert from '../../../../layout/CustomAlert';

import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { LEAD_BLUE } from '../../../../../utils/globals';
import { compareDates } from '../tableUtils';

const HeaderWrapper = styled.span`
    color: ${LEAD_BLUE};
`;

// reco is all the recos a given client.
// allRecos is the entire recos found on db and is here to update the db.
const ManipulateReco = ({ meeting, isRecoOpen, lineId }) => {
                            
    const { user: { firstName, lastName, id } } = useSelector(state => state.auth);
    const { meetingId, professional, date } = meeting;
    const { show, line } = isRecoOpen;
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const getRecoByMeetingId = async () => {
            setLoading(true);
            const res = await getEntireDocument("recommendations", professional.id);
            if (res.type === "success") {
                // eslint-disable-next-line
                res.data[professional.id].map(meeting => {
                    meeting.meetingId === meetingId && setRelevantReco({
                        original: meeting.content,
                        edit: meeting.content
                    })
                });
            } else {
                isMounted && setRelevantReco({
                    original: '',
                    edit: ''
                });
            }
            setLoading(false);
        };
        show && getRecoByMeetingId();
        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line
    }, [show])

    const [ relevantReco, setRelevantReco ] = useState({
        original: '',
        edit: ''
    });

    const [ display, setDisplay ] = useState(false);
    const dispatch = useDispatch();

    const onSaveClick = async () => {
        const newRecoObject = {
            meetingId, 
            dayDate: date.day,
            clientId: id,
            name: `${firstName} ${lastName}`,
            content: relevantReco.edit
        };
        console.log(professional.id)
        // Checking if the requested professional has a document
        const profRes = await getEntireDocument("recommendations", professional.id);

        if (profRes.type === 'success') {
            // Data exists, lets update
            dispatch(updateRecommendations(professional.id, newRecoObject));
        } else {
            // Data does not exists, creating a new document for professional

            dispatch(postRecommendations(professional.id, newRecoObject));
        }
        setDisplay(false);
    };

    const onChange = event => {
        const value = event.target.value;
        value.length ? setDisplay(true) : setDisplay(false);
        setRelevantReco({
            ...relevantReco,
            edit: value
        });
    };

    return (

        <Collapse in={show && line === lineId ? true : false}>
            <Typography variant="h6" style={{ textAlign: 'right' }}>
                <HeaderWrapper>הכנס</HeaderWrapper> חוות דעת על השירות
            </Typography>
            <Box>
                {
                    loading ? <CircularProgress /> :
                    relevantReco.original 
                    ? 
                        <RecoTextField
                            value={relevantReco.original}
                        />
                     : 
                     compareDates(meeting.date.day)
                        ?
                            <RecoTextField 
                                value={relevantReco.edit}
                                onChange={onChange}
                                helperText="כתוב את המלצתך כאן..."
                                disabled={false}
                                display={display}
                                onSaveClick={onSaveClick}
                            />    
                        : 
                            <CustomAlert 
                                type="info"
                                msg="ניתן לכתוב חוות דעת רק לאחר המפגש"
                            /> 
                }
            </Box>
        </Collapse>
    );
};

export default ManipulateReco


