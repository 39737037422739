import React from 'react';
import styled from 'styled-components';
import { PURPLE } from '../../utils/globals';

import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const Bold = styled(Typography)`
    font-weight: 700;
    display: inline;
`;

const UnderlineBold = styled(Typography)`
    font-weight: 900;
    text-decoration: underline;
    text-align: center;
`;
const TitleWrapper = styled(Box)`
    margin: 20px;
`;

const Paragraph = styled(Box)`
    margin-top: 40px;
`;

const SmallDot = styled(FiberManualRecordIcon)`
    font-size: 12px;
    margin-right: 25px;
    color: ${PURPLE};
`;

const BulletListWrapper = styled(Box)`
    padding: 20px;
`;

const ListItem = styled(Box)`   
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const Terms = () => {

    return (

        <Box>

            <TitleWrapper>
                <UnderlineBold>OpenMynd - CONTENT TAKEDOWN POLICY</UnderlineBold>
            </TitleWrapper>

            <Paragraph>
                Welcome to <Bold>“OpenMynd”</Bold> – a real-time platform aimed to help users master tools to build and maintain positive and healthy relationships in their life (the <Bold>“Service”</Bold>). We at <Bold>OpenMynd Ltd. (“we”, “us”, “our”)</Bold> respects the intellectual property and privacy rights of others and requests that its users and partners to do the same.  
            </Paragraph>

            <Paragraph>
                <Bold>ALLEGED INFRINGEMENT NOTICE</Bold>
            </Paragraph>

            <Paragraph>
               If you believe that certain content, which appears on or through the Service, infringes rights that you own or represent, such as copyrights or privacy rights, you may send our designated agent (the "Agent") a written notification, stating the location of the content claimed to be infringing. Upon your notification and subject to copyright, privacy and other pertinent laws, we may remove or disable access to any such content. 
             </Paragraph>

            <Paragraph>
                To be effective, your notification of claimed infringement must be a written communication provided to our Agent that substantially includes the following: 
             </Paragraph>

            <BulletListWrapper>
                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        A physical or electronic signature of the person authorized to act on behalf of the owner of the right that is allegedly infringed;
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        Identification of the material and the pertinent exclusive legal right claimed to be infringed, or if multiple materials or legal rights are covered by a single notification, a representative list of such elements; 
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        Identification of the content that is claimed to infringe or to be the subject of infringing activity and the access to which is to be disabled, and information reasonably sufficient to permit us to locate the content, including the exact Service page in which you discovered the allegedly infringing content; 
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an electronic mail address at which you may be contacted; 
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        A statement that you have a good faith belief that the use of the material, in the manner complained of, is not authorized by the owner of the pertinent legal right, its agent, or the law; 
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of the pertinent exclusive legal right that is allegedly infringed.
                    </Paragraph>
                </ListItem>
            </BulletListWrapper>

            <Paragraph>
                After receiving your communication, we may ask you to provide further or supplemental information, prior to removing or disabling access to any content displayed on the Service, as we deem necessary to comply with the law. We may also provide the Service user who submitted the allegedly infringing content, with your contact details, in order for that person to be able to contact you and challenge your claim.
            </Paragraph>

            <Paragraph>
                <Bold>COUNTER NOTIFICATION</Bold>
            </Paragraph>

            <Paragraph>
                If we’ve removed or disabled access to content that you submitted, pursuant to a notification of claimed infringement that we received, then you have an opportunity to respond to the notice and takedown by submitting a counter-notification to our Agent. To be effective, your counter notification must be a written communication provided to our Agent that includes substantially the following:
            </Paragraph>

            <BulletListWrapper>
                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        Your physical or electronic signature;
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        Identification of the removed content, or of the content to which access has been disabled and the location at which the content appeared before its removal or before access to it was disabled; 
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        A statement, under penalty of perjury, that you have a good faith belief that the content was removed or disabled as a result of mistake or misidentification of the content;
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the competent courts in any judicial district in which your address is located or in which you may be found, and that you will accept service of process from the person who provided notification or an agent of such person. 
                    </Paragraph>
                </ListItem>
            </BulletListWrapper>

            <Paragraph>
                After receipt of a counter notification, we will provide the person who submitted the claimed infringement notification, with a copy of the counter notification.
            </Paragraph>

            <Paragraph>
                Subject to the applicable law, we may then replace the removed content and cease disabling access to it within 10 to 14 business days following receipt of the counter notice, unless our Agent first receives notice from the person who notified us of the claimed infringement that such person has filed an action seeking a court order to restrain the user from engaging in infringing activity relating to the content on the Service.
            </Paragraph>

            <Paragraph>
                <Bold>REPEAT INFRINGEMENTS</Bold>
            </Paragraph>

            <Paragraph>
                We may terminate your user account on the Service if we, at our sole discretion, determine that you are a repeat infringer. A repeat infringer is a user who has been notified of infringing activity more than once or a user whose submitted content was removed from the Service more than once. 
            </Paragraph>

            <Paragraph>
                We may decide, at our sole discretion, that a sufficient reason exists for the immediate termination of your account for any reason, at any time. In these cases we may terminate your account immediately. 
            </Paragraph>

            <Paragraph>
                <Bold>AGENT DETAILS</Bold>
            </Paragraph>

            <Paragraph>
                Our Agent is Mr. Sam Behar. Address: OpenMynd Ltd., 2380 Street, No. 8, Tel-Aviv, Israel; Tel.: +972- 52 549 0203; Email: <Bold>sam@jinitec.com</Bold>. 
            </Paragraph>

            <Paragraph>
                <Bold>MISREPRESENTATIONS</Bold>
            </Paragraph>

            <Paragraph>
                Any person who knowingly misrepresents that: (1) certain content is infringing, or (2) certain content was removed or disabled by mistake or misidentification – may be liable for any damages, including costs and attorneys’ fees, that we may incur, if we are injured by such misrepresentation, as the result of our reliance upon such misrepresentation in removing or disabling access to the content claimed to be infringing.
            </Paragraph>

            <Paragraph>
                <Bold>Last updated:July 31, 2020 [The date the Policy was first published on the app]</Bold>
            </Paragraph>

        </Box>

    );
};

export default Terms
    
