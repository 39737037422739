/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CalanderHeader from "../../../calander/calendarUtils/CalanderHeader";
import { TextField } from "@material-ui/core";
import { indexOf } from "lodash";
import {
  CATAGORIES_ARRAY,
} from "../../../../../utils/globals";
import ExpertiseSelect from "../ExpertiseSelect";
import ReactTooltip from "react-tooltip";

const Paragraph = styled.div`
  width: 79%;
  text-align: left;
  margin-top: -25px;
`;

const expertiesFields = [
  {
    label: "התמחות 1:",
    name: "firstExpertie",
    expLabel: "שנות ותק:",
  },
  {
    label: "התמחות 2:",
    name: "secondExpertie",
    expLabel: "שנות ותק:",
  },
  {
    label: "התמחות 3:",
    name: "thirdExpertie",
    expLabel: "שנות ותק:",
  },
];

const MainWrapper = styled.div`
  display: grid;
  justify-items: flex-start;
  direction: rtl;
`;

const FormWrapper = styled.div`
  display: grid;
  padding: 10px;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
`;

const LabelAndHeader = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
`;

const TextFieldWrapper = styled.div`
  margin: 0 0 0 0;
  width: 80%;
  /* display: flex; */
  justify-content: center;
  direction: rtl;
`;

const LargeTextBox = styled(TextField)`
  margin-right: 80px;
  @media (max-width: 450px) {
    margin-right: 0;
  }
`;

const Label = styled.p`
  margin: 0;
  padding: 0;
  width: 100px;
  text-align: right;
  font-family: "Rubik", Sens-Serif;
`;

const Description = () => {
  return (
    <CalanderHeader
      header="עיסוק והתמחויות"
      description="כאן עליך לפרט בקצרה, עד 80 תווים, על עיסוקך וההתמחויות שלך."
    />
  );
};

const Profession = ({ onChange, originalExperties }) => {
  return (
    <LabelAndHeader>
      <Label style={{ paddingRight: "20px" }}>עיסוק:</Label>
      <TextFieldWrapper>
        <TextField
          style={{ direction: "rtl", width: "200px" }}
          name="profession"
          onChange={(event) => onChange(event)}
          value={originalExperties["profession"]}
          error={!originalExperties["profession"] ? true : false}
        />
      </TextFieldWrapper>
    </LabelAndHeader>
  );
};

const Field = ({ onChange, fieldName, fieldValue, fieldLabel, expLabel }) => {
  const [length, setLength] = useState(fieldValue.description.length);
  //Value of category is held as an int, see array for the map
  const [exp, setExp] = useState(indexOf(CATAGORIES_ARRAY, fieldValue.header));
  const [disabled, setDisabled] = useState(false);

  const handleChangeFirst = (event) => {
    const value = event.target.value;
    //update local state)
    setExp(value);
    //update redux state
    onChange(event, fieldName);
  };

  const MAX_LENGTH = 80;

  const Text = styled.p`
  margin-right: 4px;
  padding: 0;
  font-family: "Ariel", Sens-Serif;
`;

  useEffect(() => {
    if (
      fieldValue.header === " " ||
      fieldValue.header === undefined ||
      fieldValue.header === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [fieldValue]);

  return (
    <>
      <FormWrapper>
        <LabelAndHeader>
          <Label>{fieldLabel}</Label>
          <ExpertiseSelect
            exp={exp}
            handleChangeFirst={handleChangeFirst}
            width={"200px"}
          />
        </LabelAndHeader>
        <LabelAndHeader>
          <Label>{expLabel}</Label>
          <a data-tip data-for={fieldName} >
            <TextField
              style={{ direction: "rtl" }}
              name={fieldName}
              onChange={(event) => onChange(event, "experienceYears")}
              י
              value={fieldValue["experienceYears"]}
              disabled={disabled}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {disabled ?
              <ReactTooltip
                id={fieldName}
                type="info"
                className="tooltip"
                scrollHide={false}
                place="top"
              >
                <Text style={{ margin: "0px", padding: "0px", width: "150px" }}>
                  נא לבחור התמחות
                </Text>
              </ReactTooltip>
              : null}
          </a>
        </LabelAndHeader>

        <TextFieldWrapper>
          <a data-tip data-for={fieldName}  >
            <LargeTextBox
              style={{ direction: "rtl" }}
              name={fieldName}
              disabled={disabled}
              onChange={(event) => {
                onChange(event, "description");
                setLength(event.target.value.length);
              }}
              value={fieldValue["description"]}
              error={!fieldValue ? true : false}
              inputProps={{ maxLength: MAX_LENGTH }}
              variant="filled"
              multiline
              rows={3}
              fullWidth
            />
          </a>
        </TextFieldWrapper>

        <Paragraph>
          {length}/{MAX_LENGTH}
        </Paragraph>
      </FormWrapper>
    </>
  );
};

const ExpertiseForm = ({ originalExperties, onChange }) => {
  return (
    <MainWrapper>
      <Description />
      <Profession onChange={onChange} originalExperties={originalExperties} />
      {expertiesFields.map((field) => (
        <Field
          key={field.label}
          fieldValue={originalExperties[field.name]}
          fieldName={field.name}
          expLabel={field.expLabel}
          fieldLabel={field.label}
          onChange={onChange}
        />
      ))}
    </MainWrapper>
  );
};

export default ExpertiseForm;
