import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {getMeetings} from '../../../actions/MeetingsActions';
import CustomAlert from '../../layout/CustomAlert';
import Toggler from '../Toggler';
import MeetingsHeader from './MeetingsHeader';
import MeetingsTable from './table/MeetingsTable';
import Grid from '@material-ui/core/Grid';
import {CircularProgress} from '@material-ui/core';
import SettingsBar from '../calander/SettingsBar';
import {FINANCIAL} from '../../../utils/globals';

const MainWrapper = styled(Grid)`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-gap: 10px;
  @media (min-width: 1024px) {
    width: 100%;
    min-width: 780px;
  }
`;

const StyledLoading = styled(CircularProgress)`
  margin: 32px;
`;

const Table = ({
                   meetingsLoading, newMeetings,
                   setMeetings, id, isProfessional, requestedTable
               }) => {
    return (
        <>
            {meetingsLoading ? <StyledLoading/> :
                !newMeetings ? null :
                    <MeetingsTable
                        meetingsState={newMeetings}
                        setMeetingsState={setMeetings}
                        id={id}
                        isProfessional={isProfessional}
                        requestedTable={requestedTable}
                    />
            }
        </>
    );
};

const Meetings = ({changePage, currentPage, requestedTable, onExit}) => {

    const dispatch = useDispatch();
    const {user: {id}, isProfessional} = useSelector(state => state.auth);
    const {meetings, alert, meetingsLoading} = useSelector(state => state.meetings);
    const [newMeetings, setNewMeetings] = useState(null);

    useEffect(() => {
        isProfessional ?
            dispatch(getMeetings("professionalMeetings", id)) :
            dispatch(getMeetings("userMeetings", id))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (Object.keys(meetings).length) {
            setMeetings('newMeetings', meetings);
        }
        ;
        // eslint-disable-next-line
    }, [meetings])

    // Manipulating the schedule state
    const setMeetings = (name, value) => {
        setNewMeetings({
            ...newMeetings,
            [name]: value
        });
    };

    return (
        <MainWrapper>
            <SettingsBar
                label={requestedTable === FINANCIAL ? "הכנסות" : "מפגשים"}
                onExit={onExit}
            />
            <Toggler
                currentPage={currentPage}
                changePage={changePage}
                isProfessional={isProfessional}
            />
            <MeetingsHeader
                isProfessional={isProfessional}
                requestedTable={requestedTable}
            />

            <Table
                meetingsLoading={meetingsLoading}
                newMeetings={newMeetings}
                setMeetings={setMeetings}
                id={id}
                isProfessional={isProfessional}
                requestedTable={requestedTable}
            />
            {
                alert.msg &&
                <CustomAlert
                    type={alert.type}
                    msg={alert.msg}
                />
            }
        </MainWrapper>
    );
};

export default Meetings
