import {
    GET_SCHEDULE,
    SET_SCHEDULE,
    UPDATE_SCHEDULE,
    SET_SCHEDULE_LOADING,
    SET_SCHEDULE_ERROR,
    CLEAR_SCHEDULE_ERROR,
    CLEAR_SCHEDULE_STATE,
    GET_All_SCHEDULE,
    SET_FETCHED_FLAG
} from "./types";
import {
    addNewDoucment,
    updateFieldInCollection,
    getEntireDocument} from './FirestoreActions';
import * as firebase from "firebase";
import moment from "moment";

// Getting the entire schedule of a professional
export const getSchedule = id => async dispatch => {

    dispatch(setScheduleLoading(true));
    try {
        const res = await getEntireDocument("schedule", id);
        if (res.type === 'success') {
            const data = Object.values(res.data)[0];
            dispatch({
                type: GET_SCHEDULE,
                payload: data
            });
        } else {
            dispatch({
                type: SET_SCHEDULE_ERROR,
                payload: {
                    type: res.type,
                    msg: res.message
                }
            });
        }
        dispatch(setScheduleLoading(false));

    } catch (error) {
        dispatch(setScheduleLoading(false));
        dispatch({
            type: SET_SCHEDULE_ERROR,
            payload: {
                type: 'error',
                msg: error
            }
        });
    }
    ;
};

// Getting the entire schedule
export const getAllProfessionalsSechdule = () => async dispatch => {

    dispatch(setScheduleLoading(true))

    try {

        const availableProfesionalsId = [];
        const today = moment().format('DD-MM-YYYY')

        //get entire schedule collection
        const res = await firebase.firestore().collection('schedule');
        const snapshot = await res.get();
        const entireCollection = snapshot.docs;


        entireCollection.forEach(doc => {
            //check for each professional if he has appointment today
            const profID = Object.keys(doc.data())
            const profSechdule = (doc.data()[profID]);
            if (profSechdule.length === 0) {
                //if there are no schedules at all
                return;
            } else {
                //check to see if theres a match between the dates
                profSechdule.forEach(index => {
                    if (Object.keys(index)[0] === today) {
                        //push the professional ID to the array
                        availableProfesionalsId.push(profID)
                    }
                })
               
            }
        })
        dispatch({
            type: GET_All_SCHEDULE,
            payload: availableProfesionalsId
        })
        dispatch(setScheduleLoading(false))
    } catch (error) {
        dispatch(setScheduleLoading(false))
        console.log("There was an error loading schedule " + error)
        return {
            type: SET_SCHEDULE_ERROR,
            payload: {
                msg: error,
                type: 'error'
            }
        }
    }


};



// Setting a new date
export const postSchedule = (id, schedule) => async dispatch => {

   

    dispatch(setScheduleLoading(true));

    try {
        const res = await addNewDoucment("schedule", id, {[id]: schedule});
        if (res.type === 'success') {
            
            dispatch({
                type: SET_SCHEDULE,
                payload: schedule
            });
        } else {
            dispatch(setError({
                type: res.type,
                msg: res.message
            }));
        }
        dispatch(setScheduleLoading(false));

    } catch (error) {
        dispatch(setScheduleLoading(false));
        dispatch({
            type: SET_SCHEDULE_ERROR,
            payload: {
                type: 'error',
                msg: error
            }
        });
    }
    ;
};

// Updating a schedule
export const updateSchedule = (id, schedule) => async dispatch => {


    dispatch(setScheduleLoading(true));
    try {
        const res = await updateFieldInCollection("schedule", id, id, schedule);
        console.log(res)
        dispatch(setScheduleLoading(false));

        dispatch({
            type: UPDATE_SCHEDULE,
            payload: schedule
        });

        dispatch(setError({
            type: res.type,
            msg: res.message
        }));

    } catch (error) {
        dispatch(setScheduleLoading(false));
        dispatch({
            type: SET_SCHEDULE_ERROR,
            payload: {
                type: 'error',
                msg: error.response.data
            }
        });
    }
    ;
};

export const clearSchedule = () => {
    return {
        type: CLEAR_SCHEDULE_STATE,
        payload: []
    };
};

export const setScheduleLoading = loadingState => {
    return {
        type: SET_SCHEDULE_LOADING,
        payload: loadingState
    };
};

export const setFetchedFlag = (bool)=>{
    return {
        type: SET_FETCHED_FLAG,
        payload: bool
    };
}

export const setError = error => {
    return {
        type: SET_SCHEDULE_ERROR,
        payload: error
    };
};

export const clearError = () => {
    return {
        type: CLEAR_SCHEDULE_ERROR
    };
};
