import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {closeDialog} from '../../actions/DialogActions';
import {OPEN_CONFIRMATION_DIALOG, RED,DARK_BLUE} from '../../utils/globals';
import Dialog from '@material-ui/core/Dialog';


const MainWrapper = styled.div`
    width: auto;
    height: auto;
    display: grid;
    align-items: center;
    justify-center: center;
    background-color: #F0F0F0;
    padding: 10px;
    direction: rtl;
`;

const Warning = styled.p`
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 16px;
    direction: rtl;
    font-family: 'Rubik', Sens-Serif;
    margin: 10px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const Option = styled.button`
    cursor: pointer;
    width: 70px;
    height: 35px;
    border-radius: 10%;
    font-size: 14px;
   
    background-color: ${props => props.type === "accept" ? DARK_BLUE : 'none'};
    border-color: ${props => props.type === "accept" ? 'none' : RED};
    color:${props => props.type === "accept" ? '#ffffff':'#000000'}
`;

const OptionButton = ({onClick, label, type = null}) => {
    return (
        <Option
            onClick={onClick}
            type={type}
            whileHover={{scale: 1.1}}
        >
            {label}
        </Option>
    );
};

const Popup = ({warningText, onConfirm, onAbort}) => {
    return (
        <MainWrapper>
            <Warning>{warningText}</Warning>
            <ButtonsWrapper>
                <OptionButton
                    onClick={onConfirm}
                    label="אישור"
                    type="accept"
                />
                {/* <OptionButton
                    onClick={onConfirm}
                    label="בטל"
                /> */}
            </ButtonsWrapper>
        </MainWrapper>
    );
};

const ConfirmationModal = () => {

    const {confirmationDialog} = useSelector(state => state.dialogs);

    const {exitFunction, warningText, show} = confirmationDialog;

    const dispatch = useDispatch();

    const onConfirm = () => {
        dispatch(closeDialog(OPEN_CONFIRMATION_DIALOG));
        exitFunction();
    };

    const onAbort = () => {
        dispatch(closeDialog(OPEN_CONFIRMATION_DIALOG));
    };

    return (
        <Dialog
            open={!!show}
        >
            <Popup
                onConfirm={onConfirm}
                onAbort={onAbort}
                warningText={warningText}
            />
        </Dialog>
    )
}

export default ConfirmationModal
