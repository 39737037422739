import { combineReducers } from 'redux';
import { dialogReducer } from './DialogReducer';
import { authReducer } from './AuthReducer';
import { professionalsReducer } from './ProfessionalsReducer';
import { recommendationsReducer } from './RecommendationReducer';
import { scheduleReducer } from './ScheduleReducer';
import { meetingsReducer } from './MeetingsReducer';
import { policiesReducer } from './PoliciesReducer';
import { messagesReducer } from './MessagesReducer';
import {tokensReducer} from "./TokenReducer";
import adminReducer from './adminReducer';


export default combineReducers({
    dialogs: dialogReducer,
    auth: authReducer,
    professionals: professionalsReducer,
    recommendations: recommendationsReducer,
    schedule: scheduleReducer,
    meetings: meetingsReducer,
    policies: policiesReducer,
    messages: messagesReducer,
    tokens:tokensReducer,
    admin:adminReducer
});