import React from 'react';
import styled from 'styled-components';
import CustomAlert from '../../../layout/CustomAlert';
import DateField from './DateField';
import HoursField from './HoursField';

const MainWrapper = styled.div`
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

const RenderDate = ({ day, setScheduleState, data, 
    setUIError, isMounted }) => {
        return (
            <>
            {
                day ? 
                    <DateField 
                        setScheduleState={setScheduleState}
                        data={data}
                        setUIError={setUIError}
                        isMounted={isMounted}
                    /> : 
                    <CustomAlert 
                        type='info'
                        msg='לחץ על יום בלוח השנה'
                    />
                }
        </>
    );
};

const RenderHours = ({ hours, setScheduleState, data, onSubmit}) => {

    return (
        <>
            {
              hours&&  hours.length ? 
                    hours.map((hour, index) => 
                        <HoursField 
                            setScheduleState={setScheduleState}
                            data={data}
                            startHour={hour.start}
                            endHour={hour.end}
                            key={index}
                            index={index}
                            isMeeting={hour.meetingDetails}
                            onSubmit={onSubmit}
                        /> 
                    ) : 
                null
            }
        </>
    );
};

const ModifyCalander = ({ scheduleState, setScheduleState,onSubmit }) => {
    const { data } = scheduleState;
    const { date } = data;
    const { day, hours } = date;
  



    return (

        <MainWrapper> 
            <RenderDate
                day={day}
                setScheduleState={setScheduleState}
                data={data}
            />
            <RenderHours 
                hours={hours}
                setScheduleState={setScheduleState}
                data={data}
                onSubmit={onSubmit}
            />
        </MainWrapper>

    )
}

export default ModifyCalander
