import * as firebase from "firebase/app";
import "firebase/firestore";
import {
  GET_PROFESSIONALS,
  SET_PROFESSIONALS_LOADING,
  UPDATE_PROFESSIONAL,
  GET_CURRENT,
  CLEAR_CURRENT,
  CLEAR_PRESENTED_PROFESSIONAL,
  SET_PROFESSIONALS_ERROR,
  CLEAR_PROFESSIONALS_ERROR,
  AUTH_ERROR,
  PRESENT_PROFESSIONAL,
  CLEAR_PROFESSIONALS,
} from "./types";
import {
  queryCollectionMultipleConditions,
  querySearch,
  updateDocument,
} from "./FirestoreActions";
import { openConfirmationDialog } from "./DialogActions";

// Function brings in all professionals of a given type
export const getProfessionals = (type) => async (dispatch) => {
  dispatch(setProfessionalsLoading(true));

  const uniqueMap = new Map()

  try {
    // List of objects where each object is a professional
    // filtering by type and pending status
    const res = await queryCollectionMultipleConditions("professionals", [
      ["expertise.firstExpertie.header", "==", type],
      ["pending.status", "==", false],
    ]);
    //Searching for SecondaryType equal to type
    const res2 = await queryCollectionMultipleConditions("professionals", [
      ["expertise.secondExpertie.header", "==", type],
      ["pending.status", "==", false],
    ]);
    const res3 = await queryCollectionMultipleConditions("professionals", [
      ["expertise.thirdExpertie.header", "==", type],
      ["pending.status", "==", false],
    ]);

    res.forEach((el) => {
      uniqueMap.set(el.id, true)
    })


    //Adding SecondaryType to the res array
    for (let i = 0; i < res2.length; i++) {
      if (!uniqueMap.has(res2[i].id)) {
        uniqueMap.set(res2[i].id, true)
        res.push(res2[i]);
      }
    }
    for (let i = 0; i < res3.length; i++) {
      if (!uniqueMap.has(res3[i].id)) {
        res.push(res3[i]);
      }
    }
    if (res.length) {
      dispatch({
        type: GET_PROFESSIONALS,
        payload: res,
      });
    }
    dispatch(setProfessionalsLoading(false));
  } catch (error) {
    dispatch(setProfessionalsLoading(false));
    // In case no response from backend
    console.error(`Error while trying to get professionals: ${error}`);
    return {
      type: SET_PROFESSIONALS_ERROR,
      payload: {
        msg: error,
        type: "error",
      },
    };
  }
};

// Function brings in all professionals of a given type
export const getOthers = (type) => async (dispatch) => {
  dispatch(setProfessionalsLoading(true));

  try {
    const res = await querySearch();
    if (res.length) {
      dispatch({
        type: GET_PROFESSIONALS,
        payload: res,
      });
    }
    dispatch(setProfessionalsLoading(false));
  } catch (error) {
    dispatch(setProfessionalsLoading(false));
    // In case no response from backend
    console.error(`Error while trying to get professionals: ${error}`);
    return {
      type: SET_PROFESSIONALS_ERROR,
      payload: {
        msg: error,
        type: "error",
      },
    };
  }
};

export const clearProfessional = () => {
  return {
    type: CLEAR_PROFESSIONALS,
    payload: "",
  };
};

// Function brings in all professionals
export const getAllProfessionals = () => async (dispatch) => {
  dispatch(setProfessionalsLoading(true));

  try {
    // List of objects where each object is a professional
    // filtering by type and pending status
    const res = await queryCollectionMultipleConditions("professionals", [
      ["pending.status", "==", false],
    ]);
    if (res.length) {
      dispatch({
        type: GET_PROFESSIONALS,
        payload: res,
      });
    }
    dispatch(setProfessionalsLoading(false));
  } catch (error) {
    dispatch(setProfessionalsLoading(false));
    // In case no response from backend
    console.error(`Error while trying to get professionals: ${error}`);
    return {
      type: SET_PROFESSIONALS_ERROR,
      payload: {
        msg: error,
        type: "error",
      },
    };
  }
};

// Function brings in all professionals
export const getPendingProfessionals = () => async (dispatch) => {
  dispatch(setProfessionalsLoading(true));

  try {
    // List of objects where each object is a professional
    // filtering by type and pending status
    const res = await queryCollectionMultipleConditions("professionals", [
      ["pending.status", "==", true],
    ]);
    if (res.length) {
      dispatch({
        type: GET_PROFESSIONALS,
        payload: res,
      });
    }
    dispatch(setProfessionalsLoading(false));
  } catch (error) {
    dispatch(setProfessionalsLoading(false));
    // In case no response from backend
    console.error(`Error while trying to get professionals: ${error}`);
    return {
      type: SET_PROFESSIONALS_ERROR,
      payload: {
        msg: error,
        type: "error",
      },
    };
  }
};

export const updateProfessionals = (id, form) => async (dispatch) => {
  dispatch(setProfessionalsLoading(true));
  try {
    const res = await updateDocument("professionals", id, form);
    if (res.type === "success") {
      dispatch(
        setError({
          type: "success",
          msg: "המשימה הושלמה בהצלחה! עכשיו נוכל להכין את עמוד המטפל שלך. במידה ויהיו לנו שאלות ניצור עימך קשר.",
        })
      );

      dispatch({
        type: UPDATE_PROFESSIONAL,
        payload: form,
      });
    } else {
      dispatch(
        setError({
          type: res.type,
          msg: res.message,
        })
      );
    }
    dispatch(setProfessionalsLoading(false));
  } catch (error) {
    dispatch(setProfessionalsLoading(false));
    console.error(`Error while trying to update professionals: ${error}`);
    return {
      type: SET_PROFESSIONALS_ERROR,
      payload: {
        msg: error.message,
        type: "error",
      },
    };
  }
};

export const updateProfessionalForm = (id, form, flag) => async (dispatch) => {
  dispatch(setProfessionalsLoading(true));
  try {
    await firebase.firestore().collection("professionals").doc(id).set(form);


    dispatch(setProfessionalsLoading(false));
    if (!flag) {
      const confirmationInformation = {
        warningText: "השינויים נשמרו בהצלחה!",
        exitFunction: () => { },
        show: true,
      };
      dispatch(openConfirmationDialog(confirmationInformation));
    }

    dispatch({
      type: UPDATE_PROFESSIONAL,
      payload: form,
    });
  } catch (error) {
    console.log("Error trying to set a document " + error);
    dispatch(setProfessionalsLoading(false));
    const confirmationInformation = {
      warningText: "לצערנו,הייתה תקלה והמידע לא נשמר! אנא נסו שוב",
      exitFunction: () => { },
      show: true,
    };
    dispatch(openConfirmationDialog(confirmationInformation));
    return {
      type: SET_PROFESSIONALS_ERROR,
      payload: {
        msg: error.message,
        type: "error",
      },
    };
  }
};

export const setProfessionalsLoading = (loadingState) => {
  return {
    type: SET_PROFESSIONALS_LOADING,
    payload: loadingState,
  };
};

export const getCurrent = (professionalId) => async (dispatch) => {
  try {
    const data = await firebase
      .firestore()
      .collection("professionals")
      .doc(professionalId)
      .get();
    const current = data.data();

    dispatch({
      type: GET_CURRENT,
      payload: current,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error.message,
    });
  }
};

export const presentCurrentProfessional =
  (professionalId) => async (dispatch) => {
    try {
      const data = await firebase
        .firestore()
        .collection("professionals")
        .doc(professionalId)
        .get();
      const current = data.data();

      dispatch({
        type: PRESENT_PROFESSIONAL,
        payload: current,
      });
    } catch (error) {
      dispatch({
        type: AUTH_ERROR,
        payload: error.message,
      });
    }
  };

export const clearCurrent = () => {
  return {
    type: CLEAR_CURRENT,
  };
};

export const clearCurrentPresented = () => {
  return {
    type: CLEAR_PRESENTED_PROFESSIONAL,
  };
};

export const setError = (error) => {
  return {
    type: SET_PROFESSIONALS_ERROR,
    payload: error,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_PROFESSIONALS_ERROR,
  };
};
