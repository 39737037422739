import {
    GET_MESSAGES,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
    SET_MESSAGES_ALERT,
    CLEAR_MESSAGES_ALERT,
    SET_MESSAGES_LOADING,
    UPDATE_SCREEN_TO_RENDER,
    UPDATE_UNREAD,
    UPDATE_INDEPENDENT, CLEAR_MESSAGES
} from "./types";
import { getEntireDocument, updateDocument, checkIfUserExists,
         removeObjectFromDocument, updateFieldInCollection } from "./FirestoreActions";
import { getUserByEmail } from './FunctionsActions';

// Getting the entire messages of a user
export const getMessages = userEmail => async dispatch => {

    dispatch(setMessagesLoading(true));

    try {  
        const res = await getEntireDocument("messages", userEmail);
        if (res.type === 'success') {
            dispatch({
                type: GET_MESSAGES,
                payload: res.data
            });
        } else {
            dispatch ({
                type: SET_MESSAGES_ALERT,
                payload: {
                    type: 'warning',
                    msg: 'לא מצאנו הודעות עבורך במאגר הנתונים שלנו'
                }
            });
        }
        dispatch(setMessagesLoading(false));

    } catch (error) {
        dispatch(setMessagesLoading(false));
        console.error("Error at get meetings: ", error);
        dispatch ({
            type: SET_MESSAGES_ALERT,
            payload: {
                type: 'error',
                msg: error
            }
        });
    };
};

export const postMessage = (email, message) => async dispatch => {

    dispatch(setMessagesLoading(true));

    try {  
        const isExists = await checkIfUserExists(email);
        if (isExists) {
            const user = await getUserByEmail(email);
            message.receiver = {
                email,
                id: user.data.id,
                firstName: user.data.firstName,
                lastName: user.data.lastName
            }
            const data = {
                [message.message.id]: message
            }
            const res = await updateDocument("messages", email, data);
            dispatch({
                type: SET_MESSAGES_ALERT,
                payload: {
                    type: res.type,
                    message: res.message
                }
            });
        } else {
            dispatch ({
                type: SET_MESSAGES_ALERT,
                payload: {
                    type: 'error',
                    message: "המייל שהזנת אינו קיים במערכת"
                }
            }); 
        }
        dispatch(setMessagesLoading(false));

    } catch (error) {
        dispatch(setMessagesLoading(false));
        dispatch ({
            type: SET_MESSAGES_ALERT,
            payload: {
                type: 'error',
                message: error.response.data
            }
        });
    };
};

export const updateMessage = (documentName, messageId, field, newValue) => async dispatch => {

    dispatch(setMessagesLoading(true));
    
    try { 
        await updateFieldInCollection("messages", documentName, `${messageId}.${field}`, newValue);
        dispatch(setMessagesLoading(false));
        
        dispatch({
            type: UPDATE_MESSAGE
        });

    } catch (error) {
        dispatch(setMessagesLoading(false));
        dispatch ({
            type: SET_MESSAGES_ALERT,
            payload: {
                type: 'error',
                msg: error
            }
        });
    };
};

// Updating the meetings object in a given date with a given data.
// Returning an updated full meetings object. 
export const deleteMessages = (email, messageId) => async dispatch => {

    dispatch(setMessagesLoading(true));
    
    try { 
        await removeObjectFromDocument("messages", email, messageId);
        dispatch(setMessagesLoading(false));
        
        dispatch({
            type: DELETE_MESSAGE
        });

    } catch (error) {
        dispatch(setMessagesLoading(false));
        dispatch ({
            type: SET_MESSAGES_ALERT,
            payload: {
                type: 'error',
                msg: error
            }
        });
    };
};

// // Updating the meetings object in a given date with a given data.
// // Returning an updated full meetings object. 
export const getUnReadMessages = (documentName) => async dispatch => {
    
    try { 
        const amount = await getEntireDocument("messages", documentName);
        const filtered = Object.keys(amount.data).filter(messageId => 
            amount.data[messageId].message.isOpened === false && messageId    
        )
        dispatch({
            type: UPDATE_UNREAD,
            payload: filtered.length
        });

    } catch (error) {
        dispatch ({
            type: SET_MESSAGES_ALERT,
            payload: {
                type: 'error',
                msg: error
            }
        });
    };
};

export const setIndepedent = independentState => {
    return {
        type: UPDATE_INDEPENDENT,
        payload: independentState
    };
};

export const clearMessages = () => {
    return {
        type: CLEAR_MESSAGES,
    };
};

export const setMessagesLoading = loadingState => {
    return {
        type: SET_MESSAGES_LOADING,
        payload: loadingState
    };
};

export const setAlert = alert => {
    return {
        type: SET_MESSAGES_ALERT,
        payload: alert
    };
};

export const clearAlert = () => {
    return {
        type: CLEAR_MESSAGES_ALERT
    };
};

export const updateScreenToRender = screen => {
    return {
        type: UPDATE_SCREEN_TO_RENDER,
        payload: screen
    };
};

