import {
    SET_AUTH_LOADING,
    USER_LOADED,
    PROFESSIONAL_LOADED,
    ADMIN_LOADED,
    AUTH_ERROR,
    LOGOUT,
    CLEAR_AUTH_ERROR,
    SET_AUTH_PROCESS, AUTH_SUCCESS, SET_AUTH_ERROR
} from '../actions/types';
import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { JINIZ_INBOX_WELCOME_MESSAGE, JINIZ_INBOX_WELCOME_MESSAGE_PROFESSIONAL } from '../utils/globals';
import { getMessageObject } from '../components/professionalForm/payment/paymentActions';
import { addProfessional, genericEmail } from './FunctionsActions';
import { addNewDoucment } from "./FirestoreActions";
import { getCurrent } from "./ProfessionalsActions";
import { clearMessages } from "./MessagesActions";
import { clearTokensState } from "./TokensActions";
import { publicDetailsForm } from '../components/professionalForm/formUtils';
import { objectExtractor } from '../utils/objectManipulation';


// Load user if token exists
export const loadUser = () => async dispatch => {
    try {
        dispatch(setAuthLoading(true));
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                if (user.displayName) {
                    const firstName = user.displayName.split(' ')[0];
                    const lastName = user.displayName.split(' ')[1];
                    const res = {
                        data: {
                            id: user.uid,
                            email: user.email,
                            firstName,
                            lastName,
                        },
                    }
                    user.getIdTokenResult().then(tokenResult => {
                        if (tokenResult.claims.admin) {
                            dispatch({
                                type: ADMIN_LOADED,
                                payload: res.data
                            });
                            return;
                        }
                        ;
                        if (tokenResult.claims.professional) {
                            dispatch({
                                type: PROFESSIONAL_LOADED,
                                payload: res.data
                            });
                            return;
                        } else {
                            dispatch({
                                type: USER_LOADED,
                                payload: res.data
                            });

                        }
                        ;
                    });
                }
            }
            dispatch(setAuthLoading(false));

        }, (e) => console.log(e));

    } catch (error) {
        dispatch(setAuthLoading(false));
        console.log(error.message);
        dispatch({
            type: AUTH_ERROR,
            payload: error.message
        })
    }
    ;
};

export const professionalRegister = form => async dispatch => {
    const { email, password, firstName, lastName, localPhone,
        internationalPhone } = form;
    dispatch(setAuthLoading(true));
    try {

        await firebase.auth().createUserWithEmailAndPassword(email, password);
        const userObject = firebase.auth().currentUser;
        await userObject.updateProfile({ displayName: `${firstName} ${lastName}` })
        const message = getMessageObject(email, firstName, lastName, JINIZ_INBOX_WELCOME_MESSAGE_PROFESSIONAL, "ברוכים הבאים!");
        const messagesRef = firebase.firestore().collection('messages').doc(email).set({ [message.message.id]: message });
        const usersRef = firebase.firestore().collection('users').doc(userObject.uid).set({
            email, firstName, lastName, localPhone, internationalPhone
        });
        const applicationToCrewRef = genericEmail(form, 'applicationToCrew');
        await Promise.all([messagesRef, usersRef, applicationToCrewRef]);
        dispatch(isAuthProcessDone(true));
        await addProfessional(email);
        if (userObject.uid) {
            const newProfData = objectExtractor(publicDetailsForm({}, form, userObject.uid))
            await addNewDoucment("professionals", userObject.uid, newProfData);
        }
        dispatch(logout()); //clears the state
        dispatch(setAuthLoading(false));
    } catch (error) {
        console.error("Error at register: ", error.message);
        dispatch(setAuthLoading(false));
        dispatch({
            type: AUTH_ERROR,
            payload: error.code ? errorMessagesHandler(error.code) : error.message
        });
    }



};

export const userRegister = formData => async dispatch => {
    const { email, password, firstName, lastName, ageGroup, city } = formData;
    dispatch(setAuthLoading(true));
    try {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
        const userObject = firebase.auth().currentUser;
        await userObject.updateProfile({ displayName: `${firstName} ${lastName}` })
        dispatch(isAuthProcessDone(true));
        const message = getMessageObject(email, firstName, lastName, JINIZ_INBOX_WELCOME_MESSAGE, "ברוכים הבאים!");
        //Talea await firebase.firestore().collection('messages').doc(email).set({ [message.message.id]: message });
        await firebase.firestore().collection('users').doc(userObject.uid).set({email, firstName, lastName, ageGroup, city});
        await firebase.firestore().collection('messages').doc(email).set({ [message.message.id]: message });

        await genericEmail({ email, firstName }, 'sendWelcomeEmail');
        await genericEmail({ email, firstName }, 'notifyAdminUserRegister');
        dispatch(logout());
        dispatch(setAuthLoading(false));
    } catch (error) {
        console.error("Error at register: ", error);
        dispatch(setAuthLoading(false));
        dispatch({
            type: AUTH_ERROR,
            payload: error.code ? errorMessagesHandler(error.code) : error.message
        });
    }
    ;
};

// Registering a user
export const login = formData => async dispatch => {
    const { email, password } = formData;
    dispatch(setAuthLoading(true));
    try {
        const res = await firebase.auth().signInWithEmailAndPassword(email, password);

        dispatch(getCurrent(res.user.uid)); //load pro data to redux
        dispatch(isAuthProcessDone(true));
        dispatch(setAuthLoading(false));


    } catch (error) {
        console.log("Error from login: ", error);
        dispatch(setAuthLoading(false));
        dispatch({
            type: AUTH_ERROR,
            payload: error.code ? errorMessagesHandler(error.code) : error.message
        });
    }
    ;
};

export const logout = () => async dispatch => {


    try {
        dispatch(setAuthLoading(true));
        await firebase.auth().signOut();
        dispatch({ //clear auth state
            type: LOGOUT
        });
        dispatch(clearMessages()) // clear messages state
        dispatch(clearTokensState()) //clears tokens state

        dispatch(setAuthLoading(false));

    } catch (error) {
        console.error(error.message);
    }
    ;
};

export const resetPassword = email => async dispatch => {
    dispatch(setAuthLoading(true));
    try {
        await firebase.auth().sendPasswordResetEmail(email);
        dispatch(setAuthLoading(false));
        dispatch({
            type: AUTH_SUCCESS,
            payload: 'הסיסמא אופסה בהצלחה,אנא בדוק את כתובת הדוא"ל שלך'
        });
    } catch (error) {
        dispatch(setAuthLoading(false));
        console.error(error.message);
        dispatch({
            type: AUTH_SUCCESS,
            payload: error.code ? errorMessagesHandler(error.code) : error.message
        });
    }
};

// export const changeEmail = email => async dispatch => {
//     try {
//         await firebase.auth().sendPasswordResetEmail(email);
//     } catch (error) {
//         console.error(error.message);
//         dispatch({
//             type: AUTH_ERROR,
//             payload: error.message
//         });
//     }
// };

// Function changes loading state
export const setAuthLoading = loadingState => {
    return {
        type: SET_AUTH_LOADING,
        payload: loadingState
    };
};

export const clearAuthError = () => {
    return {
        type: CLEAR_AUTH_ERROR
    };
};

export const isAuthProcessDone = payload => {
    return {
        type: SET_AUTH_PROCESS,
        payload,
    }
};

export const setAuthError = (payload) => {
    return {
        type: SET_AUTH_ERROR,
        payload
    }

}

const errorMessagesHandler = errorCode => {
    switch (errorCode) {

        case "auth/wrong-password":
            return ("סיסמה שגויה");

        case "auth/user-not-found":
            return ('המערכת לא זיהתה משתמש רשום עם כתובת הדוא"ל שהכנסת');

        case "auth/email-already-in-use":
            return ('כתובת הדוא"ל כבר נמצאת בשימוש');

        case "auth/too-many-requests":
            return ('יותר מדי נסיונות כושלים, אנא נסה שוב מאוחר יותר');

        case "auth/signed-in":
            return ('המערכת זיהתה משתמש מחובר')

        default:
            return null;
    }
};
