import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getProfessionals, presentCurrentProfessional } from '../../../../actions/ProfessionalsActions';
import CategoriesHeader from '../CategoriesHeader';
import Professionals from '../../professionals/Professionals';
import Spinner from '../../../layout/Spinner';
import {PSYCHOLOGY, OPEN_PROFESSIONAL_DIALOG, CATAGORIES_ARRAY} from '../../../../utils/globals';
import { openDialog } from '../../../../actions/DialogActions';
import { getCurrentRecommendations } from '../../../../actions/RecommendationsActions';



const DepressionAnxietyPage = ({ location }) => {

    useEffect(() => {
        dispatch(getProfessionals(CATAGORIES_ARRAY[3]));
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has("expert")) {
            const professionalId = searchParams.get("expert"); 
            onLearnMorePress(professionalId);
        }
        // eslint-disable-next-line
    }, [location.search])


    const { allProfessionals, professionalsLoading } = useSelector(state => state.professionals)
    const dispatch = useDispatch();
    
    const onLearnMorePress = professionalId => {
        dispatch(presentCurrentProfessional(professionalId));
        dispatch(getCurrentRecommendations(professionalId));
        dispatch(openDialog(OPEN_PROFESSIONAL_DIALOG));
    };


    
    return (
        
        <div>

            <CategoriesHeader
                icon={''}
                label="דיכאון וחרדה"
                discription={PSYCHOLOGY}
            />

            {professionalsLoading ? <Spinner /> : 
                <Professionals 
                    profObject={allProfessionals}
                />
            }
        </div>
    )
}

export default DepressionAnxietyPage
