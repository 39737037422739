import React from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { LEAD_BLUE } from '../../utils/globals';
import Loader from 'react-loader-spinner';

const SpinnerWrapper = styled(Box)`
    margin: 10px;
    padding: 10px
`;

const Spinner = () => {
    return (
        <SpinnerWrapper align='center'>
            <Loader
                type="Oval"
                color={LEAD_BLUE}
                height={50}
                width={50}
            />
        </SpinnerWrapper>
    );
};

export default Spinner;
