import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Review from './Review';
import PaymentBoiler from './PaymentBoiler';
import Confirmation from './Confirmation';
import styled from 'styled-components';
import {sendMeetingURLEmail} from "../../../actions/FunctionsActions";
import {decreaseMeetingToken} from "../../../actions/MeetingsActions";


import {postNewMeeting, postTokens} from "./paymentOptions/newMeeting/NewMeeting";
import {createEmailObject, removeChoosenDate} from "./paymentHelpers";
import {setTokensLoading} from "../../../actions/TokensActions";
import {updateSchedule} from "../../../actions/ScheduleActions";


const MainWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;


// const FetchUser = () => {
//     const {user} = useSelector(state => state.auth);
//     const {firstName, lastName, email, id} = user;
//     return {
//         userFirstName: firstName,
//         userLastName: lastName,
//         userEmail: email,
//         userId: id
//     }
// }


const RenderStep = ({
                        step,
                        day,
                        hour,
                        handleNext,
                        prevStep,
                        proFirstName,
                        proLastName,
                        price,
                        modifyAnExistingMeeting,
                        handleBack,
                        professionalId,
                        schedule,
                        proEmail,
                        meetingObject,
                        onDialogClose,
                        prefix,
                        type,
                        resetStepper,
                        handleDetectingChanges,
                        handleOnGoingPayment,
                        handleChangeState,
                        professionalURL,
                        meetingAmount,
                        userHasTokens
                    }) => {
    const dispatch = useDispatch();
    // const user = FetchUser();
    const {user} = useSelector(state => state.auth);
    const {firstName: clientFirstName, lastName: clientLastName, email: clientEmail, id: clientId} = user;
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);
    const {tokens} = useSelector(state => state.tokens);

    const setAppointment = async () => {
        setLoading(true);
        dispatch(setTokensLoading(true))
        try {
            //Post the new meeting to the user and the professional
            await postNewMeeting(professionalId, day, hour, clientId, clientFirstName,
                clientLastName, clientEmail, price, proFirstName,
                proLastName, proEmail, professionalURL, meetingAmount, dispatch);

            //Update the professional schedule
            const newSchedule = removeChoosenDate(schedule, day, hour);

            //Dispatch the new schedule
            dispatch(updateSchedule(professionalId, newSchedule));

            //MeetingAmount true means we buy 3 meeting
            //user don't have tokens
            if (meetingAmount && !userHasTokens) {
                //post tokens for the user
                postTokens(professionalId, clientId, dispatch).catch((e) => console.log(e))
            } else if (userHasTokens) {
                //Decrease user tokens
                const value = tokens[professionalId] - 1;
                await decreaseMeetingToken(professionalId, clientId, value, dispatch)
            }
            dispatch(setTokensLoading(true))

        } catch (error) {
            console.error("An error took place while trying to pay: ", error);
            setLoading(false);
            dispatch(setTokensLoading(true))
            setError({
                type: error.type,
                msg: error.msg,
                display: true
            });
        }
    };

    /**
     * Sending confirm email to the professional and the client emails with
     * the meeting details including professional link to the meeting using Skype.
     * @returns {Promise<void>}
     */
    const sendConfirmationEmails = async () => {
        const mail = await createEmailObject(professionalURL, day, hour, user, proEmail, proFirstName, proLastName, modifyAnExistingMeeting);
        await sendMeetingURLEmail(mail).catch((e) => console.log(e))
        return {result: ""}
    }

    switch (step) {
        case (1):
            return (
                userHasTokens ?
                    <Confirmation
                        onDialogClose={onDialogClose}
                        resetStepper={resetStepper}
                        proemail={proEmail}
                        proFirstName={proFirstName}
                        proLastName={proLastName}
                        day={day}
                        hour={hour}
                        modifyAnExistingMeeting={modifyAnExistingMeeting}
                        handleChangeState={handleChangeState}
                        sendConfirmationEmails={sendConfirmationEmails}
                        meetingObject={meetingObject}
                        userHasTokens={userHasTokens}
                        meetingAmount={meetingAmount}
                        professionalID={professionalId}
                        setAppointment={setAppointment}
                        prefix={prefix}
                    /> :
                    <Review
                        firstName={proFirstName}
                        lastName={proLastName}
                        price={price}
                        prefix={prefix}
                        type={type}
                        day={day}
                        hour={hour}
                        nextStep={handleNext}
                        handleBack={handleBack}
                        prevStep={prevStep}
                        onExit={onDialogClose}
                        modifyAnExistingMeeting={modifyAnExistingMeeting}
                        meetingAmount={meetingAmount}
                    />
            );
        case (2):
            return (
                <PaymentBoiler
                    handleNext={handleNext}
                    handleDetectingChanges={handleDetectingChanges}
                    handleOnGoingPayment={handleOnGoingPayment}
                    prevStep={prevStep}
                    handleBack={handleBack}
                    onExit={onDialogClose}
                    professionalId={professionalId}
                    schedule={schedule}
                    price={price}
                    proFirstName={proFirstName}
                    day={day}
                    hour={hour}
                    meetingObject={meetingObject}
                    proEmail={proEmail}
                    resetStepper={resetStepper}
                    type={type}
                    proLastName={proLastName}
                    handleChangeState={handleChangeState}
                    professionalURL={professionalURL}
                    meetingAmount={meetingAmount}
                    setAppointment={setAppointment}
                    sendConfirmationEmails={sendConfirmationEmails}
                />
            );
        case (3):
            return (
                <Confirmation
                    onDialogClose={onDialogClose}
                    resetStepper={resetStepper}
                    proemail={proEmail}
                    proFirstName={proFirstName}
                    proLastName={proLastName}
                    day={day}
                    hour={hour}
                    modifyAnExistingMeeting={modifyAnExistingMeeting}
                    handleChangeState={handleChangeState}
                    sendConfirmationEmails={sendConfirmationEmails}
                    meetingObject={meetingObject}
                    meetingAmount={meetingAmount}
                    prefix={prefix}

                />
            );
        default:
            return {};
    }
    ;
};


const RenderCancelStep = ({
                              step, handleNext, prevStep, id, schedule,
                              price, firstName, lastName, day, hour, email, modifyAnExistingMeeting,
                              meetingObject, cancelMeeting, onDialogClose,
                              resetStepper, handleDetectingChanges,
                              handleOnGoingPayment, handleChangeState
                          }) => {
    switch (step) {
        case (1):
            return (
                <PaymentBoiler
                    nextStep={handleNext}
                    prevStep={prevStep}
                    professionalId={id}
                    schedule={schedule}
                    price={price}
                    proFirstName={firstName}
                    proLastName={lastName}
                    day={day}
                    hour={hour}
                    proEmail={email}
                    modifyAnExistingMeeting={modifyAnExistingMeeting}
                    meetingObject={meetingObject}
                    cancelMeeting={cancelMeeting}
                    handleDetectingChanges={handleDetectingChanges}
                    handleOnGoingPayment={handleOnGoingPayment}
                    handleChangeState={handleChangeState}
                />
            );
        case (2):
            return (
                <Confirmation
                    onDialogClose={onDialogClose}
                    resetStepper={resetStepper}
                    modifyAnExistingMeeting={modifyAnExistingMeeting}
                    cancelMeeting={cancelMeeting}
                    handleChangeState={handleChangeState}

                />
            );
        default:
            return {};
    }
    ;
};

const RenderModifyStep = ({
                              step, day, hour, handleNext, prevStep, firstName,
                              lastName, price, modifyAnExistingMeeting, id, schedule, email,
                              meetingObject, onDialogClose, resetStepper, prefix, type,
                              handleDetectingChanges, handleOnGoingPayment, handleChangeState, oldMeetingObject
                          }) => {
    switch (step) {
        case (1):
            return (
                <PaymentBoiler
                    handleNext={handleNext}
                    prevStep={prevStep}
                    professionalId={id}
                    schedule={schedule}
                    price={price}
                    proFirstName={firstName}
                    proLastName={lastName}
                    day={day}
                    hour={hour}
                    proEmail={email}
                    prefix={prefix}
                    type={type}
                    modifyAnExistingMeeting={modifyAnExistingMeeting}
                    meetingObject={meetingObject}
                    handleDetectingChanges={handleDetectingChanges}
                    handleOnGoingPayment={handleOnGoingPayment}
                    handleChangeState={handleChangeState}
                    oldMeetingObject={oldMeetingObject}
                />
            );
        case (2):
            return (
                <Confirmation
                    onDialogClose={onDialogClose}
                    resetStepper={resetStepper}
                    modifyAnExistingMeeting={modifyAnExistingMeeting}
                    handleChangeState={handleChangeState}
                />
            );
        default:
            return {};
    }
    ;
};

const RelevantPaymentFlow = ({
                                 step,
                                 handleNext,
                                 prevStep,
                                 profID,
                                 schedule,
                                 price,
                                 firstName,
                                 lastName,
                                 day,
                                 hour,
                                 email,
                                 modifyAnExistingMeeting,
                                 meetingObject,
                                 cancelMeeting,
                                 onDialogClose,
                                 resetStepper,
                                 handleBack,
                                 handleDetectingChanges,
                                 handleOnGoingPayment,
                                 prefix,
                                 type,
                                 handleChangeState,
                                 googleAuth,
                                 professionalURL,
                                 meetingAmount,
                                 userHasTokens,
                                 oldMeetingObject
                             }) => {


    return (
        <>
            {
                modifyAnExistingMeeting ?
                    cancelMeeting ?
                        <RenderCancelStep
                            resetStepper={resetStepper}
                            onDialogClose={onDialogClose}
                            cancelMeeting={cancelMeeting}
                            meetingObject={meetingObject}
                            step={step}
                            handleNext={handleNext}
                            prevStep={prevStep}
                            id={profID}
                            schedule={schedule}
                            price={price}
                            firstName={firstName}
                            lastName={lastName}
                            day={day}
                            hour={hour}
                            email={email}
                            modifyAnExistingMeeting={modifyAnExistingMeeting}
                            handleDetectingChanges={handleDetectingChanges}
                            handleOnGoingPayment={handleOnGoingPayment}
                            handleChangeState={handleChangeState}
                        /> :
                        <RenderModifyStep
                            resetStepper={resetStepper}
                            onDialogClose={onDialogClose}
                            cancelMeeting={cancelMeeting}
                            meetingObject={meetingObject}
                            step={step}
                            handleNext={handleNext}
                            prevStep={prevStep}
                            id={profID}
                            schedule={schedule}
                            price={price}
                            firstName={firstName}
                            lastName={lastName}
                            day={day}
                            hour={hour}
                            email={email}
                            prefix={prefix}
                            type={type}
                            modifyAnExistingMeeting={modifyAnExistingMeeting}
                            handleDetectingChanges={handleDetectingChanges}
                            handleOnGoingPayment={handleOnGoingPayment}
                            handleChangeState={handleChangeState}
                            oldMeetingObject={oldMeetingObject}
                        />
                    : <RenderStep
                        resetStepper={resetStepper}
                        onDialogClose={onDialogClose}
                        cancelMeeting={cancelMeeting}
                        meetingObject={meetingObject}
                        step={step}
                        handleNext={handleNext}
                        prevStep={prevStep}
                        professionalId={profID}
                        schedule={schedule}
                        price={price}
                        proFirstName={firstName}
                        proLastName={lastName}
                        handleBack={handleBack}
                        day={day}
                        hour={hour}
                        proEmail={email}
                        prefix={prefix}
                        type={type}
                        handleChangeState={handleChangeState}
                        modifyAnExistingMeeting={modifyAnExistingMeeting}
                        handleDetectingChanges={handleDetectingChanges}
                        handleOnGoingPayment={handleOnGoingPayment}
                        googleAuth={googleAuth}
                        professionalURL={professionalURL}
                        meetingAmount={meetingAmount}
                        userHasTokens={userHasTokens}

                    />
            }
        </>
    )
}

export const Payment = ({
                            day,
                            hour,
                            prevStep,
                            currentProfessional,
                            onDialogClose,
                            modifyAnExistingMeeting,
                            meetingObject,
                            cancelMeeting,
                            handleDetectingChanges,
                            handleOnGoingPayment,
                            handleChangeState,
                            googleAuth,
                            meetingAmount,
                            userHasTokens,
                            oldMeetingObject
                        }) => {

    const {schedule} = useSelector(state => state.schedule);
    const [activeStep, setActiveStep] = useState(1);

    const {
        firstName, lastName, price, id: profId,
        email, prefix, type, professionalMeetingURL
    } = currentProfessional;

    
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const resetStepper = () => {
        setActiveStep(1);
    };


    return (
        <MainWrapper>
            <RelevantPaymentFlow
                resetStepper={resetStepper}
                onDialogClose={onDialogClose}
                cancelMeeting={cancelMeeting}
                meetingObject={meetingObject}
                step={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                prevStep={prevStep}
                profID={profId}
                schedule={schedule}
                price={price}
                firstName={firstName}
                lastName={lastName}
                day={day}
                hour={hour}
                email={email}
                prefix={prefix}
                type={type}
                modifyAnExistingMeeting={modifyAnExistingMeeting}
                handleDetectingChanges={handleDetectingChanges}
                handleOnGoingPayment={handleOnGoingPayment}
                handleChangeState={handleChangeState}
                googleAuth={googleAuth}
                professionalURL={professionalMeetingURL}
                meetingAmount={meetingAmount}
                userHasTokens={userHasTokens}
                oldMeetingObject={oldMeetingObject}

            />
        </MainWrapper>
    );
};

export default Payment;