import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import CustomAlert from "../../layout/CustomAlert";
import CalanderForm from "./CalanderForm";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import { motion } from "framer-motion";

import PaymentDialog from "../payment/PaymentScreen";

const calendarVariants = {
  initial: {
    x: "-100vh",
  },
  animate: {
    x: 0,
    transition: {
      x: { duration: 1 },
    },
  },
  exit: {
    x: "-100vh",
  },
};

const MainWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 10px;
  @media (max-width: 450px) {
    display: block;
    width: 270px;
    margin: 5px;
  }
`;
const CalendarInstance = ({ onDayClick, scheduleObject }) => {
  return (
    <Calendar
      locale="he"
      onClickDay={(day) => onDayClick(day)}
      tileClassName={({ date }) => {
        // Getting all days from object
        const allDaysObjects = scheduleObject.map(
          (dayObject) => Object.keys(dayObject)[0]
        );
        // Iterating over the days and mark them.
        if (
          allDaysObjects.find(
            (requestedDayToMark) =>
              new Date(date).getTime() > new Date().getTime() &&
              requestedDayToMark === moment(date).format("DD-MM-YYYY")
          )
        ) {
          return "highlightCell";
        } else {
          return "regularCell";
        }
      }}
    />
  );
};

const RenderCalendar = ({ loading, onDayClick, scheduleObject }) => {
  return (
    <motion.div variants={calendarVariants} initial="initial" animate="animate">
      {loading ? (
        <CircularProgress />
      ) : (
        <CalendarInstance
          onDayClick={onDayClick}
          scheduleObject={scheduleObject}
        />
      )}
    </motion.div>
  );
};

const RenderError = ({ loading, error }) => {
  return (
    <>
      {!loading && error.display && (
        <CustomAlert type={error.type} msg={error.msg} />
      )}
    </>
  );
};

const CalanderItem = ({
  scheduleObject,
  loading,
  day,
  hour,
  onChange,
  nextStep,
  prevStep,
  onDayChange,
  setForm,
  setHours,
  form,
  hours,
  resetCalanderForms,
  modifyAnExistingMeeting,
  handleDetectingChanges,
}) => {
  const [error, setError] = useState({});
  // const { professionalToPresent } = useSelector((state) => state.professionals);

  useEffect(() => {
    if (!scheduleObject.length) {
      setError({
        type: "info",
        msg: "למטפל הזה אין כרגע מועדים פנויים",
        display: true,
      });
    } else {
      setError({});
    }
    // eslint-disable-next-line
  }, [scheduleObject]);

  const getAllDates = () => {
    const allDaysOList = scheduleObject.map(
      (dayObject) => Object.keys(dayObject)[0]
    );
    return allDaysOList;
  };

  const getIfClickedDayIsRelevant = (requestedDayToCheck) => {
    const allDates = getAllDates();
    const today = new moment().format("DD-MM-YYYY");
    const requestedDate = new moment(requestedDayToCheck).format("DD-MM-YYYY");
    setError({});
    // Iterating over the days and check if the pressed day is relevant.
    for (let date = 0; date < allDates.length; date++) {
      // Checking if the choosen day hasen't passed yet.
      if (requestedDate >= today) {
        // Comparing pressed day with list of relevant dates.
        if (allDates[date] === requestedDate) {
          // If the requested day is relevant, returns true
          return true;
        }
      }
    }
    // If the pressed date does not match any date.
    resetCalanderForms();
  };

  const onDayClick = (requestedDayToMark) => {
    // Checking if the pressed day is a relevant working day.
    if (getIfClickedDayIsRelevant(requestedDayToMark)) {
      // Formatting the requested day to "20-07-2020" format.
      const formattedDate = moment(requestedDayToMark).format("DD-MM-YYYY");
      // Looping over the entire schedule object.
      for (
        let dateObject = 0;
        dateObject < scheduleObject.length;
        dateObject++
      ) {
        let givenDateObject = scheduleObject[dateObject];
        // Checking if the current looped item has a key equal to the date received ("20-07-2020").
        if (givenDateObject[formattedDate]) {
          // If it has, showing form and updating hours state.
          onDayChange(formattedDate);
          setForm(true);
          setHours(givenDateObject[formattedDate]);
          handleDetectingChanges && handleDetectingChanges(true);
        }
      }
    }
  };

  return (
    <MainWrapper>
      <PaymentDialog />
      <RenderError loading={loading} error={error} />
      <RenderCalendar
        onDayClick={onDayClick}
        scheduleObject={scheduleObject}
        loading={loading}
      />

      <>
        {form && (
          <CalanderForm
            hoursList={hours}
            day={day}
            hour={hour}
            onChange={onChange}
            nextStep={nextStep}
            prevStep={prevStep}
            modifyAnExistingMeeting={modifyAnExistingMeeting}
          />
        )}

        {/*      <PlaceOrderWrapper>
                <div style={{display: 'flex',justifyContent:'space-around'}}>
                      <sapn style={{marginTop:'3px'}}>
                          מעבר לתשלום
                      </sapn>
                    <ArrowBackIcon/>
                </div>

            </PlaceOrderWrapper>*/}
      </>
    </MainWrapper>
  );
};

export default CalanderItem;
