import React from 'react'
import styled from 'styled-components';
import SearchBar from './searchBar/SearchBar';
import FilterMenu from './FilterMenu';
import { LEAD_BLUE } from '../../../../utils/globals';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';

const MainWrapper = styled(Toolbar)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    font-family: 'Rubik', Sens-Serif;
    @media (max-width: 600px) {
        display: grid;
        justify-items: center;
        grid-gap: 10px;
        padding: 10px;
    }
`;

const TitleWrapper = styled(Typography)`
    font-size: 20px;
    font-family: 'Rubik', Sens-Serif;
    color: ${LEAD_BLUE};
`;

const MenuWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const Screen = ({ onSearchBar, open, setSearchBar, 
    label, menu, handleMenuClick,
    handleOptionClick, handleMenuClose }) => {
    return (
        <MainWrapper>
            <MenuWrapper>
                <TitleWrapper>
                    {label}
                </TitleWrapper>
                <FilterMenu 
                    menu={menu}
                    handleMenuClick={handleMenuClick}
                    handleOptionClick={handleOptionClick}
                    handleMenuClose={handleMenuClose}
                />
            </MenuWrapper>
            <SearchBar 
                onChange={onSearchBar}
                open={open}
                setSearchBar={setSearchBar}
            />
        </MainWrapper>
    )
};

const TableToolBar = ({ onSearchBar, open, setSearchBar, 
    label, menu, handleMenuClick, handleOptionClick, handleMenuClose }) => {
    return (
        <Screen 
            onSearchBar={onSearchBar}
            open={open}
            setSearchBar={setSearchBar} 
            label={label}
            menu={menu}
            handleMenuClick={handleMenuClick}
            handleOptionClick={handleOptionClick}
            handleMenuClose={handleMenuClose}
        />
    );
};

export default TableToolBar
