import React, {useState, useEffect} from 'react'
import DetailedTable from './DetailedTable';
import CollapseIcon from './CollapseIcon';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import moment from "moment";
import {INFO} from "../../../../utils/globals";

const ParentTable = ({
                         isProfessional, index, isOpen,
                         onIconClick, meeting, meetingsForDay
                     }) => {

        const meetingDate = moment(meeting[0].date.day).format("DD-MM-YYYY");
        return (
            <>
                {
                    isProfessional ?
                        <TableRow key={index}>

                            <CollapseIcon isOpen={isOpen} onIconClick={onIconClick}/>
                            <TableCell align="center">
                            </TableCell>
                            <TableCell align="center">{meetingDate}</TableCell>
                            <TableCell align="center">{meetingsForDay}</TableCell>
                        </TableRow> : null
                }
            </>
        );
    }
;

const TableContent = (
    {
        meetingsForDay, meeting, index,
        collapseState, setCollapseState, isProfessional, requestedTable
    }
    ) => {
        // State takes care of opening and closing the collapsed table
        const [isOpen, setIsOpen] = useState(false);
        

        useEffect(() => {
            collapseState && isOpen && onIconClick();
            setCollapseState(false);
            // eslint-disable-next-line
        }, [collapseState])

        const onIconClick = () => {
            setIsOpen(!isOpen);
        };

        return (

            <TableBody>
                {requestedTable === INFO ?
                    <ParentTable
                        isProfessional={isProfessional}
                        index={index}
                        onIconClick={onIconClick}
                        isOpen={isOpen}
                        meeting={meeting}
                        meetingsForDay={meetingsForDay}
                    /> :
                    null}
                <DetailedTable
                    isOpen={isProfessional ? isOpen : true}
                    meetingDay={meeting}
                    requestedTable={requestedTable}
                    isProfessional={isProfessional}
                />
            </TableBody>
        );
    }
;

export default TableContent
