import {
    GET_RECOMMENDATIONS,
    POST_RECOMMENDATIONS,
    CLEAR_RECOMMENDATIONS,
    SET_RECOMMENDATIONS_LOADING,
    SET_RECOMMENDATIONS_ALERT,
    CLEAR_RECOMMENDATIONS_ALERT,
} from '../actions/types';

const initalState = {
    recommendations: [],
    recommendationsLoading: false,
    alert: {}
};

export const recommendationsReducer = (state = initalState, action) => {

    switch (action.type) {

        case GET_RECOMMENDATIONS:
            return {
                ...state,
                recommendations: action.payload
            };

        case POST_RECOMMENDATIONS:
            return {
                ...state,
                recommendations: action.payload
            };

        case CLEAR_RECOMMENDATIONS:
            return {
                ...state,
                recommendations: []
            };

        case SET_RECOMMENDATIONS_LOADING:
            return {
                ...state,
                recommendationsLoading: action.payload
            };

        case SET_RECOMMENDATIONS_ALERT:
            return {
                ...state,
                alert: action.payload
            };

        case CLEAR_RECOMMENDATIONS_ALERT:
            return {
                ...state,
                alert: {}
            };

        default:
            return state;
    };
};
