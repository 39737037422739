import * as firebase from 'firebase/app';
import 'firebase/functions';

export const getUserByEmail = async email => {
    const functionRef = firebase.functions().httpsCallable('getUserByEmail');
    try {
        const res = await functionRef({email});
        if (res.data.email === email) {
            const firstName = res.data.displayName.split(' ')[0];
            const lastName = res.data.displayName.split(' ')[1];
            const user = {
                id: res.data.uid,
                firstName,
                lastName
            }
            return {
                type: 'success',
                data: user
            }
        }
        return {
            type: 'error',
            data: res.data.message
        }
    } catch (error) {
        return {
            type: 'error',
            data: error
        }
    }
};

export const addAdmin = async email => {
    const addAdminRole = firebase.functions().httpsCallable('addAdminlRole');
    try {
        const res = await addAdminRole({email});
        const alert = {
            type: res.data.type,
            message: res.data.message
        };
        return alert;
    } catch (error) {
        const alert = {
            type: error.data.type,
            message: error.data.message
        }
        return alert;
    }
};

export const addProfessional = async email => {
    const addProfessionalRole = firebase.functions().httpsCallable('addProfessionalRole');
    let alert;
    try {
        const res = await addProfessionalRole({email});
        if (res.data.id) {
            alert = {
                type: res.data.type,
                message: res.data.message,
                id: res.data.id
            };
        } else {
            alert = {
                type: res.data.type,
                message: res.data.message,
            };
        }
        ;
        return alert;
    } catch (error) {
        console.error("Error while trying to add professional: ", error);
        alert = {
            type: 'error',
            message: 'Could not Create Professional'
        }
        return alert;
    }
};

export const sendMeetingURLEmail = async form => {
    console.log("sending new meeting email has been called")
    const meetingRef = firebase.functions().httpsCallable('sendMeetingURLEmail');
    try {
        await meetingRef({form});
        return {
            type: 'success'
        }
    } catch (error) {
        const alert = {
            type: 'error',
            message: error
        }
        console.log(error)
        return alert;
    }
};

export const sendModifyURLEmail = async form => {
    console.log("sending modify meeting has been called")
    const meetingRef = firebase.functions().httpsCallable('sendModifyURLEmail');
    try {
        await meetingRef({form});
        return {
            type: 'success'
        }
    } catch (error) {
        const alert = {
            type: 'error',
            message: error
        }
        return alert;
    }
};
export const sendProModifyURLEmail = async form => {
    const meetingRef = firebase.functions().httpsCallable('sendProModifyURLEmail');
    try {
        await meetingRef({form});
        return {
            type: 'success'
        }
    } catch (error) {
        const alert = {
            type: 'error',
            message: error
        }
        return alert;
    }
};

export const sendCancelURLEmail = async form => {
    console.log("CANCEL emails")
    console.log(form)
    const meetingRef = firebase.functions().httpsCallable('sendCancelURLEmail');
    try {
        await meetingRef({form});
        return {
            type: 'success'
        }
    } catch (error) {
        console.log(`Email error: `, error);
        const alert = {
            type: 'error',
            message: error
        }
        return alert;
    }
};

export const sendSMS = async form => {
    const SMSRef = firebase.functions().httpsCallable('sendSMS');
    try {
        await SMSRef({form});
        return {
            type: 'success'
        }
    } catch (error) {
        const alert = {
            type: 'error',
            message: error
        }
        return alert;
    }
};

export const genericEmail = async (form, emailName) => {
    const emailRef = firebase.functions().httpsCallable(emailName);
    try {
        const res = await emailRef(form);
        return res.data;
    } catch (error) {
        console.log(`An error took place while trying to send ${emailName} email: ${error}`);
        const alert = {
            type: 'error',
            message: error
        }
        return alert;
    }
};