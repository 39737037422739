/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import styled from "styled-components";
import CalanderHeader from "../../../calander/calendarUtils/CalanderHeader";
import { Label, HeadLabel } from "../../Settings";
import { TextField } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import ReactTooltip from "react-tooltip";

const educationFields = [
    {
        name: "firstDegree",
        firstDegree: {
            title: "",
            establishment: "",
            year: "",
        },
        label: "תואר ראשון ב: ",
    },
    {
        name: "secondDegree",
        secondDegree: {
            title: "",
            establishment: "",
            year: "",
        },
        label: "תואר שני ב: ",
    },
    {
        name: "thirdDegree",
        thirdDegree: {
            title: "",
            establishment: "",
            tear: "",
        },
        label: "דוקטורט ב: ",
    },
];

const ordinationFields = [
    {
        name: "ordination",
        label: "תחום:",
        ordination: {
            title: "",
            year: "",
            establishment: "",
        },
    },
    {
        name: "secondOrdination",
        label: "תחום:",
        ordination: {
            title: "",
            year: "",
            establishment: "",
        },
    },
];

const licenseFields = [
    {
        name: "defaultLicense",
        label: "סוג:",
    },
    {
        name: "secondLicense",
        label: "סוג:",
    },
];

const MainWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: flex-start;
  direction: rtl;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GenericForm = ({ onChange, state, fields, stateName, labelName }) => {
    // Hook
    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });
        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    const [rowSize, setRowSize] = useState(4);
    const [secRowSize, setSecRowSize] = useState(4);
    const size = useWindowSize();

    /**
     * Useeffect to check the screen size in order the change the row width in the greed
     * bypass becuase grid xs={12} inside the modal didn't work properly
     */
    useEffect(() => {
        if (size.width > 950) {
            setRowSize(4);
            setSecRowSize(4);
        }
        return () => {
            setRowSize(12);
            setSecRowSize(6);
        };
    }, [size.width]);

    const [yearLabel, setyearLabel] = useState("שנת סיום:");

    useEffect(() => {
        const calYearLabel = () => {
            if (labelName === "רישיונות") {
                setyearLabel("שנה:");
            }
        };
        calYearLabel();
    }, [labelName]);

    const descriptions = {
        "רישיונות": "רשיונות מגופים ממשלתיים בלבד",
        "הסמכות": "הסמכות מגופים מורשים בלבד",
        "תארים": "נא להכניס את השם המלא של התואר"
    }

    return (
        <Grid
            container
            justify={"fcenter"}
            direction={"column"}
            style={{ width: "100%" }}
        >
            <Grid item>
            <HeadLabel>{labelName}</HeadLabel>
            </Grid>
            {fields.map((item) => {
                return (
                    <>
                        <Grid container justify="flex-start" alignItems="stretch">
                            <Grid item alignItems="center" xs={rowSize}>
                                <FieldWrapper>
                                    <Label style={{ minWidth: "120px", textAlign: "right" }}>
                                        {item.label}
                                    </Label>
                                    <span data-tip data-for={labelName}>
                                        <TextField
                                            name={"title"}
                                            value={state[item.name].title}
                                            style={{ width: "100%" }}
                                            onChange={(event) => onChange(event, stateName, item.name)}
                                        />
                                    </span>
                                    <ReactTooltip
                                        style={{ margin: "0px", padding: "0px" }}
                                        id={labelName}
                                        type="info"
                                        className="tooltip"
                                        scrollHide={false}
                                        place="right"
                                    >

                                        {descriptions[labelName]}

                                    </ReactTooltip>
                                </FieldWrapper>
                            </Grid>
                            <Grid item xs={secRowSize}>
                                <FieldWrapper>
                                    <Label>מוסד:</Label>
                                    <TextField
                                        name={"establishment"}
                                        value={state[item.name].establishment}
                                        onChange={(event) => onChange(event, stateName, item.name)}
                                        style={{ width: "60%" }}
                                    />
                                </FieldWrapper>
                            </Grid>
                            <Grid item xs={secRowSize}>
                                <FieldWrapper>
                                    <Label>{yearLabel}</Label>
                                    <TextField
                                        name={"year"}
                                        value={state[item.name].year}
                                        onChange={(event) => onChange(event, stateName, item.name)}
                                        style={{ width: "25%" }}
                                    />
                                </FieldWrapper>
                            </Grid>
                        </Grid>
                    </>
                );
            })}
        </Grid>
    );
};



const Description = () => {
    return (
        <CalanderHeader
            header="תארים הסמכות ורישיונות"
            description="כאן עליך לפרט תארים, הסמכות ורישיונות שקיבלת."
        />
    );
};

const Forms = ({ onChange, educationState, licenseState }) => {
    return (
        <>
            <div style={{ display: "grid" }}>
                <GenericForm
                    onChange={onChange}
                    state={educationState}
                    fields={educationFields}
                    stateName={"educationState"}
                    labelName={"תארים"}
                />
                <GenericForm
                    onChange={onChange}
                    state={licenseState}
                    fields={ordinationFields}
                    stateName={"licenseState"}
                    labelName={"הסמכות"}
                />
                <GenericForm
                    onChange={onChange}
                    state={licenseState}
                    fields={licenseFields}
                    stateName={"licenseState"}
                    labelName={"רישיונות"}
                />
            </div>
        </>
    );
};
const AcademicForm = ({
    onChange,
    educationState,
    licenseState,
    trainingState,
}) => {
    return (
        <MainWrapper>
            <Description />
            <Forms
                onChange={onChange}
                educationState={educationState}
                licenseState={licenseState}
                trainingState={trainingState}
            />
        </MainWrapper>
    );
};

export default AcademicForm;
