// Dialog names
export const OPEN_AUTH_DIALOG = "authDialog";
export const OPEN_PROFESSIONAL_DIALOG = "professionalDialog";
export const OPEN_ADMIN_DIALOG = "adminEditDialog";
export const OPEN_VIDEO_DIALOG = "videoDialog";
export const OPEN_PROFESSIONAL_ZONE_DIALOG = "pZoneDialog";
export const OPEN_RESET_DIALOG = "resetDialog";
export const OPEN_POLICIES_DIALOG = "policiesDialog";
export const OPEN_QUIZ_DIALOG = "quizDialog";
export const OPEN_MESSAGES_DIALOG = "messagesDialog";
export const OPEN_OPTIONS_DIALOG = "optionsDialog";
export const OPEN_CONFIRMATION_DIALOG = "confirmationDialog";

// Professionals groups names
export const MYSTICANS = "Mystic";
export const MENTORS = "Mentor";
export const ALTERNATIVES = "Alternative";
export const PSYCHOLOGY = "Psychologist";
export const AVAILABLE_TODAY = "AvailableToday";
export const PSYCHIATRIST = "Psychiatrist";
//New Categories
export const FAMILY = "Family&Relationships";
export const KIDSANDYOUTH = "Kids&Youth";
export const DEPRESSIONANXIETY = "Depression&Anxiety";
export const EATINGDISORDERS = "EatingDisorders";
export const WORKCAREER = "Work&Career";
export const GERIATRICS = "Geriatrics";
export const SEXUAL_ABUSE = "Others";
export const ADDICTIONS = "Others";
export const OTHER = "Others";
//Categories Array
export const CATAGORIES_ARRAY = [
  " ",
  "משפחה וזוגיות",
  "ילדים ונוער",
  "דיכאון וחרדה",
  "הפרעות אכילה",
  "עבודה וקריירה",
  "פגיעות מיניות",
  "התמכרויות",
  "פסיכו-גריאטריה",
  "מטפלים נוספים",
];

// General variables
export const TAX = 0.66;
export const JINIZ_DISCOUNT = "25%";
export const COMPUTE_JINIZ_DISCOUNT = 0.75;
export const LANDING_PAGE_PHONE = "052-549 0203";
export const VIDEO_LINK =
  "https://firebasestorage.googleapis.com/v0/b/jiniz-5fc71.appspot.com/o/admin%2FJINIZ%20-%20%D7%94%D7%A6%D7%98%D7%A8%D7%A4%D7%95%20%D7%9C.mp4?alt=media&token=99e8863c-1cd4-4031-93c7-c4ffa3372d84";

// Colors
export const RED = "#ff6f61";
export const PURPLE = "#3f51b5";
export const YELLOW = "#EFC050";
export const DARK_BLUE = "rgb(30, 55, 100)";
export const HOME_BACKGROUND = "rgb(0, 75, 120)";
export const RecoBackground = "rgb(230, 243, 255)";
export const BlueGraident =
  "linear-gradient(90deg, rgba(37,131,203,1) 0%, rgba(14,66,124,1) 100%)";
export const LEAD_BLUE = "rgb(0, 107, 179)";

// Auth options
export const REGISTER_BOILER = "register";
export const PRO_REGISTER = "pro_registeration";
export const USER_REGISTER = "user_registeration";
export const RESET_PASSWORD = "reset-password";
export const LOGIN = "login";

// Private Zone Options
export const SETTINGS = "SETTINGS";
export const CALANDER = "CALENDAR";
export const INFO = "INFO";
export const FINANCIAL = "FINANCIAL";
export const HOME = "HOME";
export const MESSAGES = "MESSAGES";
export const NEW = "NEW";
export const MODIFY = "MODIFY";
export const CANCEL = "CANCEL";
export const DELETE = "DELETE";
export const INBOX = "INBOX";
export const WRITE = "WRITE";
export const VIDEO_BOX = "VIDEO_BOX";

// Policies Names
export const PRIVACY = "PRIVACY";
export const TERMS = "TERMS";
export const CONTENT = "CONTENT";

// Available meeting hours
export const HOURS = [
  "06:00",
  "06:30",
  "07:00",

  "07:30",

  "08:00",

  "08:30",

  "09:00",

  "09:30",

  "10:00",

  "10:30",

  "11:00",

  "11:30",

  "12:00",

  "12:30",

  "13:00",

  "13:30",

  "14:00",

  "14:30",

  "15:00",

  "15:30",

  "16:00",

  "16:30",

  "17:00",

  "17:30",

  "18:00",

  "18:30",

  "19:00",

  "19:30",

  "20:00",

  "20:30",

  "21:00",

  "21:30",

  "22:00",

  "22:30",

  "23:00",

  "23:30",

  "00:00",
];

// Welcome jiniz Platform message
export const JINIZ_INBOX_WELCOME_MESSAGE =
"שלום!\n" +
"תודה שנרשמת לאתר OpenMynd. המטפלים שלנו מגיעים מתחומים שונים ומגוונים ונמצאים כאן כדי להקשיב לך ולתת לך כלים להעצמה עצמית ובניה של יחסים טובים וזוגיות בריאה. השירות ניתן באמצעות שיחות וידאו (video chats) אישיות ודיסקרטיות, בזמן שמתאים לך ועל הכורסה שנוחה לך, דרך המחשב או הטלפון החכם .מומלץ להכנס לעמוד שאלות נפוצות באתר ולקרוא את ההנחיות לפני המפגש הראשון שלך." +
"\nבברכה," +
"\nצוות OpenMynd";
export const JINIZ_INBOX_WELCOME_MESSAGE_PROFESSIONAL =
  "\n" +
  "אנחנו שמחים על הצטרפותך לצוות המטפלים של OpenMynd.\n" +
  "\n" +
  'בכניסה הבאה לאתר יופיע עיגול כחול למעלה בצד שמאל עם האות הראשונה של שמך. לחיצה עליו תוביל אותך לאזור האישי שלך. תחת "פרפיל" עליך למלא את הנתונים על מנת שנוכל להפיק את עמוד המטפל שלך.\n' +
  "\n" +
  "מומלץ לקרוא את דף ההנחיות ודף האזור האישי המצורפים לפני שמתחילים. אנחנו כאן כדי לענות על כל שאלה.\n" +
  "\n" +
  "בהצלחה,\n" +
  "צוות OpenMynd.";

//Forms globals
export const LEGAL_FORM =
  "https://firebasestorage.googleapis.com/v0/b/jiniz-5fc71.appspot.com/o/admin%2FOpenMynd_%D7%AA%D7%A0%D7%90%D7%99_%D7%94%D7%A9%D7%99%D7%A8%D7%95%D7%AA.pdf?alt=media&token=228180c2-8603-481f-ba02-ffa1ee5f95d0";

export const migrateLabel = {
  profession: "occupation",
  establishment: "institute",
  lastName: "last_name",
  firstName: "lirst_name",
  professionalMeetingURL: "Therapist_room_URL",
  Expertise: "expertise",
  experienceYears: "years of experience",
  stringName: "URL",
  title: "degree",
  defaultExperience: "professional experience",
  normalPrice: "price_per_session",
  priceOverWrite: "price_overwrite",
  overWriteMsg: "overwrite reason",
};

export const prefixArray = ["מר", "גב'", "ד''ר", "פרופ'"];
