import React from 'react';
import {Link} from 'react-router-dom';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {SETTINGS, CALANDER, INFO, MESSAGES, RED} from '../../utils/globals';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {routeQuery} from '../../utils/routes';
import {Box} from "@material-ui/core";


const iconStyle = {
    width: '32px',
    height: '32px',
}

const ToggleGroup = styled(ToggleButtonGroup)`
  direction: ltr;
  @media (max-width: 450px) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    direction: rtl;
    display: grid;
    align-items: center;
  }
`;

const ToggleWrapper = styled.div`
  display: grid;
  padding: 8px;
  grid-gap: 5px;
  align-items: center;
  justify-items: center;
  @media (max-width: 450px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: gray;
  display: flex;
  align-items: center;
`;

const ToggleText = styled.p`
  margin-right: 5px;
  padding: 0px;
  direction: rtl;
  font-family: 'Rubik', Sens-Serif,sans-serif;
  font-size: 14px;
`;


const Notification = styled(Box)`

  position: absolute;
  
  left: 10px;
  bottom: 25px;
  z-index: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 25px;
  font-size: 12px;
  color: white;
  background-color: ${RED};
  font-family: 'Arial', serif;

`;

export const Option = ({route, value, icon, onClick, text, isLegalSigned,showNotification,notificationValue}) => {

    const routeTo = () => {
        if (isLegalSigned === false) {
            return ''
        } else return route;
    }
    const selected = () => {
        return !(isLegalSigned === undefined || isLegalSigned === true);
    }

    return (
        <LinkWrapper to={routeQuery(routeTo())}>
            <ToggleWrapper>
                <ToggleButton value={value}
                              selected={selected()}>
                    <img
                        src={icon}
                        alt="icon"
                        style={iconStyle}
                        onClick={onClick}
                    />
                  {showNotification ?   <Notification>{notificationValue}</Notification> :'' }
                </ToggleButton>
                <ToggleText>
                    {text}
                </ToggleText>
            </ToggleWrapper>
        </LinkWrapper>
    );
};

const UsersToggler = ({dispatch, goBack,unreadMessages}) => {
    const showMessages = (unreadMessages) =>{
        return unreadMessages > 0
    }

    return (
        <>
            <Option
                route={{privatePage: 'settings'}}
                value={SETTINGS}
                icon="https://img.icons8.com/cotton/64/000000/settings.png"
                text="פרופיל"
                onClick={goBack}
              
            />

            <Option
                route={{privatePage: 'info'}}
                value={INFO}
                text=" מפגשים"
                icon="https://img.icons8.com/wired/64/000000/hand-with-pen.png"
            />
            <Option
                route={{box: 'messages-home'}}
                value={MESSAGES}
                icon="https://img.icons8.com/wired/64/000000/email.png"
                text="הודעות"
                showNotification={showMessages(unreadMessages)}
                notificationValue={unreadMessages}
            />

            {/*{unreadMessages > 0 && <Notification>*/}
            {/*        <span style={spanStyle}>*/}
            {/*            {unreadMessages}*/}
            {/*        </span>*/}

            {/*</Notification>}*/}

        </>
    )
};

const ProfessionalsToggler = ({dispatch, goBack, isLegalSigned,unreadMessages}) => {

   
    const showMessages = (unreadMessages) =>{
        return unreadMessages > 0
    }

  

    return (
        <>
            <Option
                route={{privatePage: 'settings'}}
                value={SETTINGS}
                icon="https://img.icons8.com/cotton/64/000000/settings.png"
                text="פרופיל"
                onClick={goBack}
            />
            <Option
                route={{privatePage: 'calendar'}}
                value={CALANDER}
                icon="https://img.icons8.com/ios/50/000000/overtime.png"
                text="יומן"
                isLegalSigned={isLegalSigned}
            />
            <Option
                route={{privatePage: 'info'}}
                value={INFO}
                text="מפגשים"
                icon="https://img.icons8.com/wired/64/000000/hand-with-pen.png"
                isLegalSigned={isLegalSigned}
            />
            <Option
                route={{box: 'messages-home'}}
                value={MESSAGES}
                icon="https://img.icons8.com/wired/64/000000/email.png"
                text="הודעות"
                showNotification={showMessages(unreadMessages)}
                notificationValue={unreadMessages}
            />
            <Option
                route={{privatePage: 'financial'}}
                value={INFO}
                icon="https://img.icons8.com/wired/64/000000/money.png"
                text="הכנסות"
                isLegalSigned={isLegalSigned}
            />
        </>
    )
};


const Toggler = ({changePage, currentPage, isProfessional, goBack}) => {

    const dispatch = useDispatch();
    const {currentProfessional} = useSelector(state => state.professionals)
    const {unreadMessages} = useSelector(state => state.messages);

   

   
    return (
        <>
            <ToggleGroup
                value={currentPage}
                exclusive
                onChange={changePage}
            >
                {isProfessional ?
                    <ProfessionalsToggler
                        dispatch={dispatch}
                        goBack={goBack}
                        isLegalSigned={!currentProfessional ? '' : currentProfessional.isLegalDocConfirmed}
                        unreadMessages={unreadMessages}
                    /> :
                    <UsersToggler
                        dispatch={dispatch}
                        goBack={goBack}
                        unreadMessages={unreadMessages}
                    />
                }
            </ToggleGroup>
            {isProfessional &&!currentProfessional.isLegalDocConfirmed? 'אנא מלאו את תנאי השירות בכדי להמשיך':''}
        </>
    );
}

export default Toggler
