import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import {Fab} from '@material-ui/core';
import {LEAD_BLUE} from '../../../utils/globals';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';


const Toolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

const Label = styled.p`
    font-family: 'Arial',Sens-Serif,sans-serif;
    font-size: 20px;
    padding-right: 10px!important;
    margin: 0;
`;

const SettingsBar = ({label, onExit, onBackButton}) => {



    return (
        <AppBar position="static" style={{direction: 'rtl', backgroundColor: LEAD_BLUE}}>
            <Toolbar>
                <Label>{label}</Label>
                {label !== 'סיכום' ?
                    <Fab size="small" onClick={onExit} style={{color: LEAD_BLUE}}>
                        <ArrowBackIcon/>
                    </Fab>
                    :
                    <Fab size="small" onClick={onBackButton} style={{color: LEAD_BLUE}}>
                        <CloseIcon/>
                    </Fab>
                }
            </Toolbar>
        </AppBar>
    )
}

export default SettingsBar
