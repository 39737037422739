import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {closeDialog, openConfirmationDialog, openDialog} from '../../actions/DialogActions';
import {
    OPEN_AUTH_DIALOG, PRO_REGISTER, LOGIN, REGISTER_BOILER,
    USER_REGISTER, RESET_PASSWORD
} from '../../utils/globals';
import Dialog from '@material-ui/core/Dialog';
import {withRouter} from 'react-router-dom';
import {routeQuery} from '../../utils/routes';
import LoginForm from '../pages/authForms/login/LoginForm';
import {
    clearAuthError, isAuthProcessDone, login, professionalRegister,
    userRegister, resetPassword, setAuthError
} from '../../actions/AuthActions';
import RegisterBoiler from '../pages/authForms/register/RegisterBoiler';
import UserRegistration from '../pages/authForms/register/UserRegistration';
import ProRegister from '../pages/authForms/register/ProRegister';
import ResetPassword from '../pages/authForms/login/ResetPassword';

const MainWrapper = styled.div`
  background-color: #F0F0F0;
  direction: rtl;
  min-width: 400px;
  @media (max-width: 578px) {
    min-width: 300px;
  }
`;

const RenderStep = ({
                        form, onLogin, onUserRegister,
                        onProRegister, onResetPassword
                    }) => {
    switch (form) {
        case (REGISTER_BOILER):
            return (
                <RegisterBoiler/>
            )
        case (USER_REGISTER):
            return (
                <UserRegistration
                    onUserRegister={onUserRegister}
                />
            )
        case (PRO_REGISTER):
            return (
                <ProRegister
                    onProRegister={onProRegister}
                />
            )
        case (LOGIN):
            return (
                <LoginForm
                    onLogin={onLogin}
                />
            );
        case (RESET_PASSWORD):
            return (
                <ResetPassword
                    onResetPassword={onResetPassword}
                />
            );
        default:
            return {};
    }

};

const AuthModal = ({history}) => {

    const {authDialog} = useSelector(state => state.dialogs);
    const {isAuthenticated, authProcessDone} = useSelector(state => state.auth);
    const [form, setForm] = useState(null);
    const dispatch = useDispatch();

   
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
       
        const formToRender = urlParams.get('box');
        if (
            formToRender === LOGIN ||
            formToRender === PRO_REGISTER ||
            formToRender === USER_REGISTER ||
            formToRender === REGISTER_BOILER ||
            formToRender === RESET_PASSWORD
        ) {
            setForm(formToRender);
            !authDialog && dispatch(openDialog(OPEN_AUTH_DIALOG));
        }

        // eslint-disable-next-line
    }, [window.location.search])

    const exitRoutine = useCallback(() => {
        if (form === USER_REGISTER || form === PRO_REGISTER) {
            if (authProcessDone) {
                history.replace(routeQuery({box: LOGIN}));
                return;
            } else {
                setForm(null);
                history.replace(routeQuery({box: undefined}));
            }
        } else {
            setForm(null);
            history.replace(routeQuery({box: undefined}));
        }


        dispatch(closeDialog(OPEN_AUTH_DIALOG));

    },[authProcessDone, dispatch, form, history]);

    useEffect(() => {
        if (authProcessDone) {
            exitRoutine();
            dispatch(isAuthProcessDone(false));

        }
    }, [authProcessDone, dispatch, exitRoutine])


    const onLogin = form => {

        dispatch(clearAuthError());
        if (!isAuthenticated) {
            dispatch(login(form));
        }
    };

    const onUserRegister = async form => {
        dispatch(clearAuthError());
        if (!isAuthenticated) {
            dispatch(userRegister(form));
        }
    };

    const onProRegister = async form => {
        dispatch(clearAuthError());
        if (!isAuthenticated) {
            form = {
                ...form,
                internationalPhone: form.localPhone.replace(form.localPhone[0], '+972'),
            };
            dispatch(professionalRegister(form));
        } else {
            dispatch(setAuthError("המערכת זיהתה משתמש מחובר"
            ))
        }

    };

    const onResetPassword = async email => {
        dispatch(clearAuthError());
        if (!isAuthenticated) {
            dispatch(resetPassword(email));
        }

    };


    const onDialogClose = () => {
        const confirmationInformation = {
            warningText: 'האם ברצונך לשמור את הנתונים שמילאת?',
            exitFunction: exitRoutine,
            show: true,
        }
        if (isAuthenticated !== null)
            dispatch(openConfirmationDialog(confirmationInformation));
        else {
            exitRoutine();
        }

    };

    return (
        <Dialog
            open={!!authDialog}
            onClose={onDialogClose}
        >
            <MainWrapper>
                {
                    form &&
                    <RenderStep
                        form={form}
                        onLogin={onLogin}
                        onUserRegister={onUserRegister}
                        onProRegister={onProRegister}
                        onResetPassword={onResetPassword}
                    />
                }
            </MainWrapper>
        </Dialog>
    )
}

export default withRouter(AuthModal)
