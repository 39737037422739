import React from 'react'
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import ContactForm from "./ContactForm";
import {LANDING_PAGE_PHONE} from "../../../utils/globals";
import Grid from "@material-ui/core/Grid";


const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
`

const JoinFormWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 2px;
  padding: 10px;
  flex-direction: row;
  width: 100%;
  background-color: #233f89;
  height: auto;
`
const FormText = styled.div`
  font-weight: bold;
  display: block;
  color: white;
  text-align: center;
  font-size: 1em;
  margin-top: 10px;
  @media(max-width: 560px){
    font-size: 0.85em;
  }
`

const PhoneWrapper = styled.a`
  text-decoration: none;
  color :white;
`


function JoinForm({handleChange, submit, form}) {
    const phoneNumber = "tel://" + LANDING_PAGE_PHONE;
    return (
        <>
            <JoinFormWrapper>
                <Grid container justifyContent={"center"}
                      direction={"column"}
                >
                    <ContactForm
                        position={"footer"}
                        form={form}
                        handleChange={handleChange}
                        submit={submit}
                    />
                </Grid>
                <Grid container
                      justify={"center"}>
                    <Grid item xs={12}>
                        <FormText>
                            :צלצלו אלינו עכשיו
                        </FormText>
                        <FormText>
                            <PhoneWrapper
                                href={phoneNumber}>
                                {LANDING_PAGE_PHONE}
                            </PhoneWrapper>
                        </FormText>
                        <FormText>או השאירו פרטים</FormText>
                    </Grid>
                </Grid>
            </JoinFormWrapper>
        </>
    );
}

const LandingFoot = ({form, handleChange, submit}) => {
    return (
        <Wrapper>
            <JoinForm
                form={form}
                handleChange={handleChange}
                submit={submit}/>
        </Wrapper>
    );
};

export default LandingFoot;
