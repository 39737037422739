import React from 'react'
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { LEAD_BLUE } from '../../../../utils/globals';

const ImageWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-around;
    direction: rtl;
`;

const Label = styled(Box)`
    font-family: 'Rubik', Sens-Serif;
    font-size: 16px;
    color: ${LEAD_BLUE};
    padding: 10px;
    margin: 10px;
`;

const EmptyInbox = () => {
    return (
        <ImageWrapper>
            <Label>
                אין&nbsp;לך&nbsp;הודעות&nbsp;כרגע!
            </Label>
        </ImageWrapper>
    )
}

export default EmptyInbox
