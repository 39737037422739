import React from 'react';
import styled from 'styled-components';


const MainWrapper = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    margin: 10px;
    @media (max-width: 450px) {
        display: block;
        width: 270px;
        margin: 5px;
    }
`;



const PaymentScreen = () => {


    return (
        <MainWrapper>

        </MainWrapper>
    );
};

export default PaymentScreen;
