import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";


// Function queries a given collection
// collectionName is the collection name
// field is the field to check in each document
// comparator is the sign, e.g '==' or '>'
// valueToCheck is the value we compare
export const queryCollection = async (
  collectionName,
  field,
  comprator,
  valueToCheck
) => {
  try {
    const data = await firebase
      .firestore()
      .collection(collectionName)
      .where(field, comprator, valueToCheck)
      .get();
    const documentList = [];
    data.forEach((doc) => {
      const id = doc.id;
      let data = doc.data();
      data = {
        ...data,
        id: id,
      };
      documentList.push(data);
    });
    return documentList;
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: "Failed to gather information, please try again",
    };
  }
};
export const queryWholeCollection = async (collectionName) => {
  try {
    const data = await firebase.firestore().collection(collectionName).get();

    return data;
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: "Failed to gather information, please try again",
    };
  }
};

//Deletes prof documents from pros and users
// + remove auth (implement with could functions)
export const permDeletePro = async (profID) => {
  try {
    const functionRef = await firebase.functions().httpsCallable("deleteUser");
    functionRef({ uid: profID });

    await removeDocument("users", profID);
    await removeDocument("professionals", profID);

    return {
      type: "success",
      msg: "!המטפל נמחק מהבסיס נתונים בהצלחה",
    };
  } catch (e) {
    return {
      type: "error",
      msg: e,
    };
  }
};

export const getInProgressPros = async () => {
  try {
    const data = await firebase.firestore().collection("professionals").get();

    const documentList = [];
    data.forEach((doc) => {
      if (doc.data().pending === undefined) {
        documentList.push(doc.data());
      }
    });
    return documentList;
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: "Failed to gather information, please try again",
    };
  }
};
export const getEntireDocument = async (collectionName, documentName) => {
  try {
    const data = await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .get();
    if (!data.exists) {
      return {
        type: "info",
        message: "לא נמצא מידע",
      };
    }
    return {
      type: "success",
      data: data.data(),
    };
  } catch (error) {
    console.log(
      "error fetching doc " + error + " " + collectionName + " " + documentName
    );
    return {
      type: "error",
      message: "Failed to gather information, please try again",
    };
  }
};

export const querySearch = async () => {
  const othersArray = ["גריאטריה", "התמכרויות", "פגיעות מיניות"];
  const IDsMap = new Map();

  const othersResult = [];
  othersResult.specialPush = function (professional) {
    const id = professional.id;

    if (IDsMap.get(id) === undefined) {
      othersResult.push(professional);
    }
    IDsMap.set(id, true);
  };

  try {
    let query1 = await firebase
      .firestore()
      .collection("professionals")
      .where("expertise.firstExpertie.header", "in", othersArray)
      .get();
    let query2 = await firebase
      .firestore()
      .collection("professionals")
      .where("expertise.secondExpertie.header", "in", othersArray)
      .get();
    let query3 = await firebase
      .firestore()
      .collection("professionals")
      .where("expertise.thirdExpertie.header", "in", othersArray)
      .get();

    query1.docs.forEach((doc) => othersResult.specialPush(doc.data()));
    query2.docs.forEach((doc) => othersResult.specialPush(doc.data()));
  await  query3.docs.forEach((doc) => othersResult.specialPush(doc.data()));

    
    return othersResult;


  } catch (e) {
    console.log("error" + e);
  }
};

// Function queries a given collection
// collectionName is the collection name
// field is the field to check in each document
// comparator is the sign, e.g '==' or '>'
// valueToCheck is the value we compare
export const queryCollectionMultipleConditions = async (
  collectionName,
  ArrayOfConditions
) => {
  try {
    let query = firebase.firestore().collection(collectionName);

    for (
      let conditionObject = 0;
      conditionObject < ArrayOfConditions.length;
      conditionObject++
    ) {
      query = query.where(...ArrayOfConditions[conditionObject]);
    }
    const data = await query.get();
    const documentList = [];
    data.forEach((doc) => {
      documentList.push(doc.data());
    });
    return documentList;
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: "Failed to gather information, please try again",
    };
  }
};

// Function updates a given field in a given collection
export const updateFieldInCollection = async (
  collectionName,
  documentName,
  field,
  newValue
) => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .update({ [field]: newValue });
    return {
      display: true,
      type: "success",
      message: " עודכן בהצלחה!",
    };
  } catch (error) {
    console.log("Error in updating a collection: ", error);
    return {
      display: true,
      type: "error",
      message: "Failure, please try again",
    };
  }
};

// Function adds a new document in a given collection
export const addNewDoucment = async (collectionName, documentName, data) => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .set(data);
    return {
      type: "success",
      message: "המידע עודכן בהצלחה!",
    };
  } catch (error) {
    console.log(`Error on adding a new file: ${error}`);
    return {
      type: "error",
      message: error.message,
    };
  }
};

// Function adds a new field in a given collection
export const updateDocument = async (collectionName, documentName, data) => {

  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .set(data);
    return {
      type: "success",
      message: "המידע התעדכן בהצלחה!",
    };
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: error.message,
    };
  }
};

export const updateArray = async (
  collectionName,
  documentName,
  arrayName,
  data
) => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .update({
        [arrayName]: firebase.firestore.FieldValue.arrayUnion(data),
      });
    return {
      type: "success",
      message: "Information updated successfully",
    };
  } catch (error) {
    return {
      type: "error",
      message: error.message,
    };
  }
};
export const updateArrayField = async (
  collectionName,
  documentName,
  arrayName,
  data
) => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .update({
        [arrayName]: data,
      });
    return {
      type: "success",
      message: "Information updated successfully",
    };
  } catch (error) {
    return {
      type: "error",
      message: error.message,
    };
  }
};

export const deleteArray = async (collectionName, documentName, arrayName) => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .update({
        [arrayName]: firebase.firestore.FieldValue.delete(),
      });
    return {
      type: "success",
      message: "Information updated successfully",
    };
  } catch (error) {
    return {
      type: "error",
      message: error.message,
    };
  }
};

// Function sets a field as an array
// useful if we need to start a new document because update cant start document
export const setArray = async (
  collectionName,
  documentName,
  arrayName,
  data
) => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .set({
        [arrayName]: firebase.firestore.FieldValue.arrayUnion(data),
      });
    const alert = {
      type: "success",
      message: "Information updated successfully",
    };
    console.log(alert);
    return alert;
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: error.message,
    };
  }
};

// Preforming a query on an array
export const queryArray = async (collectionName, field, valueToCheck) => {
  try {
    let query = firebase
      .firestore()
      .collection(collectionName)
      .where(field, "array-contains", valueToCheck);
    const data = await query.get();
    const documentList = [];
    data.forEach((doc) => {
      documentList.push(doc.data());
    });
    return {
      type: "success",
      data: documentList,
    };
  } catch (error) {
    console.log(error);
    return {
      type: "error",
      message: "Failed to gather information, please try again",
    };
  }
};

export const checkIfUserExists = async (email) => {
  try {
    const res = await firebase.auth().fetchSignInMethodsForEmail(email);
    return !!res.length;
  } catch (error) {
    console.error(`Error took place at trying to verify user: ${error}`);
  }
};

export const removeObjectFromDocument = async (
  collectionName,
  documentName,
  objectId
) => {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(documentName)
      .update({
        [objectId]: firebase.firestore.FieldValue.delete(),
      });
  } catch (error) {
    console.error(`Error took place at trying to verify user: ${error}`);
  }
};

export const removeDocument = async (collection, docName) => {
  try {
    await firebase.firestore().collection(collection).doc(docName).delete();
  } catch (e) {
    console.log("There was a no doc to delete");
  }
};
