import React from 'react'
import { Grid, Box, Typography } from '@material-ui/core'
import styled from 'styled-components'
import EmailIcon from '@material-ui/icons/Email';
import DraftsIcon from '@material-ui/icons/Drafts';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { RED, LEAD_BLUE } from '../../../../utils/globals';

const MessageWrapper = styled(Box)`
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.20), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.10),
              0 16px 16px rgba(0,0,0,0.05);
    padding: 10px;
    margin: 10px;
    width: 450px;
    @media (max-width: 600px) {
        width: auto;
    }
`;

const ContentWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;

const LabelWrapper = styled(Box)`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const Bold = styled(Typography)`
    font-family: 'Roboto', sans-serif;
    color: ${LEAD_BLUE};
    font-size: 18px;
`;

const MainWrapper = styled(Grid)`
    width: 100%;
    min-height: 150px;
`;

const ReadMore = styled(Typography)`
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    color: rgba(0, 0, 0, 0.54); 
`;

const DateWrapper = styled(Box)`
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    color: rgba(0, 0, 0, 0.54); 
`;

const TrashCan = styled(DeleteForeverIcon)`
    color: ${RED};
    cursor: pointer;
`;

const DisplayEmail = styled.p`
    margin: 0;
    padding: 0;
    @media (max-width: 600px) {
        display: none;
    }
`;

const PcLabel = ({ sender }) => {
    return (
        <Box>
            <Bold>{sender.firstName} {sender.lastName}</Bold>
            <DisplayEmail>
                {sender.email}
            </DisplayEmail>
        </Box>
    )
};

const Message = ({ message, setShowMessage, handleDeleteMessage }) => {
    
    const { message: { isReplyable, date: { day, hour }, id, isOpened, data: { content, subject } },
            receiver, sender } = message;

    const openMessage = () => {
        const messageObject = {
            display: true,
            subject,
            content,
            firstName: sender.firstName,
            lastName: sender.lastName,
            day, 
            isReplyable, 
            destEmail: sender.email,
            messageId: id
        }
        setShowMessage(messageObject);
    };

    return (
        <MainWrapper>
            <MessageWrapper>
                <ContentWrapper>
                    <LabelWrapper onClick={openMessage}>
                        <PcLabel 
                            sender={sender}
                            day={day}
                        />
                    </LabelWrapper>

                    <LabelWrapper>
                        {!isOpened ?
                            <EmailIcon
                            style={{color: LEAD_BLUE}}
                            onClick={openMessage}/> :
                            <DraftsIcon
                            onClick={openMessage}/>}
                        <TrashCan 
                            size="large"
                            onClick={() => handleDeleteMessage(receiver.email, id)}
                        />
                    </LabelWrapper>
                </ContentWrapper>

                <ContentWrapper>
                    <LabelWrapper onClick={openMessage}>
                        <ReadMore>
                            {`${subject.split(' ').slice(0, 1).join(' ')}...`}
                        </ReadMore>
                    </LabelWrapper>

                    <LabelWrapper>
                        <DateWrapper>
                            <Box>
                                {day}, {hour}
                            </Box> 
                        </DateWrapper>
                    </LabelWrapper>
                </ContentWrapper>
            </MessageWrapper>
        </MainWrapper>
    );
};

export default Message
