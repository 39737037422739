import {
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Button,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Label } from "semantic-ui-react";
import styled from "styled-components";
import { setCalPrice, setUpdate } from "../../actions/adminActions";

import { closeDialog } from "../../actions/DialogActions";
import {
  getEntireDocument,
  removeDocument,
  updateDocument,
  updateFieldInCollection,
} from "../../actions/FirestoreActions";
import { genericEmail } from "../../actions/FunctionsActions";
import {
  CATAGORIES_ARRAY,
  OPEN_ADMIN_DIALOG,
  migrateLabel,
  prefixArray,
  LEAD_BLUE,
} from "../../utils/globals";
import { objectExtractor } from "../../utils/objectManipulation";
import CustumAlert from "../layout/CustomAlert";
import Spinner from "../layout/Spinner";

import { publicDetailsForm } from "../professionalForm/formUtils";
import { Wrapper } from "../professionalForm/payment/PaymentBoiler";
import ExpertiseSelect from "../professionalZone/settings/settingsForm/ExpertiseSelect";
import { evaluatePrice } from "../professionalZone/settings/settingsUtils";

const REJECTION_MSG =
  "עברנו על בקשתך אך לצערנו לא מצאנו התאמה לצוות המטפלים שלנו";

const MainWrapper = styled.div`
  background-color: #f0f0f0;
  padding: 25px;
  width: 90%;
  min-width: 600px;
`;
const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
`;

const hebrewTags = {
  // prefix: "תואר",
  socialNetwork: "Social Networks",
  expertise: "Occupation",
  firstExpertie: "Expertise 1 ",
  secondExpertie: "Expertise 2",
  thirdExpertie: "Expertise 3",
  // profilePicture: "תמונת פרופיל",
  // presentationVideo: "סרטון",
  firstDegree: "BA ",
  secondDegree: "MA ",
  thirdDegree: "PhD",
  defaultLicense: "License 1",
  secondLicense: "License 2 ",
  ordination: "Certification 1",
  secondOrdination: "Certification 2 ",
  languages: "Languages",
  experience: "Professional Experience",
  price: "Price",
};

const TextFieldStyled = styled(TextField)`
  width: 90%;
  direction: rtl;
  text-align: right !important;
  margin: 20px;
`;

const FixMsgBar = ({
  professionalInfo,
  setFixMsgWindow,
  onProfessionalStateChange,
}) => {
  const [fixMessage, setfixMessage] = useState("");
  return (
    <>
      <TextFieldStyled
        multiline={4}
        label={"הודעה למטפל"}
        value={fixMessage}
        onChange={(e) => setfixMessage(e.target.value)}
      />

      <Wrapper>
        <Button
          variant="contained"
          style={{ background: "blue", color: "white" }}
          onClick={async () => {
            setFixMsgWindow(false);
            await onProfessionalStateChange(
              professionalInfo.id,
              professionalInfo.email,
              professionalInfo.firstName,
              "fix",
              fixMessage
            );
          }}
        >
          Send
        </Button>
        <Button
          variant="contained"
          style={{ background: "green", color: "white" }}
          onClick={async () => {
            setFixMsgWindow(false);
          }}
        >
          Back{" "}
        </Button>
      </Wrapper>
    </>
  );
};

const AdminEditModal = () => {
  const dispatch = useDispatch();

  const { adminEditDialog } = useSelector((state) => state.dialogs);
  const { professionalToPresent } = useSelector((state) => state.professionals);
  const { id } = professionalToPresent;
  const [loading, setLoading] = useState(false);
  const [serverMsg, setserverMsg] = useState("");
  const [serverType, setserverType] = useState("");

  const [fixMsgWindow, setFixMsgWindow] = useState(false);
  const [bankAccountShow, setbankAccountShow] = useState(false);
  const [bankInfo, setbankInfo] = useState("");
  const [professionalInfo, setprofessionalInfo] = useState({});
  const [approveFlag, setapproveFlag] = useState(false);
  // const [calculatedPrice, setcalculatedPrice] = useState(0)

  useEffect(() => {
    if (Object.keys(professionalInfo).length !== 0) {
      const calPrice = evaluatePrice(professionalInfo);
      dispatch(setCalPrice(calPrice));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionalInfo]);

  useEffect(() => {
    setprofessionalInfo(
      objectExtractor(
        publicDetailsForm(
          professionalToPresent,
          {
            firstName: professionalToPresent.firstName,
            lastName: professionalToPresent.lastName,
            email: professionalToPresent.email,
          },
          professionalToPresent.id
        )
      )
    );
    return () => {
      setprofessionalInfo({});
      setbankAccountShow(false);
    };
  }, [professionalToPresent, adminEditDialog]);

  async function fetchBankDetails() {
    const a = await getEntireDocument("users", professionalInfo.id);
    setbankInfo({
      bankName: a.data.bankName,
      accountNumber: a.data.accountNumber,
      branchNumber: a.data.branchNumber,
      accountName: a.data.accountName,
    });
  }

  /**
   *
   * [firstNest,secondNest,Property,value]
   * exmaple:
   * [expertise,seconExperite,description,"blablabla"]
   */
  const handleChange = (prevTag, tag, property, value) => {
    let root = prevTag === "" && tag === "";
    let oneNest = prevTag === "" && tag !== "";

    //root field
    if (root) {
      setprofessionalInfo({
        ...professionalInfo,
        [property]: value,
      });
      //Two nested field
    } else if (oneNest) {
      setprofessionalInfo({
        ...professionalInfo,
        [tag]: {
          ...professionalInfo[tag],
          [property]: value,
        },
      });
      //Three nested field
    } else {
      setprofessionalInfo({
        ...professionalInfo,
        [prevTag]: {
          ...professionalInfo[prevTag],
          [tag]: {
            ...professionalInfo[prevTag][tag],
            [property]: value,
          },
        },
      });
    }
  };
  /**
   * Function to handle professional state changes.
   * -Approved
   * -Fix
   * -Denied
   * For each state we will send different email to notify the professional
   * @param id of the professional
   * @param state which state to execute
   * @param fixMessage optional message
   * @returns {Promise<void>}
   */
  const onProfessionalStateChange = async (
    id,
    email,
    firstName,
    state,
    fixMessage
  ) => {
    let res;
    setLoading(true);

    switch (state) {
      case "approved":
        res = await handleSubmittion(id, false, "");
        await genericEmail({ email, firstName }, "professionalCongratulations");
        break;

      case "fix":
        res = await handleSubmittion(id, "In Progress", fixMessage);
        await genericEmail(
          { email, firstName, fixMessage },
          "professionalNotifyFix"
        );
        break;
      case "denied":
        res = await handleSubmittion(id, state, REJECTION_MSG);
        await genericEmail({ email, firstName }, "professionalDenial");
        break;

      default:
        console.log(
          "There most be some error onProfessionalStateChange function"
        );
        break;
    }
    setserverMsg(res?.message);
    setserverType(res?.type);
    dispatch(setUpdate(true));
    setLoading(false);

    setTimeout(() => {
      setserverMsg(null);
      setserverType(null);
    }, 5000);
    // dispatch(setPendingUpdate(true));
    //Remove professional from struggling state if he exits
    try {
      await removeDocument("strugglingProfessionals", id);
    } catch {
      console.log("failed to delete document");
    }
  };

  //TODO update locally then save doc
  const handleSubmittion = async (id, state, fix) => {
    await updateDoc();
    await changeOnlyPendingStatus(id, state, fix);
  };

  const clearExpertise = async (number) => {
    await setprofessionalInfo({
      ...professionalInfo,
      // eslint-disable-next-line no-useless-computed-key
      ["expertise"]: {
        ...professionalInfo["expertise"],
        [number]: {
          header: "",
          experienceYears: "",
          description: "",
        },
      },
    });
  };

  const changeOnlyPendingStatus = async (id, state, msg) => {
    return await updateFieldInCollection("professionals", id, "pending", {
      status: state,
      message: msg,
    });
  };

  const updateDoc = async () => {
    const updatedObject = {
      ...professionalInfo,
      pending: {
        msg: "",
        status: false,
      },
      isApprovedProWhoMadeAChange: false,
    };
    const res = await updateDocument("professionals", id, updatedObject);
    return res;
  };

  //On save button click
  //need to check flag, if do -> send email
  const saveToDB = async ({ email, firstName }) => {
    setLoading(true);
    const res = await updateDocument("professionals", id, professionalInfo);
    if (res.type === "success") {
      setserverMsg(res?.message);
      setserverType(res?.type);
    } else {
      setserverMsg(res?.message.message);
      setserverType(res?.type);
    }

    if (approveFlag) {
      //send approve email to prof

      await genericEmail({ email, firstName }, "professionalCongratulations");
    }

    setLoading(false);
    dispatch(setUpdate(true));

    setTimeout(() => {
      setserverMsg(null);
      setserverType(null);
    }, 5000);
  };

  const onClose = () => {
    dispatch(closeDialog(OPEN_ADMIN_DIALOG));
  };

  const ButtonsBar = (email, firstName) => {
    return (
      <>
        <ButtonsWrraper>
          <Button
            variant="contained"
            style={{
              background: "RGB(32,56,100)",
              color: "white",
              border: "none",
              outline: "none",
              boxShadow: "none",
            }}
            onClick={() => {
              saveToDB(email, firstName);
            }}
          >
            Save
          </Button>
          {professionalToPresent?.pending?.status === true ? (
            <>
              {/* <Button
                style={{
                  background: "RGB(84,130,53)",
                }}
                onClick={async () => {
                  onProfessionalStateChange(
                    professionalToPresent.id,
                    professionalToPresent.email,
                    professionalToPresent.firstName,
                    "approved",
                    ""
                  );
                }}
              >
                Approve
              </Button> */}
              <Button
                variant="contained"
                style={{ background: "RGB(255,165,0)" }}
                onClick={async () => {
                  setFixMsgWindow(true);
                }}
              >
                Suggest Change
              </Button>
            </>
          ) : (
            ""
          )}

          {/* <Button
            variant="contained"
            style={{
              background: "RGB(200,23,100)",
            }}
            onClick={() => {
              dispatch(closeDialog(OPEN_ADMIN_DIALOG));
            }}
          >
            Exit
          </Button> */}
        </ButtonsWrraper>
      </>
    );
  };

  return (
    <Dialog open={!!adminEditDialog} onClose={onClose} maxWidth="md">
      <MainWrapper>
        <EditFields
          professionalToPresent={professionalInfo}
          handleChange={handleChange}
          clearExpertise={clearExpertise}
        ></EditFields>
        <div
          style={{
            display: "flex",

            width: "100%",
            justifyContent: "right",
            marginTop: "15px",
          }}
        >
          <Button
            variant="contained"
            style={{
              background: `${LEAD_BLUE}`,
              color: "white",
              position: "relative",
              left: "5px",
              maxWidth: "150px",
              maxHeight: "50px",
            }}
            onClick={() => {
              setbankAccountShow(!bankAccountShow);
            }}
          >
            Bank Info
          </Button>
          {bankAccountShow ? (
            <BankAccountInfo
              handeChange={handleChange}
              bankInfo={bankInfo}
              fetchBankDetails={fetchBankDetails}
            />
          ) : (
            ""
          )}
        </div>
        <HandlePending
          onChange={handleChange}
          pending={professionalInfo.pending}
          setapproveFlag={setapproveFlag}
        />

        {fixMsgWindow ? (
          <FixMsgBar
            professionalInfo={professionalInfo}
            setFixMsgWindow={setFixMsgWindow}
            onProfessionalStateChange={onProfessionalStateChange}
          />
        ) : loading ? (
          <Spinner />
        ) : (
          <ButtonsBar
            email={professionalInfo.email}
            firstName={professionalInfo.firstName}
          />
        )}
        {!serverMsg ? (
          ""
        ) : (
          <CustumAlert type={!!serverType ? serverType : ""} msg={serverMsg} />
        )}
      </MainWrapper>
    </Dialog>
  );
};

const PendingWrapper = styled.div`
  display: flexbox;
  flex-direction: column;
  justify-content: center;
  min-width: 400px;
  margin-bottom: 30px;
`;

const StatusTitle = styled.div`
  font-weight: bold;

  font-size: 20px;
`;
const HandlePending = ({ onChange, pending, setapproveFlag }) => {
  if (pending.status === "") {
    pending.status = "In Progress";
  }
  const [option, setoption] = useState(pending.status);
  const localChnage = (e) => {
    //ui change
    setoption(e.target.value);
    //form change
    onChange("", "pending", "status", e.target.value);
    //value false == meaning approved
    if (!e.target.value) {
      //turn on flag for approve apon save
      setapproveFlag(true);
    } else {
      //turn off flag
      setapproveFlag(false);
    }
  };
  return (
    <PendingWrapper>
      <div>
        <Select
          value={option}
          style={{ width: "200px", textAlign: "center" }}
          onChange={(e) => {
            localChnage(e);
          }}
        >
          <MenuItem value={false}>Approved</MenuItem>
          <MenuItem value={true}>Pending</MenuItem>
          <MenuItem value={"In Progress"}>In Progress</MenuItem>
          <MenuItem value={"removed"}>Suspended</MenuItem>
        </Select>
      </div>
      <StatusTitle>Status</StatusTitle>
    </PendingWrapper>
  );
};

const ButtonsWrraper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
`;

const TagTitle = ({ array, index }) => {
  let nextPrevTag = "";

  const prevTag = array[index].tag;

  if (index < 1) {
    index = 1;
  }
  nextPrevTag = array[index - 1].tag;

  if (prevTag === nextPrevTag) {
    return "";
  } else {
    return <TagHeader>{hebrewTags[prevTag]}</TagHeader>;
  }
};

const EditFields = ({
  professionalToPresent,
  handleChange,
  clearExpertise,
}) => {
  const [array, setArray] = useState([]);
  useEffect(() => {
    setArray(deStructureFields(professionalToPresent, "", "", []));
  }, [professionalToPresent]);

  return (
    <>
      {array.map((item, index) => {
        return (
          <>
            <TagTitle array={array} index={index} />
            <InputFields
              field={item}
              prof={professionalToPresent}
              handleChange={handleChange}
              key={index}
              value={professionalToPresent[item.property]}
              clearExpertise={clearExpertise}
            />
          </>
        );
      })}
    </>
  );
};

const BankAccountInfo = ({ fetchBankDetails, handleChange, bankInfo }) => {
  useEffect(() => {
    fetchBankDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <EditFields
          professionalToPresent={bankInfo}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};
const AttributeEdit = ({
  handleChange,
  initalValue,
  label,
  fieldInfo,
  clearExpertise,
}) => {
  const { prevTag, tag, property } = fieldInfo;
  const [value, setvalue] = useState(initalValue);
  const [disabled, setdisabled] = useState(false);
  const [multiLine, setMultiLine] = useState(false);
  const [isBoolean, setIsBoolean] = useState(false);
  const { calculatedPrice } = useSelector((state) => state.admin);

  const changeStateValue = (event) => {
    const updatedValue = event.target.value;
    if (prevTag === "expertise" && property === "header") {
      if (updatedValue === 0) {
        // 0 ==clear option
        setvalue(0);
        //clear all fields
        clearExpertise(tag);
      } else {
        //normal select
        setvalue(CATAGORIES_ARRAY[updatedValue]);
        handleChange(prevTag, tag, property, CATAGORIES_ARRAY[updatedValue]);
      }
    } else {
      //update textField
      setvalue(updatedValue);
      //update modal state
      handleChange(prevTag, tag, property, updatedValue);
    }
  };

  useEffect(() => {
    setvalue(initalValue);
  }, [initalValue]);

  useEffect(() => {
    if (label === "id" || label === "stringName" || label === "displayURL") {
      setdisabled(true);
    }
    if (
      label === "defaultExperience" ||
      label === "displayURL" ||
      label === "description"
    ) {
      setMultiLine(true);
    }

    if (
      (label === "isLegalDocConfirmed" ||
        label === "priceOverWrite" ||
        tag === "languages" ||
        label === "status") &&
      label !== "other"
    ) {
      setIsBoolean(true);
    }
  }, [label, tag, property]);

  return (
    <>
      {label === "header" ? (
        <EditWrapper>
          <InputLabel
            style={{ direction: "rtl", fontFamily: "Times New Roman" }}
          >
            expertise:
          </InputLabel>
          <ExpertiseSelect
            exp={value === 0 ? -1 : CATAGORIES_ARRAY.indexOf(value)}
            handleChangeFirst={changeStateValue}
            width={"50%"}
          />
        </EditWrapper>
      ) : isBoolean ? (
        <EditWrapper>
          <InputLabel
            style={{ direction: "rtl", fontFamily: "Times New Roman" }}
          >
            {migrateLabel[label] ? migrateLabel[label] : label}:
          </InputLabel>

          <Select
            value={value}
            onChange={(e) => changeStateValue(e)}
            style={{
              width: "50%",
              textAlign: "right",
            }}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={null}>clear</MenuItem>
          </Select>
        </EditWrapper>
      ) : label === "prefix" ? (
        <EditWrapper>
          <InputLabel
            style={{ direction: "rtl", fontFamily: "Times New Roman" }}
          >
            {label}:
          </InputLabel>

          <Select
            value={value}
            onChange={(e) => changeStateValue(e)}
            style={{
              width: "50%",
              textAlign: "right",
            }}
          >
            <MenuItem value={prefixArray[3]}>פרופ</MenuItem>
            <MenuItem value={prefixArray[2]}>ד''ר</MenuItem>
            <MenuItem value={prefixArray[1]}>'גב</MenuItem>
            <MenuItem value={prefixArray[0]}>'מר</MenuItem>
          </Select>
        </EditWrapper>
      ) : (
        <>
          {migrateLabel[label] === "price_per_session" ? (
            <EditWrapper>
              <Label>calculated price:</Label>
              <TextField
                style={{
                  width: "50%",
                }}
                value={calculatedPrice}
                disabled={true}
              />
            </EditWrapper>
          ) : (
            ""
          )}
          <EditWrapper>
            {migrateLabel[label] ? migrateLabel[label] + ":" : label + ":"}
            <TextField
              style={{
                width: "50%",
              }}
              onChange={(e) => {
                changeStateValue(e);
              }}
              value={value}
              disabled={disabled}
              multiline={multiLine}
            />
          </EditWrapper>
        </>
      )}
    </>
  );
};

const TagHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  direction: rtl;
  margin-top: 5px;
  text-decoration: underline;
  font-family: "Times New Roman", Times, serif;
`;

const InputFields = ({ field, prof, handleChange, value, clearExpertise }) => {
  const { prevTag, tag, property } = field;

  //Both empty == ROOT
  if (
    prevTag === "" &&
    tag === "" &&
    property !== "isApprovedProWhoMadeAChange"
  ) {
    return (
      <AttributeEdit
        handleChange={handleChange}
        initalValue={value}
        label={property}
        fieldInfo={field}
      />
    );

    //ignored fields
  } else if (
    property === "threePackPrice" ||
    prevTag === "video" ||
    prevTag === "picture" ||
    tag === "pending" ||
    property === "isApprovedProWhoMadeAChange"
  ) {
    return <div />;
  } else if (prevTag === "" && tag !== "") {
    //Nested Field on Tag
    return (
      <AttributeEdit
        handleChange={handleChange}
        initalValue={prof[tag][property]}
        label={property}
        fieldInfo={field}
      />
    );
    //Three Nested object
  } else {
    return (
      <>
        <AttributeEdit
          handleChange={handleChange}
          initalValue={prof[prevTag][tag][property]}
          label={property}
          fieldInfo={field}
          clearExpertise={clearExpertise}
        />
      </>
    );
  }
};

/**
 *
 * @param {settingsform object} obj
 * @returns the object without undefiends to avoid crashed replace them with empty sting
 */
export const deStructureFields = (obj, prevTag, tag, fieldsArray) => {
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] == "object")
        deStructureFields(obj[property], tag, property, fieldsArray);
      else {
        fieldsArray.push({ prevTag, tag, property });
      }
    }
  }
  return fieldsArray;
};

export default withRouter(AdminEditModal);
