import React from 'react'
import styled from 'styled-components';
import Box from "@material-ui/core/Box";
import bgImage from '../../../static/images/geniePictures/jinisbg.png';
import textLogo from '../../../static/images/geniePictures/LogoWithText.png'
import telLogo from '../../../static/images/geniePictures/tel.png'


import {LANDING_PAGE_PHONE} from "../../../utils/globals";
import ContactForm from "./ContactForm";


const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
`
const ImageWrapper = styled(Box)`
  background: url(${bgImage}) center;
  background-size: cover;
  width: 100%;
  height: 400px;
`

const TextLogo = styled.img`
  padding: 15px;
  width: 150px;
  height: 75px;
  @media(max-width: 560px){
    width: 125px;
  }
`

const BoxRow = styled(Box)`
  display: flex;
  justify-content: ${props => props.justifyContent ? props.justifyContent : ''};;
  margin-top: ${props => props.marginTop ? props.marginTop : 0};
  width: 100%;
  height: auto;
`

const PhoneNumber = styled.div`
  display: flex;
  padding: 10px;
  color: #053691;
  font-weight: bold;
  font-size: 1.25em;
  align-items: center;
@media(max-width:   440px){
 font-size: 0.75em;
}
`
const PhoneLogo = styled.img`
  width: 30px;
  height: 30px;
`

const PhoneClickAble = styled.a`
  text-decoration: none;
  color: #304b8e;
`

function HeaderImage() {
    const phoneNumber = "tel://" + LANDING_PAGE_PHONE;
    return (
        <>
            <ImageWrapper>

                <BoxRow justifyContent="space-between">
                    <PhoneNumber>
                        <PhoneLogo src={telLogo}/>
                        {/*Makes the link clickable to mobile phones*/}
                        <PhoneClickAble href={phoneNumber}>{LANDING_PAGE_PHONE}</PhoneClickAble>
                    </PhoneNumber>
                    <a href={"//www.open-mynd.com"} target="_blank" rel='noreferrer'>
                        <TextLogo src={textLogo}/>
                    </a>
                </BoxRow>
            </ImageWrapper>
        </>

    );
}

const JoinFormWrapper = styled(Box)`
  margin-top: 2px;
  padding: 10px;
  flex-direction: row;
  width: 100%;
  direction: rtl;
  background-color: #233f89;
  height: auto;
`
const FormText = styled.span`
  font-weight: bold;
  color: white;
  text-align: center;
`

function JoinForm({form, handleChange, submit}) {
    return (
        <>
            <JoinFormWrapper>
                <BoxRow justifyContent="center">
                    <FormText>
                        אני רוצה להצטרף ל-JINIZ ולהגדיל את החשיפה שלי ללקוחות חדשים!
                    </FormText>
                </BoxRow>
                <BoxRow justifyContent={"center"}>
                    <ContactForm
                        position={"header"}
                        handleChange={handleChange}
                        form={form}
                        submit={submit}/>
                </BoxRow>
            </JoinFormWrapper>
        </>
    );
}

const LandingHeader = ({form, handleChange, submit}) => {
    return (
        <>

            <Wrapper>
                <HeaderImage/>
            </Wrapper>
            <Wrapper>
                <JoinForm
                    form={form}
                    handleChange={handleChange}
                    submit={submit}
                />
            </Wrapper>
        </>
    );
}

export default LandingHeader;