import {
    GET_SCHEDULE,
    SET_SCHEDULE,
    UPDATE_SCHEDULE,
    DELETE_SCHEDULE,
    SET_SCHEDULE_LOADING,
    SET_SCHEDULE_ERROR,
    CLEAR_SCHEDULE_ERROR,
    CLEAR_SCHEDULE_STATE,
    GET_All_SCHEDULE,
    SET_FETCHED_FLAG
} from '../actions/types';

const initalState = {
    schedule: [],
    error: false,
    scheduleLoading: false,
    professionalId:[],
    fetched:false
};


export const scheduleReducer = (state = initalState, action) => {

   

    switch (action.type) {

        case GET_SCHEDULE: 
            return {
                ...state,
                error: false,
                schedule: action.payload ? action.payload : [],

            };
        case GET_All_SCHEDULE:
            return {
                ...state,
                error: false,
                professionalId: action.payload ? action.payload : [],
            };

        case SET_SCHEDULE: 
            return {
                ...state,
                error: false,
                schedule: action.payload,

            };

        case UPDATE_SCHEDULE: 
            return {
                ...state,
                error: false,
                schedule: action.payload,

            };

        case DELETE_SCHEDULE: 
            return {
                ...state,
                error: false,
                schedule: action.payload,
                professionalId:[]
            };

        case SET_SCHEDULE_ERROR:
            console.error("An error took place while trying to get schedule: ", action.payload);
          
            return {
                ...state,
                error: action.payload
            };

        case CLEAR_SCHEDULE_ERROR:
            return {
                ...state,
                error: false
            };

        case SET_SCHEDULE_LOADING:
         
            return {
                ...state,
                scheduleLoading: action.payload
            };

        case CLEAR_SCHEDULE_STATE:
            return {
                ...state,
                schedule: action.payload,
            };

        case SET_FETCHED_FLAG:
            return {
                ...state,
                fetched: action.payload,
            };

        default:
            return state;

    }
}