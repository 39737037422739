import React from 'react';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { LEAD_BLUE } from '../../utils/globals';

const Title = styled(FormLabel)`
    direction: rtl;
    font-size: 20px;
    color: ${LEAD_BLUE};
`;

const Text = styled(FormControlLabel)`
    direction: rtl;
    margin: 0;
`;

const RadioInput = ({title, name, options, value, handleChange}) => {
    return (
        <FormControl component="fieldset">
            <Title 
                component="legend"
            >
                {title}
            </Title>
            <RadioGroup 
                name={name} 
                value={value} 
                onChange={event => handleChange(event)}
            >
                {
                    options.map((label, index) => 
                        <Text
                            key={index} 
                            value={label} 
                            control={<Radio />} 
                            label={label} 
                        />
                    )
                }
            </RadioGroup>
        </FormControl>
    )
}

export default RadioInput
