import React from 'react';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const EditSelect = ({ label, value, onChange, name, fields, styledSelect }) => {
    return (

        <FormControl style={{width: 165, direction: 'rtl'}}>
            <InputLabel 
                shrink 
                id={label}
            >
                {label}
            </InputLabel>
            <Select
                style={styledSelect ? styledSelect : ''}
                labelId={label}
                id={`mainId${label}`}
                input={<Input name={name} />}
                value={value ? value : ''}
                onChange={event => onChange(event, "prefixState")}
                displayEmpty
                autoWidth
                required
                autoFocus
            >   
                {fields.map((field, index) => 
                    <MenuItem 
                        value={field}
                        key={index}
                        style={{direction: 'rtl'}}
                    >
                        {field}
                    </MenuItem>)}

            </Select>
      </FormControl>

    )
}

export default EditSelect
