import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { closeDialog, openConfirmationDialog } from '../../actions/DialogActions';
import { presentCurrentProfessional } from '../../actions/ProfessionalsActions';
import { OPEN_PROFESSIONAL_DIALOG } from '../../utils/globals';
import Presentation from "../../components/professionalForm/Presentation";
import Calander from "../../components/professionalForm/calander/Calander";
import Payment from "../professionalForm/payment/Payment";
import Dialog from '@material-ui/core/Dialog';
import { clearCurrentRecommendations } from '../../actions/RecommendationsActions';
import { routeQuery } from '../../utils/routes';
import { clearSchedule } from '../../actions/ScheduleActions';
import { getMeetings } from "../../actions/MeetingsActions";
import { getRelevantContacts } from "../messages/pages/inbox/InboxHelpers";

// Styles
const MainWrapper = styled.div`
  min-width: auto;
  max-width: 750px;
  height: auto;
  background-color: #F0F0F0;
`;


const RenderStep = ({
    step,
    currentProfessional,
    nextStep,
    day,
    hour,
    cofirmStep,
    onFormChange,
    setDay,
    prevStep,
    setCalanderForm,
    setHours,
    resetCalanderForms,
    calanderForm,
    handleOnGoingPayment,
    onDialogClose,
    hours,
    handleDetectingChanges,
    onExit,
    handleChangeState,
    meetingAmount,
    setMeetingAmount
}) => {

    const { tokens } = useSelector(state => state.tokens)
    const [userHaveTokens, setUserHaveTokens] = useState(false)
    const { user } = useSelector(state => state.auth);
    const { meetings } = useSelector(state => state.meetings);
    const [contacts, setContacts] = useState([]);
    const [sendMessageDisabled, setSendMessageDisabled] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => { //fetch user meetings

        if (user != null)
            dispatch(getMeetings("userMeetings", user.id));
    }, [dispatch, user])


    useEffect(() => {
        if (Object.keys(meetings).length) {
            const contact = getRelevantContacts(meetings, true);
            setContacts(contact);
        }
        // eslint-disable-next-line
    }, [meetings])

    useEffect(() => {
        contacts.forEach((item) => {
            if (item.id === currentProfessional.id) {
                setSendMessageDisabled(false)
            }
        })

    }, [contacts, currentProfessional.id])

    useEffect(() => {
        //Checks if the user has tokens with the specific professional
        if (tokens[currentProfessional.id] !== undefined) {
            //validate that the user has tokens
            if (tokens[currentProfessional.id][0] > 0) {
                setUserHaveTokens(true);
            }
        } else {
            setUserHaveTokens(false);
        }

    }, [currentProfessional, tokens])


    switch (step) {
        case (1):
            return (
                <Presentation
                    userId={user && user.id}
                    nextStep={nextStep}
                    currentProfessional={currentProfessional}
                    onExit={onExit}
                    meetingAmount={meetingAmount}
                    setMeetingAmount={setMeetingAmount}
                    userHasTokens={userHaveTokens}
                    sendMessageDisabled={sendMessageDisabled}
                />
            )
        case (2):
            return (
                <Calander
                    currentProfessional={currentProfessional}
                    day={day}
                    hour={hour}
                    onChange={onFormChange}
                    onDayChange={setDay}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    setForm={setCalanderForm}
                    setHours={setHours}
                    resetCalanderForms={resetCalanderForms}
                    form={calanderForm}
                    hours={hours}
                    onExit={prevStep}
                    handleDetectingChanges={handleDetectingChanges}
                />
            )
        case (3):
            return (
                <Payment
                    day={day}
                    hour={hour}
                    prevStep={prevStep}
                    currentProfessional={currentProfessional}
                    onDialogClose={onDialogClose}
                    handleDetectingChanges={handleDetectingChanges}
                    handleOnGoingPayment={handleOnGoingPayment}
                    handleChangeState={handleChangeState}
                    meetingAmount={meetingAmount}
                    userHasTokens={userHaveTokens}
                />
            );
        default:
            return {};
    }
    ;
};

// Dialog
const ProfessionalModal = ({ history }) => {
    const { professionalDialog } = useSelector(state => state.dialogs);
    const { professionalToPresent } = useSelector(state => state.professionals);
    const [update, setUpdate] = useState(false);
    const [change, setChange] = useState(false);
     // eslint-disable-next-line no-unused-vars
     const [onGoingPayment, setOnGoingPayment] = useState(false);
    const [calanderForm, setCalanderForm] = useState(false);
    const [hours, setHours] = useState({});
    const [meetingAmount, setMeetingAmount] = useState(true);
    const [form, setForm] = useState({
        step: 1,
        day: '',
        hour: {},
    });


    const changeCheckBoxState = () => {
        setMeetingAmount(!meetingAmount)
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const expertId = urlParams.get('expert');
        if (expertId) {
            expertId && presentCurrentProfessional(expertId);
            setUpdate(true);
        }
        // eslint-disable-next-line
    }, [window.location.search])

    const dispatch = useDispatch();
    const { step, day, hour } = form;

    const onFormChange = (name, value) => {
        setForm({
            ...form,
            [name]: value
        });
    };

    const setDay = day => {
        setForm({
            ...form,
            hour: '',
            day
        });
    };

    const resetAllForms = () => {
        resetCalanderForms();
        setForm({
            step: 1,
            day: '',
            hour: {},
        });
    };

    const resetCalanderForms = () => {
        setCalanderForm(false);
        setHours({});
    };

    const nextStep = () => {
        setForm({
            ...form,
            step: step + 1
        });
    };

    const prevStep = () => {
        setForm({
            ...form,
            step: step - 1
        });
    };

    const exitRoutine = () => {
        
        dispatch(closeDialog(OPEN_PROFESSIONAL_DIALOG));
        dispatch(clearSchedule());
        dispatch(clearCurrentRecommendations());
     
        handleDetectingChanges(false);
       
        history.replace(routeQuery({ expert: undefined }));
        resetAllForms();
        // dispatch(clearCurrentPresented());
   
    };

    // Cleaning state and closing dialog.
    const onDialogClose = () => {
        if (step === 3) {
            exitRoutine();
            return;
        }
        if (!!change) {
            const confirmationInformation = {
                warningText: 'זיהינו שינויים שביצעת, במידה והחלון יסגר, השינויים לא ישמרו',
                exitFunction: exitRoutine,
                show: true,
            }
            dispatch(openConfirmationDialog(confirmationInformation));
        } else {
            exitRoutine();
        }
    };

    const handleDetectingChanges = state => {
        setChange(state);
    };

    const handleOnGoingPayment = state => {
        setOnGoingPayment(state);
    };

    return (
        <>
            <Dialog
                open={professionalDialog}
                onClose={onDialogClose}
                maxWidth='xl'
                ref={this} //findmode deprication
            >
                <MainWrapper>
                    {update &&
                        <RenderStep
                            step={step}
                            currentProfessional={professionalToPresent}
                            day={day}
                            hour={hour}
                            calanderForm={calanderForm}
                            hours={hours}
                            nextStep={nextStep}
                            prevStep={prevStep}
                            setDay={setDay}
                            setHours={setHours}
                            setCalanderForm={setCalanderForm}
                            resetCalanderForms={resetCalanderForms}
                            onFormChange={onFormChange}
                            onDialogClose={onDialogClose}
                            handleDetectingChanges={handleDetectingChanges}
                            handleOnGoingPayment={handleOnGoingPayment}
                            onExit={onDialogClose}
                            handleChangeState={handleDetectingChanges}
                            meetingAmount={meetingAmount}
                            setMeetingAmount={changeCheckBoxState}
                        />
                    }
                </MainWrapper>
            </Dialog>
        </>
    );
};

export default withRouter(ProfessionalModal);

