import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Fab} from '@material-ui/core';
import styled from 'styled-components';
import {RED} from '../../../utils/globals';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {logout} from '../../../actions/AuthActions';
import {routeQuery} from '../../../utils/routes';
import {clearProfessional} from "../../../actions/ProfessionalsActions";

const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: black;
`;
const Notification = styled(Box)`

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 25px;
  font-size: 12px;
  color: white;
  background-color: ${RED};
  font-family: 'Arial', serif;

`;
const DialogWrapper = styled.div`
  display: flex;
  box-shadow: 1px 1px 2px #e3e3e3;
  visibility: collapse;
  width: 160px;
  justify-content: space-between;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  top: 30px;
  left: 25px;
  transition: all .1s linear;

  &:hover {
    visibility: visible;
  }

`;
const Name = styled(Fab)`
  font-family: 'Rubik', Sans-Serif,sans-serif;
  background-color: ${props => props.isopen === 'true' ? '#3D9970' : 'rgba(14,66,124,1)'};
  color: ${props => props.isopen === 'true' ? 'black' : 'white'};
  margin-top: 5px;
  font-size: 16px;
  
  &:hover {
    color: black;
  }

  &:hover ~ ${DialogWrapper} {
    visibility: visible;
  }
`;


const LeftSideNavWrapper = styled.div`

  position: relative;
`

const ListWrapper = styled.ul`
  list-style-type: none;
  display: table;
  position: relative;
  width: 100%;

`
const ItemList = styled.li`

  display: inline-flex;
  align-items: start;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;

`
const LinkBtnWrapper = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const pictureBlock = {
    position: 'relative',
    objectFit:'fitContent',
    width: '35px',
    height: '35px',
    marginTop: '15px',
    marginLeft: '15px',
    // float: 'left',

}
const DropdownNotification = styled(Box)`
  position: absolute;
  background-color: ${RED};
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  padding: 2px;
  //padding-top:2px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  left: 25px;
  top: -5px;
`
const picture = {
    width: '100%',

}
const header = {
    fontFamily: "'Rubik', Sans-Serif"
}
const msgStyle = {
    padding: '2px',
    marginTop: '45px',

    fontSize: '14px'
}

const Button = ({anchorEl, firstName, unreadMessages, showDropDown}) => {
    const dispatch = useDispatch();


    const logoutFromUser = () => {
        dispatch(logout());
        dispatch(clearProfessional())

    }
    const {authState} = useSelector(
        (state) => ({
            authState: state.auth

        })
    );
    const {isProfessional} = authState;


    return (
        <LeftSideNavWrapper>
            <Name
                isopen={Boolean(anchorEl).toString()}
                size="small"
            >
                {firstName.charAt(0)}
                {
                    unreadMessages > 0 &&
                    <Notification>
                        {unreadMessages}
                    </Notification>
                }
            </Name>
            {(isProfessional && showDropDown) ?
                <DialogWrapper>
                    <ListWrapper>
                        <LinkBtnWrapper to={routeQuery({box: 'messages-home'})}>
                            <ItemList>
                                <p style={header}>הודעות</p>
                                <div style={pictureBlock}>
                                    {
                                        unreadMessages > 0 &&
                                        <DropdownNotification>
                                            <span style={msgStyle}> {unreadMessages}</span>
                                        </DropdownNotification>
                                    }
                                    <img
                                        style={picture}
                                        src="https://img.icons8.com/wired/64/000000/email.png"
                                        alt="msg icons"
                                    />
                                </div>

                            </ItemList>
                        </LinkBtnWrapper>
                        <LinkBtnWrapper
                            id={'da'}
                            to={routeQuery({privatePage: 'info'})}>
                            <ItemList>
                                <p style={header}>מפגשים</p>
                                <div style={pictureBlock}>
                                    <img
                                        style={picture}
                                        src="https://img.icons8.com/wired/64/000000/hand-with-pen.png"
                                        alt="msg icons"
                                    />
                                </div>
                            </ItemList>
                        </LinkBtnWrapper>
                        {isProfessional &&
                        <>
                            <LinkBtnWrapper style={{marginLeft: '27px'}} to={routeQuery({privatePage: 'calendar'})}>
                                <ItemList>
                                    <p style={header}>יומן </p>
                                    <div style={pictureBlock}>
                                        <img
                                            style={picture}
                                            src="https://img.icons8.com/ios/64/000000/overtime.png"
                                            alt="msg icons"
                                        />
                                    </div>
                                </ItemList>
                            </LinkBtnWrapper>
                            <LinkBtnWrapper to={routeQuery({privatePage: 'financial'})}>
                                <ItemList>
                                    <p style={header}>הכנסות</p>
                                    <div style={pictureBlock}>
                                        <img
                                            style={picture}
                                            src="https://img.icons8.com/wired/64/000000/money.png"
                                            alt="msg icons"
                                        />
                                    </div>
                                </ItemList>
                            </LinkBtnWrapper>
                        </>
                        }
                        <LinkBtnWrapper to={routeQuery({privatePage: 'settings'})}>
                            <ItemList>
                                <p style={header}>פרופיל</p>
                                <div style={pictureBlock}>
                                    <img
                                        style={picture}
                                        src="https://img.icons8.com/ios/50/000000/settings.png"
                                        alt="msg icons"
                                    />
                                </div>
                            </ItemList>
                        </LinkBtnWrapper>
                        <LinkBtnWrapper to={"/"} >
                            <ItemList
                                onClick={logoutFromUser}
                            >
                                <p style={header}>התנתק</p>
                                <div style={pictureBlock}>
                                    <img
                                        style={{paddingLeft:'5px',height:'30px'}}
                                        src="https://img.icons8.com/ios/50/000000/export.png"
                                        alt="msg icons"

                                    />
                                </div>
                            </ItemList>
                        </LinkBtnWrapper>
                    </ListWrapper>

                </DialogWrapper>
                :
                <DialogWrapper>
                    <ListWrapper>
                        <LinkBtnWrapper to={routeQuery({box: 'messages-home'})}>
                            <ItemList>
                                <p style={header}>הודעות</p>
                                <div style={pictureBlock}>
                                    {
                                        unreadMessages > 0 &&
                                        <DropdownNotification>
                                            <span style={msgStyle}> {unreadMessages}</span>
                                        </DropdownNotification>
                                    }
                                    <img
                                        style={picture}
                                        src="https://img.icons8.com/wired/64/000000/email.png"
                                        alt="msg icons"
                                    />
                                </div>

                            </ItemList>
                        </LinkBtnWrapper>
                        <LinkBtnWrapper
                            id={'da'}
                            to={routeQuery({privatePage: 'info'})}>
                            <ItemList>
                                <p style={header}>מפגשים</p>
                                <div style={pictureBlock}>
                                    <img
                                        style={picture}
                                        src="https://img.icons8.com/wired/64/000000/hand-with-pen.png"
                                        alt="msg icons"
                                    />
                                </div>
                            </ItemList>
                        </LinkBtnWrapper>
                        {isProfessional &&
                        <>
                            <LinkBtnWrapper style={{marginLeft: '27px'}} to={routeQuery({privatePage: 'calendar'})}>
                                <ItemList>
                                    <p style={header}>יומן </p>
                                    <div style={pictureBlock}>
                                        <img
                                            style={picture}
                                            src="https://img.icons8.com/ios/50/000000/overtime.png"
                                            alt="msg icons"
                                        />
                                    </div>
                                </ItemList>
                            </LinkBtnWrapper>
                            <LinkBtnWrapper to={routeQuery({privatePage: 'financial'})}>
                                <ItemList>
                                    <p style={header}>הכנסות</p>
                                    <div style={pictureBlock}>
                                        <img
                                            style={picture}
                                            src="https://img.icons8.com/wired/64/000000/money.png"
                                            alt="msg icons"
                                        />
                                    </div>
                                </ItemList>
                            </LinkBtnWrapper>
                        </>
                        }
                        <LinkBtnWrapper to={routeQuery({privatePage: 'settings'})}>
                            <ItemList>
                                <p style={header}>פרופיל</p>
                                <div style={pictureBlock}>
                                    <img
                                        style={picture}
                                        src="https://img.icons8.com/ios/50/000000/settings.png"
                                        alt="msg icons"
                                    />
                                </div>
                            </ItemList>
                        </LinkBtnWrapper>
                        <LinkBtnWrapper to={routeQuery({privatePage: undefined})}>
                            <ItemList
                                onClick={logoutFromUser}
                            >
                                <p style={header}>התנתק</p>
                                <div style={pictureBlock}>
                                    <img
                                        style={{paddingLeft:'5px',height:'30px'}}
                                        src="https://img.icons8.com/ios/50/000000/export.png"
                                        alt="msg icons"

                                    />
                                </div>
                            </ItemList>
                        </LinkBtnWrapper>
                    </ListWrapper>

                </DialogWrapper>

            }
        </LeftSideNavWrapper>
    );
};


const Navigate = ({firstName, anchorEl, unreadMessages}) => {
    const {currentProfessional} = useSelector(state => state.professionals)
    const showDropDown = !currentProfessional ? true : currentProfessional.isLegalDocConfirmed;
    const route = () => {
        if (showDropDown === false) {
            return 'settings'
        } else {
            return 'home'
        }
    }

    return (
        window.matchMedia("(pointer: coarse)").matches ?
            <Button
                firstName={firstName}
                anchorEl={anchorEl}
                unreadMessages={unreadMessages}
                showDropDown={showDropDown}
            />
            :

            <LinkWrapper
                to={routeQuery({privatePage: route()})}
            >
                <Button
                    firstName={firstName}
                    anchorEl={anchorEl}
                    unreadMessages={unreadMessages}
                    showDropDown={showDropDown}
                />
            </LinkWrapper>


    )
};

const NavButton = ({firstName, anchorEl}) => {

    const {unreadMessages} = useSelector(state => state.messages);
    return (
        <Box>
            <Navigate
                firstName={firstName}
                anchorEl={anchorEl}
                unreadMessages={unreadMessages}
            />
        </Box>
    )
}

export default NavButton
