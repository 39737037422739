import React, {  } from "react";
import CalanderHeader from "../../../calander/calendarUtils/CalanderHeader";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { LEAD_BLUE, prefixArray } from "../../../../../utils/globals";
import EditSelect from "../EditSelect";

const professionalsPreview =
    "הנתונים הבאים נועדו כדי שנוכל להיות איתך בקשר ביותר מאמצעי אחד וכדי להעביר לך תשלום על שירותך. אנחנו מחויבים לשמור באדיקות על הפרטיות שלך בכפוף לכל דין.";
const usersPreview =
    "הנתונים הבאים הם חלק מבדיקת הנאותות שאנו עורכים. יחד עם זאת, אנחנו מחויבים לשמור באדיקות על הפרטיות שלך בכפוף לכל דין, כאמור בהסכם ההתקשרות.";

const bankFields = [
    {
        formName: "bankName",
        label: "שם בנק:",
    },
    {
        formName: "branchNumber",
        label: "מספר סניף:",
    },
    {
        formName: "accountName",
        label: "שם חשבון:",
    },
    {
        formName: "accountNumber",
        label: "מספר חשבון:",
    },
];

const MainWrapper = styled.div`
  direction: rtl;
  display: grid;
  justify-items: flex-start;
  align-items: center;
  padding: 10px;
`;

const FormWrapper = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 450px) {
    display: grid;
    justify-items: center;
  }
`;

const BankWrapper = styled.div`
  padding: 10px;
  margin-top: 10px;
  @media (min-width: 1050px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const TextFieldWrapper = styled.div`
  margin-right: 10px;
`;

const Label = styled.p`
  margin: 0;
  padding: 0;
  width: 100px;
  text-align: right;
  font-family: "Rubik", Sens-Serif, serif;
`;

const Header = ({ isProfessional }) => {
    return (
        <CalanderHeader
            header="פרטי התקשרות"
            description={isProfessional ? professionalsPreview : usersPreview}
        />
    );
};

const BankForms = ({ onChange, formValues }) => {
    return (
        <BankWrapper>
            <Label>פרטי בנק:</Label>
            <div>
                {bankFields.map((field, index) => (
                    <TextFieldWrapper key={index} style={{ marginTop: "40px" }}>
                        <Label style={{ color: LEAD_BLUE }}>{field.label}</Label>
                        <TextField
                            style={{ direction: "rtl" }}
                            name={field.formName}
                            onChange={(event) => {
                                onChange(field.formName, event.target.value);
                            }}
                            value={formValues[field.formName]}
                            // Removed the error prop to make the field not required
                            // error={!formValues[field.formName]}
                        />
                    </TextFieldWrapper>
                ))}
            </div>
        </BankWrapper>
    );
};

const PrefixForm = ({ fieldLabel, formValue, formName, onChange }) => {
    return (
        <FormWrapper>
            <Label>{fieldLabel}</Label>
            <EditSelect
                styledSelect={{ marginRight: "10px", width: "195px", marginTop: 0 }}
                value={formValue.prefix}
                onChange={onChange}
                name={formName}
                fields={prefixArray}
            />
        </FormWrapper>
    );
};

const Field = ({ fieldProperties, onChange, formValue }) => {
    const { label, formName } = fieldProperties;
    return (
        <FormWrapper>
            <Label>{label}</Label>
            <TextFieldWrapper>
                <TextField
                    style={{ direction: "rtl" }}
                    name={formName}
                    onChange={onChange}
                    value={formValue}
                    error={!formValue}
                />
            </TextFieldWrapper>
        </FormWrapper>
    );
};

const Forms = ({
    onChange,
    onPrefixChange,
    formValues,
    fields,
    isProfessional,
    prefixState,
}) => {
    return (
        <>
            {fields.map((field) =>
                field.formName !== "prefixState" ? (
                    <Field
                        key={field.label}
                        fieldProperties={field}
                        onChange={(event) => onChange(field.formName, event.target.value)}
                        formValue={formValues[field.formName]}
                    />
                ) : (
                    <PrefixForm
                        key={field.label}
                        onChange={onPrefixChange}
                        formValue={prefixState}
                        formName={"prefix"}
                        fieldLabel={field.label}
                    />
                )
            )}
            {isProfessional && (
                <BankForms onChange={onChange} formValues={formValues} />
            )}
        </>
    );
};

const ContactForm = ({
    onChange,
    privateDetails,
    fields,
    isProfessional,
    onPrefixChange,
    prefixState,
}) => {
    return (
        <MainWrapper>
            {isProfessional && <Header />}

            <Forms
                onChange={onChange}
                onPrefixChange={onPrefixChange}
                formValues={privateDetails}
                prefixState={prefixState}
                fields={fields}
                isProfessional={isProfessional}
            />
        </MainWrapper>
    );
};

export default ContactForm;
