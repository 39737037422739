import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import {Badge, Grid} from '@material-ui/core';
import { ChoiceButton } from '../AdminHome';

const Wrapper = styled(Box)`
    margin: 10px;
    padding: 5px;
`;

const AdminChoice = ({ choice, toRender,pendingAmount,selected}) => {

    const color = ()=> selected===choice ? 'green' : ''
    return (

        <Wrapper align='center'>
            <Grid item xs={12}>

                    <Badge color='secondary' badgeContent={pendingAmount}>
                        <ChoiceButton
                            variant='contained'
                            size="small"
                            onClick={() => {toRender(choice)}}
                            style={{background:color()}}
                        >
                            {choice}
                        </ChoiceButton>
                    </Badge>
            </Grid>
        </Wrapper>
    )
}

export default AdminChoice
