import { queryWholeCollection } from "./FirestoreActions";
import { ADMIN_GET_ALL_PROS, ADMIN_SET_CAL_PRICE, ADMIN_SET_LOADING, ADMIN_SET_UPDATE } from "./types";




export const adminFetchAllProfessionals = () => async dispatch => {

    dispatch(adminSetLoading(true))

    const admin = {
        Approved: [],
        Pending: [],
        inProgress: [],
        Suspended: [],
    }

    const sortArray = (profObject) => {
        try {
            
            switch (profObject.pending.status) {
                case 'removed':
                    admin.Suspended.push(profObject)
                    break;
                case false:
                    admin.Approved.push(profObject)
                    break;
                case true:
                    admin.Pending.push(profObject)
                    break;
                default:
                    admin.inProgress.push(profObject)
            }
        } catch (e) {
            admin.inProgress.push(profObject) }
    }

    const res = await queryWholeCollection("professionals");
    if (res.size > 1) {
        res.forEach((pro, index) => {
            sortArray(pro.data())
        })
    }
    dispatch({
        type:ADMIN_GET_ALL_PROS,
        payload:admin
    })
    dispatch(adminSetLoading(false))


}

export const setUpdate = (bool) => dispatch => {

    dispatch({
        type: ADMIN_SET_UPDATE,
        payload: bool
    });
}
export const adminSetLoading = (bool) => dispatch => {

    dispatch({
        type: ADMIN_SET_LOADING,
        payload: bool
    });
}

export const setCalPrice = (value) => dispatch => {
 

    dispatch({
        type: ADMIN_SET_CAL_PRICE,
        payload: value
    });
}