import React from 'react';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LEAD_BLUE } from '../../../../utils/globals';

const HeaderWrapper = styled(TableHead)`
    background-color: ${LEAD_BLUE};
`;

const CellWrapper = styled(TableCell)`
    font-size: 15px;
    color: white;
`;

const TableHeader = ({ headers, bgColor=null }) => {
    return (
        <HeaderWrapper>
            <TableRow>
                {
                    headers.map(headCell => (
                            <CellWrapper
                                align="center"
                                style={{background: bgColor}}
                                key={headCell.id}
                            >
                                {headCell.label}
                            </CellWrapper>
                        )
                    )
                }
            </TableRow>
        </HeaderWrapper>
    );
};

export default TableHeader
