import { MenuItem, Select } from "@material-ui/core"
import { DEPRESSIONANXIETY, EATINGDISORDERS, FAMILY, KIDSANDYOUTH, WORKCAREER } from "../../../../utils/globals"
import { translateType } from "../../../pages/categories/Search/SearchPage"

const ExpertiseSelect = ({ exp, handleChangeFirst, width }) => {

  if (exp === 0)
    exp = -1

  return (
    <>
      <Select
        value={exp}
        style={{ width: width, textAlign: "center" }}
        onChange={(e) => handleChangeFirst(e)}
      >
        <MenuItem value={1}>{translateType(FAMILY)}</MenuItem>
        <MenuItem value={2}>{translateType(KIDSANDYOUTH)}</MenuItem>
        <MenuItem value={3}>{translateType(DEPRESSIONANXIETY)}</MenuItem>
        <MenuItem value={4}>{translateType(EATINGDISORDERS)}</MenuItem>
        <MenuItem value={5}>{translateType(WORKCAREER)}</MenuItem>
        <MenuItem value={6}>פגיעות מיניות</MenuItem>
        <MenuItem value={7}>התמכרויות</MenuItem>
        <MenuItem value={8}>פסיכו-גריאטריה</MenuItem>
        <MenuItem value={0}>נקה</MenuItem>
        {/* <MenuItem value={-1}> </MenuItem> */}
        {/* <MenuItem value={6}>{translateType(OTHER)}</MenuItem> */}
      </Select>
    </>
  )
}

export default ExpertiseSelect;