import React from "react";
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

const FormWrapper = styled.div`
  display: grid;
  direction: rtl;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 7px;
  width: 100%;
  height: auto;
`
const Input = styled.input`
  color: #233f89;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
  height: 20px;
  border: none;
  max-width: 300px;
  //Place holder style
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #233f89;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #233f89;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #233f89;
  }
`
const Submit = styled.input`
  background-color: #6e6e2e;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-width: 0;
  width: 95%;
  height: 85%;
  max-width: 150px;
  text-align: center;
  margin-left: 5px;
  @media (max-width: 600px) {
    max-width: 220px;
  }

  &:hover {
    cursor: pointer;
  }
`

const InputWrapper = styled.div`
  margin-left: 10px;
`
const FooterSubmit = styled.input`
  background-color: #6e6e2e;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-width: 0;
  width: 100%;
  height: 30px;
  max-width: 150px;

  &:hover {
    cursor: pointer;
  }
`
const SubmitWrapper = styled.div`
  text-align: center;
  max-width: 300px;
  margin-left: 5px;
`

/**
 *
 * @param position in the page we have two possible positions for the contact form
 * @param form data passed from parent
 * @param handleChange onchange trigger
 * @param submit function passed from parent
 * @returns {JSX.Element}
 */
const ContactForm = ({position, form, handleChange, submit}) => {

    if (position === 'footer') {
        return (
            <>
                <FormWrapper>
                    <form>
                        <Grid container>
                            <Grid item xs={2}/>
                            <Grid item xs={8}>
                                <InputWrapper>
                                    <Input type={"text"} name={"name"} value={form.name} placeholder={"שם מלא:"}
                                           onChange={e => handleChange(e)}/>
                                </InputWrapper>
                                <InputWrapper>
                                    <Input type={"text"} name={"phone"} value={form.phone} placeholder={"טלפון:"}
                                           onChange={e => handleChange(e)}/>
                                </InputWrapper>
                                <InputWrapper>
                                    <Input type={"text"} name={"email"} value={form.email} placeholder={"מייל:"}
                                           onChange={e => handleChange(e)}/>
                                </InputWrapper>
                                <SubmitWrapper>
                                    <FooterSubmit type="submit" value="שלח\י" onClick={(e) => {
                                        submit(e)
                                    }}/>
                                </SubmitWrapper>
                            </Grid>
                        </Grid>
                    </form>
                </FormWrapper>
            </>
        );
    } else {
        //Header form
        return (
            <>
                <FormWrapper>
                    <form>
                        <Grid container
                              justify={"center"}>
                            <Grid item xs={6} sm={3}>
                                <InputWrapper>
                                    <Input type={"text"} name={"name"} value={form.name} placeholder={"שם מלא:"}
                                           onChange={e => handleChange(e)}/>
                                </InputWrapper>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <InputWrapper>
                                    <Input type={"text"} name={"phone"} value={form.phone} placeholder={"טלפון:"}
                                           onChange={e => handleChange(e)}/>
                                </InputWrapper>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <InputWrapper>
                                    <Input type={"text"} name={"email"} value={form.email} placeholder={"מייל:"}
                                           onChange={e => handleChange(e)}/>
                                </InputWrapper>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Submit type="submit" value="שלח\י" onClick={(e) => {
                                    submit(e)
                                }}/>
                            </Grid>
                        </Grid>
                    </form>
                </FormWrapper>
            </>
        )
    }
}

export default ContactForm;