import React from 'react';
import styled from 'styled-components';
import Alert from '@material-ui/lab/Alert';
import {routeQuery} from "../../utils/routes";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import {LEAD_BLUE} from "../../utils/globals";

const AlertWrapper = styled.div`
    margin: 5px;
    padding: 5px;
`;

const StyledAlert = styled(Alert)`
    border: 1px solid #006bb3!important;
    direction: rtl;
    font-family: 'Rubik', Sens-Serif;
    color:black;
    
`;
const LinkWrapper = styled(Link)`
    text-decoration: none;
    cursor: pointer;
`;
const LoginButton = styled(Button)`
    border-color: ${LEAD_BLUE};
    font-family: 'Rubik', Sans-Serif;
    font-weight: bold;
`;

const CustumAlert = ({type, msg}) => {
    return (
        <AlertWrapper>
            <StyledAlert
                variant="outlined"
                severity={type}
            >
                {msg+" "}
                {type === 'unAuthWarning' ?
                    <LinkWrapper to={routeQuery({box: 'register'})}>
                        <LoginButton
                            variant="outlined"
                        >
                            הרשמה
                        </LoginButton>
                    </LinkWrapper>
                    :
                    null}
            </StyledAlert>
        </AlertWrapper>
    );
};

export default CustumAlert;
