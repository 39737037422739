import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TableToolBar from './TableToolBar';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { DaysTable } from './DaysTable';
import { FilterByPastDates, FilterByUpcomingDates } from './tableUtils';
import { INFO } from '../../../../utils/globals';

const PaperWrapper = styled(Paper)`
    padding: 15px;
    background-color: #F0F0F0;
    @media (max-width: 600px) {
        width: 350px;
    }
    @media (max-width: 460px) {
        width: 250px;
    }
`;

const headers = [

    { id:"info", label: " " },
    { id: "link", label: " " },
    { id: "date", label: "תאריך" },
    { id: "meetings", label: "מספר פגישות" },

  ];

const MeetingsTable = ({ meetingsState, id, isProfessional, requestedTable }) => {

    const { newMeetings } = meetingsState;
    // This state holds the current rows to display per page
    const [ rowsPerPage, setRowsPerPage ] = useState(4);
    // Handling the state of a page change.
    const [ page, setPage ] = useState(0);
    // Getting the relevant meetings for a given professional
    const [ myMeetings, setMyMeetings ] = useState({
        original: [],
        filtered: []
    });
    // State is used to close all collapses when moving pages.
    const [ resetCollapse, setResetCollapse ] = useState(false);
    // State is responsible of closing and opening the search bar
    const [ open, setOpen ] = useState(false);
    // State takes care of menu
    const [ menu, setMenu ] = useState(null);

    useEffect(() => {
        let professionalMeetings = Object.keys(newMeetings).map(date => newMeetings[date]);
        sortByDate(professionalMeetings);
        setMyMeetings({
            original: professionalMeetings,
            filtered: professionalMeetings
        });
    }, [newMeetings]);

    const sortByDate = allMeetings => {
        allMeetings.sort((a, b) => {
            let aDate = new Date(a[0].date.day);
            let bDate = new Date(b[0].date.day);
            return bDate - aDate   
        })
    };

    const collapseState = state => {
        setResetCollapse(state);
    };

    // Adding empty rows for table look.
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, myMeetings.filtered.length - page * rowsPerPage);

    // Changing the current page on page change event.
    const handleChangePage = (event, newPage) => {
        collapseState(true);
        setPage(newPage);
      };
    
    // Chaning the amount of rows per page and resetting the current page.
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleMenuClick = event => {
        setMenu(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenu(null);
    };

    const handleOptionClick = option => {
        handleMenuClose();
        switch (option) {
            case "All":
                setMyMeetings({
                    ...myMeetings,
                    filtered: myMeetings.original
                });
                return;         
            case "Past":
                FilterByPastDates(setMyMeetings, myMeetings);
                return;     
            case "Upcoming":
                FilterByUpcomingDates(setMyMeetings, myMeetings);
                return;
            default: 
                return {};
        }
    };

    // Function closes and opens search bar, on close, resetting meetings form to original (from db) form.
    const setSearchBar = state => {
        if (!state) {
            setOpen(state);
            setMyMeetings({
                ...myMeetings,
                filtered: myMeetings.original
            });
        } else {
            setOpen(state);
        };
    };

    // If search value is an empty string, resetting meetings to original.
    const resetSearchBar = searchValue => {
        if (searchValue === '') {
            setMyMeetings({
                ...myMeetings,
                filtered: myMeetings.original
            });
            return true;
        };
        setPage(0);
        return false;
    };

    // Algorithem of the search bar, displays different results based on search.
    const onSearchBar = event => {
        const valueToFilterBy = event.target.value;
        if (resetSearchBar(valueToFilterBy)) {
            return;
        };
        // eslint-disable-next-line
        const filteredMeetings = myMeetings.original.filter(meeting => {
            for (let obj = 0; obj < meeting.length; obj++) {
                // eslint-disable-next-line
                for (const [key, value] of Object.entries(meeting[obj])) {
                    if (String(value).indexOf(valueToFilterBy) > -1 ) {
                        return meeting
                    };
                    if (typeof(value) === 'object') {
                        // eslint-disable-next-line
                        for (const [nestedKey, nestedValue] of Object.entries(value)) {
                            if (String(nestedValue).indexOf(valueToFilterBy) > -1 ) {
                                return meeting
                            };
                        };
                    };
                };
            };
        });
        setMyMeetings({
            ...myMeetings,
            filtered: filteredMeetings
        });
    };

    return (

        <PaperWrapper>
            <TableToolBar 
                onSearchBar={onSearchBar} 
                open={open} 
                setSearchBar={setSearchBar}
                menu={menu}
                handleMenuClick={handleMenuClick}
                handleOptionClick={handleOptionClick}
                handleMenuClose={handleMenuClose}
                label={requestedTable === INFO ? "מפגשים" : "הכנסות"}
            />
            <DaysTable
                myMeetings={myMeetings}
                page={page}
                rowsPerPage={rowsPerPage}
                id={id}
                resetCollapse={resetCollapse}
                setResetCollapse={setResetCollapse}
                headers={headers}
                emptyRows={emptyRows}
                isProfessional={isProfessional}
                requestedTable={requestedTable}
            />
            <TablePagination
                rowsPerPageOptions={[4, 8, 16]}
                component="div"
                count={myMeetings.filtered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

        </PaperWrapper>
    );
};

export default MeetingsTable
