import React from 'react';
import AuthModal from '../components/modals/AuthModal';
import ProfessionalModal from '../components/modals/ProfessionalModal';
import VideoModal from '../components/modals/VideoModal';
import ProfessionalZone from '../components/modals/ProfessionalZone';
import QuizModal from '../components/modals/QuizModal';
import MessagesModal from '../components/modals/MessagesModal';
import PolicyModal from '../components/modals/PolicyModal';
import OptionsModal from '../components/modals/OptionsModal';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import AdminEditModal from '../components/modals/AdminEditModal';

const Modals = () => {
    return (
        <div>
            <AuthModal />
            <ProfessionalModal />
            <VideoModal />
            <ProfessionalZone />
            <PolicyModal />
            <QuizModal />
            <MessagesModal />
            <OptionsModal />
            <ConfirmationModal />
            <AdminEditModal/>
        </div>
    )
}

export default Modals
