import React from 'react';
import Header from './Header';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import bgImage from '../../../static/images/geniePictures/jinisbg.png';
import mobilebg from '../../../static/images/geniePictures/mobilebg.png';



const HomeWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 50% 50%;
  direction: rtl;
  background: url(${bgImage});
  background: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), url(${bgImage});
  background-size: cover;
  background-position: center;
 min-height: 75vh;

  @media (max-width: 1300px) {
    grid-template-columns: 70% 30%;
  };
  @media (max-width: 600px) {
    background: linear-gradient(rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), url(${mobilebg});
     grid-auto-flow: row;
    grid-template-columns: max-content;
    background-size: cover;
    padding: 0;
        
  };
`;

const Home = () => {
    return (
     <>
         <HomeWrapper className="header">
             <Header/>
         </HomeWrapper>

     </>

    );
};

export default Home;
