import React, { useState } from "react";
import CalanderHeader from "../../../calander/calendarUtils/CalanderHeader";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { LEAD_BLUE, LEGAL_FORM } from "../../../../../utils/globals";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";

import { Form, Formik } from "formik";
import {
  checkAddress,
  checkName,
  emailValidation,
  idValidation,
  phoneValidation,
  StyledInput,
  validateNotEmpty,
} from "../../../../pages/authForms/AuthHelpers";
import Spinner from "../../../../layout/Spinner";
import {
  updateDocument,
  updateFieldInCollection,
} from "../../../../../actions/FirestoreActions";

import {
  getCurrent,
  setError,
} from "../../../../../actions/ProfessionalsActions";
import CustomAlert from "../../../../layout/CustomAlert";

const MainWrapper = styled.div`
  direction: rtl;
  display: grid;
  justify-items: flex-start;
  align-items: center;
  padding: 10px;
`;

const OpenMynd = styled.span`
  font-weight: bold;
`;

export const FormWrapper = styled(Form)`
  display: grid;
  justify-items: center;
  align-items: center;
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  margin: 5px;
`;

export const ButtonStyle = styled(Button)`
  margin: 5px;
  padding: 5px;
  color: white;
  background-color: ${LEAD_BLUE};

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.8;
  `}
  &:hover {
    background-color: ${LEAD_BLUE};
  }
`;

const Table = styled.table`
  font-family: "Rubik", Sens-Serif, sans-serif;
  width: 100%;
  padding: 10px;
  @media (max-width: 578px) {
    width: 90%;
  } ;
`;
// const jiniz = styled.span`
//   font-family: 'Arial', sans-serif;
// `;

const Header = ({ professionalName, legalURL }) => {
  return (
    <>
      <CalanderHeader header={"שלום " + professionalName} description={""} />
      <div style={{ padding: "5px", fontFamily: "Rubik,Sens-Serif" }}>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        עליך לקרוא תחילה את{" "}
        <a target={"_blank"} rel={"noreferrer"} href={legalURL}>
          תנאי השירות{" "}
        </a>{" "}
        ולאשר את טופס ההצטרפותך לשירות <jiniz>"OpenMynd"</jiniz>, כאן למטה.
      </div>
    </>
  );
};

const LegalForm = ({
  privateDetails,
  error,
  updatePrivateDetailsLocally,
  settingsForm,
  updatePublicDetailsState,
}) => {
  const [isDisabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const userId = user.id;
  const dispatch = useDispatch();

  const handleDisableChange = () => {
    setDisabled(!isDisabled);
  };
  let errors = {};

  const displayError = (errors) => {
    if (Object.keys(errors).length === 0) {
      dispatch(setError(false));
    } else {
      dispatch(
        setError({
          type: "error",
          msg: "יש למלא את כל השדות",
        })
      );
    }
  };

  const signDate = moment().format("DD-MM-YYYY");
  //assign the date attribute to the private details of a professional
  privateDetails.signDate = signDate;
  const { firstName, lastName, email, address, localPhone, idNumber } =
    privateDetails;

  const updatePrivateDetails = async (data) => {
    setLoading(true);
    await updateDocument("users", userId, data); //update data on user details
    //update form on firestore
    await updateFieldInCollection(
      "professionals",
      userId,
      "isLegalDocConfirmed",
      true
    );
    //update local state
    await dispatch(getCurrent(userId)); //update redux state
    //update local state
    updatePrivateDetailsLocally({
      //private details
      ...privateDetails,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      idNumber: data.idNumber,
      address: data.address,
      signDate: data.signDate,
      localPhone: data.localPhone,
    });
    await updatePublicDetailsState({
      //public details
      ...settingsForm,
      isLegalDocConfirmed: true,
      expertiseState: {
        ...settingsForm.expertiseState,
        profession: data.occupation,
      },
    });
    setLoading(false);
  };

  return (
    <MainWrapper>
      <Header
        professionalName={privateDetails.firstName}
        legalURL={LEGAL_FORM}
      />
      <Formik
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          email: email,
          address: address,
          localPhone: localPhone,
          idNumber: idNumber,
          occupation: settingsForm.expertiseState.profession,
        }}
        validate={(values) => {
          errors = {};
          checkName(errors, values.firstName, values.lastName);
          checkAddress(errors, values.address);
          emailValidation(errors, values.email);
          phoneValidation(errors, values.localPhone);
          idValidation(errors, values.idNumber);
          validateNotEmpty(errors, values.occupation);

          return errors;
        }}
        onSubmit={(data) => {
          data = {
            ...data,
            signDate: signDate,
          };

          if (errors.length !== undefined) {
            console.log("inside,", errors)
            displayError(errors);
          }

          //  const applicationToUserRef = genericEmail(data, "proWelcomeEmail");
          //  const notifyAdmin = genericEmail(data, "notifyAdminProHasSigned");
          //   Promise.resolve(applicationToUserRef, notifyAdmin);

          updatePrivateDetails(data).catch((e) => {
            console.log(e)
            dispatch(
              setError({
                type: "warning",
                message: e,
              })
            )
          }

          ).finally(() => {
            setLoading(false)
          })
        }}
      >
        {() => (
          <>
            <FormWrapper>
              <Grid container>
                <Grid item sm={12} lg={6}>
                  <StyledInput
                    name="firstName"
                    type="input"
                    placeholder="שם פרטי"
                    label="שם פרטי"
                  />
                  <StyledInput
                    name="lastName"
                    type="input"
                    placeholder="שם משפחה"
                    label="שם משפחה"
                  />
                  <StyledInput
                    name="idNumber"
                    type="input"
                    placeholder="ת.ז"
                    label="תעודת זהות"
                  />
                </Grid>
                <Grid item sm={12} lg={6}>
                  <StyledInput
                    name="address"
                    type="input"
                    placeholder="כתובת"
                    label="כתובת"
                  />
                  <StyledInput
                    name="email"
                    type="input"
                    placeholder='דוא"ל'
                    label="אימייל"
                  />
                  <StyledInput
                    name="localPhone"
                    type="input"
                    placeholder="דוגמה: 0512345678"
                    label="טלפון נייד"
                  />
                  <StyledInput
                    name="occupation"
                    type="input"
                    placeholder="תחום עיסוק"
                    label="תחום עיסוק"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={0} md={1} />
                <Grid item sm={12} md={10}>
                  <div>
                    <Table>
                      <tr>
                        <td style={{ width: "30%" }}>מועד הצטרפות לשירות:</td>
                        <td>{signDate}</td>
                      </tr>
                      <tr>
                        <td>תקופת ההתקשרות:</td>
                        <td>
                          בכפוף להוראות התנאים הכלליים, הסכם זה יעמוד בתוקף החל
                          ממועד חתימת המטפל/ת על טופס ההצטרפות ועד להודעת אחד
                          מהצדדים למשנהו על רצונו בסיום ההסכם, בכתב, במפורש
                          ולפחות 15 (חמישה עשר) ימי עסקים מראש (<OpenMynd>"תקופת ההסכם"</OpenMynd>).
                          נמסרה הודעה כאמור, יפקע תוקף ההסכם במועד הנקוב בהודעה.
                          המטפל/ת מצהיר/ה ומתחייב/ת כי ת/יעמוד בכל ההתחייבות
                          כלפי משתמשי השירות ומפעילת השירות במהלך תקופת ההסכם.
                          מוסכם על הצדדים כי הצדדים ימלאו את התחייבויותיהם לפי
                          ההסכם החל מהמועד הנקוב מעלה בטופס זה.
                        </td>
                      </tr>
                      <tr>
                        <td>שיעור העמלה עבור שירות המומחה למשתמשי השירות:</td>
                        <td>
                          67% - עד לשבעה מפגשים
                          <tr> 75% - בין 8 ל-10 מפגשים</tr>
                          <tr> 80% - מעל 10 מפגשים</tr>
                        </td>
                      </tr>
                    </Table>
                  </div>
                  <div
                    style={{ padding: "10px", fontFamily: "Rubik,Sens-Serif" }}
                  >
                    ברכות על הצטרפותך לשירות <OpenMynd>"OpenMynd"</OpenMynd> – פלטפורמה ייחודית
                    המאפשרת למשתמשי השירות לקבל שירות ממטפלים ויועצים אשר יקנו
                    להם כלים כדי לפתור את מה שמטריד אותם כאן ועכשיו, בשיחות
                    וידאו אישיות ודיסקרטיות במחיר מוזל ("השירות")
                  </div>
                  <div
                    style={{ padding: "10px", fontFamily: "Rubik,Sens-Serif" }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        textDecoration: "underline",
                      }}
                    >
                      אישור
                    </div>
                    המטפל/ת שפרטיו/ה מפורטים בטופס זה מאשר/ת בזאת את הצטרפותו/ה
                    לשירות <OpenMynd>"OpenMynd"</OpenMynd>. טופס הצטרפות זה,
                    ביחד עם הוראות התנאים הכלליים להתקשרות בין המטפל/ת לבין
                    מפעילת השירות ("התנאים״) מהווים את ההסכם המלא בין המטפל/ת
                    לבין מפעילת השירות. התנאים מהווים חלק בלתי נפרד מטופס
                    התקשרות זה ובחתימתך מטה את/ה מאשר/ת כי קראת אותם, הבנת אותם
                    וכי את/ה מסכים/ה להם. במידה והמטפל/ת פועל/ת באמצעות תאגיד,
                    אזי החתום/ה מטה מטעם התאגיד מאשר/ת בחתימתו/ה כי הוא/היא
                    מורשה/ת חתימה מטעם התאגיד ומוסמך/ת לחייב את התאגיד לעניין
                    טופס הצטרפות זה והתנאים, וכי נתקבלו אצל התאגיד כל ההחלטות
                    הנדרשות לצורך ההתקשרות בטופס הצטרפות זה והתנאים. עוד מאשר/ת
                    הח"מ, שפרטיו/ה האישיים מפורטים לעיל, כי י/תמלא את חובותיו/ה
                    כמטפל/ת בשירות באופן אישי מטעם התאגיד.
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "80%",
                      padding: "10px",
                      fontFamily: "Rubik,Sens-Serif",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>
                      <Checkbox
                        style={{ margin: "-10px", color: LEAD_BLUE }}
                        onChange={handleDisableChange}
                        color="primary"
                      />
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      קראתי את{" "}
                      <a href={LEGAL_FORM} target={"_blank"} rel={"noreferrer"}>
                        תנאי השירות
                      </a>{" "}
                      ואני מאשר את תנאי ההסכם
                    </span>

                    {error !== false ? (
                      <CustomAlert type={error.type} msg={error.msg} />
                    ) : null}
                    {loading ? (
                      <Spinner />
                    ) : (
                      <ButtonStyle
                        name={"submit"}
                        type={"submit"}
                        onClick={() => displayError(errors)}
                        whileHover={{ scale: 1.1 }}
                        initial={{ x: "0" }}
                        disabled={isDisabled}
                      >
                        אישור
                      </ButtonStyle>
                    )}
                  </div>
                </Grid>
                <Grid item sm={0} md={1} />
              </Grid>
            </FormWrapper>
          </>
        )}
      </Formik>
    </MainWrapper>
  );
};

export default LegalForm;
