import React from 'react'
import {Wrapper} from '../../PaymentBoiler';
import CustomAlert from '../../../../layout/CustomAlert';
import Spinner from '../../../../layout/Spinner';
import {updateMeetingObject} from '../../paymentActions';
import {getEntireDocument} from '../../../../../actions/FirestoreActions';
import {
    createMeetingToken,
    postMeetings,
    updateMeetings,
    updateMeetingToken
} from '../../../../../actions/MeetingsActions';
import PaymentForm from './PaymentForm';

export const postNewMeeting = async (professionalId, day, hour, id, firstName,
                                     lastName, email, price, proFirstName, proLastName, proEmail, professionalURL, amount, dispatch) => {


    const meeting = updateMeetingObject(professionalId, day, hour.start, hour.end,
        id, firstName, lastName, email, price, proFirstName, proLastName, proEmail, professionalURL);

    // Checking if the requested day exists
    const profRes = await getEntireDocument("professionalMeetings", professionalId);
    if (profRes.type === 'success') {
        // Data exists, lets update
        dispatch(updateMeetings(day, meeting, "professionalMeetings", professionalId));
    } else {

        // Data does not exists, creating a new document for professional
        dispatch(postMeetings(day, meeting, "professionalMeetings", professionalId));
    }

    delete meeting.notes;
    const userRes = await getEntireDocument("userMeetings", id);
    if (userRes.type === 'success') {
        // Data exists, lets update
        dispatch(updateMeetings(day, meeting, "userMeetings", id));
    } else {
        // Data does not exists, creating a new document for professional
        dispatch(postMeetings(day, meeting, "userMeetings", id));
    }

};
/**
 * In case a customer purchased three meetings pack
 * Post 2 tokens in the DB in the proper place and update Redux.
 * @param profID
 * @param userId
 * @param dispatch
 */
export const postTokens = async (profID, userId, dispatch) => {
    const tokenRes =await  getEntireDocument('usersMeetingTokens', userId)
    if (tokenRes.type === 'success') {
        dispatch(updateMeetingToken(profID, userId))
    } else {
        dispatch(createMeetingToken(profID, userId))
    }
}


const PaymentAlert = ({loading, error}) => {
    return (
        <>
            {
                loading ? <Spinner/> : !!error.msg &&
                    <CustomAlert
                        type={error.type}
                        msg={error.msg}
                    />
            }
        </>
    );
};

const NewMeetingPayment = ({
                               onPayClick, prevStep, phone, onExit,
                               error, ongoingPayment, loading, price, type, fullName, email, handleBack,
                               handleNext, userID, quantity, resetStepper, googleAuth, meetingAmount,sendConfirmationEmails
                           }) => {

    return (
        <Wrapper>
            <PaymentForm
                price={price}
                type={type}
                ongoingPayment={ongoingPayment}
                onPayClick={onPayClick}
                prevStep={prevStep}
                fullName={fullName}
                email={email}
                handleNext={handleNext}
                phone={phone}
                handleBack={handleBack}
                onExit={onExit}
                userID={userID}
                quantity={quantity}
                resetStepper={resetStepper}
                meetingAmount={meetingAmount}
                sendConfirmationEmails={sendConfirmationEmails}
            />
            <PaymentAlert
                error={error}
                loading={loading}
            />
        </Wrapper>
    )
};

const NewMeeting = ({
                        onPayClick, prevStep, error, onExit,
                        ongoingPayment, loading, price, type, fullName, email, phone, handleBack,
                        handleNext, userID, quantity, resetStepper, googleAuth, meetingAmount,sendConfirmationEmails
                    }) => {
    return (
        <>
            {
                loading ? <Spinner/> :
                    <NewMeetingPayment
                        onPayClick={onPayClick}
                        prevStep={prevStep}
                        handleNext={handleNext}
                        error={error}
                        loading={loading}
                        onExit={onExit}
                        handleBack={handleBack}
                        ongoingPayment={ongoingPayment}
                        price={price}
                        type={type}
                        phone={phone}
                        fullName={fullName}
                        email={email}
                        userID={userID}
                        quantity={quantity}
                        resetStepper={resetStepper}
                        meetingAmount={meetingAmount}
                        sendConfirmationEmails={sendConfirmationEmails}
                    />
            }
        </>
    );
};

export default NewMeeting
