import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import styled from 'styled-components';
import {TableButton} from '../../modals/ProfessionalZone';
import {motion} from 'framer-motion';

const inputVariants = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition: {
            opacity: {duration: 1}
        }
    }
}

const MainWrapper = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    grid-gap: 10px;
    padding: 10px;
`;

const InputFieldWrapper = styled(motion.div)`
    display: grid;
    justify-items: right;
    align-items: start;
`;
const TextInput = styled(motion.div)`
    background-color: red;
`;

const ButtonsWrapper = styled(motion.div)`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const NavigateButtons = ({hour, nextStep, prevStep, modifyAnExistingMeeting}) => {
    return (
        <>
            {
                hour &&
                <ButtonsWrapper
                    variants={inputVariants}
                    initial="initial"
                    animate="animate"
                >
                    <TableButton
                        onClick={nextStep}
                        whileHover={{scale: 1.1}}
                    >
                        הבא
                    </TableButton>
                    {
                        !modifyAnExistingMeeting &&
                        <TableButton
                            onClick={prevStep}
                            whileHover={{scale: 1.1}}
                            style={{margin: '10px'}}
                        >
                            חזור
                        </TableButton>
                    }
                </ButtonsWrapper>
            }
        </>
    );
};

const InputFields = ({day, hoursList, hour, onOptionSelected}) => {
    return (
        <InputFieldWrapper
            variants={inputVariants}
            initial="initial"
            animate="animate"
        >
            <TextField
                name="day"
                label="יום"
                value={day}
                className={TextInput}
                style={{direction: 'rtl', width: '100%'}}
                InputProps={{
                    readOnly: true,
                }}
            />

            {hoursList &&
            <FormControl style={{width: 200}}>
                <InputLabel
                    id='hourLabel'
                    style={{direction: 'rtl'}}
                >
                    בחר/י שעה
                </InputLabel>
                <Select
                    value={hour ? hour : 'בחר/י שעה'}
                    onChange={event => onOptionSelected(event, "hour")}
                    input={<Input name="hour"/>}
                    label="שעת ההתחלה"
                    labelId='hoursLabel'
                    style={{direction: 'rtl', width: '100%'}}
                    defaultOpen={true}
                >
                    {hoursList.map((hour, index) =>
                        <MenuItem
                            value={hour}
                            key={index}
                            style={{direction: 'rtl'}}
                        >
                            {hour.start} - {hour.end}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            }
        </InputFieldWrapper>
    )
};

const CalanderForm = ({
                          hoursList, day, hour,
                          onChange, nextStep, prevStep, modifyAnExistingMeeting
                      }) => {

    const onOptionSelected = (event, name) => {
        onChange(name, event.target.value);
    };

    return (
        <MainWrapper>
            <InputFields
                day={day}
                hoursList={hoursList}
                hour={hour}
                onOptionSelected={onOptionSelected}
            />
            <NavigateButtons
                hour={hour}
                nextStep={nextStep}
                prevStep={prevStep}
                modifyAnExistingMeeting={modifyAnExistingMeeting}
            />

        </MainWrapper>
    );
};

export default CalanderForm
