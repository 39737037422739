import React from 'react'
import CalanderHeader from '../calander/calendarUtils/CalanderHeader';
import { INFO, LEAD_BLUE } from '../../../utils/globals';
import styled from 'styled-components';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const MainWrapper = styled.div`
    padding: 20px 0;
    margin: 10px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.20), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.10),
              0 16px 16px rgba(0,0,0,0.05);
    direction: rtl;
    font-family: 'Rubik', Sens-Serif,sans-serif;
`;

const Paragraph = styled.div`
    padding: 0 10px;
`;

const SmallDot = styled(FiberManualRecordIcon)`
    font-size: 12px;
    margin-left: 20px;
    color: ${LEAD_BLUE};
`;

const BulletListWrapper = styled.div`
    padding: 3px 20px;
`;

const ListItem = styled.div`   
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const UserHeader = () => {
    return (
        <MainWrapper align="right">
            <Paragraph>כאן ניתן:</Paragraph>
            <BulletListWrapper>
                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        לעקוב אחר מפגשים שהשתתפת בהם. 
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        לכתוב חוות דעת על המטפלים שלך - חוות הדעת תופיע בעמוד המטפל תחת שם פרטי בלבד.
                    </Paragraph>
                </ListItem>

                <ListItem>
                    <SmallDot />
                    <Paragraph>
                        לשנות עד 24 שעות לפני המועד ולבטל עד 48 שעות לפני המועד.
                    </Paragraph>
                </ListItem>
            </BulletListWrapper>

            <Paragraph><br></br>ניתן לקבוע מפגש רק מתוך עמוד המטפל/ת.</Paragraph>
        </MainWrapper>
    );
};

const ProfessionalHeader = () => {
    return (
        <CalanderHeader
            description="כאן ניתן לעקוב אחר מהמפגשים שלך, לכתוב לעצמך הערות ולקרוא חוות דעת של לקוחות."
        />
    );
};

const FinancialHeader = () => {
    return (
        <CalanderHeader 
            description="כאן ניתן לראות את ההכנסות בחתך חודשי או לפי שם לקוח."
        />
    );
};

const Preview = ({ isProfessional, requestedTable }) => {
    return (
        <>
            {
                requestedTable === INFO ? 
                    isProfessional ? 
                        <ProfessionalHeader /> :
                        <UserHeader />
                    :
                        <FinancialHeader />
            }       
        </>
    );
};

const MeetingsHeader = ({ isProfessional, requestedTable }) => {
    return (
        <Preview 
            isProfessional={isProfessional}
            requestedTable={requestedTable}
        />
    )
}

export default MeetingsHeader
