import {
    GET_MEETINGS,
    SET_MEETINGS,
    UPDATE_MEETINGS,
    SET_MEETINGS_LOADING,
    SET_MEETINGS_ALERT,
    CLEAR_MEETINGS_ALERT, GET_USER_TOKENS,
} from "./types";
import {
    updateArray,
    setArray,
    getEntireDocument,
    updateFieldInCollection,
    updateArrayField,
    removeDocument,
    deleteArray,
} from "./FirestoreActions";
import {getUserToken, setTokensLoading} from "./TokensActions";

// Getting the entire meetings object of OpenMynd
export const getMeetings = (collectionName, userId) => async dispatch => {
    dispatch(setMeetingsLoading(true));
    try {
        const res = await getEntireDocument(collectionName, userId);
        if (res.type === 'success') {
            dispatch({
                type: GET_MEETINGS,
                payload: res.data
            });
        } else {
            dispatch({
                type: SET_MEETINGS_ALERT,
                payload: {
                    type: 'warning',
                    msg: 'לא קיימים כרגע נתונים על מפגשים עבורך'
                }
            });
        }
        dispatch(setMeetingsLoading(false));

    } catch (error) {
        dispatch(setMeetingsLoading(false));
        console.error("Error at get meetings: ", error);
        dispatch({
            type: SET_MEETINGS_ALERT,
            payload: {
                type: 'error',
                msg: error
            }
        });
    }
};

// Setting a new meeting at the client & professional personal windows.
// Sending a date to acknowledge the function where to post the data.
export const postMeetings = (date, data, collection, id) => async dispatch => {

    dispatch(setMeetingsLoading(true));

    try {
        await setArray(collection, id, date, data);
        const res = await getEntireDocument(collection, id);
        dispatch(setMeetingsLoading(false));
        dispatch({
            type: SET_MEETINGS,
            payload: res.data
        });


    } catch (error) {
        dispatch(setMeetingsLoading(false));
        dispatch({
            type: SET_MEETINGS_ALERT,
            payload: {
                type: 'error',
                msg: error.response.data
            }
        });
    }
    ;
};

// Updating the meetings object in a given date with a given data.
// Returning an updated full meetings object.
export const updateMeetings = (date, data, collection, id) => async dispatch => {

    dispatch(setMeetingsLoading(true));

    try {
        await updateArray(collection, id, date, data);
        const res = await getEntireDocument(collection, id);
        dispatch(setMeetingsLoading(false));

        dispatch({
            type: UPDATE_MEETINGS,
            payload: res.data
        });

    } catch (error) {
        dispatch(setMeetingsLoading(false));
        dispatch({
            type: SET_MEETINGS_ALERT,
            payload: {
                type: 'error',
                msg: error.response.data
            }
        });
    }
    ;
};
/**
 * Adding 2 tokens to a user
 * always will create new filed,as if we drop down to zero tokens
 * @param profID
 * @param userID

 */
export const createMeetingToken = (profID, userID) => async dispatch => {

    dispatch(setTokensLoading(true))
    try {
        await setArray('usersMeetingTokens', userID, profID, 2)
        const res = await getEntireDocument('usersMeetingTokens', userID);
        dispatch(setTokensLoading(false))
        dispatch({
            type: GET_USER_TOKENS,
            payload: res.data
        })
    } catch (e) {
        console.log(e)

    }
}

/**
 * Adding 2 tokens to a user
 * always will create new filed,as if we drop down to zero tokens
 * @param profID
 * @param userID

 */
export const updateMeetingToken = (profID, userID) => async dispatch => {

    dispatch(setTokensLoading(true))
    try {
        await updateArray('usersMeetingTokens', userID, profID, 2)
        const res = await getEntireDocument('usersMeetingTokens', userID);
        dispatch(setTokensLoading(false))
        dispatch({
            type: GET_USER_TOKENS,
            payload: res.data
        })
    } catch (e) {
        console.log(e)

    }
}


/**
 * Function to decrease the tokens amount inside the DB
 * if the tokens value is 0 , delete the array to save space.
 * @param profID
 * @param userID
 * @param value
 * @param dispatch
 */
export const decreaseMeetingToken = async (profID, userID, value, dispatch) => {
    //Case which we have to delete the array
    if (value === 0) {
        try {
            console.log("delete array")
            const deleteRes = await deleteArray('usersMeetingTokens', userID, profID)
            if (deleteRes.type === 'success') {
                dispatch(getUserToken(userID))
            }
            //const coupRes1 = await updateFieldInCollection("usercoupons", userID, "balance", 3)
            //const coupRes2 = await updateFieldInCollection("usercoupons", userID, "status", "not active")
        } catch (e) {
            console.log(e)
        }
        //Case which we need to decrement token by one
    } else {
        try {
            const object = [value]
            const updateRes = await updateArrayField('usersMeetingTokens', userID, profID, object)
            console.log(updateRes)
            if (updateRes.type === 'success') {
                dispatch(getUserToken(userID))
            }
        } catch (e) {
            console.log(e)
        }
    }

}

export const setMeetingsLoading = loadingState => {
    return {
        type: SET_MEETINGS_LOADING,
        payload: loadingState
    };
};

export const setAlert = alert => {
    return {
        type: SET_MEETINGS_ALERT,
        payload: alert
    };
};

export const clearAlert = () => {
    return {
        type: CLEAR_MEETINGS_ALERT
    };
};

