import React from 'react';
import styled from 'styled-components';
import { LEAD_BLUE } from '../../../../utils/globals';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';
import { getStartHour } from '../calendarUtils/calendarHelpers';
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles(() => ({
//     root: {
//         "& .MuiFormLabel-root": {
//             right: 0,
//             left: 'auto',
//             marginRight: '10px',
//         }
//     }
// }));

const DayFieldWrapper = styled.div`
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-items: center;
   
    margin: 10px;
    padding: 10px;
    @media (max-width: 600px) {
        grid-template-columns: auto;
    }
`;

const AddIcon = styled(AddCircleIcon)`
    height: 35px;
    width: 35px;
    color: ${LEAD_BLUE};
`;

const IconsWrapper = styled.div`
    display: flex;
`;

const RenderDateField = ({ day, onAddClick, classes }) => {
    return (
        <DayFieldWrapper>
            {/* <TextField
                disabled
                value={day}
                label="תאריך"
                variant="standard"
                className={classes.root}
            /> */}
            <IconsWrapper>
                <Button
                    onClick={onAddClick}
                >
                    <AddIcon />
                </Button>
            </IconsWrapper>
        </DayFieldWrapper>
    );
};

const DateField = ({ setScheduleState, data }) => {
    const { current, date } = data;
    const { day, hours } = date;


    const useStyles = makeStyles({
        root: {

            border: 0,
            borderRadius: 3,
            fontWeight: 'bold'
            ,
            textAlign:'right',
            color: 'white',
        },
    });

    const classes = useStyles()
    const setDateObject = () => {
        const newHoursObject = {
            start: getStartHour(hours).startHour,
            end: getStartHour(hours).endHour
        };
        const newHoursList = [...hours, newHoursObject]
        const newDateObject = {
            [day]: newHoursList
        };
        return newDateObject;
    };

    const createNewDataObject = newDateObject => {
        if (!hours.length) {
            const newScheduleObject = [...current, newDateObject]
            const newDataObject = {
                ...data,
                current: newScheduleObject,
                date: {}
            };
            return newDataObject
        } else {
            // Duplicating current list
            let currentDup = [...current];
            let newCurrent = '';
            // The new object is newDateObject
            // Searching for the relevant date in order to remove the old hours
            for (let date = 0; date < currentDup.length; date++) {
                let loopedDate = Object.keys(currentDup[date])[0];
                const relevantDate = Object.keys(newDateObject)[0];
                if (loopedDate === relevantDate) {
                    currentDup = current.filter(loopedDay =>
                        Object.keys(loopedDay)[0] !== relevantDate
                    );
                    newCurrent = [...currentDup, newDateObject];
                };
            };
            // If there are hours it means that is not a new day
            const newDataObject = {
                ...data,
                current: newCurrent,
                date: {}
            };
            return newDataObject
        };
    };

    const onAddClick = () => {
        const newDateObject = setDateObject();
        let newDataObject = createNewDataObject(newDateObject);
        newDataObject = {
            ...newDataObject,
            date: {
                day: day,
                hours: Object.values(newDateObject)[0],
                isSaved: false
            }
        };
        setScheduleState("data", newDataObject);
    };

    return (
        <RenderDateField
            day={day}
            classes={classes}
            onAddClick={onAddClick}
        />
    )
}

export default DateField
