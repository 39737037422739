import React from 'react';
import styled from 'styled-components';
import { TextField, Box } from '@material-ui/core';
import { RED } from '../../../utils/globals';

const Wrapper = styled(Box)`
    direction: rtl;
    font-family: 'Rubik', Sans-Serif;
    padding: 6px;
`;

const Label = styled(Box)`
    color: ${RED};
    font-size: 14px;
`;

const TextInputs = ({ handleChange, contactState, newProfessional }) => {

    const { firstName, lastName, email,
            profession, specialization, message } = contactState;

    return (
        <div>
            <Wrapper>
                <Label>שם פרטי</Label>
                <TextField 
                    required 
                    name="firstName" 
                    value={firstName}
                    onChange={event => handleChange(event)}
                />
            </Wrapper>
            <Wrapper>
                <Label>שם משפחה</Label>  
                <TextField 
                    required 
                    name="lastName" 
                    value={lastName}
                    onChange={event => handleChange(event)}
                />
            </Wrapper>
            <Wrapper>
                <Label>כתובת דוא"ל</Label> 
                <TextField 
                    required 
                    name="email"  
                    value={email}
                    onChange={event => handleChange(event)}
                />
            </Wrapper>
            {newProfessional &&
                <Box>
                    <Wrapper>
                        <Label>מקצוע</Label> 
                        <TextField 
                            required 
                            name="profession"  
                            value={profession}
                            onChange={event => handleChange(event)}
                        />
                    </Wrapper>
                    <Wrapper>
                        <Label>התמחות</Label> 
                        <TextField 
                            required 
                            name="specialization"  
                            value={specialization}
                            onChange={event => handleChange(event)}
                        />
                    </Wrapper>
                </Box>
            }
            <Wrapper>
                <Label>הודעה</Label> 
                <TextField
                    placeholder="כתוב את הודעתך כאן..."
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name="message"
                    variant="filled"
                    value={message}
                    onChange={event => handleChange(event)}
                />
            </Wrapper>
        </div>
    );
};

export default TextInputs
