import React from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import moment from "moment";
import styled from "styled-components";

const CalendarWrapper = styled.div`
  width: auto;
  @media (max-width: 600px) {
    width: 275px;
  }
`;

const CalanderItem = ({ scheduleState, setScheduleState, disabled }) => {
  const { data } = scheduleState;
  const { current } = data;

  const extractHours = (formattedDate) => {
    const hoursObject = current.find(
      (date) =>
        typeof date === "object" &&
        Object.keys(date)[0] === formattedDate &&
        Object.values(date).length &&
        date
    );
    if (hoursObject) {
      const extractedHours = Object.values(hoursObject)[0];
      return extractedHours;
    } else {
      return [];
    }
  };

  const onDayClick = (clickedDay) => {
    //if disabled is empty,meaning there are no errors
    if (disabled === "") {
      // Formatting the pressed day to a relevant format
      const formattedDate = moment(clickedDay).format("DD-MM-YYYY");
      const day = formattedDate;
      const extractedHours = extractHours(formattedDate);
      const newDateObject = {
        day,
        hours: extractedHours,
      };
      const newDataObject = {
        ...data,
        date: newDateObject,
      };
      setScheduleState("data", newDataObject);
    }
  };

  return (
    <CalendarWrapper>
      <Calendar
        locale="he"
        onClickDay={(day) => onDayClick(day)}
        tileClassName={({ date, view }) => {
          // Getting all days from object
          const allDaysObjects = current.map(
            (dayObject) => Object.keys(dayObject)[0]
          );
          if (
            allDaysObjects.find(
              (requestedDayToMark) =>
                new Date(date).getTime() > new Date().getTime() &&
                requestedDayToMark === moment(date).format("DD-MM-YYYY")
            )
          ) {
            return "highlightCell";
          } else {
            return "regularCell";
          }
        }}
        minDate={new Date()}
      />
    </CalendarWrapper>
  );
};

export default CalanderItem;
