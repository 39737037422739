import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import ContactContent from './ContactContent';
import CustomAlert from '../../layout/CustomAlert';
import { Button, Box, CircularProgress } from '@material-ui/core';
import { genericEmail } from '../../../actions/FunctionsActions';
import { LEAD_BLUE } from '../../../utils/globals';

const ContactUsWrapper = styled(Container)`
    padding: 10px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.25), 
              0 2px 2px rgba(0,0,0,0.20), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.10),
              0 16px 16px rgba(0,0,0,0.05);
`;

const ButtonWrapper = styled(Box)`
    margin: 4px;
    font-family: 'Rubik', Sans-Serif;
    direction: rtl;
`;

const Address = styled.p`
    font-family: 'Rubik', Sans-Serif;
    direction: rtl;
    text-align: right;
`;

const ContactUs = () => {

    const [ timer, setTimer ] = useState(null);
    const [ alert, setAlert ] = useState({
        type: '',
        message: ''
    });
    const [ loading, setLoading ] = useState(false);
    const [ checkState, setCheckState ] = useState({
        service: false,
        clientSupport: false,
        professionalSupport: false,
        business: false,
        payment: false,
    });
    const [ contactState, setContactState ] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    });

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [timer])

    const handleChange = event => {
        const value = event.target.value;
        const name = event.target.name;
        setContactState({
            ...contactState,
            [name]: value
        });
    };

    const createFormToSend = () => {
        const filledForms = {};
        const checkedBox = Object.keys(checkState).filter(key => 
            checkState[key] !== false && key
        )
        const allKeys = Object.keys(contactState);
        for (let key = 0; key < allKeys.length; key ++) {
            if (contactState[allKeys[key]] !== '') {
                filledForms[allKeys[key]] = contactState[allKeys[key]];
            };
        };
        filledForms['option'] = checkedBox[0];
        return filledForms;
    };

    const sendForm = async () => {
        setLoading(true);
        const form = createFormToSend();
        const res = await genericEmail(form, 'sendContactEmail');
        setLoading(false);
        setContactState({
            firstName: '',
            lastName: '',
            email: '',
            message: '',
        });
        setAlert({
            type: res.type,
            message: res.message
        });
        const timerInstance = () => setTimeout(() => setAlert({type: '', message: ''}, 5000));
        const timerId = timerInstance();
        setTimer(timerId);
    };

    const handleCheckChange = event => {
        const name = event.target.name;
        setCheckState({
            service: false,
            clientSupport: false,
            professionalSupport: false,
            business: false,
            payment: false,
            [name]: true
        });
    };

    return (
        <ContactUsWrapper maxWidth="md">    
            <ContactContent 
                handleChange={handleChange}
                setCheckState={handleCheckChange}
                contactState={contactState}
                checkState={checkState}
                newProfessional={checkState.newProfessional}
            />
            <ButtonWrapper align="center">
                {
                    loading ? <CircularProgress /> :
                    alert.message ? 
                        <CustomAlert 
                            type={alert.type}
                            msg={alert.message}
                        /> :
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={sendForm}
                        style={{ color: LEAD_BLUE }}
                    >
                        שלח
                    </Button>
                }
            </ButtonWrapper>
            <Address>כתובתנו: יוניצמן 4, ת"א טלפון: 052-5490203 מייל info@open-mynd.com</Address>
        </ContactUsWrapper>
    );
};

export default ContactUs
