import { motion } from 'framer-motion';
import React from 'react'
import styled from 'styled-components';
import { DARK_BLUE } from '../../../../utils/globals';
import { routeQuery } from '../../../../utils/routes';
import { LinkWrapper } from '../AuthHelpers';

const MainWrapper = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    background: #e0e0e0;
  
    height: 220px;
  
`;

const Centerize = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    height: 50%;
`;

const OptionWrapper = styled(motion.div)`
  
`;

const OptionButton = styled(motion.Button)`
    
    border: 1px solid #FFF;
    width: 160px;
    height: 40px;
    border-radius: 10%;
    background-color: ${DARK_BLUE };
    color: white;
    border: none;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.25);
    &:hover{
      cursor: pointer;
    }
`;

const Option = ({ to, text, direction }) => {
    return (
        <OptionWrapper>
            <LinkWrapper to={routeQuery({ box: to })}>
                <OptionButton
                    initial={direction === 'right' ? { x: 0 } : { y: 0 }}
                    style={{
                        marginBottom:'30px'
                    }}
                  /*  animate={direction === 'right' ? { x: 0 } : { y: 0 }}
                    transition={{ duration: 1.5 }}*/
                >
                    {text}
                </OptionButton>
            </LinkWrapper>
        </OptionWrapper>
    );
};

const RegisterBoiler = () => {
    return (
        <MainWrapper>
            <Centerize>
                <Option 
                    to="pro_registeration" 
                    text="הרשמת מטפלים"
                />
                <Option 
                    to="user_registeration" 
                    text="הרשמת לקוחות"
                    direction="right"
                />
            </Centerize>
        </MainWrapper>
    )
}

export default RegisterBoiler
