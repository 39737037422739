import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import styled from 'styled-components';
import { Typography, Box } from '@material-ui/core';
import { routeQuery } from '../../utils/routes';

const StyledMenu = withStyles({
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const Label = styled(Typography)`
  direction: rtl;
  font-family: 'Rubik', Sens-Serif;
  margin-left: 10px;
`;

const LabelWrapper = styled(Box)` 
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const MessagesMenu = ({ anchorEl, handleClose }) => {

  const onIconClick = () => {
    handleClose();
  };

  return (
    <div>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <LinkWrapper
          
          to={routeQuery({ box: 'messages-inbox' })}
          replace
        >
          <LabelWrapper onClick={onIconClick}>
              <InboxIcon fontSize="small"/>
            <Label>הודעות</Label>
          </LabelWrapper>
        </LinkWrapper>

        <LinkWrapper
          to={routeQuery({ box: 'messages-write' })}
          replace
        >
          <LabelWrapper onClick={onIconClick}>
            <SendIcon fontSize="small"/>
            <Label>שלח הודעה</Label>
          </LabelWrapper>
        </LinkWrapper>

      </StyledMenu>
    </div>
  );
}

export default MessagesMenu