import React from 'react';
import styled from 'styled-components';
import { Popover, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '200px',
  },
}));

const Content = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const Text = styled(Typography)`
    direction: rtl;
    font-family: 'Rubik', sans-serif;
`;

const PopOver = ({ text, handleClose, open, anchorEl }) => {
    const classes = useStyles();
    return (
        <div>
            <Popover
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClose}
                disableRestoreFocus
            >   
                <Content>
                    <Text>
                        {text}
                    </Text>
                </Content>
            </Popover>
        </div>
    );
};

export default PopOver
