import {
    OPEN_AUTH,
    CLOSE_AUTH,
    OPEN_PROFESSIONAL,
    CLOSE_PROFESSIONAL,
    OPEN_VIDEO,
    CLOSE_VIDEO,
    OPEN_PROFESSIONAL_ZONE,
    CLOSE_PROFESSIONAL_ZONE,
    OPEN_RESET,
    CLOSE_RESET,
    OPEN_POLICIES,
    CLOSE_POLICIES,
    OPEN_QUIZ,
    CLOSE_QUIZ,
    OPEN_MESSAGES,
    CLOSE_MESSAGES,
    OPEN_OPTIONS,
    CLOSE_OPTIONS,
    OPEN_CONFIRMATION,
    CLOSE_CONFIRMATION,
    OPEN_ADMIN_EDIT,
    CLOSE_ADMIN_EDIT,
} from '../actions/types';

const initalState = {
    authDialog: false,
    professionalDialog: false,
    videoDialog: false,
    professionalZonelDialog: false,
    resetDialog: false,
    policiesDialog: false,
    quizDialog: false,
    messagesDialog: false,
    adminEditDialog:false,
    optionsDialog: {},
    confirmationDialog: {},
};


export const dialogReducer = (state = initalState, action) => {
    switch (action.type) {

        // Opening auth dialog
        case OPEN_AUTH:
            return {
                ...state,
                authDialog: true
            };

        // Opening professional's dialog
        case OPEN_PROFESSIONAL:
            return {
                ...state,
                professionalDialog: true
            };

        // Opening video's dialog
        case OPEN_VIDEO:
            return {
                ...state,
                videoDialog: true
            };

        // Opening professional zone's dialog
        case OPEN_PROFESSIONAL_ZONE:
            return {
                ...state,
                professionalZonelDialog: true
            };

        // Opening professional zone's dialog
        case OPEN_RESET:
            return {
                ...state,
                resetDialog: true
            };

        // Opening policies' dialog
        case OPEN_POLICIES: 
            return {
                ...state,
                policiesDialog: true
            };

        // Opening quiz's dialog
        case OPEN_QUIZ: 
            return {
                ...state,
                quizDialog: true
            };

        // Opening messages' dialog
        case OPEN_MESSAGES: 
            return {
                ...state,
                messagesDialog: true
            };

        // Opening option's dialog
        case OPEN_OPTIONS: 
            return {
                ...state,
                optionsDialog: action.payload
            };

        // Opening confirmation's dialog
        case OPEN_CONFIRMATION: 
            return {
                ...state,
                confirmationDialog: action.payload
            };

        // Closing auth dialog
        case CLOSE_AUTH:
            return {
                ...state,
                authDialog: false
            };

        // Closing professional's dialog
        case CLOSE_PROFESSIONAL:
            return {
                ...state,
                professionalDialog: false
            };

        // Closing video's dialog
        case CLOSE_VIDEO:
            return {
                ...state,
                videoDialog: false
            };

        // Close professional zone's dialog
        case CLOSE_PROFESSIONAL_ZONE:
            return {
                ...state,
                professionalZonelDialog: false
            };

        // Close reset password's dialog
        case CLOSE_RESET:
            return {
                ...state,
                resetDialog: false
            };
            
        // Close policies' dialog
        case CLOSE_POLICIES:
            return {
                ...state,
                policiesDialog: false
            };

        // Close policies' dialog
        case CLOSE_QUIZ:
            return {
                ...state,
                quizDialog: false
            };
            
        // Close messages' dialog
        case CLOSE_MESSAGES: 
            return {
                ...state,
                messagesDialog: false
            };
            
        // Close options' dialog
        case CLOSE_OPTIONS: 
            return {
                ...state,
                optionsDialog: {}
            };
            
        // Close confirmation's dialog
        case CLOSE_CONFIRMATION: 
            return {
                ...state,
                confirmationDialog: {}
            };
              // Opening auth dialog
        case OPEN_ADMIN_EDIT:
            return {
                ...state,
                adminEditDialog: true
            };
            case CLOSE_ADMIN_EDIT:
                return {
                    ...state,
                    adminEditDialog: false
                };

        default:
            return state;

    };
};

export default dialogReducer;