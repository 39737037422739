import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import {useSelector} from "react-redux";
import SettingsBar from "../../professionalZone/calander/SettingsBar";


const Wrapper = styled(Box)`
    direction: rtl;
    min-width: 100%;
    min-height: 100%;
    font-family: 'Rubik', Sens-Serif;
   
    align-content: center;
`;

const Paragraph = styled.div`
    display: flex;
    justify-content: center;
    margin: 15px;
`;

const SummaryWrapper = styled.div`
  direction: rtl; 
`;


const Confirmation = ({
                          onDialogClose, resetStepper,
                          day, hour, proFirstName, proLastName,
                          userHasTokens,
                          setAppointment, sendConfirmationEmails, prefix
                      }) => {

    const {user} = useSelector(state => state.auth)
    const setMeetingUpdateTokens = async () => {
        await setAppointment()
    }

    useEffect(() => {
        //If the user has tokens , this is where we set up the appointment
        if (userHasTokens) {
            setMeetingUpdateTokens()
                .then(() => sendConfirmationEmails(user.email))
                .catch((e) => console.log(e))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeProfessionalModal = () => {
        onDialogClose();
        resetStepper();
    };


    //Client has used his tokens
    if (userHasTokens) {
        return (
            <Wrapper align='center'>
                <SettingsBar
                    onBackButton={closeProfessionalModal}
                    label="סיכום"/>
                <SummaryWrapper>
                    <Paragraph>המפגש נקבע בהצלחה!</Paragraph>
                    <Paragraph>קישור למפגש עם {prefix.prefix} {proFirstName} {proLastName}</Paragraph>
                    <Paragraph>בתאריך: {day} בשעה: {hour.start}</Paragraph>
                    <Paragraph> מופיע תחת ״מפגשים״ באזור האישי שלך. </Paragraph>
                </SummaryWrapper>
            </Wrapper>
        )
    }
    //purchase meeting
    else {
        return (
            <Wrapper align='center'>
                <SettingsBar
                    onBackButton={closeProfessionalModal}
                    label="סיכום"/>
                <SummaryWrapper>
                    <Paragraph>המפגש נקבע בהצלחה!</Paragraph>
                    <Paragraph>קישור למפגש עם {prefix.prefix} {proFirstName} {proLastName}</Paragraph>
                    <Paragraph>בתאריך: {day} בשעה: {hour.start}</Paragraph>
                    <Paragraph> מופיע תחת ״מפגשים״ באזור האישי שלך. </Paragraph>
                </SummaryWrapper>
                {/* <Paragraph>העסקה בוצעה בהצלחה!</Paragraph> Talea */}
            </Wrapper>
        );
    }
}

export default Confirmation
