import React, {useState} from 'react'
import {Menu, MenuItem, TableCell} from '@material-ui/core';
import {openOptionsDialog} from '../../../../actions/DialogActions';
import {CANCEL, LEAD_BLUE, MODIFY} from '../../../../utils/globals';
import {useDispatch, useSelector} from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import styled from 'styled-components';
import {hasTimeNotPassed} from './tableUtils';
import PopOver from '../../settings/settingsForm/PopOver';

const Edit = styled(EditIcon)`
  cursor: pointer;
  color: ${LEAD_BLUE};
`;

const MenuInsatnce = ({
                          onClick, type, index, meetingDate, timeAllowed,
                          text, timeFunction, handlePopoverOpen, popupText,
                          handlePopoverClose, openPopover, whichPopup
                      }) => {
    const isOptionDisabled =
        timeFunction(meetingDate.day, meetingDate.hour, timeAllowed);


    return (
        <div
            onMouseEnter={event => handlePopoverOpen(event, type)}
            onMouseLeave={handlePopoverClose}
        >
            <MenuItem
                onClick={() => {
                    onClick(type, index);
                }}
                disabled={isOptionDisabled}
            >
                {text}
            </MenuItem>
            {
                isOptionDisabled && openPopover && whichPopup === type &&
                <PopOver
                    text={popupText}
                    handleClose={handlePopoverClose}
                    open={Boolean(openPopover)}
                    anchorEl={openPopover}
                />
            }
        </div>
    );
};

const RenderOptionsMenu = ({
                               anchorEl, handleClose, index, meetingDate,
                               craftDate, handlePopoverOpen, handlePopoverClose,
                               openPopover, whichPopup, isProfessional
                           }) => {

    return (
        <Menu
            style={{direction: 'rtl'}}
            anchorEl={anchorEl}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuInsatnce
                onClick={handleClose}
                timeFunction={craftDate}
                text="שינוי"
                meetingDate={meetingDate}
                timeAllowed={86400000}
                type={MODIFY}
                index={index}
                whichPopup={whichPopup}
                handlePopoverOpen={handlePopoverOpen}
                handlePopoverClose={handlePopoverClose}
                openPopover={openPopover}
                popupText="לא ניתן לשנות מפגש בפחות מ- 24 שעות לפני מועד המפגש. נא לפנות לשירות לקוחות לעזרה במקרים דחופים"
            />
            {isProfessional ? null :
                <MenuInsatnce
                    onClick={handleClose}
                    timeFunction={craftDate}
                    text="ביטול"
                    meetingDate={meetingDate}
                    timeAllowed={172800000}
                    type={CANCEL}
                    whichPopup={whichPopup}
                    index={index}
                    handlePopoverOpen={handlePopoverOpen}
                    handlePopoverClose={handlePopoverClose}
                    openPopover={openPopover}
                    popupText="לא ניתן לבטל מפגש פחות מ- 48 שעות לפני המועד. במקרים דחופים, ניתן לפנות לשירות הלקוחות לקבלת עזרה."
                />
            }

        </Menu>
    )
};

const OptionsCell = ({meetingDay, index, meetingDate}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [popAnchor, setPopAnchor] = useState(null);
    const [whichPopup, setWhichPopup] = useState(null);
    const {isProfessional} = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const onMenuClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = (toRender, index) => {

        setAnchorEl(null);
        if (toRender === CANCEL || toRender === MODIFY) {
            const optionsDialogObject = {
                show: true,
                meetingObject: meetingDay[index],
                screenToRender: toRender
            }
            dispatch(openOptionsDialog(optionsDialogObject));
        }
    };

    const handlePopoverOpen = (event, popupType) => {
        setPopAnchor(event.currentTarget);
        setWhichPopup(popupType);
    };

    const handlePopoverClose = () => {
        setPopAnchor(null);
        setWhichPopup(null);
    };

    return (
        <>
            {
                hasTimeNotPassed(meetingDate.day, meetingDate.hour, 0) ?
                    <TableCell></TableCell> :
                    <TableCell align="center">
                        <Edit
                            size="small"
                            onClick={onMenuClick}
                            isopen={Boolean(anchorEl) ? "true" : undefined}
                        />
                        {
                            Boolean(anchorEl) &&
                            <RenderOptionsMenu
                                isProfessional={isProfessional}
                                handleClose={onMenuClose}
                                index={index}
                                anchorEl={anchorEl}
                                meetingDate={meetingDate}
                                craftDate={hasTimeNotPassed}
                                handlePopoverOpen={handlePopoverOpen}
                                handlePopoverClose={handlePopoverClose}
                                openPopover={popAnchor}
                                whichPopup={whichPopup}
                            />
                        }
                    </TableCell>
            }
        </>
    )
}

export default OptionsCell
