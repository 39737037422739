import React from 'react';
import {Wrapper, ButtonsWrapper, ButtonStyle} from '../PaymentBoiler';
import CustomAlert from '../../../layout/CustomAlert';
import Review from '../Review';
import Spinner from '../../../layout/Spinner';

const ModifyMeetingPayment = ({
                                  isProfessional, proFirstName, proLastName,
                                  firstName, lastName, modifyAnExistingMeeting,
                                  onModifyClick, prevStep, error, day, hour, loading, oldMeetingObject
                              }) => {


    const modify = async () => {
        await onModifyClick(oldMeetingObject);
    }


    return (
        <Wrapper>
            <Review
                day={day}
                hour={hour}
                firstName={isProfessional ? proFirstName : firstName}
                lastName={isProfessional ? proLastName : lastName}
                modifyAnExistingMeeting={modifyAnExistingMeeting}
            />
            <ButtonsWrapper>
                <ButtonStyle
                    variant='contained'
                    onClick={() => modify()}
                >
                    אשר
                </ButtonStyle>
                <ButtonStyle
                    variant='contained'
                    onClick={prevStep}
                >
                    הקודם
                </ButtonStyle>
            </ButtonsWrapper>

            {
                loading ? <Spinner/> : !!error.type &&
                    <CustomAlert
                        type={error.type}
                        msg={error.message}
                    />
            }
        </Wrapper>
);
};

const ModifyMeeting = (
    {
        isProfessional, proFirstName, proLastName,
            firstName, lastName, modifyAnExistingMeeting,
            onModifyClick, prevStep, error, day, hour, loading, oldMeetingObject
    }
) =>
    {

        return (
            <ModifyMeetingPayment
                isProfessional={isProfessional}
                proFirstName={proFirstName}
                proLastName={proLastName}
                firstName={firstName}
                lastName={lastName}
                modifyAnExistingMeeting={modifyAnExistingMeeting}
                onModifyClick={onModifyClick}
                prevStep={prevStep}
                error={error}
                day={day}
                hour={hour}
                loading={loading}
                oldMeetingObject={oldMeetingObject}
            />
        )
    }

export default ModifyMeeting
