import React from 'react';
import styled from 'styled-components';
import {Link, Typography} from '@material-ui/core';
import { Box } from '@material-ui/core';
import {openDialog} from "../../../../actions/DialogActions";
import {OPEN_QUIZ_DIALOG} from "../../../../utils/globals";
import {useDispatch} from 'react-redux';
const MainWrapper = styled(Box)`
    direction: rtl;
    font-family: 'Rubik', sans-serif;
`;

const Bold = styled(Typography)`
    font-weight: 700;
    display: inline;
`;
const LinkWrapper = styled(Link)`
    text-decoration: none;
    cursor: pointer;
`;

const Paragraph = styled(Box)`
    margin-top: 10px;
`;

const AvailableTodayDescription = () => {
    const dispatch = useDispatch();
    return (

        <MainWrapper align="right">
            <Paragraph>
                לכל אחד מהמטפלים שלנו ידע רב וניסיון מעשי משמעותי בתחום עיסוקו.
            </Paragraph>

            <Paragraph>
                 ניתן לבחור כל אחד מהמטפלים או לחילופין, לבקש את עזרתנו על ידי מילוי שאלון קצר ואנחנו נתאים מטפל.
                 <LinkWrapper onClick={() => {
                    dispatch(openDialog(OPEN_QUIZ_DIALOG))
                    }}>
                     {" "} <Bold>לחץ כאן</Bold>
                 </LinkWrapper>
                {' '}למילוי השאלון.




            </Paragraph>

            <Paragraph>
                אנו ממליצים לך ללמוד על הרקע של כל מטפל ולקרוא חוות דעת בעמוד המטפל לפני ההתקשרות.
            </Paragraph>

        </MainWrapper>

    );
};

export default AvailableTodayDescription
