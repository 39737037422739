import {
    OPEN_PRIVACY,
    OPEN_TERMS,
    OPEN_CONTENT,
} from '../actions/types';

const initalState = {
    current: ''
};


export const policiesReducer = (state = initalState, action) => {

    switch (action.type) {

        case OPEN_PRIVACY: 
        case OPEN_TERMS:
        case OPEN_CONTENT:
            return {
                current: action.payload
            }; 
            
        default:
            return state;

    }
}