import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {bodyRequestMaker, getURL} from '../../paymentHelpers';
import SettingsBar from '../../../../professionalZone/calander/SettingsBar';
import Iframe from 'react-iframe'
import Button from "@material-ui/core/Button";
import {Box} from '@material-ui/core'
import Spinner from "../../../../layout/Spinner";
import {getUserToken} from "../../../../../actions/TokensActions";
import {useDispatch} from "react-redux";
import { LEAD_BLUE, RED } from '../../../../../utils/globals';
import {addNewDoucment} from "../../../../../actions/FirestoreActions";

const MainWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  @media (max-width: 450px) {
    padding: 0;
  }
`;

const BlockWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-gap: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

const IframeStyle = styled.div`
  min-width: 350px;
  min-height: 400px;
  margin-top: -50px;
  width: 100%;
  height: 100%;

  max-width: 500px;
  max-height: 750px;
`;

const Text = styled.p`
  text-align: right;
  margin: 0;
  padding: 8px;
  color: ${props => props.color && props.color};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  margin: 10px;
`;

const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  padding: 10px;
`;

const ButtonStyle = styled(Button)`
  padding: 10px;
  margin: 10px;
  color: white;
`;

const Paragraph = styled.div`
    display: flex;
    justify-content: center;
    margin: 15px;
`;

const SummaryWrapper = styled.div`
  direction: rtl;
`;

const ReviewButton = ({ onClick, label}) => {
    return (
        <Button
            style={{backgroundColor: LEAD_BLUE, color: 'white', 
                    position: "relative",right: "5px", marginLeft: "10px"}}
            //style={{backgroundColor: LEAD_BLUE, opacity: 0.5, color: 'white'}}
            onClick={onClick}
        >
            {label}
        </Button>
    );
};
const ReviewButtont1 = ({ onClick, label}) => {
    return (
        <Button
            style={{backgroundColor: RED, color: 'black',
                    position: "relative",left: "5px", marginRight: "10px"}}
            //style={{backgroundColor: LEAD_BLUE, opacity: 0.5, color: 'white'}}
            onClick={onClick}
        >
            {label}
        </Button>
    );
};

const FormReview = ({onExit,price}) => {
    return (
        <BlockWrapper>
            <SettingsBar label={price.finalAMT != 0 ? "פרטי תשלום" : "מאשר/ת"} onExit={onExit}/>
        </BlockWrapper>
    );
};

const FormReviewt1 = ({onExit}) => {
    return (
        <BlockWrapper>
            <SettingsBar label="  מ א ש ר \ ת"  onExit={onExit}/>
        </BlockWrapper>
    );
};

// const Forms = ({
//                    ongoingPayment, onPayClick, email,
//                    fullName, phone, price
//                }) => {
//
//     return (
//         <BlockWrapper>
//             <Formik
//                 initialValues={
//                     {
//                         fullName,
//                         email,
//                         phone,
//                         paymentsAmount: 1,
//                         ccNumber: '',
//                         ccYear: '',
//                         ccMonth: '',
//                         idNumber: '',
//                         ccv: '',
//                         readPolicy: false,
//                         actionType: 4,
//                         price: `${(price.jinizPrice * 3) + (price.jinizPrice * 3 * 0.17)}`,
//                     }
//                 }
//                 validate={values => {
//                     const errors = {};
//                     required(errors, values);
//                     checkboxValidation(errors, values.readPolicy)
//                     phoneValidation(errors, values.phone);
//                     return errors;
//                 }}
//
//                 onSubmit={data => onPayClick(data)}
//             >
//                 {errors => (
//                     <Form>
//                         <ClientDetails/>
//                         <PaymentMethod/>
//                         <CardDetails checkboxErr={errors.errors.readPolicy}/>
//                         <SubmitWrapper>
//                             <SubmitButton name="submit" text="שלם" disabled={ongoingPayment}/>
//                         </SubmitWrapper>
//                     </Form>
//                 )}
//             </Formik>
//         </BlockWrapper>
//     );
// };

const calFullPrice = (meetingAmount, price) => {
    if (meetingAmount)
        return (price.jinizPrice * 3)// +(price.jinizPrice * 3 * 0.17)
    else
        return (price.normalPrice - (price.normalPrice * 0.1))// + (price.jinizPrice * 1 * 0.17)
}

const WaitForIframe = () => {
    return (
        <>
            <Spinner/>
            <Text>מתחבר לתשלום מאובטח....</Text>
        </>
    )
}

/**
 * I frame component , still needs work and restructure of code
 * @param price
 * @param email
 * @param fullName
 * @param phone
 * @param type
 * @param userID
 * @param quantity
 * @returns {JSX.Element}
 * @constructor
 */
const FrameLink = ({
                    price, email, fullName, phone, userID, meetingAmount, handleNext, onPayClick,
                    sendConfirmationEmails, handleBack
                   }) =>
{

    const [sessionURL, SetSessionURL] = useState(null);
    const [count, setCount] = useState(null);
    const [iFrameLoading, setIframeLoading] = useState(false) //Talea true
    const fullPrice = calFullPrice(meetingAmount, price) 
    const cartObject = (bodyRequestMaker({fullPrice, email, fullName, phone, userID, meetingAmount}))
    const dispatch = useDispatch();

    const setMeeting = async (data) => {
        await onPayClick(data);
        await dispatch(getUserToken(userID))
    }
  
    useEffect(() => {
        getURL({cartObject})
            .then(res => ((SetSessionURL(res.Data.SessionUrl))))
            .finally(() => {
                setIframeLoading(false);
                //setting count to zero after we got the URL callback
                setCount(0)
                //After that the iframe will load and set the count to 1.
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
    * After we got the URL callback we set the count to 0.
    * Then the iframe is loading the url which means the count is now 1.
    * If the count is larger than 0 it means we're done with the payment.
    * @returns {Promise<void>}
    */
    const reDirect = async () => {
        setCount(count + 1)
        //if the count is larger then one,book the meeting.
        if (count > 0) {
            await setMeeting()
                .then(() => sendConfirmationEmails(email))
                .catch((e) => console.log(e))
                .finally(() => { handleNext(); });
        }
    }

    const reDirect1 = async () => {
        //const res = await addNewDoucment("usercoupons",userID,{balance: 2, status: "acitve"});
        //if (res.type === 'success') { alert("vvvv") }
        //else {alert("xxxxx")};
        await setMeeting()
            .then(() => sendConfirmationEmails(email))
            .catch((e) => console.log(e))
            .finally(() => {handleNext();});
    };

    const [isDisabled, setIsDisabled] = useState(false);
    const disableButton = () => {
        setIsDisabled(true);
        reDirect1();
    };
    
    if (price.finalAMT != 0) {
    return (
        <> 
            {(iFrameLoading) ? <WaitForIframe/> :
            <>
                <Iframe src={sessionURL}
                        width='350px'
                        height='550px'
                        styles={IframeStyle}
                        url={sessionURL}
                        onLoad={reDirect}
                        scrolling='no'
                />
                <Button onClick={reDirect}>
                    Click for test {count}
                </Button>
                {/*    dev only*/}
            </> 
            }
        </>       
    )
        }
        else {
            return ( 
                <> 
                {(iFrameLoading) ? <WaitForIframe/> :
                    <> 
                        <ButtonsWrapper>
                            <ButtonStyle
                                 variant="contained"
                                 disabled={isDisabled}
                                 onClick={disableButton}
                                 style={{backgroundColor: LEAD_BLUE}}
                            >
                             כן
                            </ButtonStyle>
                            <ButtonStyle
                                variant="contained"
                                onClick={handleBack}
                                style={{backgroundColor: RED}}
                            >
                            לא
                            </ButtonStyle>
                        </ButtonsWrapper>
                    </> 
                }
            </>  
            )
        }
};

const FrameLinkt1 = ({
    price, email, fullName, phone, userID, meetingAmount, handleNext, onPayClick,
    sendConfirmationEmails, handleBack
    }) => {

    const [sessionURL, SetSessionURL] = useState(null);
    const [count, setCount] = useState(null);
    const [iFrameLoading, setIframeLoading] = useState(true)
    const fullPrice = calFullPrice(meetingAmount, price) 
    const cartObject = (bodyRequestMaker({fullPrice, email, fullName, phone, userID, meetingAmount}))
    const dispatch = useDispatch();

    const setMeeting = async (data) => {
        await onPayClick(data);
        await dispatch(getUserToken(userID))
    }

    // After we got the URL callback we set the count to 0.
    // Then the iframe is loading the url which means the count is now 1.
    // If the count is larger than 0 it means we're done with the payment.
    // @returns {Promise<void>}
    const reDirect1 = async () => {
        await setMeeting()
            .then(() => sendConfirmationEmails(email))
            .catch((e) => console.log(e))
            .finally(() => {handleNext();});
    };

    return ( 
        <>  
            <>
            <ButtonWrapper>
                <ReviewButtont1
                    onClick={reDirect1}
                    label="כן"
                />
                <ReviewButtont1
                    onClick={handleBack}
                    label="לא"
                />
                {/    dev only/} 
            </ButtonWrapper>
            </> 
        </> 
    )
};

const PaymentForm = ({
                         type,
                         price,
                         ongoingPayment,
                         onPayClick,
                         email,
                         fullName,
                         phone,
                         handleBack,
                         handleNext,
                         userID,
                         quantity,
                         history,
                         resetStepper,
                         meetingAmount,
                         sendConfirmationEmails
                     }) => {


    return (
        <MainWrapper>
            <FormReview
                type={''}
                price={price}
                onExit={handleBack}
            />
            <FrameLink
                email={email}
                handleNext={handleNext}
                fullName={fullName}
                phone={phone}
                price={price}
                type={type}
                userID={userID}
                quantity={quantity}
                onPayClick={onPayClick}
                ongoingPayment={ongoingPayment}
                history={history}
                resetStepper={resetStepper}
                meetingAmount={meetingAmount}
                sendConfirmationEmails={sendConfirmationEmails}
                handleBack={handleBack} //Talea S
            />
            {/*
            <FormReviewt1
                type={''}
                price={price}
                onExit={handleBack}
            />
            <FrameLinkt1
                email={email}
                handleNext={handleNext}
                fullName={fullName}
                phone={phone}
                price={price}
                type={type}
                userID={userID}
                quantity={quantity}
                onPayClick={onPayClick}
                ongoingPayment={ongoingPayment}
                history={history}
                resetStepper={resetStepper}
                meetingAmount={meetingAmount}
                sendConfirmationEmails={sendConfirmationEmails}
                handleBack={handleBack}
            />
            */}
        </MainWrapper>
    )
}

export default PaymentForm;
