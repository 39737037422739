import React from 'react';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { TableButton } from '../../../../modals/ProfessionalZone';

const SearchBarWrapper = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CloseIconColor = styled(CloseIcon)`
    font-size: 1.5rem;
`;

const Label = styled(Typography)`
    direction: rtl;
    font-size: 14px;
    font-family: 'Rubik', Sens-Serif;
    padding: 5px;
    margin: 5px;
`;

const SearchBar = ({ onChange, open, setSearchBar }) => {

    return (

        <Box>

            {!open &&
                <TableButton
                    onClick={() => setSearchBar(true)}
                    whileHover={{ scale: 1.1 }}
                >
                    <SearchIcon />
                </TableButton>
            }

            {open && 
                <SearchBarWrapper>
                    <Label>חפש...</Label>
                    <TextField 
                        onChange={event => onChange(event)}
                    />
                    <Button 
                        onClick={() => setSearchBar(false)}
                    >
                        <CloseIconColor />
                    </Button>
                </SearchBarWrapper>
            }

        </Box>
    )
};

export default SearchBar
