import React from 'react';
import { TableContainer, Table, TableRow, 
    TableCell, TableBody } from '@material-ui/core';
import TableHeader from './TableHeader';
import TableContent from './TableContent';
import {INFO} from "../../../../utils/globals";

const EmptyRows = ({ emptyRows }) => {
    return (
        <TableBody>
            {emptyRows > 0 && 
                <TableRow style={{ height: 96 * emptyRows }}>
                    <TableCell colSpan={12}/>
                </TableRow>
            }
        </TableBody>
    );   
};

const TableData = ({ myMeetings, rowsPerPage, page, 
    resetCollapse, setResetCollapse, isProfessional, requestedTable }) => {
    return (
        <>
            {
                myMeetings.filtered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((meeting, index) => 
                        <TableContent 
                            key={index}
                            index={index}
                            meeting={meeting}
                            meetingsForDay={meeting.length}
                            collapseState={resetCollapse}
                            setCollapseState={setResetCollapse}
                            isProfessional={isProfessional}
                            requestedTable={requestedTable}
                        />
                )
            }
        </>
    );
};

export const DaysTable = ({ headers, myMeetings, page, rowsPerPage, id, 
    resetCollapse, setResetCollapse, emptyRows, isProfessional, requestedTable }) => {
    return ( 
        <TableContainer>
            <Table>
                {isProfessional && requestedTable===INFO ?  <TableHeader headers={headers} /> : null }
                <TableData 
                    myMeetings={myMeetings}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    id={id}
                    resetCollapse={resetCollapse}
                    setResetCollapse={setResetCollapse}
                    isProfessional={isProfessional}
                    requestedTable={requestedTable}
                />
                <EmptyRows emptyRows={emptyRows} />
            </Table>
        </TableContainer>
    );
};