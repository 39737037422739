import _ from 'lodash';
// modal are queries that cannot live with each other at the same time
// actions are modals that can live on top of a modal
const queryTypes = {
    "modal": [
        "privatePage",
        "expert",
        "box"
    ],

}

const removeSameTypeQuery = (query, key) => {
    const sameTypeKeys = Object.values(queryTypes).find(type => type.includes(key)) ?? [];
    for (const differentKey of sameTypeKeys) {
        if (differentKey === key) continue;
        query.delete(differentKey);
    }
}

const findCategory = (key) => {
    for (const categoryKey of Object.keys(queryTypes)) {
        const sameTypeKeys = Object.values(queryTypes).find(type => type.includes(key));
        if (sameTypeKeys) return categoryKey;
    }
}

export const dedupQuery = () => {
    const currentQuery = new URLSearchParams(window.location.search);
    const chosenKeys = _.uniqBy(Array.from(currentQuery), ([key, ]) => findCategory(key) ?? key);
    const newQuery = new URLSearchParams();
    for (const [key, value] of chosenKeys) {
        newQuery.set(key, value);
    } 
    return `?${newQuery.toString()}`;
}


// js -
export const routeQuery = (replaceParams={}) => {

    const currentQuery = new URLSearchParams(window.location.search);
    for (const [key, value] of Object.entries(replaceParams)) {
        removeSameTypeQuery(currentQuery, key);
        if (value === undefined) {
            currentQuery.delete(key);
        } else {
            currentQuery.set(key, value);
        }
    }
    return `?${currentQuery.toString()}`
}
